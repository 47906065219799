// import "./App.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { GetSessionUpdatedDataApi } from "./apis/sessionUpdate";
import { sessionOutPayload } from "./components/utils/sessionOutHandler";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UserLogin } from "./apis/sessionOut";
import { handleLoginSubmit } from "./pages/login/loginHandler";
import Notification from "./components/Notification";
import Backdrop from "@mui/material/Backdrop";
import { persistor } from "./redux/store";
import { useSelector } from "react-redux";

function SessionOut() {
  const { data, mutateAsync: mutateLoginAsync, isLoading } = UserLogin();
  const { mutateAsync } = GetSessionUpdatedDataApi();
  const token = sessionStorage.getItem("token");
  // const token = useSelector((state)=>state.loginToken);
  // const details = JSON.parse(sessionStorage.getItem("details")) || {};
  const loginDetails = useSelector((state) => state.loginDetails);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [countdown, setCountdown] = useState(60); // 1-minute countdown
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [attempt, setAttempt] = useState(0);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const idleTimerRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  // const IDLE_TIMEOUT = 120000; // 2 minutes in milliseconds
  const IDLE_TIMEOUT = process.env.REACT_APP_SESSION_IDLE_TIMEOUT;
  const WARNING_DURATION = process.env.REACT_APP_WARNING_DURATION;

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  useEffect(() => {
    // debugger;
    if (data && data?.status?.code !== 200) {
      setAttempt(attempt + 1);

      if (attempt + 1 >= process.env.REACT_APP_SESSION_ATTEMPTS) {
        expireSession();
      }

      if (data && data?.status?.code == 500) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error`,
          type: "error",
        });
      } else if (data && data?.status?.code == undefined) {
        setNotify({
          isOpen: true,
          message: `Internal Server Error. please contact support team`,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      }
    } else if (data && data?.status?.code === 200) {
      setAttempt(0);
      setOpenDialog(false);
      clearInterval(countdownIntervalRef.current);
      resetIdleTimer();
    }
  }, [data]);

  const boxStyles = {
    display: "flex",
    alignItems: "center",
    gap: 2,
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
  };

  const expireSession = async () => {
    await updateSessionData(mutateAsync);
    sessionStorage.clear();
    persistor.purge();
    setSessionExpired(true);
    setOpenDialog(false);
    sessionStorage.setItem(
      "sessionExpiredMessage",
      "Your session expired due to inactivity."
    );
    window.location.href = "/login";
  };

  const showWarning = () => {
    setOpenDialog(true);
    setCountdown(WARNING_DURATION); // Assuming WARNING_DURATION is in seconds
    setPassword("");

    // Clear any existing interval to avoid overlap
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownIntervalRef.current);
          expireSession();
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Runs every second (1000 milliseconds)
  };

  const resetIdleTimer = () => {
    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }

    if (!openDialog) {
      idleTimerRef.current = setTimeout(() => {
        showWarning();
      }, IDLE_TIMEOUT);
    }
  };
  // utils/sessionUtils.js

  const updateSessionData = async (mutateAsync) => {
    const requestPayload = sessionOutPayload(details);
    try {
      const response = await mutateAsync(requestPayload);
      return response;
    } catch (error) {
      console.error("Error updating session:", error);
      throw error;
    }
  };

  async function logOut() {
    expireSession();
  }

  async function stayLoggedIn() {
    await handleLoginSubmit(
      mutateLoginAsync,
      details.companyCode,
      details.userName,
      password,
      "S",
      "N",
      () => {}
    );

    // await updateSessionData(mutateAsync);
    // setOpenDialog(false);
    // clearInterval(countdownIntervalRef.current);
    // resetIdleTimer();
  }

  async function handleLogout() {
    await updateSessionData(mutateAsync);
    expireSession();
  }

  useEffect(() => {
    if (token) {
      resetIdleTimer();

      window.addEventListener("mousemove", resetIdleTimer);
      window.addEventListener("keypress", resetIdleTimer);
      window.addEventListener("click", resetIdleTimer);

      return () => {
        if (idleTimerRef.current) {
          clearTimeout(idleTimerRef.current);
        }
        if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
        }
        window.removeEventListener("mousemove", resetIdleTimer);
        window.removeEventListener("keypress", resetIdleTimer);
        window.removeEventListener("click", resetIdleTimer);
      };
    }
  }, [token]);

  return (
    <>
      <div className="session-out">
        <Dialog
          open={openDialog}
          className="session-warning-modal"
          maxWidth="xl"
        >
          <div className="p-[10px]">
            <DialogTitle>Session Expiration Warning</DialogTitle>
            <DialogContent sx={{ fontSize: "12px" }}>
              <Typography>
                Your session is about to expire in {countdown} second
                {countdown !== 1 ? "s" : ""}.
              </Typography>
              <Typography sx={{ marginTop: "10px" }}>
                Please enter your password to stay logged in (OR) log out
              </Typography>
            </DialogContent>
            <DialogActions>
              <Box sx={boxStyles} className="!pb-0">
                <FormControl variant="outlined" style={{ flexGrow: 1 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    autoComplete="off"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        stayLoggedIn();
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </DialogActions>
            <DialogActions>
              <Box sx={boxStyles} className="justify-end">
                <Button
                  className="button-styles btnHeight"
                  variant="outlined"
                  color="primary"
                  onClick={stayLoggedIn}
                  disabled={!password || isLoading}
                  endIcon={isLoading ? <CircularProgress size={20} /> : null}
                >
                  {isLoading ? "Loading..." : "Stay Logged In"}
                </Button>
                <Button
                  className="button-styles btnHeight"
                  variant="outlined"
                  color="primary"
                  onClick={logOut}
                  disabled={password || isLoading}
                >
                  Log Out
                </Button>
              </Box>
            </DialogActions>
          </div>
        </Dialog>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={isLoading}
      ></Backdrop>
    </>
  );
}

export default SessionOut;
