import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetSessionValue } from "./Urls";

async function getSessionUpdatedData(value) {
  try {
    let { data } = await instance.post(GetSessionValue, value);
    // if (data.status.code == 200) {
    //   return data;
    // } else {
    return data;
    // }
  } catch (error) {
    return error;
  }
}

export function GetSessionUpdatedDataApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getSessionUpdatedData, {});
}
