import Constants from "../constants";

export const setReportsData = (data) => {
  return {
    type: Constants.STORE_REPORT_DATA,
    payload: data,
  };
};

export const setReportDataGrid = (data) => {
  return {
    type: Constants.STORE_REPORT_DATA_GRID,
    payload: data,
  };
};

export const setRepInfo = (data) => {
  return {
    type: Constants.STORE_REPORT_INFO,
    payload: data,
  };
};
