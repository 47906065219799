import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "./Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import Notification from "./Notification";
import { useSelector } from "react-redux";

function DocDialogs({
  open = false,
  cancel,
  submit,
  setFilters,
  filters,
  lookUpData,
  formData,
  setFormData,
  handleInputChange,
  handleAutocompleteChange,
  handleDateChange,
  handleSubmit,
  subStatusData,
  handleClearValues,
  isFrom,
  setIsFilters,
}) {
  // const details = JSON.parse(sessionStorage?.getItem("details"));
  // const details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const createdByData = useMemo(() => {
    if (details?.aIStaffList && details.aIStaffList[0]?.code !== "0") {
      return [{ description: "All", code: "0" }, ...details.aIStaffList];
    }
    return details?.aIStaffList || [];
  }, [details]);

  const locationData = useMemo(() => {
    if (details?.aILocations && details.aILocations[0]?.companyId !== "0") {
      return [{ companyName: "All", companyId: "0" }, ...details.aILocations];
    }
    return details?.aILocations || [];
  }, [details]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const statusDate = [
    { label: "Create Date", value: "CDT" },
    { label: "Priority Date", value: "PDT" },
    { label: "Assign Date", value: "ADT" },
    { label: "Completed Date", value: "CMDT" },
    { label: "Last Status Date", value: "LSD" },
  ];

  const status = [
    { label: "Open", value: "Open" },
    { label: "Card Captured", value: "Card Captured" },
    { label: "Queued For Verification", value: "Verified" },
    { label: "Completed", value: "Completed" },
  ];

  const deliveryStatus = [
    { label: "Sent", value: "Sent" },
    { label: "Viewed", value: "Viewed" },
    { label: "Submitted", value: "Submitted" },
    { label: "Completed", value: "Completed" },
  ];

  const createdBy = details?.aIStaffList?.map((i) => ({
    label: i.description,
    value: i.id,
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CloseIcon
            aria-label="close"
            className="button-stylesDialog cursor-pointer windowClose"
            onClick={cancel}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mx-2 my-4">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={locationData}
                  getOptionLabel={(option) => option.companyName}
                  isOptionEqualToValue={(option, value) =>
                    option.companyName === value.companyName
                  }
                  value={formData?.location}
                  onChange={(e, value) =>
                    handleAutocompleteChange("location", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </FormControl>
            </div>
            <div className="sm:flex my-2">
              <div className="m-2 w-2/4">
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    autoSelect={false}
                    disableClearable
                    options={statusDate}
                    value={formData?.statusDate}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    onChange={(e, value) =>
                      handleAutocompleteChange("statusDate", value)
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Status Date" />
                    )}
                  />
                </FormControl>
              </div>
              <div className="m-2 w-2/4">
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    autoSelect={false}
                    disableClearable
                    options={createdBy}
                    value={formData?.CreatedBy}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    onChange={(e, value) =>
                      handleAutocompleteChange("CreatedBy", value)
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Created By" />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2 w-2/4">
                <TextField
                  label="For"
                  name="for"
                  className="m-0"
                  size="small"
                  autoComplete="off"
                  value={formData?.for}
                  onChange={handleInputChange}
                  fullWidth
                />
              </div>
              <div className="m-2 w-2/4">
                <FormControl fullWidth size="small">
                  <InputLabel>Day(s) / Week(s) / Month(s)</InputLabel>
                  <Select
                    label="Day(s) / Week(s) / Month(s)"
                    name="daysOrMonths"
                    value={formData?.daysOrMonths}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    <MenuItem value="days">Day(s)</MenuItem>
                    <MenuItem value="weeks">Week(s)</MenuItem>
                    <MenuItem value="months">Month(s)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        handleDateChange(val, "fromDate");
                      }}
                      value={
                        formData?.fromDate ? dayjs(formData?.fromDate) : ""
                      }
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="From Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        handleDateChange(val, "toDate");
                      }}
                      sx={{
                        width: "200px !important",
                      }}
                      value={formData?.toDate ? dayjs(formData?.toDate) : ""}
                      maxDate={dayjs()}
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="To Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            {isFrom == "patientList" ? (
              <>
                <div className="sm:flex">
                  <div className="m-2 w-2/4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          onChange={(val) => {
                            handleDateChange(val, "dateOfBirth");
                          }}
                          sx={{
                            width: "200px !important",
                          }}
                          value={
                            formData?.dateOfBirth
                              ? dayjs(formData?.dateOfBirth)
                              : null
                          }
                          maxDate={dayjs()}
                          inputFormat="MM/dd/yyyy"
                          size="small"
                          label="Date of Birth"
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                width: "220px !important",
                              },
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="m-2 w-2/4 !my-[15px]">
                    <TextField
                      id="outlined-batch-input"
                      label="Batch Number"
                      name="Batch Number"
                      size="small"
                      placeholder="Batch Number"
                      className="w-full"
                      onChange={handleInputChange}
                      value={formData?.batchNumber}
                    />
                  </div>
                </div>
                <div className="sm:flex">
                  <div className="m-2 w-2/4">
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        autoSelect={false}
                        disableClearable
                        options={status}
                        value={formData?.status}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onChange={(e, value) =>
                          handleAutocompleteChange("status", value)
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="m-2 w-2/4">
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        autoSelect={false}
                        disableClearable
                        options={deliveryStatus}
                        value={formData?.deliveryStatus}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onChange={(e, value) =>
                          handleAutocompleteChange("deliveryStatus", value)
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} label="Delivery Status" />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="sm:flex">
                  <div className="mx-2 my-3 w-2/4">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      autoSelect={false}
                      disableClearable
                      options={lookUpData?.statusList}
                      value={formData?.status}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange("status", value)
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Status" />
                      )}
                    />
                  </div>
                  <div className="mx-2 my-3 w-2/4">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      autoSelect={false}
                      disableClearable
                      options={subStatusData}
                      value={formData?.subStatus}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange("subStatus", value)
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Status" />
                      )}
                    />
                  </div>
                </div>
                <div className="sm:flex">
                  <div className="mx-2 my-3 w-2/4">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      disableClearable
                      autoSelect={false}
                      options={createdByData}
                      value={formData?.assignedTo}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange("assignedTo", value)
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Assigned To" />
                      )}
                    />
                  </div>
                  <div className="mx-2 my-3 w-2/4">
                    <TextField
                      id="outlined-password-input"
                      label="Batch Number"
                      name="Batch Number"
                      size="small"
                      placeholder="Batch Number"
                      className="w-full"
                      onChange={handleInputChange}
                      value={formData?.batchNumber}
                    />
                  </div>
                </div>
                <div className="sm:flex">
                  <div className="mx-2 my-3 w-2/4">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      autoSelect={false}
                      disableClearable
                      options={lookUpData?.dataSourceList}
                      value={formData?.dataSource}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange("dataSource", value)
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Data Source" />
                      )}
                    />
                  </div>
                  <div className="mx-2 my-3 w-2/4">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      autoSelect={false}
                      disableClearable
                      options={lookUpData?.subProcessList}
                      value={formData?.subProcess}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      onChange={(e, value) =>
                        handleAutocompleteChange("subProcess", value)
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Sub Process" />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <div className="flex justify-end mx-8 mb-5">
          <div>
            <Button
              text="Close"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={cancel}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Clear"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={handleClearValues}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Apply"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={() => {
                if (!dayjs(formData.fromDate).isValid()) {
                  setNotify({
                    isOpen: true,
                    message: "Please select From Date",
                    type: "error",
                  });
                } else {
                  handleSubmit();
                  if (isFrom == "patientList") {
                    setIsFilters(true);
                  }
                }
              }}
            />
          </div>
        </div>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default DocDialogs;
//neel
