import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IdentifyPayerUrl } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function GetPayerIdentifierInfo(value) {
  try {
    const response = await instance.post(IdentifyPayerUrl, value);
    if (response.data.status.code == 200) {
      return response.data;
    } else if (response.data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      // toast.error(`${response.data.Status.Message}`);
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

export function PayerIdentifierInfo() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(GetPayerIdentifierInfo, {
    // onSuccess: (data) => {
    //   if (data?.status?.code == 200) {
    //     navigation("/patient-list/send-sms-or-email/sms-form", {
    //       state: "SMS",
    //     });
    //   }
    // },
  });
}
