import Constants from "../constants";

export const setcaptureDetailsData = (data) => {
  return {
    type: Constants.CAPTURE_DETAILS_DATA,
    payload: data,
  };
};
export const setcaptureData = (data) => {
  return {
    type: Constants.CAPTURE_DATA,
    payload: data,
  };
};

export const setCosmosId = (data) => {
  return {
    type: Constants.STORE_COSMOS_ID,
    payload: data,
  };
};

export const resetPatientData = (data) => {
  return {
    type: Constants.RESET_PATIENT_DATA,
    payload: data,
  };
};
export const setcaptureSMSData = (data) => {
  return {
    type: Constants.CAPTUR_SMS_DATA,
    payload: data,
  };  };
  export const setClaimStatusData = (data) => {
    return {
      type: Constants.CLAIM_STATUS_DATA,
      payload: data,
    };
};
