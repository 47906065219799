import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetLookUpsForDS, GetDocSummaryData } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getLookUpsForDS(value) {
  try {
    let { data } = await instance.post(GetLookUpsForDS, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getDocumentSummaryList(value) {
  try {
    let { data } = await instance.post(GetDocSummaryData, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function GetLookUpsForDSApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getLookUpsForDS, {});
}

export function GetDocumentSummaryListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getDocumentSummaryList, {});
}
