import { useContext, useMemo } from "react";
import MyContext from "../MyContext";
import { useSelector } from "react-redux";

const useCommonParams = () => {
  // const { contextValue } = useContext(MyContext);

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  return {
    organizationID: details?.orgId,
    companyID: details?.companyId,
    coCode: details?.companyCode,
    groupID: details?.groupID,
    timeZone: details?.timeZone,
    sessionId: details?.sesionId,
    loginUserId: `${details?.userId}`,
    loginUserName: details?.userName,
    AuditTrail: 0,
    editTrail: 0,
    dataSource: "",
  };
};

export default useCommonParams;
