import React, { useEffect, useState } from "react";

// Redux related Import
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom"; // Make sure you are using react-router-dom v6
import Header from "../../components/Header";
import PatientList from "../patient/PatientList";
import ErrorPage from "../../components/ErrorPage";
import PatientDetails from "../patient/PatientDetails";
import PatientInfo from "../capture/PatientInfo";
import CaptureDriverLicense from "../capture/CaptureDriverLicense";
import SentSMS from "../sendSMS/SentSMS";
import SendSMS from "../sendSMS/SendSMS";
import InsuranceDiscoverInfo from "../discoverInsurance/InsuranceDiscoverIno";
import ClaimSubmitDetails from "../claimSubmit/ClaimSubmit";
import ClaimStatusDetails from "../claimSubmit/ClaimStatus";
import DataImportModule from "../importModule/DataImportModule";
import CustomReportsModule from "../reports/CustomReportsModule";
import DocumentVerificationPage from "../documentVerification/DocumentVerification";
import SingleDocumentPage from "../documentVerification/SingleDocument";
import BatchUploadPage from "../documentVerification/BatchUpload";
import ReportsList from "../reports/ReportsList";
import TransationSummaryListObj from "../transactionSummary/TransationSummaryList";
import ClaimDynamicSubmit from "../claimSubmit/ClaimSubmitDynamic";
import TransationSummary from "../transactionSummary/TransationSummay";
import CMSMaster from "../cms1500/CMSMaster";
import DocumentSummaryPage from "../documentSummary/DocumentSummary";
import ConfigurationList from "../docAiConfiguration/ConfigurationList";
import TaskSummaryPage from "../taskSummary/TaskSummary";
import LocationPage from "../location/Location";
import StaffPage from "../staff/StaffList";
import LocationDetailsPage from "../location/LocationDetails";
import { store } from "../../redux/store";
import { setPreviousAndCurrentComponent } from "../../redux/actions/previousComponent";

const Home = () => {
  // const activeComponent = useSelector((state) => state.activeComponent);

  const activeComponent = useSelector((state) => state.activeComponent);
  const previousComponent = useSelector(
    (state) => state.previousComponent.previousComponent
  );
  const currentComponent = useSelector(
    (state) => state.previousComponent.currentComponent
  );

  const [currentComp, setCurrentComp] = useState(null);
  const [content, setContent] = useState("");
  const navigate = useNavigate(); // react-router-dom v6 navigate hook
  const dispatch = useDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if(!token || token === null){
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (currentComponent !== activeComponent) {
      // Dispatch action to update previous and current component in the Redux store
      const previousComponent =
        store.getState()?.previousComponent?.currentComponentData;

      console.log(" GLOBAL STATE ", previousComponent);
      dispatch(
        setPreviousAndCurrentComponent(currentComponent, activeComponent)
      );
    }

    if (activeComponent) {
      switch (activeComponent.toUpperCase()) {
        case "/PATIENT-LIST":
          setContent(<PatientList />);
          break;
        case "/ERROR-PAGE":
          setContent(<ErrorPage />);
          break;
        case "/PATIENT-LIST/PATIENT-DETAILS":
          setContent(<PatientDetails />);
          break;
        case "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE/PATIENT-INFO":
          setContent(<PatientInfo />);
          break;
        case "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE":
          setContent(<CaptureDriverLicense />);
          break;
        case "/PATIENT-LIST/SEND-SMS-OR-EMAIL":
          setContent(<SendSMS />);
          break;
        case "/PATIENT-LIST/SEND-SMS-OR-EMAIL/SMS-FORM":
          setContent(<SentSMS />);
          break;
        case "/PATIENT-LIST/DISCOVER-INSURANCE":
          setContent(<InsuranceDiscoverInfo />);
          break;
        case "/PATIENT-LIST/CLAIM-SUBMIT":
          setContent(<ClaimSubmitDetails />);
          break;
        case "/PATIENT-LIST/CLAIM-STATUS":
          setContent(<ClaimStatusDetails />);
          break;
        case "/PATIENT-LIST/DATA-IMPORT-MODULE":
          setContent(<DataImportModule />);
          break;
        case "/PATIENT-LIST/CUSTOM-REPORTS":
          setContent(<CustomReportsModule />);
          break;
        case "/PATIENT-LIST/DOCUMENT-VERIFICATION":
          setContent(<DocumentVerificationPage />);
          break;
        case "/PATIENT-LIST/DOCUMENT-VERIFICATION/SINGLE-DOCUMENT":
          setContent(<SingleDocumentPage />);
          break;
        case "/PATIENT-LIST/DOCUMENT-VERIFICATION/BATCH-UPLOAD":
          setContent(<BatchUploadPage />);
          break;
        case "/PATIENT-LIST/REPORTS":
          setContent(<ReportsList />);
          break;
        case "/TRANSACTIONSUMMARY/TRANSACTION-LIST":
          setContent(<TransationSummaryListObj />);
          break;
        case "/PATIENT-LIST/CLAIM-SUBMITDYNAMIC":
          setContent(<ClaimDynamicSubmit />);
          break;
        case "/PATIENT-LIST/TRANSATION-SUMMARY":
          setContent(<TransationSummary />);
          break;
        case "/CMS":
          setContent(<CMSMaster />);
          break;
        case "/DOCUMENT-SUMMARY":
          setContent(<DocumentSummaryPage />);
          break;
        case "/DOCAICONFIGURATION":
          setContent(<ConfigurationList />);
          break;
        case "/TASK-SUMMARY":
          setContent(<TaskSummaryPage />);
          break;
        case "/LOCATION":
          setContent(<LocationPage />);
          break;
        case "/STAFF":
          setContent(<StaffPage />);
          break;
        case "/LOCATION-DETAILS":
          setContent(<LocationDetailsPage />);
          break;
        default:
          setContent(<PatientList />);
      }
    } else {
      setContent(<PatientList />);
    }
  }, [activeComponent, currentComponent, previousComponent, dispatch]);

  // Function to handle component change and navigate to '/home'
  const handleComponentChange = (component) => {
    setCurrentComp(component);
    navigate("/home"); // Navigate to '/home'
  };

  return (
    <div>
      {/* Header component which triggers handleComponentChange */}
      <Header />
      <div className="px-6 mx-auto relative top-[70px] w-full">
        <div>{content}</div>
      </div>
    </div>
  );
};

export default Home;
