import React, { forwardRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box, Button } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Reusable Grid Component with ref forwarded
const ReusableGrid = forwardRef(
  (
    {
      agGridColumns,
      agGridRows,
      defaultColDef,
      onGridReady,
      btnExportCsvClick,
      btnExportXlClick,
      data,
    },
    ref
  ) => {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    useEffect(() => {
      if (!data || data.length === 0) {
        console.log("No data available.");
        return;
      }

      // Process rowData from the provided data
      const newRowData = data.map((item) => ({ ...item }));
      setRowData(newRowData);

      // Create columnDefs dynamically based on the keys of the first object in newRowData
      const newColumnDefs = Object.keys(newRowData[0]).map((key) => ({
        field: key,
        headerName: key.replace(/_/g, " "), // Replaces underscores with spaces
      }));

      setColumnDefs(newColumnDefs);
    }, [data]);

    return (
      <Box className="ag-theme-alpine docVHgt relative">
        <div className="absolute z-10 top-[6px] right-[-2px]">
          <Button
            variant="outlined"
            color="primary"
            onClick={btnExportCsvClick}
            sx={{ marginRight: 1 }}
            className="button-styles"
          >
            export List (csv)
            <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={btnExportXlClick}
            sx={{ marginRight: 1 }}
            className="button-styles"
          >
            export List (xls)
            <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
          </Button>
        </div>
        <AgGridReact
          columnDefs={agGridColumns.length > 0 || columnDefs}
          rowData={agGridRows.length > 0 || rowData}
          defaultColDef={defaultColDef}
          rowSelection="single"
          rowGroupPanelShow="always"
          pagination={true}
          paginationPageSize={25}
          onGridReady={onGridReady}
          alwaysShowVerticalScroll={true}
          domLayout="autoHeight"
          animateRows={true}
          ref={ref} // Pass ref to AG Grid
          suppressCopyRowsToClipboard={true}
        />
      </Box>
    );
  }
);

export default ReusableGrid;
