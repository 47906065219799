import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const FilterControl = ({
  controlType,
  valueList = [],
  label,
  value,
  onChange,
  size,
  isMandatory,
}) => {
  // console.log(
  //   {
  //     controlType,
  //     valueList,
  //     label,
  //     value,
  //     onChange,
  //     size,
  //   },
  //   " debugger "
  // );
  // debugger;
  const isOptionEqualToValue = (option, value) => {
    return option.field_Id === value.field_Id;
  };
  switch (controlType) {
    case "dropdown":
      return (
        <Autocomplete
          size={size}
          options={valueList || []}
          disableClearable
          autoSelect={false}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={
                <>
                  {label}
                  {isMandatory && <span style={{ color: "red" }}> *</span>}
                </>
              }
            />
          )}
          value={value}
          getOptionLabel={(option) => option.valuelist}
          onChange={(event, newValue) => onChange(newValue)}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      );
    case "Date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            size={size}
            label={
              <>
                {label}
                {isMandatory && <span style={{ color: "red" }}> *</span>}
              </>
            }
            value={dayjs(value)}
            onChange={(newValue) => onChange(newValue)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
      );
    case "Textbox":
    default:
      return (
        <TextField
          fullWidth
          size={size}
          label={
            <>
              {label}
              {isMandatory && <span style={{ color: "red" }}> *</span>}
            </>
          }
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      );
  }
};

export default FilterControl;
