import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { InsurenseDetails } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { locationByServiceTypesUrl } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function savePatientInfo(value) {
  try {
    const response = await instance.post(InsurenseDetails, value);
    if (response.data.status.code == 200) {
      // const queryParams = new URLSearchParams();
      // queryParams.set("data", JSON.stringify(response.data.data.id));
      // window.location.href = `/patient-list/patient-details?${queryParams.toString()}`;

      // sessionStorage.setItem(
      //   "cosmoskey",
      //   JSON.stringify(data?.data?.organizationSetting)
      // );
      // sessionStorage.setItem("details", JSON.stringify(data.data));
      // window.location.href = "/patient-list";
      // console.log(response.data.data.id)
      return response.data;
    } else if (response.data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      // toast.error(`${response.data.Status.Message}`);
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

export function SavePatientInfo() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(savePatientInfo, {
    // onSuccess: (data) => {
    //   if (data?.status?.code == 200) {
    //     navigation("/patient-list/send-sms-or-email/sms-form", {
    //       state: "SMS",
    //     });
    //   }
    // },
  });
}

async function locationByServiceTypeLists(value) {
  try {
    const response = await instance.post(locationByServiceTypesUrl, value);
    if (response.data.status.code == 200) {
      return response.data;
    } else if (response.data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

export function LocationByServiceTypes() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(locationByServiceTypeLists, {});
}
