import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Information from "./Information";
const CommonModal = ({
  open,
  handleClose,
  inputJSON,
  handleInputChange,
  handleContinue,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        className="inputJson"
        sx={{
          height: "80%",
          overflow: "scroll",
        }}
      >
        <Box className="mb-2 flex justify-between items-center">
          <Typography variant="h6" component="h2" id="modal-title">
            Input JSON
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Information />

        <Box className="flex justify-end">
          <Button
            onClick={handleClose}
            className="button-styles !min-w-[100px] !mt-3"
            color="primary"
          >
            Update Data
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonModal;
