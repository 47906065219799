import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyModal = ({
  open,
  handleClose,
  inputJSON,
  handleInputChange,
  handleContinue,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="inputJson">
        <Box className="mb-2 flex justify-between items-center">
          <Typography variant="h6" component="h2" id="modal-title">
            Input JSON
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <TextField
          multiline
          placeholder="Paste inputJSON here..."
          fullWidth
          value={inputJSON}
          onChange={handleInputChange}
          className="txtJson"
        />

        <Box className="flex justify-end">
          <Button
            onClick={handleContinue}
            className="button-styles !min-w-[100px] !mt-3"
            color="primary"
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MyModal;
