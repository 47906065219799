import Constants from "../constants";

export const setCosmosId = (data) => {
  return {
    type: Constants.STORE_COSMOS_ID,
    payload: data,
  };
};

export const setPatientFilteredData = (data) => {
  return {
    type: Constants.STORE_PATIENT_FILTERED_DATA,
    payload: data,
  };
};

const createAction = (type, payload) => ({
  type,
  payload,
});

export const setFilters = (data) =>
  createAction(Constants.PATIENT_SET_FORM_DATA, data);

export const setPaginationModel = (data) =>
  createAction(Constants.PATIENT_SET_PAGE_MODEL, data);

export const setPatientSortModel = (data) =>
  createAction(Constants.PATIENT_SET_SORT_MODEL, data);

export const setPatientFilterModel = (data) =>
  createAction(Constants.PATIENT_SET_FILTER_MODEL, data);

export const setIsFilters = (data) =>
  createAction(Constants.PATIENT_SET_FILTER_APPLIED, data);
