import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Edi837JSONData,
  SendSMSAPI,
  EdiClaimStatusUrl,
  GetICDs,
  GetServicesCPT,
  GetServicesModifier,
} from "./Urls";
import { saveEdi837ClaimUrl } from "./Urls";
import { getPayerNamesList } from "./Urls";
import { useNavigate } from "react-router-dom";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getEdi837Claim(value) {
  try {
    let { data } = await instance.post(Edi837JSONData, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}
async function getPayerNamesListObj(value) {
  try {
    let { data } = await instance.post(getPayerNamesList, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

async function saveEdi837Claim(value) {
  try {
    let { data } = await instance.post(saveEdi837ClaimUrl, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

async function callCHClaimStatus(value) {
  try {
    let { data } = await instance.post(EdiClaimStatusUrl, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

async function getICDs(value) {
  try {
    let { data } = await instance.post(GetICDs, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}
async function getServiceCPT(value) {
  try {
    let { data } = await instance.post(GetServicesCPT, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}
async function getServiceModifier(value) {
  try {
    let { data } = await instance.post(GetServicesModifier, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

export function GetEdi837Claim() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getEdi837Claim);
}

export function SaveEdi837Claim() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(saveEdi837Claim);
}

export function GetPayerNamesList() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getPayerNamesListObj);
}

export function CallClaimStatus() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(callCHClaimStatus);
}

export function GetICD() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getICDs);
}
export function GetServices() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getServiceCPT);
}
export function GetServiceModifier() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getServiceModifier);
}
