import React, { useState, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";

export const AgGridComponent = ({
  rows,
  columns,
  onAdGridRowClick,
  setGridApi,
  gridRef,
}) => {
  const convertToAgGridFormat = (muiRows, muiColumns) => {
    // Convert rows
    const agGridRows = muiRows.map((row) => {
      row.C2 = row.C2.split("^")[0];
      row.C7 = row.C7 ? "true" : "false";
      row.C10 = dayjs(row.C10).format("MM/DD/YYYY");
      row.C8 = dayjs(row.C8).format("MM/DD/YYYY");
      return { ...row }; // Simply map each row as it is
    });

    // Convert columns
    const agGridColumns = muiColumns.map((column) => {
      const { field, headerName, Width, ...otherProps } = column;
      return {
        field,
        headerName,
        enableRowGroup: true,
        filter: true,
        ...otherProps,
        width: 200, // Rename Width to width
      };
    });

    return { agGridRows, agGridColumns };
  };

  const { agGridRows, agGridColumns } = convertToAgGridFormat(rows, columns);

  // Add view icon to the first column
  agGridColumns[0] = {
    ...agGridColumns[0],
    width: 110,
    cellRenderer: (params) => {
      if (params.node.group) {
        return null; // Return null for grouped rows to hide the icon
      }
      return (
        <IconButton onClick={() => onAdGridRowClick(params)}>
          <VisibilityIcon />
        </IconButton>
      );
    },
  };

  const [rowData, setRowData] = useState(agGridRows);
  const [columnDefs, setColumnDefs] = useState(agGridColumns);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // width: 300,
      // editable: true,
      //   floatingFilter: true,
      //   filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <AgGridReact
        rowData={agGridRows}
        columnDefs={agGridColumns}
        defaultColDef={defaultColDef}
        rowSelection="single"
        rowGroupPanelShow="always"
        pagination={true}
        paginationPageSize={100}
        paginationPageSizeSelector={[100, 250, 500, 1000]}
        onGridReady={onGridReady}
        domLayout="autoHeight"
        alwaysShowVerticalScroll={true}
        animateRows={true}
        ref={gridRef}
        suppressCopyRowsToClipboard={true}
      />
    </div>
  );
};
