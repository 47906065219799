function convertToPhrase(str) {
  // Split the string by camel case or underscore
  const words = str.split(/(?=[A-Z])|_/);

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words with space to form the phrase
  return capitalizedWords.join(" ");
}

export default convertToPhrase;
