import Constants from "../constants";

export const setActiveComponent = (componentName) => {
    return {
        type: Constants.STORE_ACTIVE_COMPONENT,
        payload: componentName
    }
};



// console.log("data",data);