import { combineReducers } from "redux";

import {
  lookUpDataReducer,
  dIdReducer,
  filteredDataReducer,
  documentCombinedReducer,
  // setFormDataReducer,
  // setPageModelReducer,
  // setFilterModelReducer,
  // setSortModelReducer,
  // setFiltersAppliedReducer,
} from "./documentRecognizerReducer";
import { activeComponentReducer } from "./homeReducer";
import {
  // cosmosIdReducer,
  patientFilteredDataReducer,
  // commonReducer,
} from "./patientListReducer";
import {
  captureDataReducer,
  captureDetailsDataReducer,
  captureSMSDataReducer,
  ClaimStatusDataReducer
} from "./patientDeatailsReducer";
// import { reportsReducer } from "./reportsReducer";
import { previousComponentReducer } from "./previousReducer";
import { loginDetailsReducer, loginTokenReducer, setAccessRights } from "./loginDeatailsReducer";
import ClaimStatusDetails from "../../pages/claimSubmit/ClaimStatus";

const rootReducer = combineReducers({
  activeComponent: activeComponentReducer,
  // activeComponentData: documentCombinedReducer,
  // lookUpData: lookUpDataReducer,
  // dId: dIdReducer,
  // filteredData: filteredDataReducer,
  // cosmosId: cosmosIdReducer,
  // patientFilteredData: patientFilteredDataReducer,
  // reportsData: reportsReducer,
  previousComponent: previousComponentReducer,
  loginDetails: loginDetailsReducer,
  accessRights: setAccessRights,
  loginToken: loginTokenReducer,
  captureData: captureDataReducer,
  captureDetailsData: captureDetailsDataReducer,
  captureSMSData: captureSMSDataReducer,
  ClaimStatusDetails:ClaimStatusDataReducer
  // formData: setFormDataReducer,
  // pageModel: setPageModelReducer,
  // sortModel: setSortModelReducer,
  // filterModel: setFilterModelReducer,
  // filtersApplied: setFiltersAppliedReducer,
  // patientListData: commonReducer,
});

export default rootReducer;
