import "./App.css";
import Login from "./pages/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Suspense, lazy, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
// import MyContext from "./components/MyContext";
import { Backdrop, CircularProgress } from "@mui/material";
import { TabProvider } from "./components/utils/TabProvider";
const ProtectedRoute = lazy(() => import("./pages/ProtectedRoute"));
const MainRoutes = lazy(() => import("./AllRoutes"));

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";
// import {
//   AppInsightsContext,
//   // AppInsightsErrorBoundary,
// } from "@microsoft/applicationinsights-react-js";
// import { reactPlugin } from "./AppInsights";
import { DocumentContextProvider } from "./components/utils/DocumentContextProvider";
import SessionOut from "./sessionOut";
import Home from "./pages/home/home";
import { useSelector } from "react-redux";
import ErrorBoundary from "./components/errorBoundary.js";

function App() {
  LicenseManager.setLicenseKey(process.env.REACT_APP_API_AG_GRID_KEY);
  const token = sessionStorage.getItem("token");
  // const token = useSelector((state) => state.loginToken);
  // const details = JSON.parse(sessionStorage.getItem("details")) || {};
  // const details = useSelector((state) => JSON.parse(state.loginDetails)) || {};

  // const [contextValue, setContextValue] = useState(details?.companyId || null);
  const appVersion = window.env?.REACT_APP_VERSION || "unknown";
  return (
    // <AppInsightsErrorBoundary
    //   onError={() => <h1>I believe something went wrong</h1>}
    //   value={reactPlugin}
    // >
    // <AppInsightsContext.Provider value={reactPlugin}>
    <ErrorBoundary>
      <TabProvider>
        <Suspense
          fallback={
            <Backdrop
              sx={{
                color: "#1a73e8",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          {/* <MyContext.Provider value={{ contextValue, setContextValue }}> */}
          <Routes>
            {/* <Route
              path="/login"
              element={!token ? <Login /> : <Navigate to="/patient-list" />}
            /> */}
            <Route
              path="/login"
              element={!token ? <Login /> : <Navigate to="/home" />}
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <DocumentContextProvider>
                    {/* <MainRoutes /> */}
                    <Home />
                  </DocumentContextProvider>
                </ProtectedRoute>
              }
            />
          </Routes>
          {/* </MyContext.Provider> */}
          <ToastContainer autoClose={2000} />
          <SessionOut />
        </Suspense>
      </TabProvider>
    </ErrorBoundary>

    // </AppInsightsContext.Provider>
    // </AppInsightsErrorBoundary>
  );
}

export default App;
