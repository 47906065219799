

const handlePhoneNumberKeyDown = (e) => {
    if (
        !/^\d$/.test(e.key) && // Allow digits
        e.key !== "Backspace" && // Allow backspace
        e.key !== "Delete" && // Allow delete
        e.key !== "ArrowLeft" && // Allow left arrow key
        e.key !== "ArrowRight" && // Allow right arrow key
        e.key !== "Tab" // Allow tab
    ) {
        e.preventDefault();
    }
};

export default handlePhoneNumberKeyDown;
