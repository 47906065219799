import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DiscoverInsurance } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function insuranceDiscoveryDetails(value) {
  let email = value.patientDto.PatientInfo.email;
  let mbl = value.patientDto.PatientInfo.phoneNumber;
  let state;
  try {
    let { data } = await instance.post(DiscoverInsurance, value);
    if (data.status.code == 200) {
      const queryParams = new URLSearchParams();
      queryParams.set("data", JSON.stringify(data.data.id));
      window.location.href = `/patient-list/patient-details?${queryParams.toString()}`;
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

export function InsuranceDiscoveryDetails() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(insuranceDiscoveryDetails, {});
}
