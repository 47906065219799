import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { SendSMSAPI } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function savePatientDetails(value) {
  let email = value.patientDto.PatientInfo.email;
  let mbl = value.patientDto.PatientInfo.phoneNumber;
  let state;

  try {
    let { data } = await instance.post(SendSMSAPI, value);
    if (data.status.code == 200) {
      mbl != "" ? (state = "SMS") : (state = "E-Mail");
      const queryParams = new URLSearchParams();
      queryParams.set("state", JSON.stringify(state));
      sessionStorage.setItem("sendSMSId", JSON.stringify(data?.data?.id));
      window.location.href = `/patient-list/send-sms-or-email/sms-form?${queryParams.toString()}`;
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    // window.location.href = "/error-page";
    // toast.error(error.message);
    return error;
  }
}

export function SavePatientDetails() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(savePatientDetails, {});
}
