import React from "react";
import { Grid, TextField, Box, Typography, MenuItem } from "@mui/material";

const Information = () => {
  return (
    <Box sx={{ padding: 4, width: "100%", margin: "20 auto" }}>
      {/* Record Data */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
      >
        Record Data
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Location Code"
            value="14578"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Organization Code"
            value="devclp"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Record Status"
            value="Active"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Location Type"
            value="Satellite"
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Location Data */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
      >
        Location Data
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Location Name"
            value="Alliance Spine And Pain Centers"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Time Zone"
            value="Eastern"
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>

      {/* Address & Contact */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontSize: "1rem", fontWeight: "bold", paddingY: 1 }}
      >
        Address & Contact
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Country"
            value="United States"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Address"
            value="134 Riverstone"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Postal Code"
            value="30114"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="City or Town"
            value="Canton"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="State or Province"
            value="Georgia"
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Primary Phone"
            value="(770) 929-9033"
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Information;
