import Constants from '../constants';

export const loginDetailsReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.SET_LOGIN_DETAILS:
            return action.payload;
        default:
            return state;
    }
};
export const setAccessRights = (state = null, action) => {
    switch (action.type) {
        case Constants.SET_ACCESS_RIGHTS:
            return action.payload;
        default:
            return state;
    }
};
export const loginTokenReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.SET_LOGIN_TOKEN:
            return action.payload;
        default:
            return state;
    }
};