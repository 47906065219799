import * as React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch } from "react-redux";
import { setActiveComponent } from "../redux/actions/index";

const Breadcrumb = ({ items, isRedux=false }) => {

  const dispatch = useDispatch();

  const handleBreadCrumClick = (url) => {
    if(isRedux){
      // call dispatch
      dispatch(setActiveComponent(url));
    }
    else{
      window.location.href = url;
    }
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {items.map((item, index) => (
        <span key={index}>
          {index === items.length - 1 ? (
            <Typography
              key="3"
              color="text.primary"
              className="hover:!text-black cursor-default !font-semibold"
            >
              {item.label}
            </Typography>
          ) : (
              <Link 
                onClick={() => handleBreadCrumClick(item.link)}
                // href={item.link} 
                underline="hover" color="inherit">
                {item.label}
              </Link>
          )}
        </span>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
