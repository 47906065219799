const types = [
  {
    serialNum: "1",
    jsonElementName: "controlNumber",
    displayFieldName: "Control Number",
    jsonFieldPath: "controlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "4",
    maxLength: "9",
    x12Loop: "",
    x12Segment: "ST",
    x12DataElement: "ST02",
    x12Description: "Transaction Set Control Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "2",
    jsonElementName: "tradingPartnerServiceId",
    displayFieldName: "Trading Partner Service Id",
    jsonFieldPath: "tradingPartnerName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2010BB",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "Payer Identifier",
    x12ElementSeq: "5",
  },
  {
    serialNum: "3",
    jsonElementName: "tradingPartnerName",
    displayFieldName: "Trading Partner Name",
    jsonFieldPath: "tradingPartnerServiceId",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010BB",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Payer Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "4",
    jsonElementName: "submitter",
    displayFieldName: "Submitter",
    jsonFieldPath: "submitter",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "1000A",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "5",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "submitter.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "1000A",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Submitter Last or Organization Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "6",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "submitter.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "1000A",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Submitter Last or Organization Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "7",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "submitter.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "1000A",
    x12Segment: "NM1",
    x12DataElement: "NM104",
    x12Description: "Submitter First Name",
    x12ElementSeq: "4",
  },
  {
    serialNum: "8",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "submitter.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "1000A",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "Submitter Middle Name or Initial",
    x12ElementSeq: "5",
  },
  {
    serialNum: "9",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "submitter.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "1000A",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "10",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "submitter.contactInformation.name",
    lookupValues: "IC",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "1000A",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "Submitter Contact Name",
    x12ElementSeq: "2",
  },
  {
    serialNum: "11",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "submitter.contactInformation.phoneNumber",
    lookupValues: "TE",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "1000A",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "12",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "submitter.contactInformation.faxNumber",
    lookupValues: "FX",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "1000A",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number",
    x12ElementSeq: "6",
  },
  {
    serialNum: "13",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "submitter.contactInformation.email",
    lookupValues: "EM",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "1000A",
    x12Segment: "PER",
    x12DataElement: "PER08",
    x12Description: "Communication Number",
    x12ElementSeq: "8",
  },
  {
    serialNum: "14",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "submitter.contactInformation.email",
    lookupValues: "EX",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "1000A",
    x12Segment: "PER",
    x12DataElement: "PER08",
    x12Description: "Communication Number",
    x12ElementSeq: "8",
  },
  {
    serialNum: "15",
    jsonElementName: "receiver",
    displayFieldName: "Receiver",
    jsonFieldPath: "receiver",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "1000B",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "16",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "receiver.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "1000B",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Receiver Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "17",
    jsonElementName: "subscriber",
    displayFieldName: "Subscriber",
    jsonFieldPath: "subscriber",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010BA",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "18",
    jsonElementName: "memberId",
    displayFieldName: "Member Id",
    jsonFieldPath: "subscriber.memberId",
    lookupValues: "IL",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "Subscriber Primary Identifier",
    x12ElementSeq: "1",
  },
  {
    serialNum: "19",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "subscriber.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010BA",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Subscriber Supplemental Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "20",
    jsonElementName: "paymentResponsibilityLevelCode",
    displayFieldName: "Payment Responsibility Level Code",
    jsonFieldPath: "subscriber.paymentResponsibilityLevelCode",
    lookupValues: "A,B,C,D,E,D,G,H,P,S,T,U",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2000B",
    x12Segment: "SBR",
    x12DataElement: "SBR01",
    x12Description: "Payer Responsibility Sequence Number Code",
    x12ElementSeq: "1",
  },
  {
    serialNum: "21",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "subscriber.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Subscriber Last Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "22",
    jsonElementName: "insuranceTypeCode",
    displayFieldName: "Insurance Type Code",
    jsonFieldPath: "subscriber.insuranceTypeCode",
    lookupValues: "12,13,14,15,16,41,42,43,47",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2000B",
    x12Segment: "SBR",
    x12DataElement: "SBR05",
    x12Description: "Insurance Type Code",
    x12ElementSeq: "5",
  },
  {
    serialNum: "23",
    jsonElementName: "subscriberGroupName",
    displayFieldName: "Subscriber Group Name",
    jsonFieldPath: "subscriber.subscriberGroupName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2000B",
    x12Segment: "SBR",
    x12DataElement: "SBR04",
    x12Description: "Name",
    x12ElementSeq: "4",
  },
  {
    serialNum: "24",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "subscriber.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM104",
    x12Description: "Subscriber First Name",
    x12ElementSeq: "4",
  },
  {
    serialNum: "25",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "subscriber.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Subscriber Last Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "26",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "subscriber.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "Subscriber Middle Name or Initial",
    x12ElementSeq: "5",
  },
  {
    serialNum: "27",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "subscriber.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2010BA",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Subscriber Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "28",
    jsonElementName: "gender",
    displayFieldName: "Gender",
    jsonFieldPath: "subscriber.gender",
    lookupValues: "M,F,U",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2010BA",
    x12Segment: "DMG",
    x12DataElement: "DMG03",
    x12Description: "Subscriber Gender Code",
    x12ElementSeq: "3",
  },
  {
    serialNum: "29",
    jsonElementName: "dateOfBirth",
    displayFieldName: "Date Of Birth",
    jsonFieldPath: "subscriber.dateOfBirth",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2010BA",
    x12Segment: "DMG",
    x12DataElement: "DMG02",
    x12Description: "Subscriber Birth Date",
    x12ElementSeq: "2",
  },
  {
    serialNum: "30",
    jsonElementName: "policyNumber",
    displayFieldName: "Policy Number",
    jsonFieldPath: "subscriber.policyNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2000B",
    x12Segment: "SBR",
    x12DataElement: "SBR03",
    x12Description: "Reference Identification",
    x12ElementSeq: "3",
  },
  {
    serialNum: "31",
    jsonElementName: "groupNumber",
    displayFieldName: "Group Number",
    jsonFieldPath: "subscriber.groupNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010BA",
    x12Segment: "SBR",
    x12DataElement: "SBR04",
    x12Description: "Other Insured Group Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "32",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "subscriber.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010BA",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "33",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "subscriber.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010BA",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "Name",
    x12ElementSeq: "2",
  },
  {
    serialNum: "34",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "subscriber.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010BA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "35",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "subscriber.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010BA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "36",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "subscriber.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010BA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "37",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "subscriber.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010BA",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number",
    x12ElementSeq: "6",
  },
  {
    serialNum: "38",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "subscriber.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010BA",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "39",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "subscriber.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2010BA",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "1",
  },
  {
    serialNum: "40",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "subscriber.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2010BA",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "2",
  },
  {
    serialNum: "41",
    jsonElementName: "city",
    displayFieldName: "city",
    jsonFieldPath: "subscriber.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2010BA",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "1",
  },
  {
    serialNum: "42",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "subscriber.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2010BA",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "2",
  },
  {
    serialNum: "43",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "subscriber.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2010BA",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "3",
  },
  {
    serialNum: "44",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "subscriber.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2010BA",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "4",
  },
  {
    serialNum: "45",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "subscriber.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2010BA",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "5",
  },
  {
    serialNum: "46",
    jsonElementName: "dependent",
    displayFieldName: "Dependent",
    jsonFieldPath: "dependent",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010CA",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "47",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "dependent.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2010CA",
    x12Segment: "NM1",
    x12DataElement: "NM104",
    x12Description: "Patient First Name",
    x12ElementSeq: "4",
  },
  {
    serialNum: "48",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "dependent.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010CA",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Patient Last Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "49",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "dependent.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "2010CA",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "Patient Middle Name or Initial",
    x12ElementSeq: "5",
  },
  {
    serialNum: "50",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "dependent.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2010CA",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Patient Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "51",
    jsonElementName: "gender",
    displayFieldName: "Gender",
    jsonFieldPath: "dependent.gender",
    lookupValues: "M,F,U",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2010CA",
    x12Segment: "DMG",
    x12DataElement: "DMG03",
    x12Description: "Patient Gender Code",
    x12ElementSeq: "3",
  },
  {
    serialNum: "52",
    jsonElementName: "dateOfBirth",
    displayFieldName: "Date Of Birth",
    jsonFieldPath: "dependent.dateOfBirth",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2010CA",
    x12Segment: "DMG",
    x12DataElement: "DMG02",
    x12Description: "Patient Birth Date",
    x12ElementSeq: "2",
  },
  {
    serialNum: "53",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "dependent.ssn",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010CA",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Property and Casualty Patient Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "54",
    jsonElementName: "memberId",
    displayFieldName: "Member Id",
    jsonFieldPath: "dependent.memberId",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010CA",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Property and Casualty Patient Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "55",
    jsonElementName: "relationshipToSubscriberCode",
    displayFieldName: "Relationship To Subscriber Code",
    jsonFieldPath: "dependent.relationshipToSubscriberCode",
    lookupValues: "01,19,20,21,39,40,53,G8",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2000C",
    x12Segment: "PAT",
    x12DataElement: "PAT01",
    x12Description: "Individual Relationship Code",
    x12ElementSeq: "1",
  },
  {
    serialNum: "56",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "dependent.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010CA",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "57",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "dependent.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010CA",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "Name",
    x12ElementSeq: "2",
  },
  {
    serialNum: "58",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "dependent.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010CA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "59",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "dependent.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010CA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "60",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "dependent.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010CA",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "61",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "dependent.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2010CA",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number",
    x12ElementSeq: "4",
  },
  {
    serialNum: "62",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "dependent.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010CA",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "63",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "dependent.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2010CA",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Patient Address Line",
    x12ElementSeq: "1",
  },
  {
    serialNum: "64",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "dependent.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2010CA",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Patient Address Line",
    x12ElementSeq: "2",
  },
  {
    serialNum: "65",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "dependent.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2010CA",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Patient City Name",
    x12ElementSeq: "1",
  },
  {
    serialNum: "66",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "dependent.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2010CA",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Patient State Code",
    x12ElementSeq: "2",
  },
  {
    serialNum: "67",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "dependent.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2010CA",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Patient Postal Zone or ZIP Code",
    x12ElementSeq: "3",
  },
  {
    serialNum: "68",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "dependent.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2010CA",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "4",
  },
  {
    serialNum: "69",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "dependent.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2010CA",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "5",
  },
  {
    serialNum: "70",
    jsonElementName: "claimInformation",
    displayFieldName: "Claim Information",
    jsonFieldPath: "claimInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "71",
    jsonElementName: "claimFilingCode",
    displayFieldName: "Claim Filing Code",
    jsonFieldPath: "claimInformation.claimFilingCode",
    lookupValues:
      "11,12,13,14,15,16,17,AM,BL,CH,CI,DS,FI,HM,LM,MA,MB,MC,OF,TV,VA,WC,ZZ",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2000B",
    x12Segment: "SBR",
    x12DataElement: "SBR09",
    x12Description: "Claim Filing Indicator Code",
    x12ElementSeq: "6",
  },
  {
    serialNum: "72",
    jsonElementName: "propertyCasualtyClaimNumber",
    displayFieldName: "Property Casualty Claim Number",
    jsonFieldPath: "claimInformation.propertyCasualtyClaimNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010BA",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Property Casualty Claim Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "73",
    jsonElementName: "deathDate",
    displayFieldName: "Death Date",
    jsonFieldPath: "claimInformation.deathDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2000B",
    x12Segment: "PAT",
    x12DataElement: "PAT06",
    x12Description: "Patient Death Date",
    x12ElementSeq: "2",
  },
  {
    serialNum: "74",
    jsonElementName: "patientWeight",
    displayFieldName: "Patient Weight",
    jsonFieldPath: "claimInformation.patientWeight",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2000B",
    x12Segment: "PAT",
    x12DataElement: "PAT08",
    x12Description: "Patient Weight",
    x12ElementSeq: "4",
  },
  {
    serialNum: "75",
    jsonElementName: "pregnancyIndicator",
    displayFieldName: "Pregnancy Indicator",
    jsonFieldPath: "claimInformation.pregnancyIndicator",
    lookupValues: "Y,N",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2000B",
    x12Segment: "PAT",
    x12DataElement: "PAT09",
    x12Description: "Pregnancy Indicator",
    x12ElementSeq: "5",
  },
  {
    serialNum: "76",
    jsonElementName: "patientControlNumber",
    displayFieldName: "Patient Control Number",
    jsonFieldPath: "claimInformation.patientControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "38",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM01",
    x12Description: "Patient Control Number",
    x12ElementSeq: "1",
  },
  {
    serialNum: "77",
    jsonElementName: "claimChargeAmount",
    displayFieldName: "Claim Charge Amount",
    jsonFieldPath: "claimInformation.claimChargeAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "decimal",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM02",
    x12Description: "Total Claim Charge Amount",
    x12ElementSeq: "2",
  },
  {
    serialNum: "78",
    jsonElementName: "placeOfServiceCode",
    displayFieldName: "Place Of Service Code",
    jsonFieldPath: "claimInformation.placeOfServiceCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM05-01",
    x12Description: "Place of Service Code",
    x12ElementSeq: "4",
  },
  {
    serialNum: "79",
    jsonElementName: "claimFrequencyCode",
    displayFieldName: "Claim Frequency Code",
    jsonFieldPath: "claimInformation.claimFrequencyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM05-03",
    x12Description: "Claim Frequency Code",
    x12ElementSeq: "5",
  },
  {
    serialNum: "80",
    jsonElementName: "signatureIndicator",
    displayFieldName: "Signature Indicator",
    jsonFieldPath: "claimInformation.signatureIndicator",
    lookupValues: "N,Y",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM06",
    x12Description: "Provider or Supplier Signature Indicator",
    x12ElementSeq: "7",
  },
  {
    serialNum: "81",
    jsonElementName: "planParticipationCode",
    displayFieldName: "Plan Participation Code",
    jsonFieldPath: "claimInformation.planParticipationCode",
    lookupValues: "A,B,C",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM07",
    x12Description: "Assignment or Plan Participation Code",
    x12ElementSeq: "8",
  },
  {
    serialNum: "82",
    jsonElementName: "benefitsAssignmentCertificationIndicator",
    displayFieldName: "Benefits Assignment Certification Indicator",
    jsonFieldPath: "claimInformation.benefitsAssignmentCertificationIndicator",
    lookupValues: "N,W,Y",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM08",
    x12Description: "Benefits Assignment Certification Indicator",
    x12ElementSeq: "9",
  },
  {
    serialNum: "83",
    jsonElementName: "releaseInformationCode",
    displayFieldName: "Release Information Code",
    jsonFieldPath: "claimInformation.releaseInformationCode",
    lookupValues: "I,Y",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM09",
    x12Description: "Release of Information Code",
    x12ElementSeq: "10",
  },
  {
    serialNum: "84",
    jsonElementName: "patientSignatureSourceCode",
    displayFieldName: "Patient Signature Source Code",
    jsonFieldPath: "claimInformation.patientSignatureSourceCode",
    lookupValues: "false, true",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM10",
    x12Description: "Patient Signature Source Code",
    x12ElementSeq: "11",
  },
  {
    serialNum: "85",
    jsonElementName: "relatedCausesCode",
    displayFieldName: "Related Causes Code",
    jsonFieldPath: "claimInformation.delayReasonCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM11-01,",
    x12Description: "Related Causes Code",
    x12ElementSeq: "13",
  },
  {
    serialNum: "86",
    jsonElementName: "autoAccidentStateCode",
    displayFieldName: "Auto Accident State Code",
    jsonFieldPath: "claimInformation.autoAccidentStateCode",
    lookupValues: "AA,EM,QA",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: " CLM11-04",
    x12Description: "Auto Accident State or Province Code",
    x12ElementSeq: "15",
  },
  {
    serialNum: "87",
    jsonElementName: "autoAccidentCountryCode",
    displayFieldName: "Auto Accident Country Code",
    jsonFieldPath: "claimInformation.autoAccidentCountryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM11-05",
    x12Description: "Country Code",
    x12ElementSeq: "16",
  },
  {
    serialNum: "88",
    jsonElementName: "specialProgramCode",
    displayFieldName: "Special Program Code",
    jsonFieldPath: "claimInformation.specialProgramCode",
    lookupValues: "02,03,05,09",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM12",
    x12Description: "Special Program Code",
    x12ElementSeq: "17",
  },
  {
    serialNum: "89",
    jsonElementName: "delayReasonCode",
    displayFieldName: "Delay Reason Code",
    jsonFieldPath: "claimInformation.delayReasonCode",
    lookupValues: "1,2,3,4,5,6,7,8,9,10,11,15",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CLM",
    x12DataElement: "CLM20",
    x12Description: "Delay Reason Code",
    x12ElementSeq: "18",
  },
  {
    serialNum: "90",
    jsonElementName: "patientAmountPaid",
    displayFieldName: "Patient Amount Paid",
    jsonFieldPath: "claimInformation.patientAmountPaid",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "Patient Amount Paid",
    x12ElementSeq: "2",
  },
  {
    serialNum: "91",
    jsonElementName: "fileInformation",
    displayFieldName: "File Information",
    jsonFieldPath: "claimInformation.fileInformation",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "K3",
    x12DataElement: "K301",
    x12Description: "Fixed Format Information",
    x12ElementSeq: "1",
  },
  {
    serialNum: "92",
    jsonElementName: "fileInformationList",
    displayFieldName: "File Information List",
    jsonFieldPath: "claimInformation.fileInformationList",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "K3",
    x12DataElement: "K301",
    x12Description: "Fixed Format Information",
    x12ElementSeq: "1",
  },
  {
    serialNum: "93",
    jsonElementName: "claimDateInformation",
    displayFieldName: "Claim Date Information",
    jsonFieldPath: "claimInformation.claimDateInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "94",
    jsonElementName: "symptomDate",
    displayFieldName: "Symptom Date",
    jsonFieldPath: "claimInformation.claimDateInformation.symptomDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Onset of Current Illness or Injury Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "95",
    jsonElementName: "initialTreatmentDate",
    displayFieldName: "Initial Treatment Date",
    jsonFieldPath: "claimInformation.claimDateInformation.initialTreatmentDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Initial Treatment Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "96",
    jsonElementName: "lastSeenDate",
    displayFieldName: "Last Seen Date",
    jsonFieldPath: "claimInformation.claimDateInformation.lastSeenDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Last Seen Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "97",
    jsonElementName: "acuteManifestationDate",
    displayFieldName: "Acute Manifestation Date",
    jsonFieldPath:
      "claimInformation.claimDateInformation.acuteManifestationDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Acute Manifestation Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "98",
    jsonElementName: "accidentDate",
    displayFieldName: "Accident Date",
    jsonFieldPath: "claimInformation.claimDateInformation.accidentDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Accident Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "99",
    jsonElementName: "lastMenstrualPeriodDate",
    displayFieldName: "Last Menstrual Period Date",
    jsonFieldPath:
      "claimInformation.claimDateInformation.lastMenstrualPeriodDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Last Menstrual Period Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "100",
    jsonElementName: "lastXRayDate",
    displayFieldName: "Last X Ray Date",
    jsonFieldPath: "claimInformation.claimDateInformation.lastXRayDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Last X-Ray Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "101",
    jsonElementName: "hearingAndVisionPrescriptionDate",
    displayFieldName: "Hearing And Vision Prescription Date",
    jsonFieldPath:
      "claimInformation.claimDateInformation.hearingAndVisionPrescriptionDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Prescription Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "102",
    jsonElementName: "disabilityBeginDate",
    displayFieldName: "Disability Begin Date",
    jsonFieldPath: "claimInformation.claimDateInformation.disabilityBeginDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Disability From Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "103",
    jsonElementName: "disabilityEndDate",
    displayFieldName: "Disability End Date",
    jsonFieldPath: "claimInformation.claimDateInformation.disabilityEndDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Disability To Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "104",
    jsonElementName: "lastWorkedDate",
    displayFieldName: "Last Worked Date",
    jsonFieldPath: "claimInformation.claimDateInformation.lastWorkedDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Last Worked Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "105",
    jsonElementName: "authorizedReturnToWorkDate",
    displayFieldName: "Authorized Return To Work Date",
    jsonFieldPath:
      "claimInformation.claimDateInformation.authorizedReturnToWorkDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Work Return Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "106",
    jsonElementName: "admissionDate",
    displayFieldName: "Admission Date",
    jsonFieldPath: "claimInformation.claimDateInformation.admissionDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Related Hospitalization Admission Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "107",
    jsonElementName: "dischargeDate",
    displayFieldName: "Discharge Date",
    jsonFieldPath: "claimInformation.claimDateInformation.dischargeDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Related Hospitalization Discharge Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "108",
    jsonElementName: "assumedAndRelinquishedCareBeginDate",
    displayFieldName: "Assumed And Relinquished Care Begin Date",
    jsonFieldPath: "claimInformation.claimDateInformation.dischargeDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Related Hospitalization Discharge Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "109",
    jsonElementName: "assumedAndRelinquishedCareEndDate",
    displayFieldName: "Assumed And Relinquished Care End Date",
    jsonFieldPath:
      "claimInformation.claimDateInformation.assumedAndRelinquishedCareEndDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Date Time Period",
    x12ElementSeq: "3",
  },
  {
    serialNum: "110",
    jsonElementName: "repricerReceivedDate",
    displayFieldName: "Repricer Received Date",
    jsonFieldPath: "claimInformation.claimDateInformation.repricerReceivedDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Repricer Received Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "111",
    jsonElementName: "firstContactDate",
    displayFieldName: "First Contact Date",
    jsonFieldPath: "claimInformation.claimDateInformation.firstContactDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2300",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Date Time Period",
    x12ElementSeq: "3",
  },
  {
    serialNum: "112",
    jsonElementName: "claimContractInformation",
    displayFieldName: "Claim Contract Information",
    jsonFieldPath: "claimInformation.claimContractInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN101",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "113",
    jsonElementName: "contractTypeCode",
    displayFieldName: "Contract Type Code",
    jsonFieldPath: "claimInformation.claimContractInformation.contractTypeCode",
    lookupValues: "01,02,03,04,05,06,09",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN101",
    x12Description: "Contract Type Code",
    x12ElementSeq: "1",
  },
  {
    serialNum: "114",
    jsonElementName: "contractAmount",
    displayFieldName: "Contract Amount",
    jsonFieldPath: "claimInformation.claimContractInformation.contractAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN102",
    x12Description: "Contract Amount",
    x12ElementSeq: "2",
  },
  {
    serialNum: "115",
    jsonElementName: "contractPercentage",
    displayFieldName: "Contract Percentage",
    jsonFieldPath:
      "claimInformation.claimContractInformation.contractPercentage",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN103",
    x12Description: "Contract  Percentage",
    x12ElementSeq: "3",
  },
  {
    serialNum: "116",
    jsonElementName: "contractCode",
    displayFieldName: "Contract Code",
    jsonFieldPath: "claimInformation.claimContractInformation.contractCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN104",
    x12Description: "Contract Code",
    x12ElementSeq: "4",
  },
  {
    serialNum: "117",
    jsonElementName: "termsDiscountPercentage",
    displayFieldName: "Terms Discount Percentage",
    jsonFieldPath:
      "claimInformation.claimContractInformation.termsDiscountPercentage",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN105",
    x12Description: "Terms Discount Percentage",
    x12ElementSeq: "5",
  },
  {
    serialNum: "118",
    jsonElementName: "contractVersionIdentifier",
    displayFieldName: "Contract Version Identifier",
    jsonFieldPath:
      "claimInformation.claimContractInformation.contractVersionIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "2300",
    x12Segment: "CN1",
    x12DataElement: "CN106",
    x12Description: "Contract Version Identifier",
    x12ElementSeq: "6",
  },
  {
    serialNum: "119",
    jsonElementName: "claimSupplementalInformation",
    displayFieldName: "Claim Supplemental Information",
    jsonFieldPath: "claimInformation.claimSupplementalInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "120",
    jsonElementName: "reportInformation",
    displayFieldName: "Report Information",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.reportInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "121",
    jsonElementName: "attachmentReportTypeCode",
    displayFieldName: "Attachment Report Type Code",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.reportInformation.attachmentReportTypeCode",
    lookupValues: "03,04,05,06,07,08,09,10,11,13,15,21,A3,A4,AM,AS,B2,B3,B4,BR",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK01",
    x12Description: "Attachment Report Type Code",
    x12ElementSeq: "1",
  },
  {
    serialNum: "122",
    jsonElementName: "attachmentTransmissionCode",
    displayFieldName: "Attachment Transmission Code",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.reportInformation.attachmentTransmissionCode",
    lookupValues: "AA,BM,EL,EM,FT,FX",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK02",
    x12Description: "Attachment Transmission Code",
    x12ElementSeq: "2",
  },
  {
    serialNum: "123",
    jsonElementName: "attachmentControlNumber",
    displayFieldName: "Attachment Control Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.reportInformation.attachmentControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK05",
    x12Description: "Identification Code Qualifier",
    x12ElementSeq: "3",
  },
  {
    serialNum: "124",
    jsonElementName: "priorAuthorizationNumber",
    displayFieldName: "Prior Authorization Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.priorAuthorizationNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Prior Authorization Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "125",
    jsonElementName: "referralNumber",
    displayFieldName: "Referral Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.referralNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Referral Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "126",
    jsonElementName: "claimControlNumber",
    displayFieldName: "Claim Control Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.claimControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Payer Claim Control Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "127",
    jsonElementName: "cliaNumber",
    displayFieldName: "Clia Number",
    jsonFieldPath: "claimInformation.claimSupplementalInformation.cliaNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Clinical Laboratory Improvement Amendment Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "128",
    jsonElementName: "repricedClaimNumber",
    displayFieldName: "Repriced Claim Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.repricedClaimNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Repriced Claim Reference Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "129",
    jsonElementName: "adjustedRepricedClaimNumber",
    displayFieldName: "Adjusted Repriced Claim Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.adjustedRepricedClaimNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Adjusted Repriced Claim Reference Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "130",
    jsonElementName: "investigationalDeviceExemptionNumber",
    displayFieldName: "Investigational Device Exemption Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.investigationalDeviceExemptionNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Investigational Device Exemption Identifier",
    x12ElementSeq: "",
  },
  {
    serialNum: "131",
    jsonElementName: "claimNumber",
    displayFieldName: "Claim Number",
    jsonFieldPath: "claimInformation.claimSupplementalInformation.claimNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "132",
    jsonElementName: "mammographyCertificationNumber",
    displayFieldName: "Mammography Certification Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.mammographyCertificationNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Mammography Certification Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "133",
    jsonElementName: "medicalRecordNumber",
    displayFieldName: "Medical Record Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.medicalRecordNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Medical Record Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "134",
    jsonElementName: "demoProjectIdentifier",
    displayFieldName: "Demo Project Identifier",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.demoProjectIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Demonstration Project Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "135",
    jsonElementName: "carePlanOversightNumber",
    displayFieldName: "Care Plan Oversight Number",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.carePlanOversightNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Care Plan Oversight Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "136",
    jsonElementName: "medicareCrossoverReferenceId",
    displayFieldName: "Medicare Crossover Reference Id",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.medicareCrossoverReferenceId",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "137",
    jsonElementName: "serviceAuthorizationExceptionCode",
    displayFieldName: "Service Authorization Exception Code",
    jsonFieldPath:
      "claimInformation.claimSupplementalInformation.serviceAuthorizationExceptionCode",
    lookupValues: "1,2,3,4,5,6,7,NULL",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Service Authorization Exception Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "138",
    jsonElementName: "claimNote",
    displayFieldName: "Claim Note",
    jsonFieldPath: "claimInformation.claimNote",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "139",
    jsonElementName: "additionalInformation",
    displayFieldName: "Additional Information",
    jsonFieldPath: "claimInformation.claimNote.additionalInformation",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "140",
    jsonElementName: "certificationNarrative",
    displayFieldName: "Certification Narrative",
    jsonFieldPath: "claimInformation.claimNote.certificationNarrative",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "141",
    jsonElementName: "goalRehabOrDischargePlans",
    displayFieldName: "Goal Rehab Or Discharge Plans",
    jsonFieldPath: "claimInformation.claimNote.goalRehabOrDischargePlans",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "142",
    jsonElementName: "diagnosisDescription",
    displayFieldName: "Diagnosis Description",
    jsonFieldPath: "claimInformation.claimNote.diagnosisDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "143",
    jsonElementName: "thirdPartOrgNotes",
    displayFieldName: "Third Part Org Notes",
    jsonFieldPath: "claimInformation.claimNote.thirdPartOrgNotes",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "144",
    jsonElementName: "ambulanceTransportInformation",
    displayFieldName: "Ambulance Transport Information",
    jsonFieldPath: "claimInformation.ambulanceTransportInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "145",
    jsonElementName: "patientWeightInPounds",
    displayFieldName: "Patient Weight In Pounds",
    jsonFieldPath:
      "claimInformation.ambulanceTransportInformation.patientWeightInPounds",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2300",
    x12Segment: "CR1",
    x12DataElement: "CR102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "146",
    jsonElementName: "ambulanceTransportReasonCode",
    displayFieldName: "Ambulance Transport Reason Code",
    jsonFieldPath:
      "claimInformation.ambulanceTransportInformation.ambulanceTransportReasonCode",
    lookupValues: "A,B,C,D,E",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CR1",
    x12DataElement: "CR104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "147",
    jsonElementName: "transportDistanceInMiles",
    displayFieldName: "Transport Distance In Miles",
    jsonFieldPath:
      "claimInformation.ambulanceTransportInformation.transportDistanceInMiles",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2300",
    x12Segment: "CR1",
    x12DataElement: "CR106",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "148",
    jsonElementName: "roundTripPurposeDescription",
    displayFieldName: "Round Trip Purpose Description",
    jsonFieldPath:
      "claimInformation.ambulanceTransportInformation.roundTripPurposeDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "CR1",
    x12DataElement: "CR109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "149",
    jsonElementName: "stretcherPurposeDescription",
    displayFieldName: "Stretcher Purpose Description",
    jsonFieldPath:
      "claimInformation.ambulanceTransportInformation.stretcherPurposeDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "CR1",
    x12DataElement: "CR110",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "150",
    jsonElementName: "spinalManipulationServiceInformation",
    displayFieldName: "Spinal Manipulation Service Information",
    jsonFieldPath: "claimInformation.spinalManipulationServiceInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "CR2",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "151",
    jsonElementName: "patientConditionCode",
    displayFieldName: "Patient Condition Code",
    jsonFieldPath:
      "claimInformation.spinalManipulationServiceInformation.patientConditionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CR",
    x12DataElement: "CR208",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "152",
    jsonElementName: "patientConditionDescription1",
    displayFieldName: "Patient Condition Description1",
    jsonFieldPath:
      "claimInformation.spinalManipulationServiceInformation.patientConditionDescription1",
    lookupValues: "A,C,D,E,F,G,M",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "CR",
    x12DataElement: "CR210",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "153",
    jsonElementName: "patientConditionDescription2",
    displayFieldName: "Patient Condition Description2",
    jsonFieldPath:
      "claimInformation.spinalManipulationServiceInformation.patientConditionDescription2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2300",
    x12Segment: "CR",
    x12DataElement: "CR211",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "154",
    jsonElementName: "ambulanceCertification",
    displayFieldName: "Ambulance Certification",
    jsonFieldPath: "claimInformation.ambulanceCertification",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "155",
    jsonElementName: "certificationConditionIndicator",
    displayFieldName: "Certification Condition Indicator",
    jsonFieldPath:
      "claimInformation.ambulanceCertification[].certificationConditionIndicator",
    lookupValues: "N,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "156",
    jsonElementName: "conditionCodes",
    displayFieldName: "Condition Codes01",
    jsonFieldPath: "claimInformation.ambulanceCertification[].conditionCodes",
    lookupValues: "01,04,05,06,07,08,09,12",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "157",
    jsonElementName: "patientConditionInformationVision",
    displayFieldName: "Patient Condition Information Vision",
    jsonFieldPath: "claimInformation.patientConditionInformationVision",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "158",
    jsonElementName: "codeCategory",
    displayFieldName: "Code Category",
    jsonFieldPath:
      "claimInformation.patientConditionInformationVision[].codeCategory",
    lookupValues: "E1,E2,E3",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "159",
    jsonElementName: "certificationConditionIndicator",
    displayFieldName: "Certification Condition Indicator",
    jsonFieldPath:
      "claimInformation.patientConditionInformationVision[].certificationConditionIndicator",
    lookupValues: "N,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "160",
    jsonElementName: "conditionCodes",
    displayFieldName: "Condition Codes L1",
    jsonFieldPath:
      "claimInformation.patientConditionInformationVision[].conditionCodes",
    lookupValues: "L1,L2,L3,L4,L5",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "161",
    jsonElementName: "homeboundIndicator",
    displayFieldName: "Homebound Indicator",
    jsonFieldPath: "claimInformation.homeboundIndicator",
    lookupValues: "true,false",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "162",
    jsonElementName: "epsdtReferral",
    displayFieldName: "Epsdt Referral",
    jsonFieldPath: "claimInformation.epsdtReferral",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "163",
    jsonElementName: "certificationConditionCodeAppliesIndicator",
    displayFieldName: "Certification Condition Code Applies Indicator",
    jsonFieldPath:
      "claimInformation.epsdtReferral.certificationConditionCodeAppliesIndicator",
    lookupValues: "N,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "164",
    jsonElementName: "conditionCodes",
    displayFieldName: "Condition Codes A V",
    jsonFieldPath: "claimInformation.epsdtReferral.conditionCodes",
    lookupValues: "AV,NU,S2,ST",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "165",
    jsonElementName: "healthCareCodeInformation",
    displayFieldName: "Health Care Code Information",
    jsonFieldPath: "claimInformation.healthCareCodeInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "HI",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "166",
    jsonElementName: "diagnosisTypeCode",
    displayFieldName: "Diagnosis Type Code",
    jsonFieldPath:
      "claimInformation.healthCareCodeInformation[].diagnosisTypeCode",
    lookupValues: "BK,ABK,BF,ABF",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2440",
    x12Segment: "HI",
    x12DataElement: "HI01-01 ",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "167",
    jsonElementName: "diagnosisCode",
    displayFieldName: "Diagnosis Code",
    jsonFieldPath: "claimInformation.healthCareCodeInformation[].diagnosisCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "2440",
    x12Segment: "HI",
    x12DataElement: "HI01-02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "168",
    jsonElementName: "anesthesiaRelatedSurgicalProcedure",
    displayFieldName: "Anesthesia Related Surgical Procedure",
    jsonFieldPath: "claimInformation.anesthesiaRelatedSurgicalProcedure",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "2300",
    x12Segment: "HI",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "169",
    jsonElementName: "conditionInformation",
    displayFieldName: "Condition Information",
    jsonFieldPath: "claimInformation.conditionInformation[]",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "HI",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "170",
    jsonElementName: "conditionCodes",
    displayFieldName: "Condition Codes",
    jsonFieldPath: "claimInformation.conditionInformation[].conditionCodes",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "2300",
    x12Segment: "HI",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "171",
    jsonElementName: "claimPricingRepricingInformation",
    displayFieldName: "Claim Pricing Repricing Information",
    jsonFieldPath: "claimInformation.claimPricingRepricingInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2300",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "172",
    jsonElementName: "pricingMethodologyCode",
    displayFieldName: "Pricing Methodology Code",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.pricingMethodologyCode",
    lookupValues: "00,01,02,03,04,05,06,07,08,09,10,11,12,13,14",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "173",
    jsonElementName: "repricedAllowedAmount",
    displayFieldName: "Repriced Allowed Amount",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricedAllowedAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "174",
    jsonElementName: "repricedSavingAmount",
    displayFieldName: "Repriced Saving Amount",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricedSavingAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "175",
    jsonElementName: "repricingOrganizationIdentifier",
    displayFieldName: "Repricing Organization Identifier",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricingOrganizationIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "176",
    jsonElementName: "repricingPerDiemOrFlatRateAmoung",
    displayFieldName: "Repricing Per Diem Or Flat Rate Amoung",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricingPerDiemOrFlatRateAmoung",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "9",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "177",
    jsonElementName: "repricedApprovedAmbulatoryPatientGroupCode",
    displayFieldName: "Repriced Approved Ambulatory Patient Group Code",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricedApprovedAmbulatoryPatientGroupCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "178",
    jsonElementName: "repricedApprovedAmbulatoryPatientGroupAmount",
    displayFieldName: "Repriced Approved Ambulatory Patient Group Amount",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.repricedApprovedAmbulatoryPatientGroupAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP07",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "179",
    jsonElementName: "rejectReasonCode",
    displayFieldName: "Reject Reason Code",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.rejectReasonCode",
    lookupValues: "T1,T2,T3,T4,T5,T6",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP13",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "180",
    jsonElementName: "policyComplianceCode",
    displayFieldName: "Policy Compliance Code",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.policyComplianceCode",
    lookupValues: "1,2,3,4,5,6",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP14",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "181",
    jsonElementName: "exceptionCode",
    displayFieldName: "Exception Code",
    jsonFieldPath:
      "claimInformation.claimPricingRepricingInformation.exceptionCode",
    lookupValues: "1,2,3,4,5,6",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP15",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "182",
    jsonElementName: "serviceFacilityLocation",
    displayFieldName: "Service Facility Location",
    jsonFieldPath: "claimInformation.serviceFacilityLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "183",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2420C",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "184",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "185",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420C",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "186",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420C",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "187",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2420C",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "188",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2420C",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "189",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2420C",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "190",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2420C",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "191",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2420C",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "192",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2420C",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "193",
    jsonElementName: "phoneExtension",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "194",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2420C",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "195",
    jsonElementName: "qualifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2420C",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "196",
    jsonElementName: "identifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceFacilityLocation.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string ",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2420C",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "197",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Phone Name",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.phoneName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "198",
    jsonElementName: "phoneName",
    displayFieldName: "Phone Number",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "199",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "claimInformation.serviceFacilityLocation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "200",
    jsonElementName: "ambulancePickUpLocation",
    displayFieldName: "Ambulance Pick Up Location",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420G",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "201",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420G",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "202",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420G",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "203",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2420G",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "204",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2420G",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "205",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2420G",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "206",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "claimInformation.ambulancePickUpLocation.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2420G",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "207",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.ambulancePickUpLocation.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2420G",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "208",
    jsonElementName: "ambulanceDropOffLocation",
    displayFieldName: "Ambulance Drop Off Location",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2420H",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "209",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420H",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "210",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2420H",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "211",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2420H",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "212",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2420H",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "213",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2420H",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "214",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "claimInformation.ambulanceDropOffLocation.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2420H",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "215",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.ambulanceDropOffLocation.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2420H",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "216",
    jsonElementName: "otherSubscriberInformation",
    displayFieldName: "Other Subscriber Information",
    jsonFieldPath: "claimInformation.otherSubscriberInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "217",
    jsonElementName: "paymentResponsibilityLevelCode",
    displayFieldName: "Payment Responsibility Level Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].paymentResponsibilityLevelCode",
    lookupValues: "A,B,C,D,E,F,G,H,P,S,T,U",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR01",
    x12Description: "",
    x12ElementSeq: "1",
  },
  {
    serialNum: "218",
    jsonElementName: "individualRelationshipCode",
    displayFieldName: "Individual Relationship Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].individualRelationshipCode",
    lookupValues: "01,18,19,20,21,39,40,53,G8",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR02",
    x12Description: "",
    x12ElementSeq: "2",
  },
  {
    serialNum: "219",
    jsonElementName: "insuranceGroupOrPolicyNumber",
    displayFieldName: "Insurance Group Or Policy Number",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].insuranceGroupOrPolicyNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR03",
    x12Description: "",
    x12ElementSeq: "3",
  },
  {
    serialNum: "220",
    jsonElementName: "otherInsuredGroupName",
    displayFieldName: "Other Insured Group Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherInsuredGroupName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR04",
    x12Description: "",
    x12ElementSeq: "4",
  },
  {
    serialNum: "221",
    jsonElementName: "insuranceTypeCode",
    displayFieldName: "Insurance Type Code2",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].insuranceTypeCode",
    lookupValues: "12,13,14,15,17,41,42,43,47",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR05",
    x12Description: "",
    x12ElementSeq: "5",
  },
  {
    serialNum: "222",
    jsonElementName: "claimFilingIndicatorCode",
    displayFieldName: "Claim Filing Indicator Code1",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimFilingIndicatorCode",
    lookupValues:
      "11,12,13,14,15,16,17,AM,BL,CH,CI,DS,FI,HM,LM,MA,MB,MC,OF,TV,VA,WC,ZZ",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2320",
    x12Segment: "SBR",
    x12DataElement: "SBR09",
    x12Description: "",
    x12ElementSeq: "6",
  },
  {
    serialNum: "223",
    jsonElementName: "claimLevelAdjustments",
    displayFieldName: "Claim Level Adjustments",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2320",
    x12Segment: "CAS",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "224",
    jsonElementName: "adjustmentGroupCode",
    displayFieldName: "Adjustment Group Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments[].adjustmentGroupCode",
    lookupValues: "CO,CR,OA,PI,PR",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "225",
    jsonElementName: "adjustmentDetails",
    displayFieldName: "Adjustment Details",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments[].adjustmentDetails",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "226",
    jsonElementName: "adjustmentReasonCode",
    displayFieldName: "Adjustment Reason Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments[].adjustmentDetails[].adjustmentReasonCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "5",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "227",
    jsonElementName: "adjustmentAmount",
    displayFieldName: "Adjustment Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments[].adjustmentDetails[].adjustmentAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "228",
    jsonElementName: "adjustmentQuantity",
    displayFieldName: "Adjustment Quantity",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].claimLevelAdjustments[].adjustmentDetails[].adjustmentQuantity",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "229",
    jsonElementName: "payerPaidAmount",
    displayFieldName: "Payer Paid Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].payerPaidAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "230",
    jsonElementName: "nonCoveredChargeAmount",
    displayFieldName: "Non Covered Charge Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].nonCoveredChargeAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "231",
    jsonElementName: "remainingPatientLiability",
    displayFieldName: "Remaining Patient Liability",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].remainingPatientLiability",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "232",
    jsonElementName: "benefitsAssignmentCertificationIndicator",
    displayFieldName: "Benefits Assignment Certification Indicator",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].benefitsAssignmentCertificationIndicator",
    lookupValues: "N,W,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2320",
    x12Segment: "OI",
    x12DataElement: "OI03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "233",
    jsonElementName: "patientSignatureGeneratedForPatient",
    displayFieldName: "Patient Signature Generated For Patient",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].patientSignatureGeneratedForPatient",
    lookupValues: "true,false",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2320",
    x12Segment: "OI",
    x12DataElement: "OI04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "234",
    jsonElementName: "releaseOfInformationCode",
    displayFieldName: "Release Of Information Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].releaseOfInformationCode",
    lookupValues: "I,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2320",
    x12Segment: "OI",
    x12DataElement: "OI04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "235",
    jsonElementName: "medicareOutpatientAdjudication",
    displayFieldName: "Medicare Outpatient Adjudication",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "236",
    jsonElementName: "reimbursementRate",
    displayFieldName: "Reimbursement Rate",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication.reimbursementRate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "MOA01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "237",
    jsonElementName: "hcpcsPayableAmount",
    displayFieldName: "Hcpcs Payable Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication.hcpcsPayableAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "MOA02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "238",
    jsonElementName: "claimPaymentRemarkCode",
    displayFieldName: "Claim Payment Remark Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication.claimPaymentRemarkCode",
    lookupValues: "",
    controlType: "Textbox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "MOA03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "239",
    jsonElementName: "endStageRenalDiseasePaymentAmount",
    displayFieldName: "End Stage Renal Disease Payment Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication.endStageRenalDiseasePaymentAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "MOA08",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "240",
    jsonElementName: "nonPayableProfessionalComponentBilledAmount",
    displayFieldName: "Non Payable Professional Component Billed Amount",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].medicareOutpatientAdjudication.nonPayableProfessionalComponentBilledAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2320",
    x12Segment: "MOA",
    x12DataElement: "MOA09",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "241",
    jsonElementName: "otherSubscriberName",
    displayFieldName: "Other Subscriber Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330A",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "242",
    jsonElementName: "otherInsuredQualifier",
    displayFieldName: "Other Insured Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredQualifier",
    lookupValues: "1,2",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "243",
    jsonElementName: "otherInsuredLastName",
    displayFieldName: "Other Insured Last Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredLastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "244",
    jsonElementName: "otherInsuredFirstName",
    displayFieldName: "Other Insured First Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredFirstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "245",
    jsonElementName: "otherInsuredMiddleName",
    displayFieldName: "Other Insured Middle Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredMiddleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "246",
    jsonElementName: "otherInsuredNameSuffix",
    displayFieldName: "Other Insured Name Suffix",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredNameSuffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Other Insured Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "247",
    jsonElementName: "otherInsuredIdentifierTypeCode",
    displayFieldName: "Other Insured Identifier Type Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredIdentifierTypeCode",
    lookupValues: "II,MI",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM108",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "248",
    jsonElementName: "otherInsuredIdentifier",
    displayFieldName: "Other Insured Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2330A",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "Other Insured Identifier",
    x12ElementSeq: "8",
  },
  {
    serialNum: "249",
    jsonElementName: "otherInsuredAddress",
    displayFieldName: "Other Insured Address",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "250",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2330A",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "251",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2330A",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "252",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2330A",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "253",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2330A",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "254",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2330A",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "255",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330A",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "256",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAddress.countrySunDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2330A",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "257",
    jsonElementName: "otherInsuredAdditionalIdentifier",
    displayFieldName: "Other Insured Additional Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAdditionalIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330A",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Other Insured Additional Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "258",
    jsonElementName: "otherPayerName",
    displayFieldName: "Other Payer Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330B",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "259",
    jsonElementName: "otherInsuredAdditionalIdentifier",
    displayFieldName: "Other Insured Additional Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherSubscriberName.otherInsuredAdditionalIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330B",
    x12Segment: "NM1",
    x12DataElement: "NM111",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "260",
    jsonElementName: "otherPayerOrganizationName",
    displayFieldName: "Other Payer Organization Name",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerOrganizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2330B",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "Other Payer Organization Name",
    x12ElementSeq: "3",
  },
  {
    serialNum: "261",
    jsonElementName: "otherPayerIdentifierTypeCode",
    displayFieldName: "Other Payer Identifier Type Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerIdentifierTypeCode",
    lookupValues: "PI,XV",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2330B",
    x12Segment: "NM1",
    x12DataElement: "NM108",
    x12Description: "Identification Code Qualifier",
    x12ElementSeq: "4",
  },
  {
    serialNum: "262",
    jsonElementName: "otherPayerIdentifier",
    displayFieldName: "Other Payer Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2330B",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "Other Payer Primary Identifier",
    x12ElementSeq: "5",
  },
  {
    serialNum: "263",
    jsonElementName: "otherPayerAddress",
    displayFieldName: "Other Payer Address",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330B",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "264",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2330B",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "265",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "2330B",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "266",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "2330B",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "267",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2330B",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "268",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "2330B",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "269",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330B",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "270",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAddress.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2330B",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "271",
    jsonElementName: "otherPayerAdjudicationOrPaymentDate",
    displayFieldName: "Other Payer Adjudication Or Payment Date",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerAdjudicationOrPaymentDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2330B",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "Adjudication or Payment Date",
    x12ElementSeq: "3",
  },
  {
    serialNum: "272",
    jsonElementName: "otherPayerSecondaryIdentifier",
    displayFieldName: "Other Payer Secondary Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerSecondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "273",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerSecondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "Reference Identification Qualifier",
    x12ElementSeq: "1",
  },
  {
    serialNum: "274",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerSecondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Other Payer Secondary Identifier",
    x12ElementSeq: "2",
  },
  {
    serialNum: "275",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerSecondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "276",
    jsonElementName: "otherPayerPriorAuthorizationNumber",
    displayFieldName: "Other Payer Prior Authorization Number",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerPriorAuthorizationNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Other Payer Prior Authorization Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "277",
    jsonElementName: "otherPayerPriorAuthorizationOrReferralNumber",
    displayFieldName: "Other Payer Prior Authorization Or Referral Number",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerPriorAuthorizationOrReferralNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Other Payer Prior Authorization or Referral Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "278",
    jsonElementName: "otherPayerClaimAdjustmentIndicator",
    displayFieldName: "Other Payer Claim Adjustment Indicator",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerClaimAdjustmentIndicator",
    lookupValues: "true,false",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "279",
    jsonElementName: "otherPayerClaimControlNumber",
    displayFieldName: "Other Payer Claim Control Number",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerName.otherPayerClaimControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330B",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Other Payer's Claim Control Number",
    x12ElementSeq: "2",
  },
  {
    serialNum: "280",
    jsonElementName: "otherPayerReferringProvider",
    displayFieldName: "Other Payer Referring Provider",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerReferringProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "281",
    jsonElementName: "otherPayerReferringProviderIdentifier",
    displayFieldName: "Other Payer Referring Provider Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerReferringProvider[].otherPayerReferringProviderIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "282",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerReferringProvider[].otherPayerReferringProviderIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "283",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerReferringProvider[].otherPayerReferringProviderIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "284",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerReferringProvider[].otherPayerReferringProviderIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "285",
    jsonElementName: "otherPayerRenderingProvider",
    displayFieldName: "Other Payer Rendering Provider",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "286",
    jsonElementName: "entityTypeQualifier",
    displayFieldName: "Entity Type Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider[].entityTypeQualifier",
    lookupValues: "1,2",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2330D",
    x12Segment: "NM1",
    x12DataElement: "NM102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "287",
    jsonElementName: "otherPayerRenderingProviderSecondaryIdentifier",
    displayFieldName: "Other Payer Rendering Provider Secondary Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider[].otherPayerRenderingProviderSecondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330D",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "288",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider[].otherPayerRenderingProviderSecondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330D",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "289",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider[].otherPayerRenderingProviderSecondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330D",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "290",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerRenderingProvider[].otherPayerRenderingProviderSecondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330D",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "291",
    jsonElementName: "otherPayerServiceFacilityLocation",
    displayFieldName: "Other Payer Service Facility Location",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerServiceFacilityLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "292",
    jsonElementName: "otherPayerServiceFacilityLocationSecondaryIdentifier",
    displayFieldName:
      "Other Payer Service Facility Location Secondary Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerServiceFacilityLocation[].otherPayerServiceFacilityLocationSecondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330E",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "293",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerServiceFacilityLocation[].otherPayerServiceFacilityLocationSecondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "294",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerServiceFacilityLocation[].otherPayerServiceFacilityLocationSecondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "295",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerServiceFacilityLocation[].otherPayerServiceFacilityLocationSecondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330E",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "296",
    jsonElementName: "otherPayerSupervisingProvider",
    displayFieldName: "Other Payer Supervising Provider",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerSupervisingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "297",
    jsonElementName: "otherPayerSupervisingProviderIdentifier",
    displayFieldName: "Other Payer Supervising Provider Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerSupervisingProvider[].otherPayerSupervisingProviderIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330F",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "298",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerSupervisingProvider[].otherPayerSupervisingProviderIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330F",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "299",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerSupervisingProvider[].otherPayerSupervisingProviderIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330F",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "300",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerSupervisingProvider[].otherPayerSupervisingProviderIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330F",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "301",
    jsonElementName: "otherPayerBillingProvider",
    displayFieldName: "Other Payer Billing Provider",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "302",
    jsonElementName: "entityTypeQualifier",
    displayFieldName: "Entity Type Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider[].entityTypeQualifier",
    lookupValues: "1,2",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2330G",
    x12Segment: "NM1",
    x12DataElement: "NM102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "303",
    jsonElementName: "otherPayerBillingProviderIdentifier",
    displayFieldName: "Other Payer Billing Provider Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider[].otherPayerBillingProviderIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2330G",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "304",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider[].otherPayerBillingProviderIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2330G",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "305",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider[].otherPayerBillingProviderIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2330G",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "306",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.otherSubscriberInformation[].otherPayerBillingProvider[].otherPayerBillingProviderIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2330G",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "307",
    jsonElementName: "serviceLines",
    displayFieldName: "Service Lines",
    jsonFieldPath: "claimInformation.serviceLines",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "308",
    jsonElementName: "assignedNumber",
    displayFieldName: "Assigned Number",
    jsonFieldPath: "claimInformation.serviceLines[].assignedNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "2400",
    x12Segment: "LX",
    x12DataElement: "LX01",
    x12Description: "Assigned Number",
    x12ElementSeq: "1",
  },
  {
    serialNum: "309",
    jsonElementName: "serviceDate",
    displayFieldName: "Service Date",
    jsonFieldPath: "claimInformation.serviceLines[].serviceDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "310",
    jsonElementName: "serviceDateEnd",
    displayFieldName: "Service Date End",
    jsonFieldPath: "claimInformation.serviceLines[].serviceDateEnd",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "311",
    jsonElementName: "providerControlNumber",
    displayFieldName: "Provider Control Number",
    jsonFieldPath: "claimInformation.serviceLines[].providerControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF04-02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "312",
    jsonElementName: "professionalService",
    displayFieldName: "Professional Service",
    jsonFieldPath: "claimInformation.serviceLines[].professionalService",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "313",
    jsonElementName: "procedureIdentifier",
    displayFieldName: "Procedure Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.procedureIdentifier",
    lookupValues: "ER,HC,IV,WK",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV101-01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "314",
    jsonElementName: "procedureCode",
    displayFieldName: "Procedure Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.procedureCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "48",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV101-02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "315",
    jsonElementName: "procedureModifiers",
    displayFieldName: "Procedure Modifiers",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.procedureModifiers",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "Array",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV101-03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "316",
    jsonElementName: "description",
    displayFieldName: "Description",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.description",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV101-07",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "317",
    jsonElementName: "lineItemChargeAmount",
    displayFieldName: "Line Item Charge Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.lineItemChargeAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "318",
    jsonElementName: "measurementUnit",
    displayFieldName: "Measurement Unit",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.measurementUnit",
    lookupValues: "MJ,UN",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "319",
    jsonElementName: "serviceUnitCount",
    displayFieldName: "Service Unit Count",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.serviceUnitCount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "320",
    jsonElementName: "placeOfServiceCode",
    displayFieldName: "Place Of Service Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.placeOfServiceCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "321",
    jsonElementName: "compositeDiagnosisCodePointers",
    displayFieldName: "Composite Diagnosis Code Pointers",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.compositeDiagnosisCodePointers",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "SVC107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "322",
    jsonElementName: "diagnosisCodePointers",
    displayFieldName: "Diagnosis Code Pointers",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV107-01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "323",
    jsonElementName: "emergencyIndicator",
    displayFieldName: "Emergency Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.emergencyIndicator",
    lookupValues: "Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "324",
    jsonElementName: "epsdtIndicator",
    displayFieldName: "Epsdt Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.epsdtIndicator",
    lookupValues: "Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV111",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "325",
    jsonElementName: "familyPlanningIndicator",
    displayFieldName: "Family Planning Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.familyPlanningIndicator",
    lookupValues: "Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV112",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "326",
    jsonElementName: "copayStatusCode",
    displayFieldName: "Copay Status Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].professionalService.copayStatusCode",
    lookupValues: "0",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "SV1",
    x12DataElement: "SV115",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "327",
    jsonElementName: "durableMedicalEquipmentService",
    displayFieldName: "Durable Medical Equipment Service",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentService",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "SV5",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "328",
    jsonElementName: "days",
    displayFieldName: "Days",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentService.days",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2410",
    x12Segment: "SV5",
    x12DataElement: "SV503",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "329",
    jsonElementName: "rentalPrice",
    displayFieldName: "Rental Price",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentService.rentalPrice",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2410",
    x12Segment: "SV5",
    x12DataElement: "SV504",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "330",
    jsonElementName: "purchasePrice",
    displayFieldName: "Purchase Price",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentService.purchasePrice",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2410",
    x12Segment: "SV5",
    x12DataElement: "SV505",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "331",
    jsonElementName: "frequencyCode",
    displayFieldName: "Frequency Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentService.frequencyCode",
    lookupValues: "1,4,6",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2410",
    x12Segment: "SV5",
    x12DataElement: "SV506",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "332",
    jsonElementName: "serviceLineSupplementalInformation",
    displayFieldName: "Service Line Supplemental Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineSupplementalInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "333",
    jsonElementName: "attachmentReportTypeCode",
    displayFieldName: "Attachment Report Type Code3",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineSupplementalInformation[].attachmentReportTypeCode",
    lookupValues: "03,04,05,06,07,08,09,10,11,13,15,21,A3,A4,AM,AS,B2,B3,B4,BR",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "334",
    jsonElementName: "attachmentTransmissionCode",
    displayFieldName: "Attachment Transmission Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineSupplementalInformation[].attachmentTransmissionCode",
    lookupValues: "AA,BM,EL,EM,FT,FX",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "335",
    jsonElementName: "attachmentControlNumber",
    displayFieldName: "Attachment Control Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineSupplementalInformation[].attachmentControlNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "336",
    jsonElementName: "durableMedicalEquipmentCertificateOfMedicalNecessity",
    displayFieldName:
      "Durable Medical Equipment Certificate Of Medical Necessity",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentCertificateOfMedicalNecessity",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "337",
    jsonElementName: "attachmentTransmissionCode",
    displayFieldName: "Attachment Transmission Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentCertificateOfMedicalNecessity.attachmentTransmissionCode",
    lookupValues: "AB,AD,AF,AG,NS",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "PWK",
    x12DataElement: "PWK02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "338",
    jsonElementName: "ambulanceTransportInformation",
    displayFieldName: "Ambulance Transport Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "339",
    jsonElementName: "patientWeightInPounds",
    displayFieldName: "Patient Weight In Pounds",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation.patientWeightInPounds",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "CR102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "340",
    jsonElementName: "ambulanceTransportReasonCode",
    displayFieldName: "Ambulance Transport Reason Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation.ambulanceTransportReasonCode",
    lookupValues: "A,B,C,D,E",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "CR104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "341",
    jsonElementName: "transportDistanceInMiles",
    displayFieldName: "Transport Distance In Miles",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation.transportDistanceInMiles",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "CR106",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "342",
    jsonElementName: "roundTripPurposeDescription",
    displayFieldName: "Round Trip Purpose Description",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation.roundTripPurposeDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "CR109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "343",
    jsonElementName: "stretcherPurposeDescription",
    displayFieldName: "Stretcher Purpose Description",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceTransportInformation.stretcherPurposeDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "CR1",
    x12DataElement: "CR110",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "344",
    jsonElementName: "durableMedicalEquipmentCertification",
    displayFieldName: "Durable Medical Equipment Certification",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentCertification",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "CR3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "345",
    jsonElementName: "certificationTypeCode",
    displayFieldName: "Certification Type Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentCertification.certificationTypeCode",
    lookupValues: "I,R,S",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "CR3",
    x12DataElement: "CR301",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "346",
    jsonElementName: "durableMedicalEquipmentDurationInMonths",
    displayFieldName: "Durable Medical Equipment Duration In Months",
    jsonFieldPath:
      "claimInformation.serviceLines[].durableMedicalEquipmentCertification.durableMedicalEquipmentDurationInMonths",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2400",
    x12Segment: "CR3",
    x12DataElement: "CR303",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "347",
    jsonElementName: "ambulanceCertification",
    displayFieldName: "Ambulance Certification",
    jsonFieldPath: "claimInformation.serviceLines[].ambulanceCertification",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "348",
    jsonElementName: "certificationConditionIndicator",
    displayFieldName: "Certification Condition Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceCertification[].certificationConditionIndicator",
    lookupValues: "N,Y",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "349",
    jsonElementName: "conditionCodes",
    displayFieldName: "Condition Codes01",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceCertification[].conditionCodes",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "Array",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2300",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "350",
    jsonElementName: "hospiceEmployeeIndicator",
    displayFieldName: "Hospice Employee Indicator",
    jsonFieldPath: "claimInformation.serviceLines[].hospiceEmployeeIndicator",
    lookupValues: "true, false",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "351",
    jsonElementName: "conditionIndicatorDurableMedicalEquipment",
    displayFieldName: "Condition Indicator Durable Medical Equipment",
    jsonFieldPath:
      "claimInformation.serviceLines[].conditionIndicatorDurableMedicalEquipment",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "352",
    jsonElementName: "certificationConditionIndicator",
    displayFieldName: "Certification Condition Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].conditionIndicatorDurableMedicalEquipment.certificationConditionIndicator",
    lookupValues: "Y,N",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2400",
    x12Segment: "CRC",
    x12DataElement: "CRC02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "353",
    jsonElementName: "conditionIndicator",
    displayFieldName: "Condition Indicator",
    jsonFieldPath:
      "claimInformation.serviceLines[].conditionIndicatorDurableMedicalEquipment.conditionIndicator",
    lookupValues: "38,ZV",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2400",
    x12Segment: "CRC",
    x12DataElement: "CRC03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "354",
    jsonElementName: "conditionIndicatorCode",
    displayFieldName: "Condition Indicator Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].conditionIndicatorDurableMedicalEquipment.conditionIndicatorCode",
    lookupValues: "38,ZV",
    controlType: "lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2400",
    x12Segment: "CRC",
    x12DataElement: "CRC04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "355",
    jsonElementName: "serviceLineDateInformation",
    displayFieldName: "Service Line Date Information",
    jsonFieldPath: "claimInformation.serviceLines[].serviceLineDateInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "356",
    jsonElementName: "prescriptionDate",
    displayFieldName: "Prescription Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.prescriptionDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "357",
    jsonElementName: "certificationRevisionOrRecertificationDate",
    displayFieldName: "Certification Revision Or Recertification Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.certificationRevisionOrRecertificationDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "358",
    jsonElementName: "beginTherapyDate",
    displayFieldName: "Begin Therapy Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.beginTherapyDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "359",
    jsonElementName: "lastCertificationDate",
    displayFieldName: "Last Certification Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.lastCertificationDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "360",
    jsonElementName: "treatmentOrTherapyDate",
    displayFieldName: "Treatment Or Therapy Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.treatmentOrTherapyDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "361",
    jsonElementName: "hemoglobinTestDate",
    displayFieldName: "Hemoglobin Test Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.hemoglobinTestDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "362",
    jsonElementName: "serumCreatineTestDate",
    displayFieldName: "Serum Creatine Test Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.serumCreatineTestDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "363",
    jsonElementName: "shippedDate",
    displayFieldName: "Shipped Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.shippedDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "364",
    jsonElementName: "lastXRayDate",
    displayFieldName: "Last X Ray Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.lastXRayDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "365",
    jsonElementName: "initialTreatmentDate",
    displayFieldName: "Initial Treatment Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineDateInformation.initialTreatmentDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2400",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "366",
    jsonElementName: "ambulancePatientCount",
    displayFieldName: "Ambulance Patient Count",
    jsonFieldPath: "claimInformation.serviceLines[].ambulancePatientCount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2400",
    x12Segment: "QTY",
    x12DataElement: "QTY02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "367",
    jsonElementName: "obstetricAnesthesiaAdditionalUnits",
    displayFieldName: "Obstetric Anesthesia Additional Units",
    jsonFieldPath:
      "claimInformation.serviceLines[].obstetricAnesthesiaAdditionalUnits",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2400",
    x12Segment: "QTY",
    x12DataElement: "QTY02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "368",
    jsonElementName: "testResults",
    displayFieldName: "Test Results",
    jsonFieldPath: "claimInformation.serviceLines[].testResults",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "369",
    jsonElementName: "measurementReferenceIdentificationCode",
    displayFieldName: "Measurement Reference Identification Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].testResults[].measurementReferenceIdentificationCode",
    lookupValues: "OG,TR",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2400",
    x12Segment: "MEA",
    x12DataElement: "MEA01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "370",
    jsonElementName: "measurementQualifier",
    displayFieldName: "Measurement Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].testResults[].measurementQualifier",
    lookupValues: "HT,R1,R2,R3,R4",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2400",
    x12Segment: "MEA",
    x12DataElement: "MEA02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "371",
    jsonElementName: "testResults",
    displayFieldName: "Test Results",
    jsonFieldPath: "claimInformation.serviceLines[].testResults[].testResults",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "20",
    x12Loop: "2400",
    x12Segment: "MEA",
    x12DataElement: "MEA03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "372",
    jsonElementName: "contractInformation",
    displayFieldName: "Contract Information",
    jsonFieldPath: "claimInformation.serviceLines[].contractInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "373",
    jsonElementName: "contractTypeCode",
    displayFieldName: "Contract Type Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.contractTypeCode",
    lookupValues: "01,02,03,04,05,06,09",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN101",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "374",
    jsonElementName: "contractAmount",
    displayFieldName: "Contract Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.contractAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "str",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "375",
    jsonElementName: "contractPercentage",
    displayFieldName: "Contract Percentage",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.contractPercentage",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "str",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "376",
    jsonElementName: "contractCode",
    displayFieldName: "Contract Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.contractCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "str",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "377",
    jsonElementName: "termsDiscountPercentage",
    displayFieldName: "Terms Discount Percentage",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.termsDiscountPercentage",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "str",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "378",
    jsonElementName: "contractVersionIdentifier",
    displayFieldName: "Contract Version Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].contractInformation.contractVersionIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "str",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "CN1",
    x12DataElement: "CN106",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "379",
    jsonElementName: "serviceLineReferenceInformation",
    displayFieldName: "Service Line Reference Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "380",
    jsonElementName: "repricedLineItemReferenceNumber",
    displayFieldName: "Repriced Line Item Reference Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.repricedLineItemReferenceNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "381",
    jsonElementName: "adjustedRepricedLineItemReferenceNumber",
    displayFieldName: "Adjusted Repriced Line Item Reference Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.adjustedRepricedLineItemReferenceNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "382",
    jsonElementName: "priorAuthorization",
    displayFieldName: "Prior Authorization",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.priorAuthorization",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "383",
    jsonElementName: "priorAuthorizationOrReferralNumber",
    displayFieldName: "Prior Authorization Or Referral Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.priorAuthorization[].priorAuthorizationOrReferralNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "384",
    jsonElementName: "otherPayerPrimaryIdentifier",
    displayFieldName: "Other Payer Primary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.priorAuthorization[].otherPayerPrimaryIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF04-2",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "385",
    jsonElementName: "mammographyCertificationNumber",
    displayFieldName: "Mammography Certification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.mammographyCertificationNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "386",
    jsonElementName: "clinicalLaboratoryImprovementAmendmentNumber",
    displayFieldName: "Clinical Laboratory Improvement Amendment Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.clinicalLaboratoryImprovementAmendmentNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "387",
    jsonElementName: "referringCliaNumber",
    displayFieldName: "Referring Clia Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.referringCliaNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "388",
    jsonElementName: "immunizationBatchNumber",
    displayFieldName: "Immunization Batch Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.immunizationBatchNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "389",
    jsonElementName: "referralNumber",
    displayFieldName: "Referral Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceLineReferenceInformation.referralNumber",
    lookupValues: "9F",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "390",
    jsonElementName: "salesTaxAmount",
    displayFieldName: "Sales Tax Amount",
    jsonFieldPath: "claimInformation.serviceLines[].salesTaxAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2400",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "391",
    jsonElementName: "postageTaxAmount",
    displayFieldName: "Postage Tax Amount",
    jsonFieldPath: "claimInformation.serviceLines[].postageTaxAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2400",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "392",
    jsonElementName: "fileInformation",
    displayFieldName: "File Information",
    jsonFieldPath: "claimInformation.serviceLines[].fileInformation",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String Array",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "K3",
    x12DataElement: "K301",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "393",
    jsonElementName: "additionalNotes",
    displayFieldName: "Additional Notes",
    jsonFieldPath: "claimInformation.serviceLines[].additionalNotes",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "394",
    jsonElementName: "goalRehabOrDischargePlans",
    displayFieldName: "Goal Rehab Or Discharge Plans",
    jsonFieldPath: "claimInformation.serviceLines[].goalRehabOrDischargePlans",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "395",
    jsonElementName: "thirdPartyOrganizationNotes",
    displayFieldName: "Third Party Organization Notes",
    jsonFieldPath:
      "claimInformation.serviceLines[].thirdPartyOrganizationNotes",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2400",
    x12Segment: "NTE",
    x12DataElement: "NTE02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "396",
    jsonElementName: "purchasedServiceInformation",
    displayFieldName: "Purchased Service Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "397",
    jsonElementName: "purchasedServiceProviderIdentifier",
    displayFieldName: "Purchased Service Provider Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceInformation.purchasedServiceProviderIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2400",
    x12Segment: "PS1",
    x12DataElement: "PS101",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "398",
    jsonElementName: "purchasedServiceChargeAmount",
    displayFieldName: "Purchased Service Charge Amount0",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceInformation.purchasedServiceChargeAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2400",
    x12Segment: "PS1",
    x12DataElement: "PS102",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "399",
    jsonElementName: "linePricingRepricingInformation",
    displayFieldName: "Line Pricing Repricing Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "HCP",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "400",
    jsonElementName: "pricingMethodologyCode",
    displayFieldName: "Pricing Methodology Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.pricingMethodologyCode",
    lookupValues: "00,01,02,03,04,05,06,07,08,09,10,11,12,13,14",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "401",
    jsonElementName: "repricedAllowedAmount",
    displayFieldName: "Repriced Allowed Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricedAllowedAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "402",
    jsonElementName: "repricedSavingAmount",
    displayFieldName: "Repriced Saving Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricedSavingAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "403",
    jsonElementName: "repricingOrganizationIdentifier",
    displayFieldName: "Repricing Organization Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricingOrganizationIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "404",
    jsonElementName: "repricingPerDiemOrFlatRateAmoung",
    displayFieldName: "Repricing Per Diem Or Flat Rate Amoung",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricingPerDiemOrFlatRateAmoung",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "9",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "405",
    jsonElementName: "repricedApprovedAmbulatoryPatientGroupCode",
    displayFieldName: "Repriced Approved Ambulatory Patient Group Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricedApprovedAmbulatoryPatientGroupCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "406",
    jsonElementName: "repricedApprovedAmbulatoryPatientGroupAmount",
    displayFieldName: "Repriced Approved Ambulatory Patient Group Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.repricedApprovedAmbulatoryPatientGroupAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP07",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "407",
    jsonElementName: "rejectReasonCode",
    displayFieldName: "Reject Reason Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.rejectReasonCode",
    lookupValues: "T1,T2,T3,T4,T5,T6",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP13",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "408",
    jsonElementName: "policyComplianceCode",
    displayFieldName: "Policy Compliance Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.policyComplianceCode",
    lookupValues: "1,2,3,4,5",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP14",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "409",
    jsonElementName: "exceptionCode",
    displayFieldName: "Exception Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].linePricingRepricingInformation.exceptionCode",
    lookupValues: "1,2,3,4,5,6",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2300",
    x12Segment: "HCP",
    x12DataElement: "HCP15",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "410",
    jsonElementName: "drugIdentification",
    displayFieldName: "Drug Identification",
    jsonFieldPath: "claimInformation.serviceLines[].drugIdentification",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2410",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "411",
    jsonElementName: "serviceIdQualifier",
    displayFieldName: "Service Id Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.serviceIdQualifier",
    lookupValues: "EN,EO,HI,N4,ON,UK,UP",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2410",
    x12Segment: "LIN",
    x12DataElement: "LIN02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "412",
    jsonElementName: "nationalDrugCode",
    displayFieldName: "National Drug Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.nationalDrugCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "48",
    x12Loop: "2410",
    x12Segment: "LIN",
    x12DataElement: "LIN03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "413",
    jsonElementName: "nationalDrugUnitCount",
    displayFieldName: "National Drug Unit Count",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.nationalDrugUnitCount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2410",
    x12Segment: "CTP",
    x12DataElement: "CTP04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "414",
    jsonElementName: "measurementUnitCode",
    displayFieldName: "Measurement Unit Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.measurementUnitCode",
    lookupValues: "F2,GR,ME,ML,UN",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "?",
    maxLength: "?",
    x12Loop: "2410",
    x12Segment: "CTP",
    x12DataElement: "CTP05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "415",
    jsonElementName: "linkSequenceNumber",
    displayFieldName: "Link Sequence Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.linkSequenceNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2410",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "416",
    jsonElementName: "pharmacyPrescriptionNumber",
    displayFieldName: "Pharmacy Prescription Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].drugIdentification.pharmacyPrescriptionNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2410",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "417",
    jsonElementName: "renderingProvider",
    displayFieldName: "Rendering Provider",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420F",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "418",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "419",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2420F",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "420",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2420F",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "421",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2420F",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "422",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2420F",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "423",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2420F",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "424",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "425",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "426",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "427",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "428",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "429",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "430",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "431",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "432",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "433",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "434",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "435",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "436",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "claimInformation.serviceLines[].renderingProvider.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "437",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "438",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "439",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "440",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N5",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "441",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N6",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "442",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N7",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "443",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N8",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "444",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "445",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "446",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "447",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "448",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "449",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "450",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "451",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "452",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "453",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "454",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].renderingProvider.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "455",
    jsonElementName: "purchasedServiceProvider",
    displayFieldName: "Purchased Service Provider",
    jsonFieldPath: "claimInformation.serviceLines[].purchasedServiceProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420B",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "456",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "457",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "458",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "459",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "460",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "461",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "462",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "463",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "464",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "465",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "466",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "467",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "468",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "469",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "470",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "471",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "472",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "473",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "474",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "475",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "476",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "477",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "478",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "479",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "480",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "481",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "482",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "483",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "484",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "485",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "486",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "487",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "488",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "489",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420B",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "490",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "491",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "492",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].purchasedServiceProvider.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "493",
    jsonElementName: "serviceFacilityLocation",
    displayFieldName: "Service Facility Location",
    jsonFieldPath: "claimInformation.serviceLines[].serviceFacilityLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "494",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2420C",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "495",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "496",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "497",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "498",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "499",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "500",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "501",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "502",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "503",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2420C",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "504",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420C",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "505",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "506",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "507",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "508",
    jsonElementName: "phoneName",
    displayFieldName: "Phone Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.phoneName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "509",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "510",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].serviceFacilityLocation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "2310C",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "511",
    jsonElementName: "supervisingProvider",
    displayFieldName: "Supervising Provider",
    jsonFieldPath: "claimInformation.serviceLines[].supervisingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420D",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "512",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "513",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "claimInformation.serviceLines[].supervisingProvider.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "514",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "claimInformation.serviceLines[].supervisingProvider.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "515",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "516",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "517",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "518",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "519",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "520",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "521",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "claimInformation.serviceLines[].supervisingProvider.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "522",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "523",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "524",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "525",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "526",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "527",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "528",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "claimInformation.serviceLines[].supervisingProvider.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Supervising Provider Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "529",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "530",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "531",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "532",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "533",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "534",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "535",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "536",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "537",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "538",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "539",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "540",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "541",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "542",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "543",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "544",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "545",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420D",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "546",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "547",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "548",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].supervisingProvider.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "549",
    jsonElementName: "orderingProvider",
    displayFieldName: "Ordering Provider",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420E",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "550",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "551",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "552",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "553",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "554",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "555",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "556",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "557",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "558",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "559",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "560",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "561",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "562",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "563",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "564",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "565",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "566",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "567",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "568",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "claimInformation.serviceLines[].orderingProvider.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420E",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "569",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "570",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "571",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "572",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "573",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "574",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "575",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "576",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420E",
    x12Segment: "PER",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "577",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "578",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "579",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "580",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "581",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "582",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "583",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420E",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "584",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "585",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "586",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].orderingProvider.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "587",
    jsonElementName: "referringProvider",
    displayFieldName: "Referring Provider",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420F",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "588",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "589",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "NM",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "590",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "591",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "592",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "593",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "594",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "595",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "596",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "597",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "598",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "599",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "600",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "601",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "602",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "603",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "604",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "605",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "606",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "claimInformation.serviceLines[].referringProvider.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "607",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "608",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "609",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "610",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "611",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "612",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "613",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "614",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "615",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "616",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "617",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "618",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "619",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "620",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "??",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "621",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.secondaryIdentifier",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "S",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420F",
    x12Segment: "REF",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "622",
    jsonElementName: "qualifier",
    displayFieldName: "Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.secondaryIdentifier[].qualifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "623",
    jsonElementName: "identifier",
    displayFieldName: "Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.secondaryIdentifier[].identifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "624",
    jsonElementName: "otherIdentifier",
    displayFieldName: "Other Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].referringProvider.secondaryIdentifier[].otherIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "625",
    jsonElementName: "ambulancePickUpLocation",
    displayFieldName: "Ambulance Pick Up Location",
    jsonFieldPath: "claimInformation.serviceLines[].ambulancePickUpLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420G",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "626",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "627",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "628",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "629",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "630",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "631",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "632",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulancePickUpLocation.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "633",
    jsonElementName: "ambulanceDropOffLocation",
    displayFieldName: "Ambulance Drop Off Location",
    jsonFieldPath: "claimInformation.serviceLines[].ambulanceDropOffLocation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420H",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "634",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "635",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "636",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "637",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "638",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "639",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "640",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].ambulanceDropOffLocation.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "641",
    jsonElementName: "lineAdjudicationInformation",
    displayFieldName: "Line Adjudication Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "642",
    jsonElementName: "otherPayerPrimaryIdentifier",
    displayFieldName: "Other Payer Primary Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].otherPayerPrimaryIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "643",
    jsonElementName: "serviceLinePaidAmount",
    displayFieldName: "Service Line Paid Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].serviceLinePaidAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "644",
    jsonElementName: "serviceIdQualifier",
    displayFieldName: "Service Id Qualifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].serviceIdQualifier",
    lookupValues: "ER,HC,HP,IV,WK",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD03-01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "645",
    jsonElementName: "procedureCode",
    displayFieldName: "Procedure Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].procedureCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "48",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD03-02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "646",
    jsonElementName: "procedureModifier",
    displayFieldName: "Procedure Modifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].procedureModifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD03 (SVD03-03 to SVD03-06)",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "647",
    jsonElementName: "procedureCodeDescription",
    displayFieldName: "Procedure Code Description",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].procedureCodeDescription",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "80",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD03-07",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "648",
    jsonElementName: "paidServiceUnitCount",
    displayFieldName: "Paid Service Unit Count",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].paidServiceUnitCount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "15",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "649",
    jsonElementName: "bundledOrUnbundledLineNumber",
    displayFieldName: "Bundled Or Unbundled Line Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].bundledOrUnbundledLineNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "2430",
    x12Segment: "SVD",
    x12DataElement: "SVD06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "650",
    jsonElementName: "claimAdjustmentInformation",
    displayFieldName: "Claim Adjustment Information",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "651",
    jsonElementName: "adjustmentGroupCode",
    displayFieldName: "Adjustment Group Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation[].adjustmentGroupCode",
    lookupValues: "CO,CR,OA,PI,PR",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "652",
    jsonElementName: "adjustmentDetails",
    displayFieldName: "Adjustment Details",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation[].adjustmentDetails",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "653",
    jsonElementName: "adjustmentReasonCode",
    displayFieldName: "Adjustment Reason Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation[].adjustmentDetails[].adjustmentReasonCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "5",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "654",
    jsonElementName: "adjustmentAmount",
    displayFieldName: "Adjustment Amount",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation[].adjustmentDetails[].adjustmentAmount",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "655",
    jsonElementName: "adjustmentQuantity",
    displayFieldName: "Adjustment Quantity",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].claimAdjustmentInformation[].adjustmentDetails[].adjustmentQuantity",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "25",
    x12Loop: "2430",
    x12Segment: "CAS",
    x12DataElement: "CAS04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "656",
    jsonElementName: "adjudicationOrPaymentDate",
    displayFieldName: "Adjudication Or Payment Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].adjudicationOrPaymentDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "2330B",
    x12Segment: "DTP",
    x12DataElement: "DTP03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "657",
    jsonElementName: "remainingPatientLiability",
    displayFieldName: "Remaining Patient Liability",
    jsonFieldPath:
      "claimInformation.serviceLines[].lineAdjudicationInformation[].remainingPatientLiability",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "18",
    x12Loop: "2430",
    x12Segment: "AMT",
    x12DataElement: "AMT02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "658",
    jsonElementName: "formIdentification",
    displayFieldName: "Form Identification",
    jsonFieldPath: "claimInformation.serviceLines[].formIdentification",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2440",
    x12Segment: "LQ",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "659",
    jsonElementName: "formTypeCode",
    displayFieldName: "Form Type Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.formTypeCode",
    lookupValues: "AS,UT",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "2440",
    x12Segment: "LQ",
    x12DataElement: "LQ01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "660",
    jsonElementName: "formIdentifier",
    displayFieldName: "Form Identifier",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.formIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "30",
    x12Loop: "2440",
    x12Segment: "LQ",
    x12DataElement: "LQ02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "661",
    jsonElementName: "supportingDocumentation",
    displayFieldName: "Supporting Documentation",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object Array",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "662",
    jsonElementName: "questionNumber",
    displayFieldName: "Question Number",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation.questionNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "20",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "FRM01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "663",
    jsonElementName: "questionResponseCode",
    displayFieldName: "Question Response Code",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation.questionResponseCode",
    lookupValues: "N,W,Y",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "FRM02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "664",
    jsonElementName: "questionResponse",
    displayFieldName: "Question Response",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation.questionResponse",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "FRM03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "665",
    jsonElementName: "questionResponseAsDate",
    displayFieldName: "Question Response As Date",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation.questionResponseAsDate",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "DateNum",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "8",
    maxLength: "8",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "FRM04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "666",
    jsonElementName: "questionResponseAsPercent",
    displayFieldName: "Question Response As Percent",
    jsonFieldPath:
      "claimInformation.serviceLines[].formIdentification.supportingDocumentation.questionResponseAsPercent",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Decimal",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "6",
    x12Loop: "2440",
    x12Segment: "FRM",
    x12DataElement: "FRM05",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "667",
    jsonElementName: "payToAddress",
    displayFieldName: "Pay To Address",
    jsonFieldPath: "Professoional.payToAddress",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010AB",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "668",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "Professoional.payToAddress.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "669",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "Professoional.payToAddress.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "670",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "Professoional.payToAddress.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "671",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "Professoional.payToAddress.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "672",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "Professoional.payToAddress.postalcode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "673",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "Professoional.payToAddress.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "674",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "Professoional.payToAddress.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "675",
    jsonElementName: "payToPlan",
    displayFieldName: "Pay To Plan",
    jsonFieldPath: "Professoional.payToPlan",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010AC",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "676",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "Professoional.payToPlan.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "2010AC",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "677",
    jsonElementName: "primaryIdentifierTypeCode",
    displayFieldName: "Primary Identifier Type Code",
    jsonFieldPath: "Professoional.payToPlan.primaryIdentifierTypeCode",
    lookupValues: "PI,XV",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "2",
    x12Loop: "2010AC",
    x12Segment: "NM1",
    x12DataElement: "NM108",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "678",
    jsonElementName: "primaryIdentifier",
    displayFieldName: "Primary Identifier",
    jsonFieldPath: "Professoional.payToPlan.primaryIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "2010AC",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "679",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "Professoional.payToPlan.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2010AC",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "680",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "Professoional.payToPlan.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "681",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "Professoional.payToPlan.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "682",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "Professoional.payToPlan.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "683",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "Professoional.payToPlan.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "684",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "Professoional.payToPlan.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "685",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "Professoional.payToPlan.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "686",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "Professoional.payToPlan.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "687",
    jsonElementName: "secondaryIdentifierTypeCode",
    displayFieldName: "Secondary Identifier Type Code",
    jsonFieldPath: "Professoional.payToPlan.secondaryIdentifierTypeCode",
    lookupValues: "2U,FY,NF",
    controlType: "Lookup",
    valueType: "Lookup",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF01",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "688",
    jsonElementName: "secondaryIdentifier",
    displayFieldName: "Secondary Identifier1",
    jsonFieldPath: "Professoional.payToPlan.secondaryIdentifier",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "689",
    jsonElementName: "taxIdentificationNumber",
    displayFieldName: "Tax Identification Number",
    jsonFieldPath: "Professoional.payToPlan.taxIdentificationNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "690",
    jsonElementName: "payerAddress",
    displayFieldName: "Payer Address",
    jsonFieldPath: "payerAddress",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "691",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "payerAddress.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "692",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "payerAddress.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "693",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "payerAddress.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "694",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "payerAddress.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "695",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "payerAddress.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "696",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "payerAddress.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "697",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "payerAddress.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "698",
    jsonElementName: "usageIndicator",
    displayFieldName: "Usage Indicator",
    jsonFieldPath: "usageIndicator",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "String",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "1",
    x12Loop: "",
    x12Segment: "ISA",
    x12DataElement: "ISA15",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "699",
    jsonElementName: "billing",
    displayFieldName: "Billing",
    jsonFieldPath: "billing",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2000A",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "700",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath: "billing.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2000A",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "701",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "billing.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM109",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "702",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "billing.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "703",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath: "billing.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "704",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath: "billing.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "705",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath: "billing.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "REF02",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "706",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath: "billing.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "707",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath: "billing.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "708",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath: "billing.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "709",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "billing.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "710",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath: "billing.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "711",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath: "billing.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "712",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath: "billing.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "713",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "billing.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "714",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "billing.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "715",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "billing.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "716",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "billing.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM107",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "717",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "billing.OrganizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "718",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "billing.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "719",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "billing.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "720",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "billing.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "721",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "billing.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "722",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "billing.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "723",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "billing.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "724",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "billing.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "725",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "billing.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "726",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "billing.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "727",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "billing.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "728",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "billing.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "729",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "billing.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "730",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "billing.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "731",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "billing.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "732",
    jsonElementName: "referring",
    displayFieldName: "Referring",
    jsonFieldPath: "referring",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420F",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "733",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath: "referring.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420F",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "734",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "referring.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "735",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "referring.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "736",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath: "referring.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "737",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath: "referring.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "738",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath: "referring.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "739",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath: "referring.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "740",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath: "referring.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "741",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath: "referring.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "742",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "referring.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "743",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath: "referring.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "744",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath: "referring.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "745",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath: "referring.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "746",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "referring.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "747",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "referring.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "748",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "referring.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "749",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "referring.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM107",
    x12Description: "Referring Provider Name Suffix",
    x12ElementSeq: "Referring Provider Name Suffix",
  },
  {
    serialNum: "750",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "referring.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "751",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "referring.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "752",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "referring.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "753",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "referring.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "754",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "referring.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "755",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "referring.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "756",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "referring.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "757",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "referring.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "758",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "referring.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "759",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "referring.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "760",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "referring.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "761",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "referring.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "762",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "referring.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "763",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "referring.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "764",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "referring.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "765",
    jsonElementName: "rendering",
    displayFieldName: "Rendering",
    jsonFieldPath: "rendering",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420A",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "766",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath: "rendering.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420A",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "767",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "rendering.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "768",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "rendering.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "769",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath: "rendering.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "770",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath: "rendering.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "771",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath: "rendering.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "772",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath: "rendering.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "773",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath: "rendering.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "774",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath: "rendering.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "775",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "rendering.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "776",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath: "rendering.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "777",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath: "rendering.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "778",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath: "rendering.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "779",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "rendering.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "780",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "rendering.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "781",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "rendering.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "782",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "rendering.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2420",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Rendering Provider Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "783",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "rendering.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "784",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "rendering.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "785",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "rendering.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "786",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "rendering.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "787",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "rendering.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "788",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "rendering.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "789",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "rendering.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "790",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "rendering.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "791",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "rendering.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "792",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "rendering.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "",
    x12DataElement: "",
    x12Description: "Contact Function Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "793",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "rendering.contactInformation.name",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "794",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "rendering.contactInformation.phoneNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "795",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "rendering.contactInformation.faxNumber",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "796",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "rendering.contactInformation.email",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "797",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "rendering.contactInformation.phoneExtension",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number",
    x12ElementSeq: "",
  },
  {
    serialNum: "798",
    jsonElementName: "supervising",
    displayFieldName: "Supervising",
    jsonFieldPath: "supervising",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420D",
    x12Segment: "NM1",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "799",
    jsonElementName: "providerType",
    displayFieldName: "Provider Type",
    jsonFieldPath: "supervising.providerType",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "2420D",
    x12Segment: "??",
    x12DataElement: "??",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "800",
    jsonElementName: "npi",
    displayFieldName: "NPI",
    jsonFieldPath: "supervising.npi",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "80",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM109",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "801",
    jsonElementName: "ssn",
    displayFieldName: "SSN",
    jsonFieldPath: "supervising.ssn",
    lookupValues: "SY",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "802",
    jsonElementName: "employerId",
    displayFieldName: "Employer Id",
    jsonFieldPath: "supervising.employerId",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "803",
    jsonElementName: "commercialNumber",
    displayFieldName: "Commercial Number",
    jsonFieldPath: "supervising.commercialNumber",
    lookupValues: "G2",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "804",
    jsonElementName: "locationNumber",
    displayFieldName: "Location Number",
    jsonFieldPath: "supervising.locationNumber",
    lookupValues: "LU",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "805",
    jsonElementName: "payerIdentificationNumber",
    displayFieldName: "Payer Identification Number",
    jsonFieldPath: "supervising.payerIdentificationNumber",
    lookupValues: "2U",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "806",
    jsonElementName: "employerIdentificationNumber",
    displayFieldName: "Employer Identification Number",
    jsonFieldPath: "supervising.employerIdentificationNumber",
    lookupValues: "EI",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "807",
    jsonElementName: "claimOfficeNumber",
    displayFieldName: "Claim Office Number",
    jsonFieldPath: "supervising.claimOfficeNumber",
    lookupValues: "FY",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "808",
    jsonElementName: "naic",
    displayFieldName: "Naic",
    jsonFieldPath: "supervising.naic",
    lookupValues: "NF",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "2010AC",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "809",
    jsonElementName: "stateLicenseNumber",
    displayFieldName: "State License Number",
    jsonFieldPath: "supervising.stateLicenseNumber",
    lookupValues: "08",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "810",
    jsonElementName: "providerUpinNumber",
    displayFieldName: "Provider Upin Number",
    jsonFieldPath: "supervising.providerUpinNumber",
    lookupValues: "1G",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "REF",
    x12DataElement: "REF02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "811",
    jsonElementName: "taxonomyCode",
    displayFieldName: "Taxonomy Code",
    jsonFieldPath: "supervising.taxonomyCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "50",
    x12Loop: "",
    x12Segment: "PRV",
    x12DataElement: "PRV03",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "812",
    jsonElementName: "firstName",
    displayFieldName: "First Name",
    jsonFieldPath: "supervising.firstName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "35",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM104",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "813",
    jsonElementName: "lastName",
    displayFieldName: "Last Name",
    jsonFieldPath: "supervising.lastName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "814",
    jsonElementName: "middleName",
    displayFieldName: "Middle Name",
    jsonFieldPath: "supervising.middleName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "25",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM105",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "815",
    jsonElementName: "suffix",
    displayFieldName: "Suffix",
    jsonFieldPath: "supervising.suffix",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "S",
    usage: null,
    minLength: "1",
    maxLength: "10",
    x12Loop: "2420D",
    x12Segment: "NM1",
    x12DataElement: "NM107",
    x12Description: "Supervising Provider Name Suffix",
    x12ElementSeq: "6",
  },
  {
    serialNum: "816",
    jsonElementName: "organizationName",
    displayFieldName: "Organization Name",
    jsonFieldPath: "supervising.organizationName",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "NM1",
    x12DataElement: "NM103",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "817",
    jsonElementName: "address",
    displayFieldName: "Address",
    jsonFieldPath: "supervising.address",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "818",
    jsonElementName: "address1",
    displayFieldName: "Address1",
    jsonFieldPath: "supervising.address.address1",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N301",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "819",
    jsonElementName: "address2",
    displayFieldName: "Address2",
    jsonFieldPath: "supervising.address.address2",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "55",
    x12Loop: "",
    x12Segment: "N3",
    x12DataElement: "N302",
    x12Description: "Subscriber Address Line",
    x12ElementSeq: "",
  },
  {
    serialNum: "820",
    jsonElementName: "city",
    displayFieldName: "City",
    jsonFieldPath: "supervising.address.city",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "2",
    maxLength: "30",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N401",
    x12Description: "Subscriber City Name",
    x12ElementSeq: "",
  },
  {
    serialNum: "821",
    jsonElementName: "state",
    displayFieldName: "State",
    jsonFieldPath: "supervising.address.state",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "2",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N402",
    x12Description: "Subscriber State Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "822",
    jsonElementName: "postalCode",
    displayFieldName: "Postal Code",
    jsonFieldPath: "supervising.address.postalCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "3",
    maxLength: "15",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N403",
    x12Description: "Subscriber Postal Zone or ZIP Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "823",
    jsonElementName: "countryCode",
    displayFieldName: "Country Code",
    jsonFieldPath: "supervising.address.countryCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "2",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N404",
    x12Description: "Country Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "824",
    jsonElementName: "countrySubDivisionCode",
    displayFieldName: "Country Sub Division Code",
    jsonFieldPath: "supervising.address.countrySubDivisionCode",
    lookupValues: "",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "3",
    x12Loop: "",
    x12Segment: "N4",
    x12DataElement: "N407",
    x12Description: "Country Subdivision Code",
    x12ElementSeq: "",
  },
  {
    serialNum: "825",
    jsonElementName: "contactInformation",
    displayFieldName: "Contact Information",
    jsonFieldPath: "supervising.contactInformation",
    lookupValues: "",
    controlType: "Label",
    valueType: "",
    dataType: "Object",
    chcIsRequired: "",
    usage: null,
    minLength: "",
    maxLength: "",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "826",
    jsonElementName: "name",
    displayFieldName: "Name",
    jsonFieldPath: "supervising.contactInformation.name",
    lookupValues: "IC",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "R",
    usage: null,
    minLength: "1",
    maxLength: "60",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER02",
    x12Description: "",
    x12ElementSeq: "",
  },
  {
    serialNum: "827",
    jsonElementName: "phoneNumber",
    displayFieldName: "Phone Number",
    jsonFieldPath: "supervising.contactInformation.phoneNumber",
    lookupValues: "TE",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER04",
    x12Description: "Communication Number ",
    x12ElementSeq: "6",
  },
  {
    serialNum: "828",
    jsonElementName: "faxNumber",
    displayFieldName: "Fax Number",
    jsonFieldPath: "supervising.contactInformation.faxNumber",
    lookupValues: "FX",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number ",
    x12ElementSeq: "6",
  },
  {
    serialNum: "829",
    jsonElementName: "email",
    displayFieldName: "Email",
    jsonFieldPath: "supervising.contactInformation.email",
    lookupValues: "EM",
    controlType: "TextBox",
    valueType: "String",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER08",
    x12Description: "Communication Number ",
    x12ElementSeq: "6",
  },
  {
    serialNum: "830",
    jsonElementName: "phoneExtension",
    displayFieldName: "Phone Extension",
    jsonFieldPath: "supervising.contactInformation.phoneExtension",
    lookupValues: "EX",
    controlType: "TextBox",
    valueType: "Number",
    dataType: "string",
    chcIsRequired: "",
    usage: null,
    minLength: "1",
    maxLength: "256",
    x12Loop: "",
    x12Segment: "PER",
    x12DataElement: "PER06",
    x12Description: "Communication Number ",
    x12ElementSeq: "6",
  },
];

export default types;
