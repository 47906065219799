import Constants from "../constants";

export const setLoginDetailsLookup = (data) => {
    return {
        type: Constants.SET_LOGIN_DETAILS,
        payload: data,
    };
};
export const setAccessRights = (data) => {
    return {
        type: Constants.SET_ACCESS_RIGHTS,
        payload: data,
    };
};
export const setLoginTokenLookup = (data) => {
    return {
        type: Constants.SET_LOGIN_TOKEN,
        payload: data,
    };
};