import Constants from '../constants';

// export const activeComponentReducer = (state=null, action) => {
//     switch (action.type) {
//         case Constants.STORE_ACTIVE_COMPONENT:
//             return action.payload;
//         default:
//             return state;
//     }
// };
export const activeComponentReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.STORE_ACTIVE_COMPONENT:
            return action.payload;
        case Constants.CLEAR_ACTIVE_COMPONENT:
            return null; // Reset the state to null
        default:
            return state;
    }
};