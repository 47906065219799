import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "./Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

function DocDialogs({
  open = false,
  cancel,
  submit,
  setFilters,
  setIsFilters,
  filters,
  lookUpData,
  formData,
  setFormData,
  handleInputChange,
  handleAutocompleteChange,
  handleDateChange,
  handleSubmit,
  subStatusData,
  handleClearValues,
}) {
  // const details = JSON.parse(sessionStorage?.getItem("details"));
  // const details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const createdByData = details?.aIStaffList;
  const locationData = details?.aILocations;
  locationData.unshift({
    companyName: "All",
    companyId: "0",
  });
  createdByData.unshift({
    description: "All",
    code: "0",
  });
  const statusDate = [
    { label: "Create Date", value: "CDT" },
    { label: "Priority Date", value: "PDT" },
    { label: "Assign Date", value: "ADT" },
    { label: "Completed Date", value: "CMDT" },
    { label: "Last Status Date", value: "LSD" },
  ];

  return (
    <div>
      <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CloseIcon
            aria-label="close"
            className="button-stylesDialog cursor-pointer windowClose"
            onClick={cancel}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mx-2 my-4">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={locationData}
                  getOptionLabel={(option) => option.companyName}
                  isOptionEqualToValue={(option, value) =>
                    option.companyName === value.companyName
                  }
                  value={formData?.location}
                  onChange={(e, value) =>
                    handleAutocompleteChange("location", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </FormControl>
            </div>
            <div className="mx-2 my-4">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={statusDate}
                  value={formData?.statusDate}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("statusDate", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status Date" />
                  )}
                />
              </FormControl>
            </div>
            <div className="sm:flex">
              <div className="m-2 w-2/4">
                <TextField
                  label="For"
                  name="for"
                  className="m-0"
                  size="small"
                  value={formData?.for}
                  onChange={handleInputChange}
                  fullWidth
                />
              </div>
              <div className="m-2 w-2/4">
                <FormControl fullWidth size="small">
                  <InputLabel>Day(s) / Month(s)</InputLabel>
                  <Select
                    label="Day(s) / Month(s)"
                    name="daysOrMonths"
                    value={formData?.daysOrMonths}
                    onChange={handleInputChange}
                    fullWidth
                  >
                    <MenuItem value="days">Day(s)</MenuItem>
                    <MenuItem value="months">Month(s)</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        handleDateChange(val, "fromDate");
                      }}
                      value={
                        formData?.fromDate ? dayjs(formData?.fromDate) : ""
                      }
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="From Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        handleDateChange(val, "toDate");
                      }}
                      sx={{
                        width: "200px !important",
                      }}
                      value={formData?.toDate ? dayjs(formData?.toDate) : ""}
                      maxDate={dayjs()}
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="To Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="sm:flex">
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={lookUpData?.statusList ?? []}
                  value={formData?.status}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("status", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
              </div>
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={subStatusData ?? []}
                  value={formData?.subStatus}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("subStatus", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Sub Status" />
                  )}
                />
              </div>
            </div>
            <div className="sm:flex">
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  disableClearable
                  autoSelect={false}
                  options={createdByData}
                  value={formData?.assignedTo}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("assignedTo", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Assigned To" />
                  )}
                />
              </div>
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={lookUpData?.dataSourceList ?? []}
                  value={formData?.dataSource ?? null}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("payer", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Payer" />
                  )}
                />
              </div>
            </div>
            <div className="sm:flex">
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={lookUpData?.processList ?? []}
                  value={formData?.process}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("process", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Process" />
                  )}
                />
              </div>
              <div className="mx-2 my-3 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  autoSelect={false}
                  disableClearable
                  options={lookUpData?.subProcessList ?? []}
                  value={formData?.subProcess}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.description === value.description
                  }
                  onChange={(e, value) =>
                    handleAutocompleteChange("subProcess", value)
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Sub Process" />
                  )}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <div className="flex justify-end mx-8 mb-5">
          <div>
            <Button
              text="Close"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={cancel}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Clear"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={handleClearValues}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Apply"
              filled
              classname={"button-styles min-w-[80px]"}
              onclick={() => {
                handleSubmit();
              }}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default DocDialogs;
