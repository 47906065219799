import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "./Button";
import { UpdateAllPatientList } from "../apis/patientList";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

function Dialogs({
  open = false,
  cancel,
  submit,
  text,
  page,
  setFilters,
  filters,
  setIsFilters,
  setOpen,
}) {
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);

  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  let createdByData = details?.aIStaffList?.map((i) => i.description);
  let locationData = details?.aILocations;
  locationData.unshift({
    companyId: "0",
    companyName: "All",
  });

  let schema = yup.object({
    filter: yup.string().trim(),
    dateofBirthFrom: yup.string(),
    createdBy: yup.string().trim(),
    fromDate: yup.date(),
    toDate: yup.date(),
    status: yup.string().trim(),
    deliveryStatus: yup.string().trim(),
    locationId: yup.string(),
    locationName: yup.string(),
  });
  // debugger;

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      filter: filters.filter ? filters.filter : "",
      dateofBirthFrom: filters.dateOfBirth ? filters.dateOfBirth : "",
      batchNumber: filters.batchNumber ? filters.batchNumber : "",
      createdBy: filters.createdBy ? filters.createdBy : "",
      fromDate: filters.fromDate ? filters.fromDate : "",
      toDate: filters.toDate ? filters.toDate : "",
      status: filters.processStatus ? filters.processStatus : "",
      deliveryStatus: filters.deliveryStatus ? filters.deliveryStatus : "",
      locationId: filters.locationId ? filters.locationId : "",
      locationName: filters.locationName ? filters.locationName : "",
      location: {
        companyId: filters.locationId ? filters.locationId : "",
        companyName:
          typeof filters?.locationName === "object" &&
          filters?.locationName !== null
            ? filters?.locationName?.companyName
            : filters?.locationName || "",
      },
    },
    validationSchema: schema,

    onSubmit: async (values) => {
      setFilters({
        filter: values.filter,
        dateOfBirth:
          values.dateofBirthFrom != null ? values.dateofBirthFrom : "",
        fromDate: values.fromDate ? values.fromDate : "",
        toDate: values.toDate ? values.toDate : "",
        batchNumber: values.batchNumber != null ? values.batchNumber : "",
        processStatus: values.status,
        deliveryStatus: values.deliveryStatus,
        createdBy: values.createdBy,
        locationId:
          values.locationId != null
            ? values.locationId != ""
              ? values.locationId
              : ""
            : "",
        locationName:
          values.locationName != null
            ? values.locationName != ""
              ? values.locationName
              : ""
            : "",
        location: {
          locationId:
            values.locationId != null
              ? values.locationId != ""
                ? values.locationId
                : ""
              : "",
          locationName:
            values.locationName != null
              ? values.locationName != ""
                ? values.locationName
                : ""
              : "",
        },
      });
    },
  });

  const updateItem = (e) => {
    let name = e.target.innerHTML;
    let id = details?.aIStaffList?.filter((i) => i?.description == name);
    setFieldValue("createdBy", id[0]?.code);
  };

  const handleClose = () => {
    cancel();
  };

  const ClearValues = () => {
    values.locationName = "All";
    values.filter = "";
    values.dateofBirthFrom = "";
    values.fromDate = dayjs().subtract(7, "day");
    values.toDate = dayjs();
    values.status = "";
    values.deliveryStatus = "";
    values.createdBy = "";
    values.locationId = "0";
    resetForm();
  };

  const handleClear = () => {
    values.filter = "";
  };

  const updateSearchLocationItem = (e, val) => {
    setFieldValue("locationId", val?.companyId);
    setFieldValue("locationName", val?.companyName);
    setFieldValue("location", val);
  };

  const handleCancelClick = () => {
    cancel();
    setIsFilters(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CloseIcon
            aria-label="close"
            className="button-stylesDialog cursor-pointer windowClose"
            onClick={handleClose}
            filled={true}
          />
        </DialogTitle>

        <DialogContent>
          <div>
            <div className="mx-2 my-4">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demoSearch"
                  options={locationData}
                  value={values.location}
                  getOptionLabel={(option) => option.companyName}
                  isOptionEqualToValue={(option, value) =>
                    option.companyName == (value.companyName ?? value)
                  }
                  autoSelect={false}
                  disableClearable
                  onChange={updateSearchLocationItem}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />{" "}
              </FormControl>
            </div>
            <div className="m-2">
              <TextField
                id="outlined-password-input"
                label="Filter"
                name="Filter"
                size="small"
                placeholder="Filter by mobile number (or) email"
                className="w-full"
                value={values.filter}
                onChange={handleChange("filter")}
              />
            </div>

            <div className="sm:flex">
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        setFieldValue("fromDate", val);
                      }}
                      value={values?.fromDate ? dayjs(values?.fromDate) : ""}
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="From Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(val) => {
                        setFieldValue("toDate", val);
                      }}
                      sx={{
                        width: "200px !important",
                      }}
                      value={values?.toDate ? dayjs(values?.toDate) : ""}
                      maxDate={dayjs()}
                      inputFormat="MM/dd/yyyy"
                      size="small"
                      label="To Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "220px !important",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2 w-2/4">
                <div className="w-full">
                  <TextField
                    id="outlined-dob-input"
                    label="Date of Birth"
                    name="Date of Birth"
                    className="m-0"
                    size="small"
                    value={values.dateofBirthFrom}
                    onChange={handleChange("dateofBirthFrom")}
                    placeholder="MM/DD/YYYY"
                    fullWidth
                    // error={errors.Location && touched.Location}
                    // className="m-0"
                    // onKeyDown={handlePrice}
                    // style={textFieldStyles}
                  />
                </div>
              </div>
              <div className="m-2 w-2/4">
                <TextField
                  id="outlined-batch-input"
                  label="Batch Number"
                  name="Batch Number"
                  size="small"
                  placeholder="Batch Number"
                  className="w-full"
                  onChange={handleChange("batchNumber")}
                  value={values.batchNumber}
                />
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2 w-2/4">
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  size="small"
                  options={createdByData}
                  value={values.createdBy}
                  onChange={updateItem}
                  renderInput={(params) => (
                    <TextField {...params} label="Created By" />
                  )}
                />
              </div>
              <div className="m-2 w-2/4">
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={values.status}
                    label="Status"
                    onChange={handleChange("status")}
                    placeholder="Pending / UnVerified / Verified"
                  >
                    <MenuItem value={"Open"}>Open</MenuItem>
                    <MenuItem value={"Card Captured"}>Card Captured</MenuItem>
                    <MenuItem value={"Verified"}>
                      Queued For Verification
                    </MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="sm:flex">
              <div className="m-2 w-2/4">
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Delivery Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={values.deliveryStatus}
                    label="Delivery Status"
                    onChange={handleChange("deliveryStatus")}
                    placeholder="Sent / Delivered / Viewed"
                  >
                    <MenuItem value={"Sent"}>Sent</MenuItem>
                    <MenuItem value={"Viewed"}>Viewed</MenuItem>
                    <MenuItem value={"Submitted"}>Submitted</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="m-2 w-2/4"></div>
            </div>
          </div>
        </DialogContent>

        <div className="flex justify-end mx-8 mb-5">
          <div>
            <Button
              text="Close"
              filled={true}
              classname={"button-styles min-w-[80px]"}
              // navigate="/patient-list"
              onclick={handleClose}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Clear"
              filled={true}
              classname={"button-styles min-w-[80px]"}
              // navigate="/patient-list"
              onclick={ClearValues}
            />
          </div>
          <div className="ml-2">
            <Button
              text="Apply"
              filled={true}
              classname={"button-styles min-w-[80px]"}
              // navigate="/patient-list"
              onclick={() => {
                handleSubmit();
                submit(values);
                setIsFilters(true);
              }}
            />
          </div>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default Dialogs;
