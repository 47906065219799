import React, { Component } from 'react';
import Button from "./Button";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.timeoutId = null;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && !prevState.hasError) {
      this.timeoutId = setTimeout(() => {
        this.setState({ hasError: false });
        window.location.reload();
      }, 10000);
    } else if (!this.state.hasError && prevState.hasError) {
      clearTimeout(this.timeoutId);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='err-boundary-css'
        // style={{
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "center",
        //   justifyContent: "center",
        //   height: "100vh",
        //   textAlign: "center",
        //   backgroundColor: "#f9f9f9",
        //   color: "#333",
        //   padding: "20px",
        //   boxSizing: "border-box"
        // }}
        >
          <h2 className='err-heading'>
            Something went wrong.
          </h2>
          <p className='err-content'>
            Please try refreshing the page or contact support.
          </p>
          <button className='err-refresh-btn'
            onClick={() => window.location.reload()}
          >
            Refresh Page
          </button>
          {/* <Button
            text="Refresh Page"
            classname={"button-styles btnHeight"}
            filled={true}
            onClick={() => window.location.reload()}
          /> */}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;