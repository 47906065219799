import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetConfigLookup, GetTaskSummaryData } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getConfigLookup(value) {
  try {
    let { data } = await instance.post(GetConfigLookup, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getTaskSummaryList(value) {
  try {
    let { data } = await instance.post(GetTaskSummaryData, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function GetConfigLookupApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getConfigLookup, {});
}

export function GetTaskSummaryListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getTaskSummaryList, {});
}
