import {
  Autocomplete,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Button as MuiButton,
  Container,
} from "@mui/material";
import MenuList from "@mui/material/MenuList";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Stack from "@mui/material/Stack";
import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyContext from "../../components/MyContext";
import { useContext } from "react";
import { baseurl } from "../../apis/Urls";
import { SendSMSAPI } from "../../apis/Urls";
import { instance } from "../../apis/axios";
import { GetEdi837Claim } from "../../apis/edi837claim";
import { SaveEdi837Claim } from "../../apis/edi837claim";
import { GetPayerNamesList } from "../../apis/edi837claim";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { TabContext } from "../../components/utils/TabProvider";
import { SignalWifiStatusbarNullOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { logTelemetry } from "../../logTelemetry";
import { useSelector, useDispatch } from "react-redux";
import { setActiveComponent } from "../../redux/actions";
import { setClaimStatusData } from "../../redux/actions/patientDetails";
const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};

const textFieldStylesddl = {
  width: "100%",
  background: "#fff",
};

const ClaimSubmitDetails = ({
  dataFromTSL,
  isFromList,
  setEdi837ListObject,
  edi837ListObject,
  setIsAnythingChanged,
  isAnythingChanged,
  setDataFromTSL,
  setdataForCStatus,
  setValue,
}) => {
  const {
    orbitClaimId,
    setOrbitClaimId,
    setProviderControlNumber,
    providerControlNumber,
    setPatientControlNumber,
    patientControlnumber,
  } = useContext(TabContext);
  const providerRef = useRef(null);
  const inputBaseRef = useRef(null);
  const inputBaseIdRef = useRef(null);
  const inputPayerIdRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [hidePrev, setHidePrev] = React.useState(false);
  const [listOne, setListOne] = useState([]);
  const [listTwo, setListTwo] = useState([]);
  const [listThree, setlistThree] = useState([]);
  var setisFocusOut = false;
  let [isSubmitButtonClick, setisSubmitButtonClick] = useState(0);
  const [isSubmitClick, setisSubmitClick] = React.useState(0);
  const navigation = useNavigate();
  useEffect(() => {
    if (
      dataFromTSL?.orbitAssignedClaimId != null &&
      dataFromTSL?.orbitAssignedClaimId != ""
    ) {
      dataFromTSL.patientControlnumber = null;
      dataFromTSL.providercontrolnumber = null;
    }
    if (providerRef?.current) {
      providerRef.current.value = "";
    }
    if (providerClaimId?.current) {
      providerClaimId.current.value = "";
    }
    if (patientControlRef?.current) {
      patientControlRef.current.value = "";
    }
    if (
      patientControlRef?.current &&
      dataFromTSL?.patientControlnumber != undefined
    ) {
      patientControlRef.current.value = !patientControlnumber
        ? isFromList
          ? dataFromTSL?.patientControlnumber
          : ""
        : patientControlnumber || "";
    }

    if (
      providerRef?.current &&
      dataFromTSL?.providercontrolnumber != undefined
    ) {
      // console.log(dataFromTSL?.providercontrolnumber?.providercontrolnumber, "providerRef");
      providerRef.current.value = !providerControlNumber
        ? isFromList
          ? dataFromTSL?.providercontrolnumber
          : ""
        : providerControlNumber || "";
    }
    if (
      providerClaimId.current &&
      dataFromTSL?.orbitAssignedClaimId != undefined
    ) {
      providerClaimId.current.value = !orbitClaimId
        ? isFromList
          ? dataFromTSL?.orbitAssignedClaimId
          : ""
        : orbitClaimId;
    }

    if (
      dataFromTSL?.providercontrolnumber != null &&
      dataFromTSL?.providercontrolnumber != "" &&
      dataFromTSL?.providercontrolnumber != undefined
    ) {
      if (isFromList) {
        handleSubmit();
      } else {
        reUsedFun(edi837ListObject, 1);
      }
    } else if (
      dataFromTSL?.orbitAssignedClaimId != null &&
      dataFromTSL?.orbitAssignedClaimId != "" &&
      dataFromTSL?.orbitAssignedClaimId != undefined
    ) {
      if (isFromList) {
        handleSubmit(isFromList);
      } else {
        reUsedFun(edi837ListObject, 1);
      }
    }
  }, []);

  // Debounced handler for input change
  // const debouncedHandleInputChange = debounce(handleProviderInputChange, 100); // Adjust delay as needed

  const handleClose = () => {
    setOpen(false);
  };
  let [PayerName, setPayerName] = useState("");
  let [prevVal, setPrevVal] = useState("");
  let [previousPayerName, setPreviousPayerName] = useState("");
  let [PayerId, setPayerId] = useState("");
  let [CHPayerId, setCHPayerId] = useState("");
  let [PayerReadOnly, setPayerReadOnly] = useState(0);
  let [CopyClaimFlag, setCopyClaimFlag] = useState("");
  let [previousPayerId, setPreviousPayerId] = useState("");
  let [stgEDI837Id, setstgEDI837Id] = useState("");
  let [Remarks, setRemarks] = useState("");
  let [transactionReamrks, settransactionReamrks] = useState("");
  let [claimType, setclaimType] = useState("");
  let [claimTypeDate, setclaimTypeDate] = useState("");
  let [handleErrors, sethandleErrors] = useState([]);

  let [isSeacrhCall, setisSeacrhCall] = useState(0);

  const [accordionCurrent, setAccordionCurrent] = useState(0);
  const [accordionClaimInformation, setAccordionClaimInformation] = useState(0);
  const [accordionClaimInformation111, setAccordionClaimInformation111] =
    useState(0);
  const [accordionClaimInformation1111, setAccordionClaimInformation1111] =
    useState(0);

  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);
  const { data, mutateAsync, isLoading, isError } = GetEdi837Claim();
  const {
    data: saveData,
    mutateAsync: mutateServiceTypeAsync,
    isLoading: proceedLoading,
  } = SaveEdi837Claim();
  const {
    data: PayerData,
    mutateAsync: mutatePayerTypeAsync,
    isLoading: payerLoading,
  } = GetPayerNamesList();

  const [emailError, setemailError] = useState(false);
  const [orbitAssignedClaimID, setorbitAssignedClaimID] = useState("");
  const [emailTestData, setemailTestData] = useState(false);
  const [isDataDisplaying, setisDataDisplaying] = useState(false);
  const [PayerPriority, setPayerPriority] = useState("");
  const [ClaimAction, setClaimAction] = useState("");

  const [mobileNumberError, setmobileNumberError] = useState(false);
  const [providerControlnumber, setproviderControlnumber] =
    useState("227378367");

  const providerClaimId = useRef(null);
  const patientControlRef = useRef(null);
  const [procedureModifiersvalue, setprocedureModifiersvalue] = useState("");
  const [lineprocedureModifiersvalue, setlineprocedureModifiersvalue] =
    useState("");
  const [procPiagnosisCodePointersvalue, setprocPiagnosisCodePointersvalue] =
    useState("");

  const [billyEntries, setbillyEntries] = useState({});
  const [headerEntities, setheaderEntities] = useState({});
  const [loadingg, setLoadingg] = useState(false);

  const objClaimInformationList = [];

  const [claimNote, setclaimNote] = useState([]);
  const [healthCareCodeInformation, sethealthCareCodeInformation] = useState(
    []
  );
  const [serviceLines, setserviceLines] = useState([]);
  const [ambulanceDropOffLocation, setambulanceDropOffLocation] = useState([]);
  const [
    spinalManipulationServiceInformation,
    setspinalManipulationServiceInformation,
  ] = useState([]);
  const [serviceFacilityLocation, setserviceFacilityLocation] = useState([]);
  const [ambulanceCertification, setambulanceCertification] = useState([]);
  const [
    patientConditionInformationVision,
    setpatientConditionInformationVision,
  ] = useState([]);
  const [claimSupplementalInformation, setclaimSupplementalInformation] =
    useState([]);
  const [ambulanceTransportInformation, setambulanceTransportInformation] =
    useState([]);
  const [ambulancePickUpLocation, setambulancePickUpLocation] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  console.log("headerEntities", headerEntities);

  const breadcrumbItems = [
    // { label: "Patient List", link: "/patient-list" },
    { label: "Claims", link: "/patient-list/send-sms-or-email" },
  ];

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;

  const handleRemoveDiagnosisCodePointers = (e) => {};

  const handleInputCodeChange1 = (event, index) => {
    const { value } = event.target;
    const updatedItems = [...healthCareCodeInformation]; // Create a copy of the items array
    updatedItems[index].diagnosisCode = value; // Update the name property of the corresponding item
    sethealthCareCodeInformation(updatedItems); // Update state with the new items array
  };

  const handleTextInputChange = (e, checkIndex) => {
    if (checkIndex == 1) {
      billyEntries.ControlNumber = e.target.value;
    }

    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
    // setFieldValue("mobileNumber", formatPhoneNumber(newValue));
  };

  const handleClaimStatusChange = (e) => {
    const newValue = e.target.value;
    setproviderClaimId(newValue);
    // setFieldValue("mobileNumber", formatPhoneNumber(newValue));
  };

  function reUsedFun(dat, indx) {
    if (
      PayerData != undefined &&
      PayerData.data != null &&
      PayerData.data != undefined
    ) {
      PayerData.data.patientContact = null;
    }
    setHidePrev(false);
    let data = dat?.length
      ? dat
      : !providerRef?.current?.value || !providerClaimId?.current?.value
      ? []
      : edi837ListObject;
    // console.log("304");
    setisDataDisplaying(data?.length > 0);
    // console.log(edi837ListObject, " edi837ListObject ",data);
    if (data.length > 0) {
      setheaderEntities(data[indx - 1]);
      setPayerReadOnly(parseInt(data[indx - 1]?.readOnlyFlag));
      setCopyClaimFlag(data[indx - 1]?.copyClaimFlag);

      setstgEDI837Id(data[indx - 1]?.stgEDI837Id);
      setRemarks(data[indx - 1]?.statusRemarks);
      settransactionReamrks(data[indx - 1]?.transactionReamrks);
      sethandleErrors(data[indx - 1]?.headerErrors);
      setclaimType(data[indx - 1]?.claimType);
      setclaimTypeDate(data[indx - 1]?.dataProcessDate);
      setorbitAssignedClaimID(data[indx - 1]?.orbitAssignedClaimID);
      try {
        const classObjectArray = JSON.parse(data[indx - 1]?.inputJson);

        setbillyEntries(classObjectArray);
        setCHPayerId(data[indx - 1]?.cpid);
        setPayerId(classObjectArray?.Professional?.tradingPartnerServiceId);
        setPayerName(data[indx - 1]?.payerName);
        if (data[indx - 1]?.claimType == "Original Claim") {
          setClaimAction(1);
        } else {
          setClaimAction(
            classObjectArray?.Professional?.claimInformation?.claimFrequencyCode
          );
        }
        setPayerPriority(
          classObjectArray?.Professional?.subscriber
            ?.paymentResponsibilityLevelCode
        );
        setPrevVal(classObjectArray?.Professional?.receiver?.organizationName);
        sethealthCareCodeInformation(
          classObjectArray?.Professional?.claimInformation
            ?.healthCareCodeInformation
        );
        setTimeout(() => {
          setLoadingg(false);
        }, 100);
      } catch (ex) {
        setLoadingg(false);
        setNotify({
          isOpen: true,
          message: `Record you searched for does not have correct JSON format.`,
          type: "error",
        });
      }
    } else {
      setLoadingg(false);
    }
  }

  // console.log(" set is DataDisplaying is-->", isDataDisplaying);

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        // alert("373");
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${data?.status?.message}`
        );
        setLoadingg(false);
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
        handleClearSaveData();
        // if (setIsAnythingChanged) {
        //   setIsAnythingChanged(false);
        // }
      }
      if (data?.status && data?.status?.code == 200) {
        if (data?.data?.edi837ListObject?.length > 0) {
          var chkindex = 1;
          if (
            providerClaimId.current.value != "" &&
            providerClaimId.current.value != undefined
          ) {
            var checkValue = providerClaimId.current.value;
            var index = data?.data?.edi837ListObject
              .map(function (o) {
                return o.orbitAssignedClaimID?.toLowerCase().trim();
              })
              .indexOf(checkValue.toLowerCase().trim());
            if (index != -1) {
              chkindex = index + 1;
            }
          }
          setPage(chkindex);
          reUsedFun(data?.data?.edi837ListObject, chkindex);
          // if (isFromList) {
          if (setEdi837ListObject) {
            setEdi837ListObject(data?.data?.edi837ListObject);
          }
          // if (!isFromList && setIsAnythingChanged) {
          //   setIsAnythingChanged(true);
          // }
          setOrbitClaimId(providerClaimId?.current?.value);
          setProviderControlNumber(providerRef?.current?.value);
          setPatientControlNumber(patientControlRef?.current?.value);
          if (setDataFromTSL) {
          setDataFromTSL({
            providercontrolnumber: providerRef?.current?.value,
            orbitAssignedClaimId: providerClaimId?.current?.value,
            patientControlnumber:patientControlRef?.current?.value,
            selObject: undefined,
      });
    }
          // setisDataDisplaying(true);
          // const classObjectArray = JSON.parse(data?.data[0].inputJson);
          // setorbitAssignedClaimID(data?.data[0].orbitAssignedClaimID);
          // //// debugger;
          // setbillyEntries(classObjectArray);
          // setPayerId(classObjectArray?.SourceTradingPartnerServiceId);
          // setPayerName(
          //   classObjectArray.Professional?.receiver?.organizationName
          // );
          // sethealthCareCodeInformation(
          //   billyEntries.Professional?.claimInformation
          //     ?.healthCareCodeInformation
          // );
          // setLoadingg(false);
        } else {
          setNotify({
            isOpen: true,
            message: "There is no data",
            type: "error",
          });
          if (setIsAnythingChanged) {
            setIsAnythingChanged(false);
          }
          // alert("281");
          setOrbitClaimId("");
          setProviderControlNumber("");
          setPatientControlNumber("");
          setLoadingg(false);
          if (setEdi837ListObject) {
            setEdi837ListObject([]);
          }
        }
      }
    }

    if (data) {
      if (data?.response && data?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${data?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (saveData) {
      if (saveData?.status && saveData?.status?.code != 200) {
        setLoadingg(false);
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${saveData?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${saveData?.status?.message}`,
          type: "error",
        });
      }
      if (saveData?.status && saveData?.status?.code == 200) {
        setLoadingg(false);
        if (saveData?.data?.edi837ListObject?.length > 0) {
          // console.log(data, " on submit claimsubmit1234 ");

          try {
            if (data != null && data.data != null) {
              data.data.edi837ListObject = saveData?.data?.edi837ListObject;
            } else {
              data = { data: { edi837ListObject: [] } };
              data.data.edi837ListObject = saveData?.data?.edi837ListObject;
            }
            handleBeforeClearSaveData();
            if (setEdi837ListObject)
              setEdi837ListObject(saveData.data.edi837ListObject);
            if (isSubmitClick == 3) {
              setPage(1);
              reUsedFun(data?.data?.edi837ListObject, 1);
            } else {
              if (data?.data?.edi837ListObject?.length >= page) {
                setPage(page);
                reUsedFun(data?.data?.edi837ListObject, page);
              } else {
                setPage(1);
                reUsedFun(data?.data?.edi837ListObject, 1);
              }
            }
          } catch (ex) {
            handleSubmit();
          }
        }

        setNotify({
          isOpen: true,
          message: `${saveData?.status?.message}`,
          type: "success",
        });
      }
    }

    if (saveData) {
      if (saveData?.response && saveData?.response?.status != 200) {
        setLoadingg(false);
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${saveData?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${saveData?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [saveData]);

  useEffect(() => {
    if (PayerData) {
      setisFocusOut = false;
      if (PayerData?.status && PayerData?.status?.code != 200) {
        setNotify({
          isOpen: true,
          message: `${PayerData?.status?.message}`,
          type: "error",
        });
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${PayerData?.status?.message}`
        );
      }
      if (
        PayerData?.status &&
        PayerData?.status?.code == 200 &&
        PayerData?.data?.length > 0
      ) {
        if (isSeacrhCall == 0) {
          setListOne(PayerData?.data);
          inputBaseRef?.current?.focus();
        } else if (isSeacrhCall == 2) {
          setlistThree(PayerData?.data);
          inputPayerIdRef?.current?.focus();
        } else {
          setListTwo(PayerData?.data);
          inputBaseIdRef?.current?.focus();
        }
      }
    }

    if (PayerData) {
      if (PayerData?.resultStatus && PayerData?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${PayerData?.response?.message}`,
          type: "error",
        });
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "useEffect",
          `${PayerData?.response?.message}`
        );
      }
    }
  }, [PayerData]);

  const handleInputChange = (e, parentField, nestedFields, name) => {
    const newValue = e.target.value;

    if (!nestedFields || typeof nestedFields !== "string") {
      console.error("nestedFields must be a valid string.");
      return;
    }

    setbillyEntries((prevState) => {
      // Split the nested fields by dot to access them dynamically
      const nestedFieldsArray = nestedFields.split(".");

      // Create a copy of the previous state
      const newState = { ...prevState };
      if (!newState.Professional[parentField]) {
        newState.Professional[parentField] = {};
      }

      // Traverse the state object to reach the nested field
      let currentState = newState.Professional[parentField];

      nestedFieldsArray.forEach((field, index) => {
        // If it's the last field in the nestedFieldsArray, update its value
        if (index === nestedFieldsArray.length - 1) {
          currentState[field] = newValue;
        } else {
          // If the nested field does not exist, initialize it as an empty object
          if (!currentState[field]) {
            currentState[field] = {};
          }
          // Traverse deeper into the nested object
          currentState = currentState[field];
        }
      });
      // debugger;
      // Return the updated state
      return newState;
    });

    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChange111 = (
    e,
    parentField,
    nestedFields1,
    nestedFields2,
    index
  ) => {
    let a1 = parentField;
    let a2 = nestedFields1;
    let a3 = nestedFields2;
    // console.log(
    //   "888",
    //   e.target.value,
    //   parentField,
    //   nestedFields1,
    //   nestedFields2,
    //   index
    // );
    // console.log("88881", a1, a2, a3);
    let billyEntires = { ...billyEntries };
    // console.log("8880", billyEntires);
    // console.log("8881", billyEntires.Professional.claimInformation);
    billyEntires.Professional[parentField][nestedFields1][index][
      nestedFields2
    ] = e.target.value;
    setbillyEntries(billyEntires);
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChangeProf1 = (
    e,
    parentField,
    nestedFields1,
    nestedFields2,
    nestedFields3,
    index
  ) => {
    // console.log(
    //   "888",
    //   e.target.value,
    //   parentField,
    //   nestedFields1,
    //   nestedFields2,
    //   nestedFields3,
    //   index
    // );
    let billyEntires = { ...billyEntries };

    billyEntires.Professional[parentField][nestedFields1][index][nestedFields2][
      nestedFields3
    ] = e.target.value;
    setbillyEntries(billyEntires);
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChangeProf = (e, parentField, nestedField) => {
    let billyEntriesCopy = { ...billyEntries };

    // Check if Professional object exists, if not, initialize it
    if (!billyEntriesCopy.Professional) {
      billyEntriesCopy.Professional = {};
    }

    // Check if nestedField is provided and if Professional[parentField] exists
    if (nestedField && billyEntriesCopy.Professional[parentField]) {
      billyEntriesCopy.Professional[parentField][nestedField] = e.target.value;
    } else if (!nestedField && billyEntriesCopy.Professional[parentField]) {
      billyEntriesCopy.Professional[parentField] = e.target.value;
    } else {
      // Handle the case where Professional[parentField] is null or undefined
      console.error("Professional[parentField] is null or undefined");
      return; // or handle this case appropriately
    }

    setbillyEntries(billyEntriesCopy);

    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChangeProfline = (
    e,
    parentField,
    nestedFields1,
    nestedFields2,
    nestedFields3,
    nestedFields4, //new
    nestedFields5, //new
    index,
    childIndex,
    grandChildIndex, //extend functionality only if it exists
    greatgrandChildIndex //extend functionality only if it exists
  ) => {
    // Making a deep copy of the relevant parts of billyEntries to ensure immutability
    let billyEntriesCopy = {
      ...billyEntries,
      Professional: {
        ...billyEntries.Professional,
        [parentField]: {
          ...billyEntries.Professional[parentField],
          [nestedFields1]: [
            ...billyEntries.Professional[parentField][nestedFields1],
          ],
        },
      },
    };
    // Assign the value based on the level of nesting
    if (grandChildIndex !== undefined && greatgrandChildIndex !== undefined) {
      // 5-level deep nesting
      billyEntriesCopy.Professional[parentField][nestedFields1][index][
        nestedFields2
      ][childIndex][nestedFields3][grandChildIndex][nestedFields4][
        greatgrandChildIndex
      ][nestedFields5] = e.target.value;
    } else if (grandChildIndex !== undefined) {
      // 4-level deep nesting
      billyEntriesCopy.Professional[parentField][nestedFields1][index][
        nestedFields2
      ][childIndex][nestedFields3][grandChildIndex][nestedFields4] =
        e.target.value;
    } else {
      // 3-level deep nesting (default)
      billyEntriesCopy.Professional[parentField][nestedFields1][index][
        nestedFields2
      ][childIndex][nestedFields3] = e.target.value;
    }

    // Update the state with the copied object
    setbillyEntries(billyEntriesCopy);

    // If nothing has changed, update the flag
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChangeProf2 = (
    e,
    parentField,
    nestedFields1,
    nestedFields2,
    nestedFields3,
    index
  ) => {
    // console.log(
    //   "888",
    //   e.target.value,
    //   parentField,
    //   nestedFields1,
    //   nestedFields2,
    //   nestedFields3,
    //   index
    // );
    let billyEntires = { ...billyEntries };

    billyEntires.Professional[parentField][nestedFields1][index][nestedFields2][
      nestedFields3
    ] = e.target.value;
    setbillyEntries(billyEntires);
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputChangeProf3 = (
    e,
    parentField,
    nestedFields1,
    nestedFields2,
    nestedFields3,
    index
  ) => {
    let billyEntires = { ...billyEntries };

    billyEntires.Professional[parentField][nestedFields1][index][nestedFields2][
      nestedFields3
    ] = e.target.value;
    setbillyEntries(billyEntires);
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputCodeChange = (event, index) => {
    billyEntries.Professional.claimInformation.healthCareCodeInformation[
      index
    ].diagnosisCode = event.target.value;
    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleInputTypeCodeChange = (event, index) => {
    billyEntries.Professional.claimInformation.healthCareCodeInformation[
      index
    ].diagnosisTypeCode = event.target.value;
    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };
  // const handleInputCodeChange1 = (event, index) => {

  const handleInputCodeChange2 = (event, index) => {
    healthCareCodeInformation[index].diagnosisCode = event.target.value;
    sethealthCareCodeInformation([...healthCareCodeInformation]);
  };

  const handleInputChangeSuper = (e, parentField, nestedFields, name) => {
    const newValue = e.target.value;
    setbillyEntries((prevState) => {
      const newState = { ...prevState };

      // Check if Professional object exists, if not, initialize it
      if (!newState.Professional) {
        newState.Professional = {};
      }

      // Check if parentField object exists, if not, initialize it
      if (!newState.Professional[parentField]) {
        newState.Professional[parentField] = {};
      }

      const nestedFieldsArray = nestedFields.split(".");
      let currentState = newState.Professional[parentField];

      // Traverse nestedFieldsArray and initialize objects if necessary
      nestedFieldsArray.forEach((field, index) => {
        if (index === nestedFieldsArray.length - 1) {
          // If it's the last field, set its value
          currentState[field] = newValue;
        } else {
          // If it's not the last field, initialize the nested object if necessary
          if (!currentState[field]) {
            currentState[field] = {};
          }
          currentState = currentState[field];
        }
      });

      return newState;
    });

    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const deleteByIndex = (index) => {
    var healthCareCodeInformation = [
      ...billyEntries.Professional.claimInformation.healthCareCodeInformation.splice(
        index,
        1
      ),
    ];
    billyEntries.Professional.claimInformation.healthCareCodeInformation =
      billyEntries.Professional.claimInformation.healthCareCodeInformation;
    for (
      var i = 0;
      i < billyEntries.Professional.claimInformation.serviceLines.length;
      i++
    ) {
      if (
        billyEntries.Professional.claimInformation.serviceLines[i]
          .professionalService != null
      ) {
        if (
          billyEntries.Professional.claimInformation.serviceLines[i]
            .professionalService?.compositeDiagnosisCodePointers
            ?.diagnosisCodePointers != null
        ) {
          for (
            var k = 0;
            k <
            billyEntries.Professional.claimInformation.serviceLines[i]
              .professionalService?.compositeDiagnosisCodePointers
              ?.diagnosisCodePointers.length;
            k++
          ) {
            if (
              parseInt(index + 1) <=
              parseInt(
                billyEntries.Professional.claimInformation.serviceLines[i]
                  .professionalService?.compositeDiagnosisCodePointers
                  ?.diagnosisCodePointers[k]
              )
            ) {
              if (
                parseInt(index + 1) ==
                parseInt(
                  billyEntries.Professional.claimInformation.serviceLines[i]
                    .professionalService?.compositeDiagnosisCodePointers
                    ?.diagnosisCodePointers[k]
                )
              ) {
                var diagnosisCodePointers = [
                  ...billyEntries.Professional.claimInformation.serviceLines[
                    i
                  ].professionalService?.compositeDiagnosisCodePointers.diagnosisCodePointers.splice(
                    k,
                    1
                  ),
                ];
              } else {
                billyEntries.Professional.claimInformation.serviceLines[
                  i
                ].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers[
                  k
                ] =
                  billyEntries.Professional.claimInformation.serviceLines[i]
                    .professionalService?.compositeDiagnosisCodePointers
                    .diagnosisCodePointers[k] - 1;
              }
            }
          }

          for (
            var k = 0;
            k <
            billyEntries.Professional.claimInformation.serviceLines[i]
              .professionalService?.compositeDiagnosisCodePointers
              ?.diagnosisCodePointers.length;
            k++
          ) {
            if (
              parseInt(index + 1) <=
              parseInt(
                billyEntries.Professional.claimInformation.serviceLines[i]
                  .professionalService?.compositeDiagnosisCodePointers
                  ?.diagnosisCodePointers[k]
              )
            ) {
              billyEntries.Professional.claimInformation.serviceLines[
                i
              ].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers[
                k
              ] =
                billyEntries.Professional.claimInformation.serviceLines[i]
                  .professionalService?.compositeDiagnosisCodePointers
                  .diagnosisCodePointers[k] - 1;
            }
          }
        }
      }
    }
    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  const handleChangeRendering = (e) => {
    const { name, value } = e.target;
    const keys = name.split("."); // Split name by '.' to get nested keys

    // Update billyEntries state based on nested keys
    setbillyEntries((prevState) => {
      const updatedEntries = { ...prevState };
      let current = updatedEntries;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
          current[keys[i]] = {};
        }
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      return updatedEntries;
    });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  function BindDisplayModifiers(strmodiers) {
    var chkModiers = "";

    if (strmodiers != null) {
      for (var i = 0; i < strmodiers.length; i++) {
        chkModiers = chkModiers + strmodiers[i] + ",";
      }
    }
    return chkModiers;
  }

  //   function addHealthCareCode(setIndex){
  //     //// debugger;
  // //  setbillyEntries(...billyEntries.Professional.claimInformation.healthCareCodeInformation,{diagnosisTypeCode:"",diagnosisCode:""});
  //     }
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    reUsedFun(
      !data?.data?.edi837ListObject
        ? edi837ListObject
        : data?.data?.edi837ListObject,
      value
    );
  };

  const addHealthCareCode = async (selVal) => {
    var healthCareCodeInformation = [
      ...billyEntries.Professional.claimInformation.healthCareCodeInformation,
      { diagnosisTypeCode: "", diagnosisCode: "" },
    ];
    billyEntries.Professional.claimInformation.healthCareCodeInformation =
      healthCareCodeInformation;
    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
    // if(billyEntries.Professional?.claimInformation?.healthCareCodeInformation==undefined || billyEntries.Professional?.claimInformation?.healthCareCodeInformation==null)
    // {
    //   billyEntries.Professional?.claimInformation?.healthCareCodeInformation=[];
    // }

    // billyEntries.Professional?.claimInformation?.healthCareCodeInformation.push({diagnosisTypeCode :"",diagnosisTypeCode :""});
  };

  const handleProClick = async () => {};

  const procedureAdd = async (selVal) => {
    var claimInfortmation = billyEntries;
    if (
      billyEntries.Professional.claimInformation.serviceLines == null ||
      billyEntries.Professional.claimInformation.serviceLines == undefined
    ) {
      billyEntries.Professional.claimInformation.serviceLines = [];
    }
    if (claimInfortmation != null) {
      var healthCareCodeInformation = [
        ...billyEntries.Professional.claimInformation.serviceLines,
        {
          assignedNumber: "",
          serviceDate: "",
          assignedNumber: null,
          serviceDate: "",
          serviceDateEnd: null,
          providerControlNumber: null,
          professionalService: {
            procedureIdentifier: "",
            procedureCode: "",
            procedureModifiers: [],
            description: "",
            lineItemChargeAmount: "",
            measurementUnit: "",
            serviceUnitCount: "",
            placeOfServiceCode: null,
            compositeDiagnosisCodePointers: {
              diagnosisCodePointers: [],
            },
            emergencyIndicator: null,
            epsdtIndicator: null,
            familyPlanningIndicator: null,
            copayStatusCode: null,
          },
          durableMedicalEquipmentService: null,
          serviceLineSupplementalInformation: null,
          durableMedicalEquipmentCertificateOfMedicalNecessity: null,
          ambulanceTransportInformation: null,
          durableMedicalEquipmentCertification: null,
          ambulanceCertification: null,
          hospiceEmployeeIndicator: false,
          conditionIndicatorDurableMedicalEquipment: null,
          serviceLineDateInformation: null,
          ambulancePatientCount: 0,
          obstetricAnesthesiaAdditionalUnits: 0,
          testResults: null,
          contractInformation: null,
          serviceLineReferenceInformation: null,
          salesTaxAmount: null,
          postageTaxAmount: null,
          fileInformation: null,
          additionalNotes: null,
          goalRehabOrDischargePlans: null,
          thirdPartyOrganizationNotes: null,
          purchasedServiceInformation: null,
          linePricingRepricingInformation: null,
          drugIdentification: {
            serviceIdQualifier: "",
            nationalDrugCode: "",
            nationalDrugUnitCount: "",
            measurementUnitCode: "",
            linkSequenceNumber: null,
            pharmacyPrescriptionNumber: null,
          },
          renderingProvider: {
            providerType: "RenderingProvider",
            npi: "",
            ssn: null,
            employerId: "",
            commercialNumber: null,
            locationNumber: null,
            payerIdentificationNumber: null,
            employerIdentificationNumber: null,
            claimOfficeNumber: null,
            naic: null,
            stateLicenseNumber: null,
            providerUpinNumber: null,
            taxonomyCode: "",
            firstName: "Ronnie",
            lastName: "Neuberg",
            middleName: null,
            suffix: null,
            organizationName: null,
            address: null,
            contactInformation: null,
            otherIdentifier: null,
            secondaryIdentifier: null,
          },
          purchasedServiceProvider: null,
          serviceFacilityLocation: null,
          supervisingProvider: null,
          orderingProvider: null,
          referringProvider: null,
          ambulancePickUpLocation: null,
          ambulanceDropOffLocation: null,
          lineAdjudicationInformation: null,
          formIdentification: null,
        },
      ];
      billyEntries.Professional.claimInformation.serviceLines =
        healthCareCodeInformation;
      setbillyEntries({ ...billyEntries });
      if (!isAnythingChanged && setIsAnythingChanged) {
        setIsAnythingChanged(true);
      }
    }
  };

  const deleteProc = async (index) => {
    var healthCareCodeInformation = [
      ...billyEntries.Professional.claimInformation.serviceLines.splice(
        index,
        1
      ),
    ];
    billyEntries.Professional.claimInformation.serviceLines =
      billyEntries.Professional.claimInformation.serviceLines;
    setbillyEntries({ ...billyEntries });
    if (!isAnythingChanged && setIsAnythingChanged) {
      setIsAnythingChanged(true);
    }
  };

  function beforeSearchClearData() {
    setPayerReadOnly(0);
    setCopyClaimFlag("0");
    setstgEDI837Id("");
    setRemarks("");
    settransactionReamrks("");
    setclaimType("");
    setclaimTypeDate("");
    setorbitAssignedClaimID("");
    //// debugger;
    setbillyEntries([]);
    setheaderEntities({});
    // console.log("936");
    setisDataDisplaying(false);
  }

  function handleClearSaveData() {
    setPayerReadOnly(0);
    setCopyClaimFlag("0");
    setstgEDI837Id("");
    setRemarks("");
    settransactionReamrks("");
    setclaimType("");
    setorbitAssignedClaimID("");
    setclaimTypeDate("");
    //// debugger;
    setbillyEntries([]);
    setheaderEntities({});
    // console.log("952");
    setisDataDisplaying(false);
    providerClaimId.current.value = "";
    patientControlRef.current.value = "";
    providerRef.current.value = "";
    if (setDataFromTSL) {
      setDataFromTSL();
    }
    if (setEdi837ListObject) {
      setEdi837ListObject([]);
    }
    setOrbitClaimId("");
    setProviderControlNumber("");
    setPatientControlNumber("");
    providerClaimId.current.focus();
  }

  function handleErrorvalidatrions(isSubmitFlag) {
    if (PayerId == "" || PayerId == null) {
      setNotify({
        isOpen: true,
        message: "Enter the payer id.",
        type: "error",
      });
      return true;
    }
    if (PayerName == "" || PayerName == null) {
      setNotify({
        isOpen: true,
        message: "Enter the payer Name.",
        type: "error",
      });
      return true;
    }
    if (PayerPriority == "" || PayerPriority == null) {
      setNotify({
        isOpen: true,
        message: "Select the Payer Priority.",
        type: "error",
      });
      return true;
    }

    if (ClaimAction == "" || ClaimAction == null) {
      setNotify({
        isOpen: true,
        message: "Select the Claim Action.",
        type: "error",
      });
      return true;
    }
    if (ClaimAction == "7") {
      var claimControlNumber =
        billyEntries.Professional?.claimInformation
          ?.claimSupplementalInformation?.claimControlNumber;
      if (claimControlNumber == "" || claimControlNumber == null) {
        setNotify({
          isOpen: true,
          message: "Enter the ICN value.",
          type: "error",
        });
        return true;
      }
    }
    if (isSubmitFlag == 1) {
      var ismissingDiagonsis = false;
      for (
        var i = 0;
        i <
        billyEntries?.Professional?.claimInformation?.healthCareCodeInformation
          ?.length;
        i++
      ) {
        var ischeckMofifiersFalse = false;
        for (
          var k = 0;
          k < billyEntries.Professional?.claimInformation?.serviceLines.length;
          k++
        ) {
          //debugger;
          if (
            billyEntries.Professional?.claimInformation?.serviceLines[k]
              .professionalService?.compositeDiagnosisCodePointers
              ?.diagnosisCodePointers != null
          ) {
            var chkIntger = parseInt(i + 1).toString();
            if (
              billyEntries.Professional?.claimInformation?.serviceLines[k]
                .professionalService?.compositeDiagnosisCodePointers
                ?.diagnosisCodePointers != null
            ) {
              try {
                if (
                  billyEntries.Professional?.claimInformation?.serviceLines[
                    k
                  ].professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf(
                    chkIntger
                  ) == -1
                ) {
                } else {
                  ischeckMofifiersFalse = true;
                  break;
                }
              } catch (ex) {}
            }
          }
        }

        if (ischeckMofifiersFalse == false) {
          ismissingDiagonsis = true;
          break;
        }
      }
      if (ismissingDiagonsis == true) {
        setNotify({
          isOpen: true,
          message:
            "Link at least one diagnosis ( '" +
            billyEntries?.Professional?.claimInformation
              ?.healthCareCodeInformation[chkIntger - 1].diagnosisTypeCode +
            "' ) to the procedure.",
          type: "error",
        });
        return true;
      }
    }
    billyEntries.SourceTradingPartnerServiceId = CHPayerId;

    if (billyEntries.Professional == undefined) {
      billyEntries.Professional = {};
      billyEntries.Professional.receiver = {};
      billyEntries.Professional.subscriber = {};
      billyEntries.Professional.subscriber.paymentResponsibilityLevelCode = {};
      billyEntries.Professional.claimInformation = {};
      billyEntries.Professional.claimInformation.serviceLines = [];
    }
    billyEntries.Professional.tradingPartnerServiceId = PayerId;
    billyEntries.Professional.receiver.organizationName = PayerName;
    billyEntries.Professional.subscriber.paymentResponsibilityLevelCode =
      PayerPriority;
    billyEntries.Professional.claimInformation.claimFrequencyCode = ClaimAction;
    return false;
  }

  const handleBeforeClearSaveData = async () => {
    setPayerReadOnly(0);
    setCopyClaimFlag("0");
    setstgEDI837Id("");
    setRemarks("");
    settransactionReamrks("");
    setclaimType("");
    setclaimTypeDate("");
    setorbitAssignedClaimID("");
    //// debugger;
    setbillyEntries([]);
    setheaderEntities({});
    // console.log("1071");
    setisDataDisplaying(false);
  };

  const handleOrgSaveData = async () => {
    if (billyEntries != null) {
      let valuess = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          auditTrail: 0,
          editTrail: 0,
          dataSource: "",
          processStatus: "",
          deliveryStatus: "",
        },
        orbitAssignedClaimID: orbitAssignedClaimID,
        providerControlNumber: providerRef.current.value,
        orbitAssignedClaimIDSrch: providerClaimId.current.value,
        patientControlNumber: patientControlRef?.current?.value,
        submitDocument:
          isSubmitButtonClick == 5 ? 5 : isSubmitButtonClick == 6 ? 6 : 3,
        stgEDI837Id: 0,
        inputJson: JSON.stringify(billyEntries),
      };
      if (isSubmitButtonClick != 5 && isSubmitButtonClick != 6) {
        setisSubmitClick(3);
      }
      try {
        await mutateServiceTypeAsync(valuess);
      } catch (e) {
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "handleOrgSaveData",
          e?.message,
          e
        );
      }
    }

    //   setNotify({
    //     isOpen: true,
    //     message: 'Saved Sucessfully',
    //     type: 'success',
    //   });
    // }
    // else{setNotify({
    //     isOpen: true,
    //     message: 'There is no data for submit',
    //     type: 'error',
    //   });}
  };

  const handleSaveData = async () => {
    // debugger;
    if (billyEntries != null) {
      var isCheckError = handleErrorvalidatrions();
      if (isCheckError == true) {
        return;
      }
      try {
        let valuess = {
          commonParams: {
            organizationID: details?.orgId,
            companyID: details?.companyId,
            coCode: details?.companyCode,
            groupID: details?.groupID,
            timeZone: details?.timeZone,
            sessionId: details?.sesionId,
            loginUserId: `${details?.userId}`,
            loginUserName: details?.userName,
            auditTrail: 0,
            editTrail: 0,
            dataSource: "",
            processStatus: "",
            deliveryStatus: "",
          },
          orbitAssignedClaimID: orbitAssignedClaimID,
          providerControlNumber: providerRef.current.value,
          orbitAssignedClaimIDSrch: providerClaimId.current.value,
          patientControlNumber: patientControlRef?.current?.value,
          submitDocument: 0,
          stgEDI837Id: stgEDI837Id,
          inputJson: JSON.stringify(billyEntries),
        };
        setisSubmitClick(0);
        await mutateServiceTypeAsync(valuess);
      } catch (ex) {
        // debugger;
        // catch(e){
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "handleSaveData",
          ex?.message,
          ex
        );
        // }
      }
    }

    //   setNotify({
    //     isOpen: true,
    //     message: 'Saved Sucessfully',
    //     type: 'success',
    //   });
    // }
    // else{setNotify({
    //     isOpen: true,
    //     message: 'There is no data for submit',
    //     type: 'error',
    //   });}
  };

  const handlePopupCallButton = async () => {
    debugger;
    setOpen(false);
    if (isSubmitButtonClick == 0) {
      handleVoidData();
    } else if (isSubmitButtonClick == 1) {
      handleOrgSaveData();
    } else if (isSubmitButtonClick == 5) {
      handleOrgSaveData();
    } else if (isSubmitButtonClick == 6) {
      handleOrgSaveData();
    } else {
      handleButtonClick();
    }
  };

  const handleVoidPopupData = async () => {
    setisSubmitButtonClick(0);
    setOpen(true);
  };

  const handleHoldPopupData = async () => {
    setisSubmitButtonClick(5);
    setOpen(true);
  };

  const handleReleasePopupData = async () => {
    setisSubmitButtonClick(6);
    setOpen(true);
  };

  const handleStatusPopupData = async () => {
    setisSubmitButtonClick(2);
    setOpen(true);
  };

  const handleCopyClaimData = async () => {
    setisSubmitButtonClick(1);
    setOpen(true);
  };

  const handleVoidData = async () => {
    setOpen(false);
    if (billyEntries != null) {
      try {
        let valuess = {
          commonParams: {
            organizationID: details?.orgId,
            companyID: details?.companyId,
            coCode: details?.companyCode,
            groupID: details?.groupID,
            timeZone: details?.timeZone,
            sessionId: details?.sesionId,
            loginUserId: `${details?.userId}`,
            loginUserName: details?.userName,
            auditTrail: 0,
            editTrail: 0,
            dataSource: "",
            processStatus: "",
            deliveryStatus: "",
          },
          orbitAssignedClaimID: orbitAssignedClaimID,
          searchClaimID: providerClaimId?.current?.value,
          providerControlNumber: providerRef.current.value,
          orbitAssignedClaimIDSrch: providerClaimId.current.value,
          patientControlNumber: patientControlRef?.current?.value,
          submitDocument: 4,
          stgEDI837Id: stgEDI837Id,
          inputJson: JSON.stringify(billyEntries),
        };
        setisSubmitClick(4);
        await mutateServiceTypeAsync(valuess);
      } catch (ex) {
        // debugger;
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "handleVoidData",
          ex?.message,
          ex
        );
      }
    }

    //   setNotify({
    //     isOpen: true,
    //     message: 'Saved Sucessfully',
    //     type: 'success',
    //   });
    // }
    // else{setNotify({
    //     isOpen: true,
    //     message: 'There is no data for submit',
    //     type: 'error',
    //   });}
  };
  // const history = useLocation();
  // console.log(history, "history");

  const handleSubmitData = async () => {
    if (billyEntries != null) {
      var isCheckError = handleErrorvalidatrions(1);
      if (isCheckError == true) {
        return;
      }

      let valuess = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          auditTrail: 0,
          editTrail: 0,
          dataSource: "",
          processStatus: "",
          deliveryStatus: "",
        },
        orbitAssignedClaimID: orbitAssignedClaimID,
        searchClaimID: providerClaimId?.current?.value,
        providerControlNumber: providerRef.current.value,
        orbitAssignedClaimIDSrch: providerClaimId.current.value,
        patientControlNumber: patientControlRef?.current?.value,
        submitDocument: 1,
        stgEDI837Id: stgEDI837Id,
        inputJson: JSON.stringify(billyEntries),
      };
      setisSubmitClick(1);
      try {
        await mutateServiceTypeAsync(valuess);
      } catch (e) {
        logTelemetry(
          "Failed",
          "ClaimSubmitDetails",
          "handleSubmitData",
          e?.message,
          e
        );
      }
    }

    //   setNotify({
    //     isOpen: true,
    //     message: 'Saved Sucessfully',
    //     type: 'success',
    //   });
    // }
    // else{setNotify({
    //     isOpen: true,
    //     message: 'There is no data for submit',
    //     type: 'error',
    //   });}
  };
  const handleSearchpayerData = async (selval) => {
    let valuess = {
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: "System",
        auditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      },
      inputJson: selval == 1 ? CHPayerId : selval == 2 ? PayerId : PayerName,
      requestName: selval == 1 ? "payerId" : selval == 2 ? "payerId" : "name",
    };
    try {
      await mutatePayerTypeAsync(valuess);
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimSubmitDetails",
        "handleSearchpayerData",
        e?.message,
        e
      );
    }
  };

  const handleButtonClick = () => {
    debugger;
    if (dataFromTSL?.selObject != undefined) {
      dataFromTSL.selObject.providercontrolnumber =
        headerEntities?.providerControlNumber;
        dataFromTSL.selObject.memberId=  billyEntries.Professional?.subscriber?.memberId;
        dataFromTSL.selObject.dos= headerEntities?.dateOfService;
        dataFromTSL.selObject.renderingprovider= billyEntries.Professional?.rendering?.lastName;
        dataFromTSL.selObject.renderingproviderNPI= billyEntries.Professional?.rendering?.npi;
        dataFromTSL.patientFirstName= billyEntries.Professional?.subscriber?.firstName;
        dataFromTSL.patientLastName= billyEntries.Professional?.subscriber?.lastName;
        dataFromTSL.patientDob= billyEntries.Professional?.subscriber?.dateOfBirth;
        dataFromTSL.patientGender= billyEntries.Professional?.subscriber?.gender;
      setdataForCStatus({ providercontrolnumber: dataFromTSL?.selObject });
      setValue(3);
    } else {
      if (setdataForCStatus == undefined) {
        var providercontrolnumber = {
          orbitAssignedClaimId: headerEntities?.orbitAssignedClaimID,
          patientControlNumber: "",
          providercontrolnumber: headerEntities?.providerControlNumber,
          orbitclaimspayerid: headerEntities?.orbitAssignedClaimID,
          cpid: headerEntities?.cpid,
          payername: headerEntities?.payerName,
          patientFirstName: billyEntries.Professional?.subscriber?.firstName,
          patientLastName: billyEntries.Professional?.subscriber?.lastName,
          patientDob: billyEntries.Professional?.subscriber?.dateOfBirth,
          patientGender: billyEntries.Professional?.subscriber?.gender,
          dos: headerEntities?.dateOfService,
          renderingprovider: billyEntries.Professional?.rendering?.lastName,
          renderingproviderNPI: billyEntries.Professional?.rendering?.npi,
          memberId: billyEntries.Professional?.subscriber?.memberId,
        };
        dispatch(setClaimStatusData(providercontrolnumber));
        dispatch(setActiveComponent("/patient-list/claim-Status"));
        // navigation("/patient-list/claim-Status", {
        //   state: providercontrolnumber,
        // });
      } else {
        setdataForCStatus({
          providercontrolnumber: {
            orbitAssignedClaimId: headerEntities?.orbitAssignedClaimID,
            patientControlNumber: "",
            providercontrolnumber: headerEntities?.providerControlNumber,
            orbitclaimspayerid: headerEntities?.orbitAssignedClaimID,
            cpid: headerEntities?.cpid,
            payername: headerEntities?.payerName,
            patientFirstName: billyEntries.Professional?.subscriber?.firstName,
            patientLastName: billyEntries.Professional?.subscriber?.lastName,
            patientDob: billyEntries.Professional?.subscriber?.dateOfBirth,
            patientGender: billyEntries.Professional?.subscriber?.gender,
            dos:  headerEntities?.dateOfService,
            providerName:  billyEntries.Professional?.rendering?.lastName,
            providerNpi:  billyEntries.Professional?.rendering?.npi,
            renderingprovider: billyEntries.Professional?.rendering?.lastName,
            renderingproviderNPI: billyEntries.Professional?.rendering?.npi,
            memberId: billyEntries.Professional?.subscriber?.memberId,
          },
        });
        setValue(3);
      }
    }
  };

  const handleSubmit = async (isFromList) => {
    const providerClaimIdValue = providerClaimId.current.value;
    const providerRefValue = providerRef.current.value;
    const patientRefValue = patientControlRef.current.value;
    if (!providerClaimIdValue && !providerRefValue && !patientRefValue) {
      setNotify({
        isOpen: true,
        message: "Enter Provider Control Number or Claim ID",
        type: "error",
      });
      return false;
    }
    // if (!isFromList && setIsAnythingChanged) {
    //   setIsAnythingChanged(true);
    // }
    beforeSearchClearData();
    setLoadingg(true);
    debugger;
      
    try {
      await mutateAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.coCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
          processStatus: "",
          deliveryStatus: "",
        },
        providerControlNumber: providerRef.current.value,
        orbitAssignedClaimID: providerClaimId.current.value,
        patientControlNumber: patientControlRef?.current?.value,
      });
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimSubmitDetails",
        "handleSubmit",
        e?.message,
        e
      );
    }
  };

  const inputValue =
    providerRef.current && typeof providerRef.current.value === "object"
      ? "" // If the value is an object, set inputValue to empty string
      : providerRef.current && providerRef.current.value !== undefined
      ? providerRef.current.value // If the value is defined and not an object, use the actual value
      : ""; // If the value is undefined, set inputValue to empty string

  // console.log("accordionClaimInformation", accordionClaimInformation111);
  // console.log("billyEntries", billyEntries);

  return (
    <div>
      <Container maxWidth="xl">
        <Grid container>
          {!location?.pathname?.includes("transation-summary") && (
            <Grid xs={8} sm={10} className="my-4 flex items-center">
              <Breadcrumb items={breadcrumbItems} isRedux={true} />
            </Grid>
          )}
        </Grid>

        <Card>
          <CardContent>
            <Grid
              container
              sx={{
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid item xs={3} sm={3} className="flex justify-center">
                  <TextField
                    autoFocus
                    id="Patient Control Number"
                    label="Patient Control Number"
                    name="Patient Control Number"
                    autoComplete="off"
                    // value={inputValue}
                    // value={
                    //   providerRef?.current &&
                    //   typeof providerRef?.current?.value === "object"
                    //     ? ""
                    //     : providerRef?.current?.value
                    // }
                    // onChange={(e) => handleProviderInputChange(e)}
                    inputRef={patientControlRef}
                    className="m-0"
                    size="small"
                    sx={{ background: "white" }}
                    style={textFieldStyles}
                  />
                </Grid>
                <span
                  style={{
                    marginTop: "18px",
                  }}
                >
                  (or)
                </span>
                <Grid item xs={3} sm={3} className="flex justify-center">
                  <TextField
                    id="Provider Control Number"
                    label="Provider Control Number"
                    name="Provider Control Number"
                    autoComplete="off"
                    // value={inputValue}
                    // value={
                    //   providerRef?.current &&
                    //   typeof providerRef?.current?.value === "object"
                    //     ? ""
                    //     : providerRef?.current?.value
                    // }
                    // onChange={(e) => handleProviderInputChange(e)}
                    inputRef={providerRef}
                    className="m-0"
                    size="small"
                    sx={{ background: "white" }}
                    style={textFieldStyles}
                  />
                </Grid>
                <span
                  style={{
                    marginTop: "18px",
                  }}
                >
                  (or)
                </span>
                <Grid item xs={4} sm={4} className="flex justify-center">
                  <TextField
                    autoFocus
                    id="outlined-password-input2"
                    label="Orbit Claim Id"
                    name="Orbit Claim Id"
                    autoComplete="off"
                    inputRef={providerClaimId}
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  className="pt-4"
                  style={{ paddingLeft: "10px" }}
                >
                  <Button
                    text="Search"
                    // navigate="/capture/capture-driving-license/patient-info"
                    classname={"button-styles btnHeight"}
                    filled={true}
                    onclick={() => handleSubmit()}
                  />
                </Grid>
                {isDataDisplaying &&
                  PayerReadOnly == "0" &&
                  CopyClaimFlag == "0" && (
                    <>
                      <Grid item xs={12} sm={1} className="pt-4 pl-1">
                        <Button
                          text="Save"
                          classname={"button-styles btnHeight"}
                          filled={true}
                          onclick={handleSaveData}
                        />
                      </Grid>

                      {headerEntities?.onHold == 1 &&
                      headerEntities?.processFlag == 10 ? (
                        <></>
                      ) : (
                        <Grid xs={12} sm={1} className="pt-4 pl-1">
                          <Button
                            text="Submit"
                            classname={"button-styles btnHeight"}
                            filled={true}
                            onclick={() => handleSubmitData()}
                          />{" "}
                        </Grid>
                      )}
                    </>
                  )}
                {isDataDisplaying && CopyClaimFlag == "1" && (
                  <>
                    <Grid item xs={12} sm={2} className="pt-4 pl-1">
                      <Button
                        width="200px"
                        text="Copy Claim"
                        classname={"button-styles btnHeight"}
                        filled={true}
                        onclick={handleCopyClaimData}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={1} className="pt-4 pl-1">
                  <Button
                    width="200px"
                    text="Clear"
                    classname={"button-styles btnHeight"}
                    filled={true}
                    onclick={handleClearSaveData}
                  />
                </Grid>{" "}
              </Grid>
              {/* {billyEntries.map(([key, value]) => {  */}
            </Grid>
          </CardContent>
        </Card>

        {isDataDisplaying && (
          <Grid>
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <div className="w-full my-4">
                  <Grid
                    container
                    alignItems={"center"}
                    className="my-4"
                    style={{ paddingRight: "16px" }}
                  >
                    <Grid item xs={2} sm={2} style={{ paddingLeft: "10px" }}>
                      <Button
                        text="Verify Claim Status"
                        // navigate="/capture/capture-driving-license/patient-info"
                        classname={"button-styles btnHeight btnOver"}
                        variant="contained"
                        filled={true}
                        onclick={() => handleStatusPopupData()}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} className="pl-1">
                      {headerEntities?.recordStatus == 1 && (
                        <Button
                          text="Void"
                          // navigate="/capture/capture-driving-license/patient-info"
                          classname={"button-styles btnHeight"}
                          filled={true}
                          onclick={() => handleVoidPopupData()}
                        />
                      )}
                    </Grid>
                    <Grid item xs={1} sm={1} className="pl-1">
                      {headerEntities?.onHold == 0 &&
                        headerEntities?.processFlag == 10 && (
                          <Button
                            text="Hold"
                            // navigate="/capture/capture-driving-license/patient-info"
                            classname={"button-styles btnHeight"}
                            filled={true}
                            onclick={() => handleHoldPopupData()}
                          />
                        )}
                      {headerEntities?.onHold == 1 &&
                        headerEntities?.processFlag == 10 && (
                          <Button
                            text="Release"
                            // navigate="/capture/capture-driving-license/patient-info"
                            classname={"button-styles btnHeight"}
                            filled={true}
                            onclick={() => handleReleasePopupData()}
                          />
                        )}
                    </Grid>
                    <Grid item xs={2} sm={2} className="pl-1">
                      {headerEntities?.readOnlyFlag == 1 && (
                        <span className="statusLabel">Read Only</span>
                      )}
                      {headerEntities?.readOnlyFlag == 0 && (
                        <span className="statusLabel">Editable</span>
                      )}
                    </Grid>
                    <Grid item xs={3} sm={3} style={{ marginLeft: "auto" }}>
                      <Stack spacing={2} style={{ float: "right" }}>
                        <Typography sx={{ fontWeight: "" }} gutterBottom>
                          Record Count :&nbsp;
                          <b>
                            {data?.data?.edi837ListObject?.length
                              ? data.data.edi837ListObject.length
                              : edi837ListObject?.length
                              ? edi837ListObject.length
                              : 0}
                          </b>
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container alignItems={"center"}>
                    <Grid item xs={2} sm={2}></Grid>
                    <Grid item xs={1} sm={1} className="pl-1"></Grid>
                    <Grid item xs={1} sm={1} className="pl-1"></Grid>
                    <Grid item xs={5} sm={5} style={{ marginLeft: "auto" }}>
                      <Stack spacing={2} style={{ float: "right" }}>
                        <Pagination
                          page={page}
                          onChange={(e, val) => {
                            setLoadingg(!loadingg);
                            handleChange(e, val);
                            // if (setIsAnythingChanged) {
                            //   setIsAnythingChanged(true);
                            // }
                            if (accordionCurrent !== 0) {
                              setAccordionCurrent(0);
                            }
                          }}
                          count={
                            data?.data?.edi837ListObject?.length
                              ? data.data.edi837ListObject.length
                              : edi837ListObject?.length
                              ? edi837ListObject.length
                              : 1
                          }
                          size="large"
                        />
                      </Stack>
                    </Grid>{" "}
                  </Grid>

                  {/*  start */}
                  <Card sx={{ paddingTop: "10px" }}>
                    <CardContent sx={{ background: "#ebebeb" }}>
                      <Grid container spacing={2}>
                        {/* Row 1 */}
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">PCN :&nbsp; </label>
                            <label className="minWid200">
                              {headerEntities?.providerControlNumber}
                            </label>
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            <label className="fixWid200">
                              Claim Id :&nbsp;{" "}
                            </label>
                            <label className="minWid200">
                              {headerEntities?.orbitAssignedClaimID}
                            </label>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            <label className="fixWid200">DOS :&nbsp;</label>
                            <label className="minWid200">
                              {moment(headerEntities?.dateOfService).format(
                                "MM/DD/YYYY"
                              ) == "Invalid date"
                                ? ""
                                : headerEntities?.dateOfService == undefined
                                ? ""
                                : moment(headerEntities?.dateOfService).format(
                                    "MM/DD/YYYY"
                                  )}
                            </label>
                          </Typography>
                        </Grid>

                        {/* Row 2 */}
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">
                              Rec. Type :&nbsp;
                            </label>
                            <label className="minWid200">{claimType}</label>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">
                              Claim Received Date :&nbsp;
                            </label>
                            <label className="minWid200">
                              {moment(headerEntities?.claimReceivedDate).format(
                                "MM/DD/YYYY"
                              ) == "Invalid date"
                                ? ""
                                : headerEntities?.claimReceivedDate == undefined
                                ? ""
                                : moment(
                                    headerEntities?.claimReceivedDate
                                  ).format("MM/DD/YYYY")}
                            </label>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">
                              Claim Sent Date :&nbsp;
                            </label>
                            <label className="minWid200">
                              {moment(headerEntities?.fileProcessDate).format(
                                "MM/DD/YYYY"
                              ) == "Invalid date"
                                ? ""
                                : headerEntities?.fileProcessDate == undefined
                                ? ""
                                : moment(
                                    headerEntities?.fileProcessDate
                                  ).format("MM/DD/YYYY")}
                            </label>
                          </Typography>
                        </Grid>

                        {/* Row 3 */}
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">
                              Claim Status :&nbsp;
                            </label>
                            <label
                              className="minWid200"
                              style={{
                                color:
                                  headerEntities?.transactionReamrks ==
                                  "Rejected"
                                    ? "black"
                                    : "black",
                                background:
                                  headerEntities?.transactionReamrks ==
                                  "Rejected"
                                    ? "#f7e0e2"
                                    : "",
                              }}
                            >
                              {headerEntities?.transactionReamrks}
                            </label>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <label className="fixWid200">
                              Claim Status Date :&nbsp;
                            </label>
                            <label className="minWid200">
                              {moment(headerEntities?.claimStatusDate).format(
                                "MM/DD/YYYY"
                              ) == "Invalid date"
                                ? ""
                                : headerEntities?.claimStatusDate == undefined
                                ? ""
                                : moment(
                                    headerEntities?.claimStatusDate
                                  ).format("MM/DD/YYYY")}
                            </label>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <label className="fixWid200">Remarks :&nbsp;</label>
                            <label className="minWid200">
                              {Remarks?.length > 0 && (
                                <span
                                  style={{
                                    padding: "5px",
                                    color: "black",
                                    background: "#f7e0e2",
                                  }}
                                >
                                  {Remarks}
                                </span>
                              )}
                            </label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  {/*  end */}
                  {handleErrors?.length > 0 && (
                    <Card sx={{ paddingTop: "10px" }}>
                      <CardContent sx={{ background: "#f7e0e2" }}>
                        {headerEntities?.previousHeaderErrors?.length > 0 && (
                          <Typography gutterBottom>
                            <b>Current Claim Errors</b>
                          </Typography>
                        )}

                        <Grid container sx={{ paddingTop: "10px" }}>
                          <Grid item xs={12} sm={12}>
                            <div
                              style={{
                                color: "black",
                                color: "red",
                                paddingTop: "10px",
                                paddingBottom: "5px",
                              }}
                            >
                              {handleErrors.map((label) => (
                                <div style={{ color: "black" }}>
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {moment(label.responseReceivedDate).format(
                                      "MM/DD/YYYY"
                                    ) == "Invalid date"
                                      ? ""
                                      : label.responseReceivedDate}
                                  </span>{" "}
                                  ||&nbsp;{" "}
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {label.receivedByEntity}
                                  </span>{" "}
                                  &nbsp;||&nbsp;{" "}
                                  <span style={{ color: "black" }}>
                                    {" "}
                                    {label.clearinghouseClaimNumber}
                                  </span>{" "}
                                  &nbsp;||&nbsp;
                                  {label.statusDescription} &nbsp;||{" "}
                                  {label.claimStatusSpecialNotes} &nbsp;
                                </div>
                              ))}
                            </div>
                          </Grid>{" "}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                  {headerEntities?.previousHeaderErrors?.length > 0 && (
                    <Card sx={{ paddingTop: "10px" }}>
                      <CardContent sx={{ background: "#f7e0e2" }}>
                        <Typography gutterBottom>
                          <b>Original Claim Errors</b>
                        </Typography>
                        <Grid container sx={{ paddingTop: "10px" }}>
                          <Grid item xs={12} sm={12}>
                            <div
                              style={{
                                color: "black",
                                color: "red",
                                paddingTop: "10px",
                                paddingBottom: "5px",
                              }}
                            >
                              {headerEntities?.previousHeaderErrors?.map(
                                (label) => (
                                  <div style={{ color: "black" }}>
                                    <span style={{ color: "black" }}>
                                      {" "}
                                      {moment(
                                        label.responseReceivedDate
                                      ).format("MM/DD/YYYY") == "Invalid date"
                                        ? ""
                                        : label.responseReceivedDate}
                                    </span>{" "}
                                    ||&nbsp;{" "}
                                    <span style={{ color: "black" }}>
                                      {" "}
                                      {label.receivedByEntity}
                                    </span>{" "}
                                    &nbsp;||&nbsp;{" "}
                                    <span style={{ color: "black" }}>
                                      {" "}
                                      {label.clearinghouseClaimNumber}
                                    </span>{" "}
                                    &nbsp;||&nbsp;
                                    {label.statusDescription} &nbsp;||{" "}
                                    {label.claimStatusSpecialNotes} &nbsp;
                                  </div>
                                )
                              )}
                            </div>
                          </Grid>{" "}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}

                  <Card sx={{ paddingTop: "10px" }}>
                    <CardContent sx={{ background: "#ebebeb" }}>
                      <b>Current Payer Details</b>
                      <Grid container>
                        <Grid item xs={4} sm={4}>
                          &nbsp;Payer Name
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          CPID
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          Payer ID
                        </Grid>
                        <Grid item xs={2} sm={2}></Grid>
                        <Grid item xs={2} sm={2}></Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          gap: "20px",
                          flexWrap: "nowrap",
                          width: "auto",
                        }}
                      >
                        <Grid item xs={4} sm={4}>
                          <Paper
                            size="small"
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            style={{ height: "40px", boxShadow: "none" }}
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              size="small"
                              value={PayerName}
                              placeholder="Search by payer name"
                              readOnly={PayerReadOnly === 0 ? false : true}
                              onChange={(e) => {
                                setPayerId("");
                                setCHPayerId("");
                                setPayerName(e.target.value);
                              }}
                              inputRef={inputBaseRef}
                              onBlur={(e) => {
                                setTimeout(() => {
                                  if (setisFocusOut == false) {
                                    setListOne([]);
                                  }
                                }, 1000);
                              }}
                              //   if (PayerData?.data?.patientContact !== null) {
                              //     PayerData.data.patientContact = null;
                              //   }
                              // }}
                              // onFocus={() => setPrevVal(PayerName)}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" &&
                                  PayerReadOnly === 0
                                ) {
                                  setisSeacrhCall(0);
                                  handleSearchpayerData(0);
                                  event.preventDefault();
                                }
                              }}
                            />{" "}
                            {PayerReadOnly === 0 && (
                              <>
                                <Divider
                                  sx={{ height: 10, m: 0.5 }}
                                  orientation="vertical"
                                />
                                <IconButton
                                  type="button"
                                  sx={{ p: "10px" }}
                                  aria-label="search"
                                  className="search-icon"
                                  onClick={() => {
                                    setisFocusOut = true;
                                    setisSeacrhCall(0);
                                    handleSearchpayerData(0);
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </>
                            )}
                          </Paper>
                          {listOne?.length > 0 && isSeacrhCall == 0 && (
                            <Paper className="menuPopupData">
                              <MenuList dense>
                                {listOne?.map((k, index) => {
                                  return (
                                    <MenuItem>
                                      <ListItemText
                                        onClick={(e, newVal) => {
                                          setHidePrev(true);
                                          // debugger;
                                          // Batch the state updates into a single call
                                          setPayerName((prevPayerName) => {
                                            setPreviousPayerName(prevVal); // Update previous payer name
                                            return k.chPayerName; // Set new payer name
                                          });
                                          setCHPayerId(k.chPayerId);

                                          setPayerId((prevPayerId) => {
                                            setPreviousPayerId(prevPayerId); // Update previous payer ID
                                            return k.payerId; // Set new payer ID
                                          });
                                          setListOne([]);
                                        }}
                                        inset
                                      >
                                        {k.displayName}
                                      </ListItemText>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Paper>
                          )}
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <Paper
                            size="small"
                            label="CPID"
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            style={{ height: "40px", boxShadow: "none" }}
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              size="small"
                              label="Payer Id"
                              value={CHPayerId}
                              inputRef={inputBaseIdRef}
                              readOnly={PayerReadOnly === 0 ? false : true}
                              placeholder="Search by CPID"
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              onBlur={(e) => {
                                setTimeout(() => {
                                  if (setisFocusOut == false) {
                                    setListTwo([]);
                                  }
                                }, 1000);
                              }}
                              onChange={(e) => {
                                setPayerName("");
                                setPayerId("");
                                setCHPayerId(e.target.value);
                              }}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" &&
                                  PayerReadOnly === 0
                                ) {
                                  setisSeacrhCall(1);
                                  handleSearchpayerData(1);
                                  event.preventDefault();
                                }
                              }}
                            />
                            {PayerReadOnly === 0 && (
                              <>
                                <Divider
                                  sx={{ height: 10, m: 0.5 }}
                                  orientation="vertical"
                                />
                                <IconButton
                                  type="button"
                                  sx={{ p: "10px" }}
                                  aria-label="search"
                                  className="search-icon"
                                  onClick={() => {
                                    setisFocusOut = true;
                                    setisSeacrhCall(1);
                                    handleSearchpayerData(1);
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </>
                            )}
                          </Paper>
                          {listTwo?.length > 0 && isSeacrhCall == 1 && (
                            <Paper className="menuPopupData">
                              <MenuList dense>
                                {listTwo?.map((k, index) => {
                                  return (
                                    <MenuItem>
                                      <ListItemText
                                        onClick={(e, newVal) => {
                                          // debugger;
                                          // Batch the state updates into a single call
                                          setPayerName((prevPayerName) => {
                                            setPreviousPayerName(prevVal); // Update previous payer name
                                            return k.chPayerName; // Set new payer name
                                          });
                                          setHidePrev(true);
                                          setCHPayerId(k.chPayerId);
                                          setPayerId((prevPayerId) => {
                                            setPreviousPayerId(prevPayerId); // Update previous payer ID
                                            return k.payerId; // Set new payer ID
                                          });
                                          setListTwo([]);
                                        }}
                                        inset
                                      >
                                        {k.displayName}
                                      </ListItemText>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Paper>
                          )}
                        </Grid>{" "}
                        <Grid item xs={2} sm={2}>
                          <Paper
                            size="small"
                            label="Payer ID"
                            component="form"
                            sx={{
                              p: "2px 4px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            style={{ height: "40px", boxShadow: "none" }}
                          >
                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              size="small"
                              label="Payer Id"
                              value={PayerId}
                              inputRef={inputPayerIdRef}
                              readOnly={PayerReadOnly === 0 ? false : true}
                              placeholder="Search by Payer id"
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              onBlur={(e) => {
                                setTimeout(() => {
                                  if (setisFocusOut == false) {
                                    setlistThree([]);
                                  }
                                }, 1000);
                              }}
                              onChange={(e) => {
                                setPayerName("");
                                setCHPayerId("");
                                setPayerId(e.target.value);
                              }}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" &&
                                  PayerReadOnly === 0
                                ) {
                                  setisSeacrhCall(2);
                                  handleSearchpayerData(2);
                                  event.preventDefault();
                                }
                              }}
                            />
                            {PayerReadOnly === 0 && (
                              <>
                                <Divider
                                  sx={{ height: 10, m: 0.5 }}
                                  orientation="vertical"
                                />
                                <IconButton
                                  type="button"
                                  sx={{ p: "10px" }}
                                  aria-label="search"
                                  className="search-icon"
                                  onClick={() => {
                                    setisFocusOut = true;
                                    setisSeacrhCall(2);
                                    handleSearchpayerData(2);
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              </>
                            )}
                          </Paper>
                          {listThree?.length > 0 && isSeacrhCall == 2 && (
                            <Paper className="menuPopupData">
                              <MenuList dense>
                                {listThree?.map((k, index) => {
                                  return (
                                    <MenuItem>
                                      <ListItemText
                                        onClick={(e, newVal) => {
                                          // debugger;
                                          // Batch the state updates into a single call
                                          setPayerName((prevPayerName) => {
                                            setPreviousPayerName(prevVal); // Update previous payer name
                                            return k.chPayerName; // Set new payer name
                                          });
                                          setHidePrev(true);
                                          setCHPayerId(k.chPayerId);
                                          setPayerId((prevPayerId) => {
                                            setPreviousPayerId(prevPayerId); // Update previous payer ID
                                            return k.payerId; // Set new payer ID
                                          });
                                          setlistThree([]);
                                        }}
                                        inset
                                      >
                                        {k.displayName}
                                      </ListItemText>
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </Paper>
                          )}
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          <FormControl style={textFieldStylesddl}>
                            <InputLabel id="demo-simple-select-label">
                              Payer Priority
                            </InputLabel>
                            <Select
                              className={PayerReadOnly == 1 ? "read-only" : ""}
                              labelId="demo-simple-select-random-id"
                              id="demo-simple-select"
                              label="Payer Priority"
                              name="Payer Priority"
                              inputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              size="small"
                              width="300px"
                              value={PayerPriority}
                              onChange={(event, newValue) => {
                                setPayerPriority(event.target.value);
                              }}
                            >
                              <MenuItem value={"P"}>Primary</MenuItem>
                              <MenuItem value={"S"}>Secondary</MenuItem>
                              <MenuItem value={"T"}>Tertiary</MenuItem>
                              <MenuItem value={"U"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                          {PayerReadOnly === 0 && (
                            <>
                              <Tooltip title="Clear">
                                <MuiButton
                                  onClick={() => {
                                    setPayerName("");
                                    setPayerId("");
                                    setCHPayerId("");
                                    setPayerPriority("");
                                    setListOne([]);
                                    setListTwo([]);
                                    setHidePrev(false);
                                  }}
                                  className={"filterButton"}
                                >
                                  <ClearIcon />
                                </MuiButton>
                              </Tooltip>{" "}
                              <Tooltip title="Reset">
                                <MuiButton
                                  onClick={() => {
                                    setListOne([]);
                                    setListTwo([]);
                                    setHidePrev(false);
                                    setPayerName(
                                      billyEntries?.Professional?.receiver
                                        ?.organizationName
                                    );
                                    setCHPayerId(
                                      billyEntries?.SourceTradingPartnerServiceId
                                    );
                                    setPayerId(
                                      billyEntries?.Professional
                                        ?.tradingPartnerServiceId
                                    );
                                    setPayerPriority(
                                      billyEntries?.Professional?.subscriber
                                        ?.paymentResponsibilityLevelCode
                                    );
                                  }}
                                  className={"filterButton"}
                                >
                                  <RefreshIcon />
                                </MuiButton>
                              </Tooltip>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Divider style={{ paddingTop: "10px" }}></Divider>
                      {hidePrev && (
                        <>
                          <b>Previous Payer Details</b>
                          <Grid container>
                            <Grid item xs={4} sm={4}>
                              &nbsp;Payer Name
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              CPID
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              Payer ID
                            </Grid>
                            <Grid item xs={2} sm={2}></Grid>
                            <Grid item xs={2} sm={2}></Grid>
                          </Grid>
                          <Grid
                            container
                            style={{
                              gap: "20px",
                              flexWrap: "nowrap",
                              width: "auto",
                              paddingTop: "10px",
                            }}
                          >
                            <Grid item xs={4} sm={4}>
                              <Paper
                                size="small"
                                component="form"
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                style={{ height: "40px", boxShadow: "none" }}
                              >
                                <span>{previousPayerName}</span>
                              </Paper>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <Paper
                                size="small"
                                component="form"
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                style={{ height: "40px", boxShadow: "none" }}
                              >
                                <span>
                                  {billyEntries.SourceTradingPartnerServiceId}
                                </span>
                              </Paper>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <Paper
                                size="small"
                                component="form"
                                sx={{
                                  p: "2px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                style={{ height: "40px", boxShadow: "none" }}
                              >
                                <span>
                                  {
                                    billyEntries?.Professional
                                      ?.tradingPartnerServiceId
                                  }
                                </span>
                              </Paper>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <FormControl style={textFieldStylesddl}>
                                <InputLabel id="demo-simple-select-label">
                                  Payer Priority
                                </InputLabel>
                                <Select
                                  className={
                                    PayerReadOnly == 1 ? "read-only" : ""
                                  }
                                  inputProps={{ readOnly: true }}
                                  xs={3}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.paymentResponsibilityLevelCode
                                  }
                                  label="Gender"
                                  width="300px"
                                  tabIndex={6}
                                >
                                  <MenuItem value={"A"}>
                                    Payer Responsibility Four
                                  </MenuItem>
                                  <MenuItem value={"B"}>
                                    Payer Responsibility Five
                                  </MenuItem>
                                  <MenuItem value={"C"}>
                                    Payer Responsibility Six
                                  </MenuItem>
                                  <MenuItem value={"D"}>
                                    Payer Responsibility Seven
                                  </MenuItem>
                                  <MenuItem value={"E"}>
                                    Payer Responsibility Eight
                                  </MenuItem>
                                  <MenuItem value={"F"}>
                                    Payer Responsibility Nine
                                  </MenuItem>
                                  <MenuItem value={"G"}>
                                    Payer Responsibility Ten
                                  </MenuItem>
                                  <MenuItem value={"H"}>
                                    Payer Responsibility Eleven
                                  </MenuItem>
                                  <MenuItem value={"P"}>Primary</MenuItem>
                                  <MenuItem value={"S"}>Secondary</MenuItem>
                                  <MenuItem value={"T"}>Tertiary </MenuItem>
                                  <MenuItem value={"U"}>Unknown</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>{" "}
                            <Grid item xs={2} sm={2}></Grid>
                          </Grid>
                        </>
                      )}
                      <Grid
                        container
                        style={{
                          gap: "20px",
                          flexWrap: "nowrap",
                          width: "auto",
                          paddingTop: "20px",
                        }}
                      >
                        <Grid item xs={2} sm={2}>
                          <div></div>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <div>
                            <Typography
                              style={{
                                marginRight: "3px",
                                marginLeft: "2px",
                                overflowWrap: "break-word",
                                fontWeight: "bold",
                              }}
                              variant="body1"
                            >
                              Claim Action{" "}
                              <span className="lbl837Claim">string</span>
                              <span className="lbl837ClaimColor">required</span>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <FormControl style={textFieldStylesddl}>
                            <InputLabel id="demo-simple-select-label">
                              Action
                            </InputLabel>
                            <Select
                              className={PayerReadOnly == 1 ? "read-only" : ""}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              size="small"
                              inputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={ClaimAction}
                              onChange={(event, newValue) => {
                                // debugger;
                                setClaimAction(event.target.value);
                              }}
                            >
                              <MenuItem value={1}>
                                Original (Admit thru discharge claim)
                              </MenuItem>
                              <MenuItem value={7}>
                                Replacement (Replacement of prior claim)
                              </MenuItem>
                              <MenuItem value={8}>
                                Void (Void/cancel prior claim)
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {ClaimAction != 1 &&
                          (ClaimAction == 7 || ClaimAction == 8) && (
                            <Grid item xs={2} sm={2}>
                              <TextField
                                id="ICN1"
                                label="ICN"
                                name="ICN"
                                autoComplete="off"
                                inputProps={{
                                  readOnly: PayerReadOnly === 1,
                                }}
                                onChange={(event) => {
                                  const newValue = event.target.value;

                                  setbillyEntries((prevState) => {
                                    const updatedState = {
                                      ...prevState,
                                      Professional: {
                                        ...prevState.Professional,
                                        claimInformation: {
                                          ...prevState.Professional
                                            .claimInformation,
                                          claimSupplementalInformation: {
                                            ...prevState.Professional
                                              .claimInformation
                                              .claimSupplementalInformation,
                                            claimControlNumber: newValue,
                                          },
                                        },
                                      },
                                    };
                                    return updatedState;
                                  });
                                }}
                                value={
                                  billyEntries.Professional?.claimInformation
                                    ?.claimSupplementalInformation
                                    ?.claimControlNumber ?? ""
                                }
                                className="m-0"
                                size="small"
                                sx={{ background: "white" }}
                              />
                            </Grid>
                          )}
                        <Grid item xs={2} sm={2}></Grid>
                      </Grid>
                      {/* <Grid
                      sx={12}
                      sm={12}
                      className="my-4"
                      display={{ xs: "none", sm: "block" }}
                    >
                      <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                      <Grid xs={1} style={{ flexBasis: "40.2%" }}>
                          <div>
                            </div></Grid>
                        <Grid xs={4} style={{ flexBasis: "40.2%" }}>
                          <div>
                            <Typography
                              style={{
                                marginRight: "3px",
                                marginLeft: "2px",
                                overflowWrap: "break-word",
                                fontWeight: "bold",
                              }}
                              variant="body1"
                            >
                              Claim Action{" "}
                              <span className="lbl837Claim">string</span>
                              <span className="lbl837ClaimColor">required</span>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={3} style={{ flexBasis: "24%" }}>
                          <FormControl style={textFieldStylesddl}>
                            <InputLabel id="demo-simple-select-label">
                              Action
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              size="small"
                              value={ClaimAction}
                              onChange={(event, newValue) => {
                                setClaimAction(event.target.value);
                              }}
                            >
                              <MenuItem value={1}>
                                Original (Admit thru discharge claim)
                              </MenuItem>
                              <MenuItem value={7}>
                                Replacement (Replacement of prior claim)
                              </MenuItem>
                              <MenuItem value={8}>
                                Void (Void/cancel prior claim)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <div></div>
                        </Grid>
                        <Grid xs={4} style={{ flexBasis: "24%" }}>
                          <FormControl style={textFieldStylesddl}>
                            <InputLabel id="demo-simple-select-label">
                              ICN Number
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Age"
                              size="small"
                              value={ClaimAction}
                              onChange={(event, newValue) => {
                                setClaimAction(event.target.value);
                              }}
                            >
                              <MenuItem value={1}>
                                Original (Admit thru discharge claim)
                              </MenuItem>
                              <MenuItem value={7}>
                                Replacement (Replacement of prior claim)
                              </MenuItem>
                              <MenuItem value={8}>
                                Void (Void/cancel prior claim)
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <div></div>
                        </Grid>
                      </Grid>
                    </Grid> */}
                    </CardContent>
                  </Card>

                  <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                    <Grid xs={5}>
                      <div>
                        <Typography
                          style={{
                            marginRight: "3px",
                            marginLeft: "2px",
                            overflowWrap: "break-word",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          Control Number{" "}
                          <span className="lbl837Claim">string</span>
                          <span className="lbl837ClaimColor">required</span>
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          style={{ fontSize: "12px" }}
                          color="text.secondary"
                        >
                          Header, Segment: ST02 (no loop)
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={3}>
                      {" "}
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => handleTextInputChange(e, 1)}
                        value={billyEntries.ControlNumber}
                        size="small"
                      ></TextField>
                      <div></div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                    <Grid xs={5}>
                      <div>
                        <Typography
                          style={{
                            marginRight: "3px",
                            marginLeft: "2px",
                            overflowWrap: "break-word",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          Source Trading Partner ServiceId{" "}
                          <span className="lbl837Claim">string</span>
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          style={{ fontSize: "12px" }}
                          color="text.secondary"
                        >
                          Loop: 2010BB Segment: NM1, Element: NM109
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={3}>
                      {" "}
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        value={billyEntries.SourceTradingPartnerServiceId}
                        size="small"
                      ></TextField>
                      <div></div>
                    </Grid>
                  </Grid>
                  <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                    <Grid xs={5}>
                      <div>
                        <Typography
                          style={{
                            marginRight: "3px",
                            marginLeft: "2px",
                            overflowWrap: "break-word",
                            fontWeight: "bold",
                          }}
                          variant="body1"
                        >
                          Trading Partner ServiceId{" "}
                          <span className="lbl837Claim">string</span>
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5 }}
                          style={{ fontSize: "12px" }}
                          color="text.secondary"
                        >
                          Loop: 2010BB Segment: NM1, Element: NM109
                        </Typography>
                      </div>
                    </Grid>
                    <Grid xs={3}>
                      {" "}
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          billyEntries?.Professional?.tradingPartnerServiceId
                        }
                        size="small"
                      ></TextField>
                      <div></div>
                    </Grid>
                  </Grid>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 1}
                    onChange={() => {
                      if (accordionCurrent == 1) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(1);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Submitter ( Loop: 1000A){" "}
                        <span className="lbl837Claim">object</span>
                        <span className="lbl837ClaimColor">required</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 1 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 1000A, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              value={
                                billyEntries.Professional?.submitter
                                  ?.organizationName
                              }
                              size="small"
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="submitter.organizationName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "submitter",
                                  "organizationName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">lastName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 1000A, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              value={
                                billyEntries.Professional?.submitter?.lastName
                              }
                              size="small"
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="submitter.lastName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "submitter",
                                  "lastName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">firstName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 1000A, Segment: NM1, Element: NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              value={
                                billyEntries.Professional?.submitter?.firstName
                              }
                              size="small"
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="submitter.firstName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "submitter",
                                  "firstName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 1000A, Segment: NM1, Element: NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              value={
                                billyEntries.Professional?.submitter?.middleName
                              }
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              size="small"
                              name="submitter.middleName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "submitter",
                                  "middleName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">name</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  value={
                                    billyEntries.Professional?.submitter
                                      ?.contactInformation?.name
                                  }
                                  size="small"
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="submitter.contactInformation.name"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "submitter",
                                      "contactInformation.name",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  value={
                                    billyEntries.Professional?.submitter
                                      ?.contactInformation?.phoneNumber
                                  }
                                  size="small"
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="submitter.contactInformation.phoneNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "submitter",
                                      "contactInformation.phoneNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  value={
                                    billyEntries.Professional?.submitter
                                      ?.contactInformation?.faxNumber
                                  }
                                  size="small"
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="submitter.contactInformation.faxNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "submitter",
                                      "contactInformation.faxNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                {/* <TextField
                              value={
                                billyEntries.Professional?.submitter
                                  ?.contactInformation?.faxNumber
                              }
                              size="small"
                            ></TextField> */}
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">email</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  value={
                                    billyEntries.Professional?.submitter
                                      ?.contactInformation?.email
                                  }
                                  size="small"
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="submitter.contactInformation.email"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "submitter",
                                      "contactInformation.email",
                                      e.target.name
                                    )
                                  }
                                />
                                {/* <TextField
                              value={
                                billyEntries.Professional?.submitter
                                  ?.contactInformation?.email
                              }
                              size="small"
                            ></TextField> */}
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  value={
                                    billyEntries.Professional?.submitter
                                      ?.contactInformation?.phoneExtension
                                  }
                                  size="small"
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="submitter.contactInformation.phoneExtension"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "submitter",
                                      "contactInformation.phoneExtension",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 2}
                    onChange={() => {
                      if (accordionCurrent == 2) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(2);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Receiver ( Loop: 1000B){" "}
                        <span className="lbl837Claim">object</span>
                        <span className="lbl837ClaimColor">required</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 2 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 1000B, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.receiver
                                  ?.organizationName
                              }
                              size="small"
                              name="receiver.organizationName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "receiver",
                                  "organizationName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 3}
                    onChange={() => {
                      if (accordionCurrent == 3) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(3);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Subscriber ( Loop: 2000B){" "}
                        <span className="lbl837Claim">object</span>
                        <span className="lbl837ClaimColor">required</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 3 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">memberId</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM1, Element: NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            {/* <TextField
                          value={
                            billyEntries.Professional?.subscriber?.memberId
                          }
                          size="small"
                        ></TextField> */}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber?.memberId
                              }
                              size="small"
                              name="subscriber.memberId"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "memberId",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">ssn</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: REF, Element: REF02 when
                                REF01=SY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            {/* <TextField
                          value={billyEntries.Professional?.subscriber?.ssn}
                          size="small"
                        ></TextField> */}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={billyEntries.Professional?.subscriber?.ssn}
                              size="small"
                              name="subscriber.ssn"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "ssn",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                paymentResponsibilityLevelCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2000B, Segment: SBR, Element: SBR01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            <Select
                              className={PayerReadOnly == 1 ? "read-only" : ""}
                              xs={3}
                              labelId="demo-simple-select-label-paymentResponsibilityLevelCode"
                              id="demo-simple-select-random"
                              size="small"
                              inputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.paymentResponsibilityLevelCode || ""
                              }
                              label="paymentResponsibilityLevelCode"
                              tabIndex={6}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "paymentResponsibilityLevelCode",
                                  "paymentResponsibilityLevelCode"
                                )
                              }
                            >
                              <MenuItem value={"A"}>A</MenuItem>
                              <MenuItem value={"B"}>B</MenuItem>
                              <MenuItem value={"C"}>C</MenuItem>
                              <MenuItem value={"D"}>D</MenuItem>
                              <MenuItem value={"E"}>E</MenuItem>
                              <MenuItem value={"F"}>F</MenuItem>
                              <MenuItem value={"G"}>G</MenuItem>
                              <MenuItem value={"H"}>H</MenuItem>
                              <MenuItem value={"P"}>P</MenuItem>
                              <MenuItem value={"S"}>S</MenuItem>
                              <MenuItem value={"T"}>T</MenuItem>
                              <MenuItem value={"U"}>U</MenuItem>
                            </Select>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM1, Element: NM103 when
                                NM102=2, Notes: when subscriber is organization
                                pass patient as dependent
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.organizationName
                              }
                              size="small"
                              name="subscriber.organizationName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "organizationName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                insuranceTypeCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2000B, Segment: SBR, Element:SBR05
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.insuranceTypeCode
                              }
                              size="small"
                              name="subscriber.insuranceTypeCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "insuranceTypeCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                subscriberGroupName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2000B, Segment: SBR, Element: SBR04 Notes:
                                Freeform text
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.subscriberGroupName
                              }
                              size="small"
                              name="subscriber.subscriberGroupName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "subscriberGroupName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">firstName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM1, Element: NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber?.firstName
                              }
                              size="small"
                              name="subscriber.firstName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "firstName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">lastName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber?.lastName
                              }
                              size="small"
                              name="subscriber.lastName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "lastName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM, Element: NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.middleName
                              }
                              size="small"
                              name="subscriber.middleName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "middleName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">suffix</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: NM, Element: NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber?.suffix
                              }
                              size="small"
                              name="subscriber.suffix"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "suffix",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">gender</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: DMG, Element: DMG03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            <Select
                              className={PayerReadOnly == 1 ? "read-only" : ""}
                              xs={3}
                              labelId="demo-simple-select-label-gender"
                              id="demo-simple-select"
                              size="small"
                              value={
                                billyEntries.Professional?.subscriber?.gender ||
                                ""
                              }
                              label="Gender"
                              tabIndex={6}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "gender",
                                  "gender"
                                )
                              }
                            >
                              <MenuItem value={"M"}>M</MenuItem>
                              <MenuItem value={"F"}>F</MenuItem>
                              <MenuItem value={"U"}>U</MenuItem>
                            </Select>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                dateOfBirth
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: DMG, Element: DMG02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.dateOfBirth
                              }
                              size="small"
                              name="subscriber.dateOfBirth"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "dateOfBirth",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                policyNumber
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2000B, Segment: SBR, Element: SBR03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.policyNumber
                              }
                              size="small"
                              name="subscriber.policyNumber"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "policyNumber",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                groupNumber
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010BA, Segment: SBR, Element: SBR04
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.subscriber
                                  ?.groupNumber
                              }
                              size="small"
                              name="subscriber.groupNumber"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "subscriber",
                                  "groupNumber",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation ( PER )
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">name</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.contactInformation?.name
                                  }
                                  size="small"
                                  name="subscriber.contactInformation.name"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "contactInformation.name",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter), Note: Used when PER03=TE
                                    (Provider, Submitter, Subscriber, Dependent)
                                    or PER05=TE (Provider, Submitter) or
                                    PER07=TE (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.contactInformation?.phoneNumber
                                  }
                                  size="small"
                                  name="subscriber.contactInformation.phoneNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "contactInformation.phoneNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.contactInformation?.faxNumber
                                  }
                                  size="small"
                                  name="subscriber.contactInformation.faxNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "contactInformation.faxNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">email</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.contactInformation?.email
                                  }
                                  size="small"
                                  name="subscriber.contactInformation.email"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "contactInformation.email",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.contactInformation?.phoneExtension
                                  }
                                  size="small"
                                  name="subscriber.contactInformation.phoneExtension"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "contactInformation.phoneExtension",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 )
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.address1
                                  }
                                  size="small"
                                  name="subscriber.address.address1"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "subscriber",
                                      "address.address1",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.address2
                                  }
                                  size="small"
                                  name="subscriber.address.address2"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">city</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.city
                                  }
                                  size="small"
                                  name="subscriber.address.city"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">state</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.state
                                  }
                                  size="small"
                                  name="subscriber.address.state"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.postalCode
                                  }
                                  size="small"
                                  name="subscriber.address.postalCode"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.countryCode
                                  }
                                  size="small"
                                  name="subscriber.address.countryCode"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.subscriber
                                      ?.address?.countrySubDivisionCode
                                  }
                                  size="small"
                                  name="subscriber.address.countrySubDivisionCode"
                                  onChange={handleInputChange}
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 4}
                    onChange={() => {
                      if (accordionCurrent == 4) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(4);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Dependent ( Loop: 2000C){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 4 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">firstName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: NM1, Element: NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.firstName
                              }
                              size="small"
                              name="dependent.firstName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "firstName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">lastName</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.lastName
                              }
                              size="small"
                              name="dependent.lastName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "lastName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: NM, Element: NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.middleName
                              }
                              size="small"
                              name="dependent.middleName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "middleName",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">suffix</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: NM, Element: NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.suffix
                              }
                              size="small"
                              name="dependent.suffix"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "suffix",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">gender</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: DMG, Element: DMG03,
                                Note: Allowed Values are: 'M' Male, 'F' Female,
                                'U' Unknown
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.gender
                              }
                              size="small"
                              name="dependent.gender"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "gender",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                dateOfBirth
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: DMG, Element: DMG02 when
                                DMG01=D8
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent
                                  ?.dateOfBirth
                              }
                              size="small"
                              name="dependent.dateOfBirth"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "dateOfBirth",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">ssn</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: REF, Element: REF02 when
                                REF01=SY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={billyEntries.Professional?.dependent?.ssn}
                              size="small"
                              name="dependent.ssn"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "ssn",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">memberId</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010CA, Segment: REF, Element: REF02 when
                                REF01=1W
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent?.memberId
                              }
                              size="small"
                              name="dependent.memberId"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "memberId",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                relationshipToSubscriberCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2000C, Segment: PAT, Element: PAT01, Note:
                                Allowed Values are: '01' Spouse, '19' Child,
                                '20' Employee, '21' Unknown, '39' Organ Donor,
                                '40' Cadaver Donor, '53' Life Partner, 'G8'
                                Other Relationship
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.dependent
                                  ?.relationshipToSubscriberCode
                              }
                              size="small"
                              name="dependent.relationshipToSubscriberCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "dependent",
                                  "relationshipToSubscriberCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation ( PER )
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">name</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.contactInformation?.name
                                  }
                                  size="small"
                                  name="dependent.contactInformation.name"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "contactInformation.name",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter), Note: Used when PER03=TE
                                    (Provider, Submitter, Subscriber, Dependent)
                                    or PER05=TE (Provider, Submitter) or
                                    PER07=TE (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.contactInformation?.phoneNumber
                                  }
                                  size="small"
                                  name="dependent.contactInformation.phoneNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "contactInformation.phoneNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.contactInformation?.faxNumber
                                  }
                                  size="small"
                                  name="dependent.contactInformation.faxNumber"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "contactInformation.faxNumber",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">email</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.contactInformation?.email
                                  }
                                  size="small"
                                  name="dependent.contactInformation.email"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "contactInformation.email",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.contactInformation?.phoneExtension
                                  }
                                  size="small"
                                  name="dependent.contactInformation.phoneExtension"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "contactInformation.phoneExtension",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 )
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.address1
                                  }
                                  size="small"
                                  name="dependent.address.address1"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.address1",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.address2
                                  }
                                  size="small"
                                  name="dependent.address.address2"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.address2",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">city</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.city
                                  }
                                  size="small"
                                  name="dependent.address.city"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.city",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">state</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.state
                                  }
                                  size="small"
                                  name="dependent.address.state"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.state",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.postalCode
                                  }
                                  size="small"
                                  name="dependent.address.postalCode"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.postalCode",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.countryCode
                                  }
                                  size="small"
                                  name="dependent.address.countryCode"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.countryCode",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  value={
                                    billyEntries.Professional?.dependent
                                      ?.address?.countrySubDivisionCode
                                  }
                                  size="small"
                                  name="dependent.address.countrySubDivisionCode"
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      "dependent",
                                      "address.countrySubDivisionCode",
                                      e.target.name
                                    )
                                  }
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 5}
                    onChange={() => {
                      if (accordionCurrent == 5) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(5);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Claim Information ( Loop: 2300){" "}
                        <span className="lbl837Claim">object</span>
                        <span className="lbl837ClaimColor">required</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 5 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimFilingCode{" "}
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2000B, Segment: SBR, Element: SBR09
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <FormControl style={textFieldStylesddl}>
                              <InputLabel id="demo-simple-select-label">
                                Action
                              </InputLabel>
                              <Select
                                className={
                                  PayerReadOnly == 1 ? "read-only" : ""
                                }
                                labelId="demo-claimFilingCode-select-label"
                                id="demo-claimFilingCode-select"
                                label="claimFilingCode"
                                size="small"
                                name="claimInformation.claimFilingCode"
                                inputProps={{
                                  readOnly: PayerReadOnly === 1,
                                }}
                                value={
                                  billyEntries.Professional?.claimInformation
                                    ?.claimFilingCode
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    "claimInformation",
                                    "claimFilingCode",
                                    e.target.name
                                  )
                                }
                              >
                                <MenuItem value={11}>
                                  11-Other Non-Federal Programs
                                </MenuItem>
                                <MenuItem value={12}>
                                  12-Preferred Provider Organization (PPO)
                                </MenuItem>
                                <MenuItem value={13}>
                                  13-Point of Service (POS)
                                </MenuItem>
                                <MenuItem value={14}>
                                  14-Exclusive Provider Organization (EPO)
                                </MenuItem>
                                <MenuItem value={15}>
                                  15-Indemnity Insurance
                                </MenuItem>

                                <MenuItem value={16}>
                                  16-Health Maintenance Organization (HMO)
                                  Medicare Risk
                                </MenuItem>

                                <MenuItem value={17}>
                                  17-Dental Maintenance Organization, 'AM'
                                  Automobile Medical
                                </MenuItem>

                                <MenuItem value={"BL"}>
                                  BL-Blue Cross/Blue Shield
                                </MenuItem>

                                <MenuItem value={"CH"}>CH-Champus</MenuItem>

                                <MenuItem value={"CI"}>
                                  CI-Commercial Insurance Co.
                                </MenuItem>

                                <MenuItem value={"DS"}>DS-Disability</MenuItem>

                                <MenuItem value={"FI"}>
                                  FI-Federal Employees Program
                                </MenuItem>
                                <MenuItem value={"HM"}>
                                  HM-Health Maintenance Organization
                                </MenuItem>
                                <MenuItem value={"LM"}>
                                  LM-Liability Medical
                                </MenuItem>
                                <MenuItem value={"MA"}>
                                  MA-Medicare Part A
                                </MenuItem>
                                <MenuItem value={"MB"}>
                                  MB-Medicare Part B
                                </MenuItem>
                                <MenuItem value={"MC"}>MC-Medicaid</MenuItem>
                                <MenuItem value={"OF"}>
                                  OF-Other Federal Program
                                </MenuItem>
                                <MenuItem value={"TV"}>TV-Title V</MenuItem>
                                <MenuItem value={"VA"}>
                                  VA-Veterans Affairs Plan
                                </MenuItem>
                                <MenuItem value={"WC"}>
                                  WC-Workers Compensation Health Claim
                                </MenuItem>
                                <MenuItem value={"ZZ"}>
                                  ZZ-Mutually Defined
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                propertyCasualtyClaimNumber
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2010BA, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.propertyCasualtyClaimNumber
                              }
                              size="small"
                              name="claimInformation.propertyCasualtyClaimNumber"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "propertyCasualtyClaimNumber",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">deathDate</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2000B and 2000C, Segment: PAT, Element:
                                PAT06 and PAT05=D8
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.deathDate
                              }
                              size="small"
                              name="claimInformation.deathDate"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "deathDate",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                patientWeight
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2000B and 2000C, Segment: PAT, Element:
                                PAT08 and PAT07=01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.patientWeight
                              }
                              size="small"
                              name="claimInformation.patientWeight"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "patientWeight",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                pregnancyIndicator
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.pregnancyIndicator
                              }
                              size="small"
                              name="claimInformation.pregnancyIndicator"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "pregnancyIndicator",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                patientControlNumber
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.patientControlNumber
                              }
                              size="small"
                              name="claimInformation.patientControlNumber"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "patientControlNumber",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimChargeAmount
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.claimChargeAmount
                              }
                              size="small"
                              name="claimInformation.claimChargeAmount"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "claimChargeAmount",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                placeOfServiceCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM05-01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.placeOfServiceCode
                              }
                              size="small"
                              name="claimInformation.placeOfServiceCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "placeOfServiceCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimFrequencyCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM05-03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.claimFrequencyCode
                              }
                              size="small"
                              name="claimInformation.claimFrequencyCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "claimFrequencyCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                signatureIndicator
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM06, Note:
                                Allowed Values are: 'N' NO, 'Y' Ye
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.signatureIndicator
                              }
                              size="small"
                              name="claimInformation.signatureIndicator"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "signatureIndicator",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                planParticipationCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM07, Note:
                                Allowed Values are: 'A' Assigned, 'B' Assignment
                                Accepted on Clinical Lab Services Only, 'C' Not
                                Assigned
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.planParticipationCode
                              }
                              size="small"
                              name="claimInformation.planParticipationCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "planParticipationCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                benefitsAssignmentCertificationIndicator
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM08, Note:
                                Allowed Values are: 'N' No, 'W' Not Applicable -
                                Use code 'W' when the patient refuses to assign
                                benefits, 'Y' Yes
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.benefitsAssignmentCertificationIndicator
                              }
                              size="small"
                              name="claimInformation.benefitsAssignmentCertificationIndicator"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "benefitsAssignmentCertificationIndicator",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                releaseInformationCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM09, Note:
                                Allowed Values are: 'I' Informed Consent to
                                Release Medical Information for Conditions or
                                Diagnoses Regulated by Federal Statutes, 'Y' Yes
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.releaseInformationCode
                              }
                              size="small"
                              name="claimInformation.releaseInformationCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "releaseInformationCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                patientSignatureSourceCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM10, Note:
                                Allowed Values are: 'P' Signature generated by
                                provider because the patient was not physically
                                present for services
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.patientSignatureSourceCode
                              }
                              size="small"
                              name="claimInformation.patientSignatureSourceCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "patientSignatureSourceCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                relatedCausesCode
                                <span className="lbl837Claim">string</span>
                                <span className="lbl837ClaimColor">
                                  required
                                </span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM11-01,
                                CLM11-02, Note: Allowed Values are: 'AA' Auto
                                Accident, 'EM' Employment, 'OA' Other Accident
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.relatedCausesCode
                              }
                              size="small"
                              name="claimInformation.relatedCausesCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "relatedCausesCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                autoAccidentStateCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM11-04,
                                Note: When CLM11-1 or CLM11-2 has a value of
                                'AA' to identify the state, province or
                                sub-country code in which the automobile
                                accident occurred.
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.autoAccidentStateCode
                              }
                              size="small"
                              name="claimInformation.autoAccidentStateCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "autoAccidentStateCode",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                autoAccidentCountryCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM11-05,
                                Note: When CLM11-1 or CLM11-2 = AA and the
                                accident occurred in a country other than US or
                                Canada.
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.autoAccidentCountryCode
                              }
                              size="small"
                              name="claimInformation.autoAccidentCountryCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "autoAccidentCountryCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                specialProgramCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM12, Note:
                                Allowed Values are: '02' Physically Handicapped
                                Children's Program, '03' Special Federal
                                Funding, '05' Disabolity, '09' Second Opinion or
                                Surgery
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.specialProgramCode
                              }
                              size="small"
                              name="claimInformation.specialProgramCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "specialProgramCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                delayReasonCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CLM, Element: CLM2
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.delayReasonCode
                              }
                              size="small"
                              name="claimInformation.delayReasonCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "delayReasonCode",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                patientAmountPaid
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: AMT, Element: AMT02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.patientAmountPaid
                              }
                              size="small"
                              name="claimInformation.patientAmountPaid"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "patientAmountPaid",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                fileInformation
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: K3, Element: K301
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={JSON.stringify(
                                billyEntries.Professional?.claimInformation
                                  ?.fileInformation
                              )}
                              size="small"
                              name="claimInformation.fileInformation"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "fileInformation",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                fileInformationList
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: K3, Element: K301
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={JSON.stringify(
                                billyEntries.Professional?.claimInformation
                                  ?.fileInformationList
                              )}
                              size="small"
                              name="claimInformation.fileInformationList"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "fileInformationList",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                homeboundIndicator
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop 2300, Segment: CRC
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.claimInformation
                                  ?.homeboundIndicator
                              }
                              size="small"
                              name="claimInformation.homeboundIndicator"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "claimInformation",
                                  "homeboundIndicator",
                                  e.target.name
                                )
                              }
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 1}
                          onChange={() => {
                            if (accordionClaimInformation == 1) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(1);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Claim Date Information ( DTP )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 1 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      symptomDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.symptomDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.symptomDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "symptomDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      initialTreatmentDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.initialTreatmentDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.initialTreatmentDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "claimDateInformation.initialTreatmentDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      lastSeenDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.lastSeenDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.lastSeenDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "lastSeenDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      acuteManifestationDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.acuteManifestationDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.acuteManifestationDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "acuteManifestationDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      accidentDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.accidentDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.accidentDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "accidentDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      lastMenstrualPeriodDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.lastMenstrualPeriodDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.lastMenstrualPeriodDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "lastMenstrualPeriodDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      lastXRayDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.lastXRayDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.lastXRayDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "lastXRayDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      hearingAndVisionPrescriptionDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.hearingAndVisionPrescriptionDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.hearingAndVisionPrescriptionDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "hearingAndVisionPrescriptionDate",
                                        e.target.name
                                      )
                                    }
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      disabilityBeginDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.disabilityBeginDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.disabilityBeginDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "disabilityBeginDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      disabilityEndDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.disabilityEndDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.disabilityEndDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "disabilityEndDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      lastWorkedDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.lastWorkedDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.lastWorkedDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "lastWorkedDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      authorizedReturnToWorkDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.authorizedReturnToWorkDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.authorizedReturnToWorkDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "authorizedReturnToWorkDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      admissionDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.admissionDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.admissionDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "admissionDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      dischargeDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.dischargeDate
                                    }
                                    size="small"
                                    name="claimInformation.claimDateInformation.dischargeDate"
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        "claimInformation",
                                        "dischargeDate",
                                        e.target.name
                                      )
                                    }
                                  />
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      assumedAndRelinquishedCareBeginDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.assumedAndRelinquishedCareBeginDate
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      assumedAndRelinquishedCareEndDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.assumedAndRelinquishedCareEndDate
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      repricerReceivedDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.repricerReceivedDate
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      firstContactDate
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: DTP, Element: DTP03
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimDateInformation
                                        ?.firstContactDate
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 2}
                          onChange={() => {
                            if (accordionClaimInformation == 2) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(2);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Claim Contract Information ( PER )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 2 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      contractTypeCode
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300,Segment: CN1, Element: CN101,
                                      Note: Allowed Values are: '01' Diagnosis
                                      Related Group (DRG), '02' Per Diem, '03'
                                      Variable Per Diem, '04' Flat, '05'
                                      Capitated, '06' Percent, '09' Other
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation
                                        ?.contractTypeCode
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      contractAmount
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CN1, Element: CN102
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation
                                        ?.contractAmount
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      contractPercentage
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CN1, Element: CN103
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation
                                        ?.contractPercentage
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      contractCode
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CN1, Element: CN104
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation?.contractCode
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      termsDiscountPercentage
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CN1, Element: CN105
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation
                                        ?.termsDiscountPercentage
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      contractVersionIdentifier
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CN1, Element: CN106
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimContractInformation
                                        ?.contractVersionIdentifier
                                    }
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 3}
                          onChange={() => {
                            if (accordionClaimInformation == 3) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(3);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Claim Supplemental Information (PWK and REF )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 3 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      priorAuthorizationNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=G1
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    name="Professional.claimInformation.claimSupplementalInformation.priorAuthorizationNumber"
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.priorAuthorizationNumber
                                    }
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      referralNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=9F
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.referralNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.referralNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      claimControlNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=F8
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.claimControlNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.claimControlNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      cliaNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=X4
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.cliaNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.cliaNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      repricedClaimNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=9A
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.repricedClaimNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.repricedClaimNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      adjustedRepricedClaimNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=9C
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.adjustedRepricedClaimNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.adjustedRepricedClaimNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      investigationalDeviceExemptionNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=LX
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.investigationalDeviceExemptionNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.investigationalDeviceExemptionNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      claimNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=D9
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.claimNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.claimNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      mammographyCertificationNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=EW
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.mammographyCertificationNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.mammographyCertificationNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      medicalRecordNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=EA
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.medicalRecordNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.medicalRecordNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      demoProjectIdentifier
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=P4
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.demoProjectIdentifier
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.demoProjectIdentifier"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      carePlanOversightNumber
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=1J
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.carePlanOversightNumber
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.carePlanOversightNumber"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      medicareCrossoverReferenceId
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=LX
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.medicareCrossoverReferenceId
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.medicareCrossoverReferenceId"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      serviceAuthorizationExceptionCode
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: REF, Element: REF02
                                      and REF01=4N
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.claimSupplementalInformation
                                        ?.serviceAuthorizationExceptionCode
                                    }
                                    name="Professional.claimInformation.claimSupplementalInformation.serviceAuthorizationExceptionCode"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 4}
                          onChange={() => {
                            if (accordionClaimInformation == 4) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(4);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Claim Note ( NTE )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 4 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      additionalInformation
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop 2300, Segment: NTE, Element: NTE02,
                                      Note: NTE01=ADD
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimNote
                                        ?.additionalInformation
                                    }
                                    name="Professional.claimInformation.claimNote.additionalInformation"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      certificationNarrative
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop 2300, Segment: NTE, Element: NTE02,
                                      Note: NTE01=CER
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimNote
                                        ?.certificationNarrative
                                    }
                                    name="Professional.claimInformation.claimNote.certificationNarrative"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      goalRehabOrDischargePlans
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop 2300, Segment: NTE, Element: NTE02,
                                      Note: NTE01=DCP
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimNote
                                        ?.goalRehabOrDischargePlans
                                    }
                                    name="Professional.claimInformation.claimNote.goalRehabOrDischargePlans"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      diagnosisDescription
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop 2300, Segment: NTE, Element: NTE02,
                                      Note: NTE01=DGN
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimNote
                                        ?.diagnosisDescription
                                    }
                                    name="Professional.claimInformation.claimNote.diagnosisDescription"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      thirdPartOrgNotes
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop 2300, Segment: NTE, Element: NTE02,
                                      Note: NTE01=TPO
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.claimNote
                                        ?.thirdPartOrgNotes
                                    }
                                    name="Professional.claimInformation.claimNote.thirdPartOrgNotes"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 5}
                          onChange={() => {
                            if (accordionClaimInformation == 5) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(5);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Ambulance Transport Information ( CR1 )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 5 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      patientWeightInPounds
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Segment: CR1, Element: CR102
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      ambulanceTransportReasonCode
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Segment: CR1,Element: CR104
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.ambulanceTransportInformation
                                        ?.ambulanceTransportReasonCode
                                    }
                                    name="Professional.claimInformation.ambulanceTransportInformation.ambulanceTransportReasonCode"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      transportDistanceInMiles
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Segment: CR1, Element: CR106
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.ambulanceTransportInformation
                                        ?.transportDistanceInMiles
                                    }
                                    name="Professional.claimInformation.ambulanceTransportInformation.transportDistanceInMiles"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      roundTripPurposeDescription
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Segment: CR1, Element: CR109
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.ambulanceTransportInformation
                                        ?.roundTripPurposeDescription
                                    }
                                    name="Professional.claimInformation.ambulanceTransportInformation.roundTripPurposeDescription"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      stretcherPurposeDescription
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Segment: CR1, Element: CR110
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.ambulanceTransportInformation
                                        ?.stretcherPurposeDescription
                                    }
                                    name="Professional.claimInformation.ambulanceTransportInformation.stretcherPurposeDescription"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 6}
                          onChange={() => {
                            if (accordionClaimInformation == 6) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(6);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Spinal Manipulation Service Information ( CR2 )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 6 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      patientConditionCode
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CR, Element: CR208
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.spinalManipulationServiceInformation
                                        ?.patientConditionCode
                                    }
                                    name="Professional.claimInformation.spinalManipulationServiceInformation.patientConditionCode"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      patientConditionDescription1
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CR, Element: CR210
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.spinalManipulationServiceInformation
                                        ?.patientConditionDescription1
                                    }
                                    name="Professional.claimInformation.spinalManipulationServiceInformation.patientConditionDescription1"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      patientConditionDescription2
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CR, Element: CR211
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation
                                        ?.spinalManipulationServiceInformation
                                        ?.patientConditionDescription2
                                    }
                                    name="Professional.claimInformation.spinalManipulationServiceInformation.patientConditionDescription2"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 7}
                          onChange={() => {
                            if (accordionClaimInformation == 7) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(7);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Ambulance Certification ( Loop 2300, Segment: CRC
                              )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 7 ? (
                            <AccordionDetails>
                              {billyEntries.Professional?.claimInformation?.ambulanceCertification?.map(
                                (k, index) => {
                                  return (
                                    <Grid
                                      style={{
                                        background: "rgba(25, 118, 210, 0.08)",
                                        paddingLeft: "5px",
                                        marginTop: "10px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              certificationConditionIndicator
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2300, Segment: CRC, Element:
                                              CRC02
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            value={
                                              k?.certificationConditionIndicator
                                            }
                                            name="Professional.claimInformation.ambulanceCertification.certificationConditionIndicator"
                                            onChange={handleChangeRendering}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              conditionCodes
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2300, Segment: CRC, Element:
                                              CRC03, CRC04, CRC05, CRC06, CRC07,
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            value={k?.conditionCodes}
                                            name="Professional.claimInformation.ambulanceCertification.conditionCodes"
                                            onChange={handleChangeRendering}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 8}
                          onChange={() => {
                            if (accordionClaimInformation == 8) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(8);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Patient Condition Information Vision (Loop 2300,
                              Segment: CRC )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 8 ? (
                            <AccordionDetails>
                              {billyEntries.Professional?.claimInformation?.patientConditionInformationVision?.map(
                                (k, index) => {
                                  return (
                                    <Grid
                                      style={{
                                        background: "rgba(25, 118, 210, 0.08)",
                                        paddingLeft: "5px",
                                        marginTop: "10px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              codeCategory
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2300, Segment: CRC, Element:
                                              CRC01
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            value={k?.codeCategory}
                                            name="Professional.claimInformation.patientConditionInformationVision.codeCategory"
                                            onChange={handleChangeRendering}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              certificationConditionIndicator
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2300, Segment: CRC, Element:
                                              CRC02
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            value={
                                              k?.certificationConditionIndicator
                                            }
                                            name="Professional.claimInformation.patientConditionInformationVision.certificationConditionIndicator"
                                            onChange={handleChangeRendering}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>{" "}
                                    </Grid>
                                  );
                                }
                              )}
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 9}
                          onChange={() => {
                            if (accordionClaimInformation == 9) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(9);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Epsdt Referral (CRC )
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 9 ? (
                            <AccordionDetails>
                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      certificationConditionCodeAppliesIndicator
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CRC, Element: CRC02
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.epsdtReferral
                                        ?.certificationConditionCodeAppliesIndicator
                                    }
                                    name="Professional.claimInformation.epsdtReferral.certificationConditionCodeAppliesIndicator"
                                    onChange={handleChangeRendering}
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>

                              <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                                <Grid xs={5}>
                                  <div>
                                    <Typography variant="body1">
                                      conditionCodes
                                    </Typography>
                                    <Typography
                                      sx={{ mb: 1.5 }}
                                      style={{ fontSize: "12px" }}
                                      color="text.secondary"
                                    >
                                      Loop: 2300, Segment: CRC, Elements: CRC03,
                                      CRC04, CRC05
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid xs={3}>
                                  {" "}
                                  <TextField
                                    InputProps={{
                                      readOnly: PayerReadOnly === 1,
                                    }}
                                    value={
                                      billyEntries.Professional
                                        ?.claimInformation?.epsdtReferral
                                        ?.conditionCodes
                                    }
                                    name="Professional.claimInformation.epsdtReferral.conditionCodes"
                                    size="small"
                                  ></TextField>
                                  <div></div>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 10}
                          onChange={() => {
                            if (accordionClaimInformation == 10) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(10);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              HealthCare Code Information (Loop 2300, Segment:
                              HI ) <span className="lbl837Claim">object</span>
                              <span className="lbl837ClaimColor">required</span>
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 10 ? (
                            <AccordionDetails>
                              <Grid container md={12}>
                                <Grid xs={11}></Grid>
                                <Grid xs={1}>
                                  &nbsp;
                                  {PayerReadOnly != 1 && (
                                    <Button
                                      text="Add"
                                      // navigate="/capture/capture-driving-license/patient-info"
                                      classname={"button-styles btnHeight"}
                                      filled={true}
                                      onclick={addHealthCareCode}
                                    />
                                  )}{" "}
                                  &nbsp;&nbsp;
                                </Grid>
                              </Grid>
                              {billyEntries.Professional?.claimInformation?.healthCareCodeInformation?.map(
                                (k, index) => {
                                  return (
                                    <Grid
                                      style={{
                                        background: "rgba(25, 118, 210, 0.08)",
                                        paddingLeft: "5px",
                                        marginTop: "10px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <Grid container md={12}>
                                        <Grid xs={11}></Grid>
                                        <Grid xs={1}>
                                          &nbsp;
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Link: {index + 1}
                                          </span>{" "}
                                          {PayerReadOnly != 1 && (
                                            <DeleteIcon
                                              onClick={() =>
                                                deleteByIndex(index)
                                              }
                                              sx={{
                                                float: "right",
                                                color: red[500],
                                              }}
                                            ></DeleteIcon>
                                          )}
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              diagnosisTypeCode
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2440, Segment: HI, Element:
                                              HI01-01 or HI02-01 or HI03-01 or
                                              HI04-01 or HI05-01 or HI06-01 or
                                              HI07-01 or HI08-01 or HI09-01 or
                                              HI10-01 or HI11-01 or HI12-01
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            style={{ background: "#fff" }}
                                            onChange={(e) =>
                                              handleInputTypeCodeChange(
                                                e,
                                                index
                                              )
                                            }
                                            // onChange={(e, index) =>
                                            //   addTodo(e, index)
                                            // }
                                            // handleInputChange(e, index)}
                                            value={k?.diagnosisTypeCode}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        container
                                        md={12}
                                        sx={{ pl: 2, pt: 1 }}
                                      >
                                        <Grid xs={5}>
                                          <div>
                                            <Typography variant="body1">
                                              diagnosisCode
                                            </Typography>
                                            <Typography
                                              sx={{ mb: 1.5 }}
                                              style={{ fontSize: "12px" }}
                                              color="text.secondary"
                                            >
                                              Loop: 2440, Segment: HI, Element:
                                              HI01-02 or HI02-02 or HI03-02 or
                                              HI04-02 or HI05-02 or HI06-02 or
                                              HI07-02 or HI08-02 or HI09-02 or
                                              HI10-02 or HI11-02 or HI12-02
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid xs={3}>
                                          {" "}
                                          <TextField
                                            InputProps={{
                                              readOnly: PayerReadOnly === 1,
                                            }}
                                            style={{ background: "#fff" }}
                                            onChange={(e) =>
                                              handleInputCodeChange(e, index)
                                            }
                                            value={k.diagnosisCode}
                                            size="small"
                                          ></TextField>
                                          <div></div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 11}
                          onChange={() => {
                            if (accordionClaimInformation == 11) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(11);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              anesthesiaRelatedSurgicalProcedure (Loop 2300,
                              Segment: HI ){" "}
                              <span className="lbl837Claim">
                                array of strings
                              </span>
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 11 ? (
                            <AccordionDetails>
                              <Grid container md={12}>
                                <Grid xs={11}></Grid>
                                <Grid xs={1}>
                                  &nbsp;
                                  {PayerReadOnly != 1 && (
                                    <Button
                                      text="Add"
                                      // navigate="/capture/capture-driving-license/patient-info"
                                      classname={"button-styles btnHeight"}
                                      filled={true}
                                      onclick={addHealthCareCode}
                                    />
                                  )}{" "}
                                  &nbsp;&nbsp;
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          expanded={accordionClaimInformation == 12}
                          onChange={() => {
                            if (accordionClaimInformation == 12) {
                              setAccordionClaimInformation(0);
                            } else {
                              setAccordionClaimInformation(12);
                            }
                          }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              Service Lines (Loop 2400 ){" "}
                              <span className="lbl837Claim">object</span>
                              <span className="lbl837ClaimColor">required</span>
                            </Typography>
                          </AccordionSummary>
                          {accordionClaimInformation == 12 ? (
                            <AccordionDetails>
                              <Grid container md={12}>
                                <Grid xs={11}></Grid>
                                <Grid xs={1}>
                                  &nbsp;
                                  {PayerReadOnly != 1 && (
                                    <Button
                                      text="Add"
                                      // navigate="/capture/capture-driving-license/patient-info"
                                      classname={"button-styles btnHeight"}
                                      filled={true}
                                      onclick={procedureAdd}
                                    />
                                  )}{" "}
                                  &nbsp;&nbsp;
                                </Grid>
                              </Grid>
                              {billyEntries.Professional?.claimInformation?.serviceLines?.map(
                                (row, index) => (
                                  <Accordion
                                    slotProps={{
                                      transition: { unmountOnExit: true },
                                    }}
                                    expanded={
                                      accordionClaimInformation111 ==
                                      `${index + 55}`
                                    }
                                    onChange={() => {
                                      if (
                                        accordionClaimInformation111 ==
                                        `${index + 55}`
                                      ) {
                                        setAccordionClaimInformation111(0);
                                      } else {
                                        setAccordionClaimInformation111(
                                          `${index + 55}`
                                        );
                                      }
                                    }}
                                    sm={12}
                                    md={12}
                                    // expanded={expanded === `${i.coverageCode}`}
                                    // onChange={handleChange(`${i.coverage}`)}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                      style={{ background: "#dfe7f6" }}
                                    >
                                      <Typography
                                        component="div"
                                        gutterBottom
                                        sx={{
                                          width: "73%",
                                          flexShrink: 0,
                                          fontWeight: "bold",
                                        }}
                                        variant="body2"
                                      >
                                        Procedure Info :{" "}
                                        {row.professionalService.procedureCode}{" "}
                                        | Modifiers :
                                        {BindDisplayModifiers(
                                          row.professionalService
                                            ?.procedureModifiers
                                        )}
                                        | Service From : {row.serviceDate} -
                                        Service To : {row.serviceDateEnd} |{" "}
                                        {PayerReadOnly != 1 && (
                                          <DeleteIcon
                                            sx={{
                                              float: "right",
                                              color: red[500],
                                            }}
                                            color="red"
                                            onClick={() => deleteProc(index)}
                                          ></DeleteIcon>
                                        )}
                                      </Typography>
                                    </AccordionSummary>
                                    {accordionClaimInformation111 ==
                                    `${index + 55}` ? (
                                      <AccordionDetails>
                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                assignedNumber
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: LX,
                                                Element: LX01
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={row.assignedNumber}
                                              size="small"
                                              onChange={(e) =>
                                                handleInputChange111(
                                                  e,
                                                  "claimInformation",
                                                  "serviceLines",
                                                  "assignedNumber",
                                                  index
                                                )
                                              }
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                serviceDate
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: DTP,
                                                Element: DTP03,
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={row.serviceDate}
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                serviceDateEnd
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: DTP,
                                                Element: DTP03
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={row.serviceDateEnd}
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                providerControlNumber
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: REF,
                                                Element: REF04-02 when REF01=6R
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={row.providerControlNumber}
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                hospiceEmployeeIndicator
                                                <span className="lbl837Claim">
                                                  boolean
                                                </span>
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: REF,
                                                Element: REF04-02 when REF01=6R
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={
                                                row.hospiceEmployeeIndicator
                                              }
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                ambulancePatientCount
                                                <span className="lbl837Claim">
                                                  int32
                                                </span>
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: QTY,
                                                Element: QTY02 when QTY01=PT
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={row.ambulancePatientCount}
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          container
                                          md={12}
                                          sx={{ pl: 2, pt: 1 }}
                                        >
                                          <Grid xs={5}>
                                            <div>
                                              <Typography variant="body1">
                                                obstetricAnesthesiaAdditionalUnits
                                                <span className="lbl837Claim">
                                                  int32
                                                </span>
                                              </Typography>
                                              <Typography
                                                sx={{ mb: 1.5 }}
                                                style={{ fontSize: "12px" }}
                                                color="text.secondary"
                                              >
                                                Loop: 2400, Segment: QTY,
                                                Element: QTY02 when QTY01=FL
                                              </Typography>
                                            </div>
                                          </Grid>
                                          <Grid xs={3}>
                                            {" "}
                                            <TextField
                                              InputProps={{
                                                readOnly: PayerReadOnly === 1,
                                              }}
                                              value={
                                                row.obstetricAnesthesiaAdditionalUnits
                                              }
                                              size="small"
                                            ></TextField>
                                            <div></div>
                                          </Grid>
                                        </Grid>
                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          sm={12}
                                          md={12}
                                          expanded={
                                            accordionClaimInformation1111 == 66
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              66
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                66
                                              );
                                            }
                                          }}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              Professional Service{" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ===
                                          66 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      procedureIdentifier
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2400, Segment: SV1,
                                                      Element: SV101-01
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .procedureIdentifier
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf1(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "procedureIdentifier",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      procedureCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV101-02
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .procedureCode
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf2(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "procedureCode",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      procedureModifiers
                                                      <span className="lbl837Claim">
                                                        array of strings
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Elements: SV101-03 to
                                                      SV101-06
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  <Stack
                                                    direction="row"
                                                    spacing={2}
                                                  >
                                                    {" "}
                                                    <TextField
                                                      InputProps={{
                                                        readOnly:
                                                          PayerReadOnly === 1,
                                                      }}
                                                      value={
                                                        procedureModifiersvalue
                                                      }
                                                      onChange={(e) => {
                                                        setprocedureModifiersvalue(
                                                          e.target.value
                                                        );
                                                      }}
                                                      size="small"
                                                    ></TextField>
                                                    {PayerReadOnly != 1 && (
                                                      <Button
                                                        text="Add"
                                                        onclick={() => {
                                                          if (
                                                            procedureModifiersvalue ==
                                                              "" ||
                                                            procedureModifiersvalue ==
                                                              null
                                                          ) {
                                                            return;
                                                          }
                                                          var dum = [
                                                            ...row
                                                              .professionalService
                                                              .procedureModifiers,
                                                          ];
                                                          dum.push(
                                                            procedureModifiersvalue
                                                          );
                                                          billyEntries.Professional.claimInformation.serviceLines[
                                                            index
                                                          ].professionalService.procedureModifiers =
                                                            dum;
                                                          // // stateSet.Professional?.claimInformation?.serviceLines[index].professionalService.procedureModifiers = dum;
                                                          setbillyEntries(
                                                            billyEntries
                                                          );
                                                          if (
                                                            !isAnythingChanged &&
                                                            setIsAnythingChanged
                                                          ) {
                                                            setIsAnythingChanged(
                                                              true
                                                            );
                                                          }
                                                          setemailTestData(
                                                            !emailTestData
                                                          );
                                                          setprocedureModifiersvalue(
                                                            ""
                                                          );
                                                        }}
                                                        classname={
                                                          "button-styles btnHeight"
                                                        }
                                                        filled={true}
                                                      />
                                                    )}
                                                    <div></div>
                                                  </Stack>
                                                  <ul>
                                                    {row.professionalService.procedureModifiers?.map(
                                                      (
                                                        item,
                                                        deleteModiIndex
                                                      ) => (
                                                        <li
                                                          style={{
                                                            background:
                                                              "#FFFFE0",
                                                            marginTop: "10px",
                                                            width: "100px",
                                                          }}
                                                        >
                                                          <span>{item}</span>
                                                          {PayerReadOnly !=
                                                            1 && (
                                                            <DeleteIcon
                                                              onClick={() => {
                                                                billyEntries.Professional.claimInformation.serviceLines[
                                                                  index
                                                                ].professionalService.procedureModifiers.splice(
                                                                  deleteModiIndex,
                                                                  1
                                                                );
                                                                setbillyEntries(
                                                                  billyEntries
                                                                );
                                                                if (
                                                                  !isAnythingChanged &&
                                                                  setIsAnythingChanged
                                                                ) {
                                                                  setIsAnythingChanged(
                                                                    true
                                                                  );
                                                                }
                                                                setemailTestData(
                                                                  !emailTestData
                                                                );
                                                              }}
                                                              sx={{
                                                                float: "right",
                                                                color: red[500],
                                                              }}
                                                            ></DeleteIcon>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      description
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV101-07
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .description
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf3(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "description",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      lineItemChargeAmount
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV102,
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .lineItemChargeAmount
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf3(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "lineItemChargeAmount",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      measurementUnit
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV103
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .measurementUnit
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf3(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "measurementUnit",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      serviceUnitCount
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .serviceUnitCount
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf3(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "serviceUnitCount",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      placeOfServiceCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV105
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .placeOfServiceCode
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf3(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "placeOfServiceCode",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      compositeDiagnosisCodePointers
                                                      <span className="lbl837Claim">
                                                        object
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV105
                                                    </Typography>
                                                  </div>
                                                </Grid>

                                                <Grid xs={3}>
                                                  {" "}
                                                  <Stack
                                                    direction="row"
                                                    spacing={2}
                                                  >
                                                    {" "}
                                                    <TextField
                                                      InputProps={{
                                                        readOnly:
                                                          PayerReadOnly === 1,
                                                      }}
                                                      value={
                                                        procPiagnosisCodePointersvalue
                                                      }
                                                      onChange={(e) => {
                                                        setprocPiagnosisCodePointersvalue(
                                                          e.target.value
                                                        );
                                                      }}
                                                      size="small"
                                                    ></TextField>
                                                    {PayerReadOnly != 1 && (
                                                      <Button
                                                        text="Add"
                                                        onclick={() => {
                                                          if (
                                                            procPiagnosisCodePointersvalue ==
                                                              "" ||
                                                            procPiagnosisCodePointersvalue ==
                                                              null
                                                          ) {
                                                            return;
                                                          }

                                                          if (
                                                            parseInt(
                                                              procPiagnosisCodePointersvalue
                                                            ) >
                                                            billyEntries
                                                              .Professional
                                                              ?.claimInformation
                                                              ?.healthCareCodeInformation
                                                              ?.length
                                                          ) {
                                                            setprocPiagnosisCodePointersvalue(
                                                              ""
                                                            );
                                                            return;
                                                          }
                                                          if (
                                                            billyEntries.Professional.claimInformation.serviceLines[
                                                              index
                                                            ].professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf(
                                                              procPiagnosisCodePointersvalue
                                                            ) == -1
                                                          ) {
                                                            // true
                                                            var dum = [
                                                              ...row
                                                                .professionalService
                                                                .compositeDiagnosisCodePointers
                                                                ?.diagnosisCodePointers,
                                                            ];
                                                            dum.push(
                                                              procPiagnosisCodePointersvalue
                                                            );
                                                            billyEntries.Professional.claimInformation.serviceLines[
                                                              index
                                                            ].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers =
                                                              dum;
                                                            // // stateSet.Professional?.claimInformation?.serviceLines[index].professionalService.procedureModifiers = dum;
                                                            setbillyEntries(
                                                              billyEntries
                                                            );
                                                            if (
                                                              !isAnythingChanged &&
                                                              setIsAnythingChanged
                                                            ) {
                                                              setIsAnythingChanged(
                                                                true
                                                              );
                                                            }
                                                            setemailTestData(
                                                              !emailTestData
                                                            );
                                                            setprocPiagnosisCodePointersvalue(
                                                              ""
                                                            );
                                                          }
                                                        }}
                                                        classname={
                                                          "button-styles btnHeight"
                                                        }
                                                        filled={true}
                                                      />
                                                    )}
                                                  </Stack>
                                                  {/* <AddIcon onClick={()=>{
                 //// debugger;
                 if(parseInt(procPiagnosisCodePointersvalue)> billyEntries.Professional?.claimInformation?.healthCareCodeInformation?.length)
                 {
                  setprocPiagnosisCodePointersvalue("");
                  return;
                 }
                 if(billyEntries.Professional.claimInformation.serviceLines[index].professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.indexOf(procPiagnosisCodePointersvalue) == -1) // true
                 {

                 var dum = [...row.professionalService.compositeDiagnosisCodePointers?.diagnosisCodePointers];
                  dum.push(procPiagnosisCodePointersvalue);
                  billyEntries.Professional.claimInformation.serviceLines[index].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers=dum;
                  // // stateSet.Professional?.claimInformation?.serviceLines[index].professionalService.procedureModifiers = dum;
                 setbillyEntries(billyEntries);
                 setemailTestData(!emailTestData);
                 setprocPiagnosisCodePointersvalue("");
                 }
                }}></AddIcon><div></div> */}
                                                  <ul>
                                                    {row.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers?.map(
                                                      (
                                                        item,
                                                        deleteModiIndex
                                                      ) => (
                                                        <li
                                                          style={{
                                                            background:
                                                              "#FFFFE0",
                                                            marginTop: "10px",
                                                            width: "120px",
                                                          }}
                                                        >
                                                          <span>
                                                            {item} (
                                                            {
                                                              billyEntries
                                                                .Professional
                                                                ?.claimInformation
                                                                ?.healthCareCodeInformation[
                                                                deleteModiIndex
                                                              ]
                                                                .diagnosisTypeCode
                                                            }
                                                            )
                                                          </span>
                                                          {PayerReadOnly !=
                                                            1 && (
                                                            <DeleteIcon
                                                              onClick={() => {
                                                                billyEntries.Professional.claimInformation.serviceLines[
                                                                  index
                                                                ].professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers.splice(
                                                                  deleteModiIndex,
                                                                  1
                                                                );

                                                                setbillyEntries(
                                                                  billyEntries
                                                                );
                                                                if (
                                                                  !isAnythingChanged &&
                                                                  setIsAnythingChanged
                                                                ) {
                                                                  setIsAnythingChanged(
                                                                    true
                                                                  );
                                                                }
                                                                setemailTestData(
                                                                  !emailTestData
                                                                );
                                                              }}
                                                              sx={{
                                                                float: "right",
                                                                color: red[500],
                                                              }}
                                                            ></DeleteIcon>
                                                          )}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </Grid>

                                                {/* <Grid xs={3}>
                  
                    <TextField
 InputProps={{
                              readOnly: PayerReadOnly === 1,
                            }} value={row.professionalService.compositeDiagnosisCodePointers?.diagnosisCodePointers}   size="small"></TextField>
                    <ul>
      {row.professionalService?.compositeDiagnosisCodePointers?.diagnosisCodePointers.map((item) => (
        <li>
          <span>{item}</span>
                    <button type="button" onClick={() => handleRemoveDiagnosisCodePointers(item)}>
            Remove
          </button>
        </li>
      ))}
    </ul>
                    
                    <div></div>
              </Grid> */}
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      emergencyIndicator
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row.professionalService
                                                        .emergencyIndicator
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "emergencyIndicator",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  />
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      epsdtIndicator
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV111
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row?.professionalService
                                                        ?.epsdtIndicator
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "epsdtIndicator",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  />
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      familyPlanningIndicator
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV112
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row?.professionalService
                                                        ?.familyPlanningIndicator
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "familyPlanningIndicator",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  />
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      copayStatusCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: SV1,
                                                      Element: SV115
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      row?.professionalService
                                                        ?.copayStatusCode
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangeProf(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "professionalService",
                                                        "copayStatusCode",
                                                        index
                                                      )
                                                    }
                                                    size="small"
                                                  />
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 67
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              67
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                67
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              Durable Medical Equipment Service
                                              (SV5){" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          67 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      days
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2410, Segment: SV5,
                                                      Element: SV503
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      rentalPrice
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2410, Segment: SV5,
                                                      Element: SV504
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      purchasePrice
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2410, Segment: SV5,
                                                      Element: SV505
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      frequencyCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2410, Segment: SV5,
                                                      Element: SV506,
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 68
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              68
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                68
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              Service Line Supplemental
                                              Information{" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          68 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      attachmentReportTypeCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2400, Segment: PWK,
                                                      Element: PWK01
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      attachmentTransmissionCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2400, Segment: PWK,
                                                      Element: PWK02
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      attachmentControlNumber
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop 2400, Segment: PWK,
                                                      Element: PWK05
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 69
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              69
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                69
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              durableMedicalEquipmentCertificateOfMedicalNecessity
                                              ( PWK ){" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          69 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      attachmentTransmissionCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2400, Segment: PWK,
                                                      Element: PWK02
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 70
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              70
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                70
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              ambulanceTransportInformation (
                                              CR1 ){" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          70 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 71
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              71
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                71
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              durableMedicalEquipmentCertification
                                              ( CR3 ){" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          71 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 72
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              72
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                72
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              ambulanceCertification{" "}
                                              <span className="lbl837Claim">
                                                array of objects
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          72 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 73
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              73
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                73
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              conditionIndicatorDurableMedicalEquipment
                                              (CRC){" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          73 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 74
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              74
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                74
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              serviceLineDateInformation{" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          74 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 75
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              75
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                75
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              ambulancePatientCount{" "}
                                              <span className="lbl837Claim">
                                                object
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          75 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      patientWeightInPounds
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR102
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      ambulanceTransportReasonCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      CR104
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      transportDistanceInMiles
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR106
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      roundTripPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR109
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      stretcherPurposeDescription
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: CR1, Element:
                                                      CR110
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField size="small"></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 76
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              76
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                76
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              ambulancePickUpLocation ( N3 and
                                              N4 )
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          76 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      address1
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N3, Element: N301
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.address1
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      address2
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N3, Element: N302
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.address2
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      city
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N401
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.city
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      state
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N402
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.state
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      postalCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N403
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.postalCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      countryCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N404
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.countryCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      countrySubDivisionCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N407
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.countrySubDivisionCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 77
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              77
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                77
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              ambulanceDropOffLocation ( N3 and
                                              N4 )
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          77 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      address1
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N3, Element: N301
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.address1
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      address2
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N3, Element: N302
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.address2
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      city
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N401
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.city
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      state
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N402
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.state
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      postalCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N403
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.postalCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      countryCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N404
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.countryCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      countrySubDivisionCode
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Segment: N4, Element: N407
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    value={
                                                      billyEntries.Professional
                                                        ?.subscriber?.address
                                                        ?.countrySubDivisionCode
                                                    }
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 78
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              78
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                78
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              lineAdjudicationInformation{" "}
                                              <span className="lbl837Claim">
                                                array of objects
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          78 ? (
                                            <AccordionDetails>
                                              <Grid container md={12}>
                                                <Grid xs={11}></Grid>
                                                <Grid xs={1}>
                                                  &nbsp;
                                                  {PayerReadOnly != 1 && (
                                                    <Button
                                                      text="Add"
                                                      // navigate="/capture/capture-driving-license/patient-info"
                                                      classname={
                                                        "button-styles btnHeight"
                                                      }
                                                      filled={true}
                                                      onclick={() => {
                                                        setbillyEntries(
                                                          (prevState) => {
                                                            // Ensure the row exists
                                                            if (!row)
                                                              return prevState;

                                                            // Get the current service line from the previous state
                                                            const currentServiceLines =
                                                              [
                                                                ...prevState
                                                                  .Professional
                                                                  .claimInformation
                                                                  .serviceLines,
                                                              ];
                                                            let updatedServiceLine =
                                                              {
                                                                ...currentServiceLines[
                                                                  index
                                                                ],
                                                              };

                                                            // Check if lineAdjudicationInformation exists and has entries
                                                            if (
                                                              Array.isArray(
                                                                row?.lineAdjudicationInformation
                                                              ) &&
                                                              row
                                                                .lineAdjudicationInformation
                                                                .length > 0
                                                            ) {
                                                              // Clone the existing lineAdjudicationInformation array
                                                              updatedServiceLine.lineAdjudicationInformation =
                                                                [
                                                                  ...row.lineAdjudicationInformation,
                                                                ];
                                                              updatedServiceLine.lineAdjudicationInformation.push(
                                                                {
                                                                  otherPayerPrimaryIdentifier:
                                                                    "",
                                                                  serviceLinePaidAmount:
                                                                    "",
                                                                }
                                                              );
                                                            } else {
                                                              // Create a new lineAdjudicationInformation array if it doesn't exist
                                                              updatedServiceLine.lineAdjudicationInformation =
                                                                [
                                                                  {
                                                                    otherPayerPrimaryIdentifier:
                                                                      "",
                                                                    serviceLinePaidAmount:
                                                                      "",
                                                                  },
                                                                ];
                                                            }

                                                            // Update the specific service line in the array
                                                            currentServiceLines[
                                                              index
                                                            ] =
                                                              updatedServiceLine;

                                                            // Return the updated state, keeping the rest of prevState unchanged
                                                            return {
                                                              ...prevState,
                                                              Professional: {
                                                                ...prevState.Professional,
                                                                claimInformation:
                                                                  {
                                                                    ...prevState
                                                                      .Professional
                                                                      .claimInformation,
                                                                    serviceLines:
                                                                      currentServiceLines,
                                                                  },
                                                              },
                                                            };
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  )}{" "}
                                                  &nbsp;&nbsp;
                                                </Grid>
                                              </Grid>
                                              {console.log(
                                                row,
                                                " lineAdjust murali row data"
                                              )}
                                              {row?.lineAdjudicationInformation?.map(
                                                (lineAdjust, lineindex) => {
                                                  return (
                                                    <Grid
                                                      style={{
                                                        background:
                                                          "rgba(25, 118, 210, 0.08)",
                                                        paddingLeft: "5px",
                                                        marginTop: "10px",
                                                        paddingRight: "5px",
                                                      }}
                                                    >
                                                      {console.log(
                                                        lineAdjust,
                                                        " lineAdjust murali "
                                                      )}
                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              otherPayerPrimaryIdentifier
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD01
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "otherPayerPrimaryIdentifier",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            value={
                                                              lineAdjust?.otherPayerPrimaryIdentifier
                                                            }
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <DeleteIcon
                                                            onClick={() => {
                                                              // Update billyEntries immutably using setbillyEntries
                                                              setbillyEntries(
                                                                (prevState) => {
                                                                  // Clone the serviceLines array
                                                                  const updatedServiceLines =
                                                                    [
                                                                      ...prevState
                                                                        .Professional
                                                                        .claimInformation
                                                                        .serviceLines,
                                                                    ];

                                                                  // Clone the lineAdjudicationInformation array for the specific service line
                                                                  const updatedLineAdjudicationInformation =
                                                                    [
                                                                      ...updatedServiceLines[
                                                                        index
                                                                      ]
                                                                        .lineAdjudicationInformation,
                                                                    ];

                                                                  // Remove the element at lineindex
                                                                  updatedLineAdjudicationInformation.splice(
                                                                    lineindex,
                                                                    1
                                                                  );

                                                                  // Update the specific service line with the modified lineAdjudicationInformation
                                                                  updatedServiceLines[
                                                                    index
                                                                  ] = {
                                                                    ...updatedServiceLines[
                                                                      index
                                                                    ],
                                                                    lineAdjudicationInformation:
                                                                      updatedLineAdjudicationInformation,
                                                                  };

                                                                  // Return the updated state
                                                                  return {
                                                                    ...prevState,
                                                                    Professional:
                                                                      {
                                                                        ...prevState.Professional,
                                                                        claimInformation:
                                                                          {
                                                                            ...prevState
                                                                              .Professional
                                                                              .claimInformation,
                                                                            serviceLines:
                                                                              updatedServiceLines,
                                                                          },
                                                                      },
                                                                  };
                                                                }
                                                              );
                                                            }}
                                                            sx={{
                                                              float: "right",
                                                              color: red[500],
                                                            }}
                                                          ></DeleteIcon>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              serviceLinePaidAmount
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD02
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "serviceLinePaidAmount",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            value={
                                                              lineAdjust?.serviceLinePaidAmount
                                                            }
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              serviceIdQualifier
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD03-01
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.serviceIdQualifier
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "serviceIdQualifier",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              procedureCode
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD03-02
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.procedureCode
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "procedureCode",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              procedureModifier
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          <Stack
                                                            direction="row"
                                                            spacing={2}
                                                          >
                                                            {" "}
                                                            <TextField
                                                              InputProps={{
                                                                readOnly:
                                                                  PayerReadOnly ===
                                                                  1,
                                                              }}
                                                              value={
                                                                lineprocedureModifiersvalue
                                                              }
                                                              onChange={(e) => {
                                                                setlineprocedureModifiersvalue(
                                                                  e.target.value
                                                                );
                                                              }}
                                                              size="small"
                                                            ></TextField>
                                                            {PayerReadOnly !=
                                                              1 && (
                                                              <Button
                                                                text="Add"
                                                                onclick={() => {
                                                                  // Validate lineprocedureModifiersvalue
                                                                  if (
                                                                    lineprocedureModifiersvalue ===
                                                                      "" ||
                                                                    lineprocedureModifiersvalue ===
                                                                      null
                                                                  ) {
                                                                    return;
                                                                  }

                                                                  // Use setState to update billyEntries immutably
                                                                  setbillyEntries(
                                                                    (
                                                                      prevState
                                                                    ) => {
                                                                      // Clone the serviceLines array
                                                                      const updatedServiceLines =
                                                                        [
                                                                          ...prevState
                                                                            .Professional
                                                                            .claimInformation
                                                                            .serviceLines,
                                                                        ];

                                                                      // Clone the lineAdjudicationInformation array for the specific service line
                                                                      const updatedLineAdjudicationInformation =
                                                                        [
                                                                          ...updatedServiceLines[
                                                                            index
                                                                          ]
                                                                            .lineAdjudicationInformation,
                                                                        ];

                                                                      // Check if procedureModifier exists, if not initialize as an empty array
                                                                      let procedureModifier =
                                                                        updatedLineAdjudicationInformation[
                                                                          lineindex
                                                                        ]
                                                                          .procedureModifier ||
                                                                        [];

                                                                      // Clone the procedureModifier array
                                                                      const updatedProcedureModifier =
                                                                        [
                                                                          ...procedureModifier,
                                                                        ];

                                                                      // Push the new value to the procedureModifier array
                                                                      updatedProcedureModifier.push(
                                                                        lineprocedureModifiersvalue
                                                                      );

                                                                      // Update the specific lineAdjudicationInformation with the updated procedureModifier
                                                                      updatedLineAdjudicationInformation[
                                                                        lineindex
                                                                      ] = {
                                                                        ...updatedLineAdjudicationInformation[
                                                                          lineindex
                                                                        ],
                                                                        procedureModifier:
                                                                          updatedProcedureModifier,
                                                                      };

                                                                      // Update the service line with the updated lineAdjudicationInformation
                                                                      updatedServiceLines[
                                                                        index
                                                                      ] = {
                                                                        ...updatedServiceLines[
                                                                          index
                                                                        ],
                                                                        lineAdjudicationInformation:
                                                                          updatedLineAdjudicationInformation,
                                                                      };

                                                                      // Return the new state
                                                                      return {
                                                                        ...prevState,
                                                                        Professional:
                                                                          {
                                                                            ...prevState.Professional,
                                                                            claimInformation:
                                                                              {
                                                                                ...prevState
                                                                                  .Professional
                                                                                  .claimInformation,
                                                                                serviceLines:
                                                                                  updatedServiceLines,
                                                                              },
                                                                          },
                                                                      };
                                                                    }
                                                                  );

                                                                  // Set the `isAnythingChanged` flag to true if needed
                                                                  if (
                                                                    !isAnythingChanged &&
                                                                    setIsAnythingChanged
                                                                  ) {
                                                                    setIsAnythingChanged(
                                                                      true
                                                                    );
                                                                  }

                                                                  // Reset the lineprocedureModifiersvalue to an empty string
                                                                  setlineprocedureModifiersvalue(
                                                                    ""
                                                                  );
                                                                }}
                                                                classname={
                                                                  "button-styles btnHeight"
                                                                }
                                                                filled={true}
                                                              />
                                                            )}
                                                            <div></div>
                                                          </Stack>
                                                          <ul>
                                                            {Array.isArray(
                                                              lineAdjust?.procedureModifier
                                                            ) &&
                                                              lineAdjust?.procedureModifier?.map(
                                                                (
                                                                  item,
                                                                  deleteModiIndex
                                                                ) => (
                                                                  <li
                                                                    style={{
                                                                      background:
                                                                        "#FFFFE0",
                                                                      marginTop:
                                                                        "10px",
                                                                      width:
                                                                        "100px",
                                                                    }}
                                                                  >
                                                                    <span>
                                                                      {item}
                                                                    </span>
                                                                    {PayerReadOnly !=
                                                                      1 && (
                                                                      <DeleteIcon
                                                                        onClick={() => {
                                                                          billyEntries.Professional.claimInformation.serviceLines[
                                                                            index
                                                                          ].lineAdjudicationInformation[
                                                                            lineindex
                                                                          ].procedureModifier.splice(
                                                                            deleteModiIndex,
                                                                            1
                                                                          );
                                                                          setbillyEntries(
                                                                            billyEntries
                                                                          );
                                                                          if (
                                                                            !isAnythingChanged &&
                                                                            setIsAnythingChanged
                                                                          ) {
                                                                            setIsAnythingChanged(
                                                                              true
                                                                            );
                                                                          }
                                                                          setemailTestData(
                                                                            !emailTestData
                                                                          );
                                                                        }}
                                                                        sx={{
                                                                          float:
                                                                            "right",
                                                                          color:
                                                                            red[500],
                                                                        }}
                                                                      ></DeleteIcon>
                                                                    )}
                                                                  </li>
                                                                )
                                                              )}
                                                          </ul>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              procedureCodeDescription
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD03-07
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.procedureCodeDescription
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "procedureCodeDescription",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              paidServiceUnitCount
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD05
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            value={
                                                              lineAdjust?.paidServiceUnitCount
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "paidServiceUnitCount",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              bundledOrUnbundledLineNumber
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: SVD,
                                                              Element: SVD06
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.bundledOrUnbundledLineNumber
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "bundledOrUnbundledLineNumber",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              adjudicationOrPaymentDate
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: DTP,
                                                              Element=DTP03 when
                                                              DTP02=D8 and
                                                              DTP01=573
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.adjudicationOrPaymentDate
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "adjudicationOrPaymentDate",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              remainingPatientLiability
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: AMT,
                                                              Element=AMT02 when
                                                              AMT01=EAF
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          <TextField
                                                            value={
                                                              lineAdjust?.remainingPatientLiability
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangeProfline(
                                                                e,
                                                                "claimInformation",
                                                                "serviceLines",
                                                                "lineAdjudicationInformation",
                                                                "remainingPatientLiability",
                                                                null,
                                                                null,
                                                                index,
                                                                lineindex
                                                              )
                                                            }
                                                            InputProps={{
                                                              readOnly:
                                                                PayerReadOnly ===
                                                                1,
                                                            }}
                                                            size="small"
                                                          ></TextField>
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid
                                                        container
                                                        md={12}
                                                        sx={{ pl: 2, pt: 1 }}
                                                      >
                                                        <Grid xs={5}>
                                                          <div>
                                                            <Typography variant="body1">
                                                              claimAdjustmentInformation
                                                              <span className="lbl837Claim">
                                                                string
                                                              </span>
                                                            </Typography>
                                                            <Typography
                                                              sx={{ mb: 1.5 }}
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                              color="text.secondary"
                                                            >
                                                              Loop: 2430,
                                                              Segment: CAS
                                                            </Typography>
                                                          </div>
                                                        </Grid>
                                                        <Grid xs={3}>
                                                          {" "}
                                                          {PayerReadOnly !=
                                                            1 && (
                                                            <Button
                                                              text="Add"
                                                              // navigate="/capture/capture-driving-license/patient-info"
                                                              classname={
                                                                "button-styles btnHeight"
                                                              }
                                                              filled={true}
                                                              onclick={() => {
                                                                setbillyEntries(
                                                                  (
                                                                    prevState
                                                                  ) => {
                                                                    // Clone the serviceLines array
                                                                    const updatedServiceLines =
                                                                      [
                                                                        ...prevState
                                                                          .Professional
                                                                          .claimInformation
                                                                          .serviceLines,
                                                                      ];

                                                                    // Clone the lineAdjudicationInformation array for the specific service line
                                                                    const updatedLineAdjudicationInformation =
                                                                      [
                                                                        ...updatedServiceLines[
                                                                          index
                                                                        ]
                                                                          .lineAdjudicationInformation,
                                                                      ];

                                                                    // Check if procedureModifier exists, if not initialize as an empty array
                                                                    let claimAdjustmentInformation =
                                                                      updatedLineAdjudicationInformation[
                                                                        lineindex
                                                                      ]
                                                                        .claimAdjustmentInformation ||
                                                                      [];

                                                                    // Clone the procedureModifier array
                                                                    const updatedclaimAdjustmentInformation =
                                                                      [
                                                                        ...claimAdjustmentInformation,
                                                                      ];

                                                                    // Push the new value to the procedureModifier array
                                                                    updatedclaimAdjustmentInformation.push(
                                                                      {
                                                                        adjustmentGroupCode:
                                                                          "",
                                                                        adjustmentDetails:
                                                                          [],
                                                                      }
                                                                    );

                                                                    // Update the specific lineAdjudicationInformation with the updated procedureModifier
                                                                    updatedLineAdjudicationInformation[
                                                                      lineindex
                                                                    ] = {
                                                                      ...updatedLineAdjudicationInformation[
                                                                        lineindex
                                                                      ],
                                                                      claimAdjustmentInformation:
                                                                        updatedclaimAdjustmentInformation,
                                                                    };

                                                                    // Update the service line with the updated lineAdjudicationInformation
                                                                    updatedServiceLines[
                                                                      index
                                                                    ] = {
                                                                      ...updatedServiceLines[
                                                                        index
                                                                      ],
                                                                      lineAdjudicationInformation:
                                                                        updatedLineAdjudicationInformation,
                                                                    };

                                                                    // Return the new state
                                                                    return {
                                                                      ...prevState,
                                                                      Professional:
                                                                        {
                                                                          ...prevState.Professional,
                                                                          claimInformation:
                                                                            {
                                                                              ...prevState
                                                                                .Professional
                                                                                .claimInformation,
                                                                              serviceLines:
                                                                                updatedServiceLines,
                                                                            },
                                                                        },
                                                                    };
                                                                  }
                                                                );

                                                                if (
                                                                  !isAnythingChanged &&
                                                                  setIsAnythingChanged
                                                                ) {
                                                                  setIsAnythingChanged(
                                                                    true
                                                                  );
                                                                }
                                                              }}
                                                            />
                                                          )}
                                                          {/* <TextField
                                                   value={
                                                    lineAdjust?.claimAdjustmentInformation
                                                  }
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    onChange={(e) =>
                                                      handleInputChangeProfline(
                                                        e,
                                                        "claimInformation",
                                                        "serviceLines",
                                                        "lineAdjudicationInformation",
                                                        "claimAdjustmentInformation",
                                                        index,
                                                          lineindex
                                                      )
                                                    }
                                                    size="small"
                                                  ></TextField> */}
                                                          <div></div>
                                                        </Grid>
                                                      </Grid>

                                                      {/* ggg */}
                                                      {Array.isArray(
                                                        lineAdjust?.claimAdjustmentInformation
                                                      ) &&
                                                        lineAdjust?.claimAdjustmentInformation?.map(
                                                          (cai, caIndx) => (
                                                            <React.Fragment
                                                              key={caIndx}
                                                            >
                                                              {" "}
                                                              <Grid
                                                                container
                                                                md={12}
                                                                style={{
                                                                  border:
                                                                    "2px #bebed7 solid",
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                                sx={{
                                                                  background:
                                                                    "#f7f7f7",
                                                                  pl: 2,
                                                                  pt: 2,
                                                                  pb: 4,
                                                                }}
                                                              >
                                                                <Grid
                                                                  container
                                                                  md={12}
                                                                  sx={{
                                                                    pl: 2,
                                                                    pt: 1,
                                                                  }}
                                                                >
                                                                  <Grid xs={5}>
                                                                    <div>
                                                                      <Typography variant="body1">
                                                                        adjustmentGroupCode
                                                                        <span className="lbl837Claim">
                                                                          string
                                                                        </span>
                                                                      </Typography>
                                                                      <Typography
                                                                        sx={{
                                                                          mb: 1.5,
                                                                        }}
                                                                        style={{
                                                                          fontSize:
                                                                            "12px",
                                                                        }}
                                                                        color="text.secondary"
                                                                      >
                                                                        Loop:
                                                                        2430,
                                                                        Segment:
                                                                        CAS
                                                                      </Typography>
                                                                    </div>
                                                                  </Grid>
                                                                  <Grid xs={3}>
                                                                    <TextField
                                                                      value={
                                                                        cai?.adjustmentGroupCode
                                                                      }
                                                                      InputProps={{
                                                                        readOnly:
                                                                          PayerReadOnly ===
                                                                          1,
                                                                      }}
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleInputChangeProfline(
                                                                          e,
                                                                          "claimInformation",
                                                                          "serviceLines",
                                                                          "lineAdjudicationInformation",
                                                                          "claimAdjustmentInformation",
                                                                          "adjustmentGroupCode",
                                                                          null,
                                                                          index,
                                                                          lineindex,
                                                                          caIndx
                                                                        )
                                                                      }
                                                                      size="small"
                                                                    ></TextField>
                                                                    <div></div>
                                                                  </Grid>
                                                                  <Grid xs={1}>
                                                                    <DeleteIcon
                                                                      onClick={() => {
                                                                        // Update billyEntries immutably using setbillyEntries
                                                                        setbillyEntries(
                                                                          (
                                                                            prevState
                                                                          ) => {
                                                                            // Clone the serviceLines array
                                                                            const updatedServiceLines =
                                                                              [
                                                                                ...prevState
                                                                                  .Professional
                                                                                  .claimInformation
                                                                                  .serviceLines,
                                                                              ];

                                                                            // Clone the lineAdjudicationInformation array for the specific service line
                                                                            const updatedLineAdjudicationInformation =
                                                                              [
                                                                                ...updatedServiceLines[
                                                                                  index
                                                                                ]
                                                                                  .lineAdjudicationInformation,
                                                                              ];

                                                                            // Clone the claimAdjustmentInformation for the specific lineAdjudicationInformation
                                                                            const claimAdjustmentInformation =
                                                                              [
                                                                                ...updatedLineAdjudicationInformation[
                                                                                  lineindex
                                                                                ]
                                                                                  .claimAdjustmentInformation,
                                                                              ];

                                                                            // Remove the element at lineindex
                                                                            claimAdjustmentInformation.splice(
                                                                              caIndx,
                                                                              1
                                                                            );

                                                                            // Update the specific lineAdjudicationInformation with the updated claimAdjustmentInformation
                                                                            updatedLineAdjudicationInformation[
                                                                              lineindex
                                                                            ] =
                                                                              {
                                                                                ...updatedLineAdjudicationInformation[
                                                                                  lineindex
                                                                                ],
                                                                                claimAdjustmentInformation:
                                                                                  claimAdjustmentInformation,
                                                                              };

                                                                            // Update the service line with the updated lineAdjudicationInformation
                                                                            updatedServiceLines[
                                                                              index
                                                                            ] =
                                                                              {
                                                                                ...updatedServiceLines[
                                                                                  index
                                                                                ],
                                                                                lineAdjudicationInformation:
                                                                                  updatedLineAdjudicationInformation,
                                                                              };

                                                                            // Return the new state
                                                                            return {
                                                                              ...prevState,
                                                                              Professional:
                                                                                {
                                                                                  ...prevState.Professional,
                                                                                  claimInformation:
                                                                                    {
                                                                                      ...prevState
                                                                                        .Professional
                                                                                        .claimInformation,
                                                                                      serviceLines:
                                                                                        updatedServiceLines,
                                                                                    },
                                                                                },
                                                                            };
                                                                          }
                                                                        );
                                                                      }}
                                                                      sx={{
                                                                        float:
                                                                          "right",
                                                                        color:
                                                                          red[500],
                                                                      }}
                                                                    ></DeleteIcon>
                                                                  </Grid>
                                                                </Grid>
                                                                <Grid
                                                                  container
                                                                  md={12}
                                                                  sx={{
                                                                    pl: 2,
                                                                    pt: 1,
                                                                  }}
                                                                >
                                                                  <Grid xs={5}>
                                                                    <div>
                                                                      <Typography variant="body1">
                                                                        adjustmentDetails
                                                                        <span className="lbl837Claim">
                                                                          array
                                                                          of
                                                                          objects
                                                                        </span>
                                                                      </Typography>
                                                                      <Typography
                                                                        sx={{
                                                                          mb: 1.5,
                                                                        }}
                                                                        style={{
                                                                          fontSize:
                                                                            "12px",
                                                                        }}
                                                                        color="text.secondary"
                                                                      >
                                                                        Loop:
                                                                        2430,
                                                                        Segment:
                                                                        CAS
                                                                      </Typography>
                                                                    </div>
                                                                  </Grid>
                                                                  <Grid xs={3}>
                                                                    {PayerReadOnly !=
                                                                      1 && (
                                                                      <Button
                                                                        text="Add"
                                                                        // navigate="/capture/capture-driving-license/patient-info"
                                                                        classname={
                                                                          "button-styles btnHeight"
                                                                        }
                                                                        filled={
                                                                          true
                                                                        }
                                                                        onclick={() => {
                                                                          setbillyEntries(
                                                                            (
                                                                              prevState
                                                                            ) => {
                                                                              const updatedServiceLines =
                                                                                [
                                                                                  ...prevState
                                                                                    .Professional
                                                                                    .claimInformation
                                                                                    .serviceLines,
                                                                                ];

                                                                              // Clone the lineAdjudicationInformation array for the specific service line
                                                                              const updatedLineAdjudicationInformation =
                                                                                [
                                                                                  ...updatedServiceLines[
                                                                                    index
                                                                                  ]
                                                                                    .lineAdjudicationInformation,
                                                                                ];

                                                                              // Clone the claimAdjustmentInformation for the specific lineAdjudicationInformation
                                                                              const claimAdjustmentInformation =
                                                                                [
                                                                                  ...updatedLineAdjudicationInformation[
                                                                                    lineindex
                                                                                  ]
                                                                                    .claimAdjustmentInformation,
                                                                                ];

                                                                              // Get the specific adjustmentDetails array from claimAdjustmentInformation
                                                                              const adjustmentDetails =
                                                                                claimAdjustmentInformation[
                                                                                  caIndx
                                                                                ]
                                                                                  .adjustmentDetails ||
                                                                                [];

                                                                              // Clone the adjustmentDetails array and add the new value
                                                                              const updatedAdjustmentDetails =
                                                                                [
                                                                                  ...adjustmentDetails,
                                                                                  {
                                                                                    adjustmentReasonCode:
                                                                                      "",
                                                                                    adjustmentAmount:
                                                                                      "",
                                                                                    adjustmentQuantity:
                                                                                      "",
                                                                                  },
                                                                                ];

                                                                              // Update claimAdjustmentInformation[caIndx] with the new adjustmentDetails array
                                                                              claimAdjustmentInformation[
                                                                                caIndx
                                                                              ] =
                                                                                {
                                                                                  ...claimAdjustmentInformation[
                                                                                    caIndx
                                                                                  ],
                                                                                  adjustmentDetails:
                                                                                    updatedAdjustmentDetails,
                                                                                };

                                                                              // Update the specific lineAdjudicationInformation with the updated claimAdjustmentInformation
                                                                              updatedLineAdjudicationInformation[
                                                                                lineindex
                                                                              ] =
                                                                                {
                                                                                  ...updatedLineAdjudicationInformation[
                                                                                    lineindex
                                                                                  ],
                                                                                  claimAdjustmentInformation:
                                                                                    claimAdjustmentInformation,
                                                                                };

                                                                              // Update the service line with the updated lineAdjudicationInformation
                                                                              updatedServiceLines[
                                                                                index
                                                                              ] =
                                                                                {
                                                                                  ...updatedServiceLines[
                                                                                    index
                                                                                  ],
                                                                                  lineAdjudicationInformation:
                                                                                    updatedLineAdjudicationInformation,
                                                                                };

                                                                              // Return the new state
                                                                              return {
                                                                                ...prevState,
                                                                                Professional:
                                                                                  {
                                                                                    ...prevState.Professional,
                                                                                    claimInformation:
                                                                                      {
                                                                                        ...prevState
                                                                                          .Professional
                                                                                          .claimInformation,
                                                                                        serviceLines:
                                                                                          updatedServiceLines,
                                                                                      },
                                                                                  },
                                                                              };
                                                                            }
                                                                          );

                                                                          // Trigger change tracking if applicable
                                                                          if (
                                                                            !isAnythingChanged &&
                                                                            setIsAnythingChanged
                                                                          ) {
                                                                            setIsAnythingChanged(
                                                                              true
                                                                            );
                                                                          }
                                                                        }}
                                                                      />
                                                                    )}
                                                                    <div></div>
                                                                  </Grid>
                                                                  {/* start  */}
                                                                  {Array.isArray(
                                                                    lineAdjust?.claimAdjustmentInformation
                                                                  ) &&
                                                                    lineAdjust?.claimAdjustmentInformation[
                                                                      caIndx
                                                                    ]?.adjustmentDetails?.map(
                                                                      (
                                                                        aj,
                                                                        adIndx
                                                                      ) => (
                                                                        <React.Fragment
                                                                          key={
                                                                            adIndx
                                                                          }
                                                                        >
                                                                          <Grid
                                                                            container
                                                                            md={
                                                                              12
                                                                            }
                                                                            style={{
                                                                              border:
                                                                                "2px #bebed7 solid",
                                                                              marginBottom:
                                                                                "5px",
                                                                            }}
                                                                            sx={{
                                                                              background:
                                                                                "aliceblue",
                                                                              pl: 2,
                                                                              pt: 2,
                                                                              pb: 4,
                                                                            }}
                                                                          >
                                                                            <Grid
                                                                              container
                                                                              md={
                                                                                12
                                                                              }
                                                                              sx={{
                                                                                pl: 2,
                                                                                pt: 1,
                                                                              }}
                                                                            >
                                                                              <Grid
                                                                                xs={
                                                                                  5
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <Typography variant="body1">
                                                                                    adjustmentReasonCode
                                                                                    <span className="lbl837Claim">
                                                                                      string
                                                                                    </span>
                                                                                  </Typography>
                                                                                  <Typography
                                                                                    sx={{
                                                                                      mb: 1.5,
                                                                                    }}
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "12px",
                                                                                    }}
                                                                                    color="text.secondary"
                                                                                  >
                                                                                    Loop:
                                                                                    2430,
                                                                                    Segment:
                                                                                    CAS
                                                                                  </Typography>
                                                                                </div>
                                                                              </Grid>
                                                                              <Grid
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <TextField
                                                                                  value={
                                                                                    aj?.adjustmentReasonCode
                                                                                  }
                                                                                  InputProps={{
                                                                                    readOnly:
                                                                                      PayerReadOnly ===
                                                                                      1,
                                                                                  }}
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleInputChangeProfline(
                                                                                      e,
                                                                                      "claimInformation",
                                                                                      "serviceLines",
                                                                                      "lineAdjudicationInformation",
                                                                                      "claimAdjustmentInformation",
                                                                                      "adjustmentDetails",
                                                                                      "adjustmentReasonCode",
                                                                                      index,
                                                                                      lineindex,
                                                                                      caIndx,
                                                                                      adIndx
                                                                                    )
                                                                                  }
                                                                                  size="small"
                                                                                ></TextField>
                                                                                <div></div>
                                                                              </Grid>
                                                                              <Grid
                                                                                xs={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <DeleteIcon
                                                                                  onClick={() => {
                                                                                    // Update billyEntries immutably using setbillyEntries
                                                                                    setbillyEntries(
                                                                                      (
                                                                                        prevState
                                                                                      ) => {
                                                                                        // Clone the serviceLines array
                                                                                        const updatedServiceLines =
                                                                                          [
                                                                                            ...prevState
                                                                                              .Professional
                                                                                              .claimInformation
                                                                                              .serviceLines,
                                                                                          ];

                                                                                        // Clone the lineAdjudicationInformation array for the specific service line
                                                                                        const updatedLineAdjudicationInformation =
                                                                                          [
                                                                                            ...updatedServiceLines[
                                                                                              index
                                                                                            ]
                                                                                              .lineAdjudicationInformation,
                                                                                          ];

                                                                                        // Clone the claimAdjustmentInformation for the specific lineAdjudicationInformation
                                                                                        const updateclaimAdjustmentInformation =
                                                                                          [
                                                                                            ...updatedLineAdjudicationInformation[
                                                                                              lineindex
                                                                                            ]
                                                                                              .claimAdjustmentInformation,
                                                                                          ];

                                                                                        const adjustmentDetails =
                                                                                          [
                                                                                            ...updateclaimAdjustmentInformation[
                                                                                              caIndx
                                                                                            ]
                                                                                              .adjustmentDetails,
                                                                                          ];

                                                                                        // Remove the element at lineindex
                                                                                        adjustmentDetails.splice(
                                                                                          adIndx,
                                                                                          1
                                                                                        );

                                                                                        // Update the specific lineAdjudicationInformation with the updated claimAdjustmentInformation
                                                                                        updateclaimAdjustmentInformation[
                                                                                          caIndx
                                                                                        ] =
                                                                                          {
                                                                                            ...updateclaimAdjustmentInformation[
                                                                                              caIndx
                                                                                            ],
                                                                                            adjustmentDetails:
                                                                                              adjustmentDetails,
                                                                                          };
                                                                                        // Update the specific lineAdjudicationInformation with the updated claimAdjustmentInformation
                                                                                        updatedLineAdjudicationInformation[
                                                                                          lineindex
                                                                                        ] =
                                                                                          {
                                                                                            ...updatedLineAdjudicationInformation[
                                                                                              lineindex
                                                                                            ],
                                                                                            claimAdjustmentInformation:
                                                                                              updateclaimAdjustmentInformation,
                                                                                          };

                                                                                        // Update the service line with the updated lineAdjudicationInformation
                                                                                        updatedServiceLines[
                                                                                          index
                                                                                        ] =
                                                                                          {
                                                                                            ...updatedServiceLines[
                                                                                              index
                                                                                            ],
                                                                                            lineAdjudicationInformation:
                                                                                              updatedLineAdjudicationInformation,
                                                                                          };

                                                                                        // Return the new state
                                                                                        return {
                                                                                          ...prevState,
                                                                                          Professional:
                                                                                            {
                                                                                              ...prevState.Professional,
                                                                                              claimInformation:
                                                                                                {
                                                                                                  ...prevState
                                                                                                    .Professional
                                                                                                    .claimInformation,
                                                                                                  serviceLines:
                                                                                                    updatedServiceLines,
                                                                                                },
                                                                                            },
                                                                                        };
                                                                                      }
                                                                                    );
                                                                                  }}
                                                                                  sx={{
                                                                                    float:
                                                                                      "right",
                                                                                    color:
                                                                                      red[500],
                                                                                  }}
                                                                                ></DeleteIcon>
                                                                              </Grid>
                                                                            </Grid>
                                                                            <Grid
                                                                              container
                                                                              md={
                                                                                12
                                                                              }
                                                                              sx={{
                                                                                pl: 2,
                                                                                pt: 1,
                                                                              }}
                                                                            >
                                                                              <Grid
                                                                                xs={
                                                                                  5
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <Typography variant="body1">
                                                                                    adjustmentAmount
                                                                                    <span className="lbl837Claim">
                                                                                      str
                                                                                    </span>
                                                                                  </Typography>
                                                                                  <Typography
                                                                                    sx={{
                                                                                      mb: 1.5,
                                                                                    }}
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "12px",
                                                                                    }}
                                                                                    color="text.secondary"
                                                                                  >
                                                                                    Loop:
                                                                                    2430,
                                                                                    Segment:
                                                                                    CAS
                                                                                  </Typography>
                                                                                </div>
                                                                              </Grid>
                                                                              <Grid
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <TextField
                                                                                  value={
                                                                                    aj?.adjustmentAmount
                                                                                  }
                                                                                  InputProps={{
                                                                                    readOnly:
                                                                                      PayerReadOnly ===
                                                                                      1,
                                                                                  }}
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleInputChangeProfline(
                                                                                      e,
                                                                                      "claimInformation",
                                                                                      "serviceLines",
                                                                                      "lineAdjudicationInformation",
                                                                                      "claimAdjustmentInformation",
                                                                                      "adjustmentDetails",
                                                                                      "adjustmentAmount",
                                                                                      index,
                                                                                      lineindex,
                                                                                      caIndx,
                                                                                      adIndx
                                                                                    )
                                                                                  }
                                                                                  size="small"
                                                                                ></TextField>
                                                                                <div></div>
                                                                              </Grid>
                                                                              {/* start  */}
                                                                              {/* end */}
                                                                            </Grid>
                                                                            <Grid
                                                                              container
                                                                              md={
                                                                                12
                                                                              }
                                                                              sx={{
                                                                                pl: 2,
                                                                                pt: 1,
                                                                              }}
                                                                            >
                                                                              <Grid
                                                                                xs={
                                                                                  5
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <Typography variant="body1">
                                                                                    adjustmentQuantity
                                                                                    <span className="lbl837Claim">
                                                                                      string
                                                                                    </span>
                                                                                  </Typography>
                                                                                  <Typography
                                                                                    sx={{
                                                                                      mb: 1.5,
                                                                                    }}
                                                                                    style={{
                                                                                      fontSize:
                                                                                        "12px",
                                                                                    }}
                                                                                    color="text.secondary"
                                                                                  >
                                                                                    Loop:
                                                                                    2430,
                                                                                    Segment:
                                                                                    CAS
                                                                                  </Typography>
                                                                                </div>
                                                                              </Grid>
                                                                              <Grid
                                                                                xs={
                                                                                  3
                                                                                }
                                                                              >
                                                                                <TextField
                                                                                  value={
                                                                                    aj?.adjustmentQuantity
                                                                                  }
                                                                                  InputProps={{
                                                                                    readOnly:
                                                                                      PayerReadOnly ===
                                                                                      1,
                                                                                  }}
                                                                                  onChange={(
                                                                                    e
                                                                                  ) =>
                                                                                    handleInputChangeProfline(
                                                                                      e,
                                                                                      "claimInformation",
                                                                                      "serviceLines",
                                                                                      "lineAdjudicationInformation",
                                                                                      "claimAdjustmentInformation",
                                                                                      "adjustmentDetails",
                                                                                      "adjustmentQuantity",
                                                                                      index,
                                                                                      lineindex,
                                                                                      caIndx,
                                                                                      adIndx
                                                                                    )
                                                                                  }
                                                                                  size="small"
                                                                                ></TextField>
                                                                                <div></div>
                                                                              </Grid>
                                                                            </Grid>
                                                                          </Grid>
                                                                        </React.Fragment>
                                                                      )
                                                                    )}
                                                                  {/* end */}
                                                                </Grid>{" "}
                                                              </Grid>
                                                            </React.Fragment>
                                                          )
                                                        )}
                                                      {/* ggg */}

                                                      {/* <Accordion
                                                slotProps={{
                                                  transition: {
                                                    unmountOnExit: true,
                                                  },
                                                }}
                                                sm={12}
                                                md={12}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <ExpandMoreIcon />
                                                  }
                                                  aria-controls="panel1bh-content"
                                                  id="panel1bh-header"
                                                  style={{
                                                    background: "#e8ebeb",
                                                  }}
                                                >
                                                  <Typography
                                                    component="div"
                                                    gutterBottom
                                                    sx={{
                                                      width: "73%",
                                                      flexShrink: 0,
                                                      fontWeight: "bold",
                                                    }}
                                                    variant="body2"
                                                  >
                                                    supportingDocumentation{" "}
                                                    <span className="lbl837Claim">
                                                      array of objects
                                                    </span>
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionNumber
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM01
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseCode
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM02
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponse
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM03
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseAsDate
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM04
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseAsPercent
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM05
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>
                                                </AccordionDetails>
                                              </Accordion> */}
                                                    </Grid>
                                                  );
                                                }
                                              )}
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>

                                        <Accordion
                                          slotProps={{
                                            transition: { unmountOnExit: true },
                                          }}
                                          expanded={
                                            accordionClaimInformation1111 == 79
                                          }
                                          onChange={() => {
                                            if (
                                              accordionClaimInformation1111 ==
                                              79
                                            ) {
                                              setAccordionClaimInformation1111(
                                                0
                                              );
                                            } else {
                                              setAccordionClaimInformation1111(
                                                79
                                              );
                                            }
                                          }}
                                          sm={12}
                                          md={12}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ background: "#e8ebeb" }}
                                          >
                                            <Typography
                                              component="div"
                                              gutterBottom
                                              sx={{
                                                width: "73%",
                                                flexShrink: 0,
                                                fontWeight: "bold",
                                              }}
                                              variant="body2"
                                            >
                                              formIdentification{" "}
                                              <span className="lbl837Claim">
                                                array of objects
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          {accordionClaimInformation1111 ==
                                          79 ? (
                                            <AccordionDetails>
                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      formTypeCode
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2440, Segment: LQ,
                                                      Element: LQ01
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Grid
                                                container
                                                md={12}
                                                sx={{ pl: 2, pt: 1 }}
                                              >
                                                <Grid xs={5}>
                                                  <div>
                                                    <Typography variant="body1">
                                                      formIdentifier
                                                      <span className="lbl837Claim">
                                                        string
                                                      </span>
                                                    </Typography>
                                                    <Typography
                                                      sx={{ mb: 1.5 }}
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                      color="text.secondary"
                                                    >
                                                      Loop: 2440, Segment: LQ,
                                                      Element: LQ02
                                                    </Typography>
                                                  </div>
                                                </Grid>
                                                <Grid xs={3}>
                                                  {" "}
                                                  <TextField
                                                    InputProps={{
                                                      readOnly:
                                                        PayerReadOnly === 1,
                                                    }}
                                                    size="small"
                                                  ></TextField>
                                                  <div></div>
                                                </Grid>
                                              </Grid>

                                              <Accordion
                                                slotProps={{
                                                  transition: {
                                                    unmountOnExit: true,
                                                  },
                                                }}
                                                sm={12}
                                                md={12}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <ExpandMoreIcon />
                                                  }
                                                  aria-controls="panel1bh-content"
                                                  id="panel1bh-header"
                                                  style={{
                                                    background: "#e8ebeb",
                                                  }}
                                                >
                                                  <Typography
                                                    component="div"
                                                    gutterBottom
                                                    sx={{
                                                      width: "73%",
                                                      flexShrink: 0,
                                                      fontWeight: "bold",
                                                    }}
                                                    variant="body2"
                                                  >
                                                    supportingDocumentation{" "}
                                                    <span className="lbl837Claim">
                                                      array of objects
                                                    </span>
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionNumber
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM01
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseCode
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM02
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponse
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM03
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseAsDate
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM04
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>

                                                  <Grid
                                                    container
                                                    md={12}
                                                    sx={{ pl: 2, pt: 1 }}
                                                  >
                                                    <Grid xs={5}>
                                                      <div>
                                                        <Typography variant="body1">
                                                          questionResponseAsPercent
                                                          <span className="lbl837Claim">
                                                            string
                                                          </span>
                                                        </Typography>
                                                        <Typography
                                                          sx={{ mb: 1.5 }}
                                                          style={{
                                                            fontSize: "12px",
                                                          }}
                                                          color="text.secondary"
                                                        >
                                                          Loop: 2440, Segment:
                                                          FRM, Element: FRM05
                                                        </Typography>
                                                      </div>
                                                    </Grid>
                                                    <Grid xs={3}>
                                                      {" "}
                                                      <TextField
                                                        InputProps={{
                                                          readOnly:
                                                            PayerReadOnly === 1,
                                                        }}
                                                        size="small"
                                                      ></TextField>
                                                      <div></div>
                                                    </Grid>
                                                  </Grid>
                                                </AccordionDetails>
                                              </Accordion>
                                            </AccordionDetails>
                                          ) : (
                                            ""
                                          )}
                                        </Accordion>
                                      </AccordionDetails>
                                    ) : (
                                      ""
                                    )}
                                  </Accordion>
                                )
                              )}
                            </AccordionDetails>
                          ) : (
                            ""
                          )}
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 6}
                    onChange={() => {
                      if (accordionCurrent == 6) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(6);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Pay To Plan ( Loop: 2010AC){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 6 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.organizationName
                              }
                              size="small"
                              name="payToPlan.organizationName"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "organizationName",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                primaryIdentifierTypeCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM108
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.primaryIdentifierTypeCode
                              }
                              size="small"
                              name="payToPlan.primaryIdentifierTypeCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "primaryIdentifierTypeCode",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                primaryIdentifier{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.primaryIdentifier
                              }
                              size="small"
                              name="payToPlan.primaryIdentifier"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "primaryIdentifier",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                secondaryIdentifierTypeCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF01
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.secondaryIdentifierTypeCode
                              }
                              size="small"
                              name="payToPlan.secondaryIdentifierTypeCode"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "secondaryIdentifierTypeCode",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                secondaryIdentifier{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.secondaryIdentifier
                              }
                              size="small"
                              name="payToPlan.secondaryIdentifier"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "secondaryIdentifier",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                taxIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02 when
                                REF01=EI
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.payToPlan
                                  ?.taxIdentificationNumber
                              }
                              size="small"
                              name="payToPlan.taxIdentificationNumber"
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  "payToPlan",
                                  "taxIdentificationNumber",
                                  e.target.name
                                )
                              }
                            ></TextField>
                            <div></div>
                          </Grid>
                        </Grid>
                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 )
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.address1"
                                  value={
                                    billyEntries.Professional?.payToPlan
                                      ?.address1
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.address2"
                                  value={
                                    billyEntries.Professional?.payToPlan
                                      ?.address2
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">city</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.city"
                                  value={
                                    billyEntries.Professional?.payToPlan?.city
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">state</Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.state"
                                  value={
                                    billyEntries.Professional?.payToPlan?.state
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.postalCode"
                                  value={
                                    billyEntries.Professional?.payToPlan
                                      ?.postalCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.countryCode"
                                  value={
                                    billyEntries.Professional?.payToPlan
                                      ?.countryCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.payToPlan.countrySubDivisionCode"
                                  value={
                                    billyEntries.Professional?.payToPlan
                                      ?.countrySubDivisionCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 7}
                    onChange={() => {
                      if (accordionCurrent == 7) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(7);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Payer Address ( N3 and N4 ){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 7 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">address1</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N3, Element: N301
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.address1"
                              value={
                                billyEntries.Professional?.payerAddress
                                  ?.address1
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">address2</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N3, Element: N302
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.address2"
                              value={
                                billyEntries.Professional?.payerAddress
                                  ?.address2
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">city</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N4, Element: N401
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.city"
                              value={
                                billyEntries.Professional?.payerAddress?.city
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">state</Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N4, Element: N402
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.state"
                              value={
                                billyEntries.Professional?.payerAddress?.state
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                postalCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N4, Element: N403
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.postalCode"
                              value={
                                billyEntries.Professional?.payerAddress
                                  ?.postalCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                countryCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N4, Element: N404
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.countryCode"
                              value={
                                billyEntries.Professional?.payerAddress
                                  ?.countryCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                countrySubDivisionCode
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Segment: N4, Element: N407
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.payerAddress.countrySubDivisionCode"
                              value={
                                billyEntries.Professional?.payerAddress
                                  ?.countrySubDivisionCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 8}
                    onChange={() => {
                      if (accordionCurrent == 8) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(8);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Billing (Loop: 2000A){" "}
                        <span className="lbl837Claim">object</span>
                        <span className="lbl837ClaimColor">required</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 8 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerType{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.providerType"
                              value={
                                billyEntries.Professional?.billing?.providerType
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                npi <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.npi"
                              value={billyEntries.Professional?.billing?.npi}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                ssn <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=SY,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.ssn"
                              value={billyEntries.Professional?.billing?.ssn}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerId{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=EI,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.employerId"
                              value={
                                billyEntries.Professional?.billing?.employerId
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                commercialNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=G2
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.commercialNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.commercialNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                locationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=LU
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.locationNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.locationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                payerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=2U
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.payerIdentificationNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.payerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=EI
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.employerIdentificationNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.employerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimOfficeNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02 when
                                REF01=FY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.claimOfficeNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.claimOfficeNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                naic <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.naic"
                              value={billyEntries.Professional?.billing?.naic}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                stateLicenseNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=0B
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.stateLicenseNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.stateLicenseNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerUpinNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=1G, Notes: UPINs must be
                                formatted as either X99999 or XXX999
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.providerUpinNumber"
                              value={
                                billyEntries.Professional?.billing
                                  ?.providerUpinNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                taxonomyCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                PRV03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.taxonomyCode"
                              value={
                                billyEntries.Professional?.billing?.taxonomyCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                firstName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.firstName"
                              value={
                                billyEntries.Professional?.billing?.firstName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                lastName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.lastName"
                              value={
                                billyEntries.Professional?.billing?.lastName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.middleName"
                              value={
                                billyEntries.Professional?.billing?.middleName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                suffix{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.suffix"
                              value={billyEntries.Professional?.billing?.suffix}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.billing.organizationName"
                              value={
                                billyEntries.Professional?.billing
                                  ?.organizationName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 ){" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.address1"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.address1
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.address2"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.address2
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    city{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.city"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.city
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    state{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.state"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.state
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.postalCode"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.postalCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.countryCode"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.countryCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.address.countrySubDivisionCode"
                                  value={
                                    billyEntries.Professional?.billing?.address
                                      ?.countrySubDivisionCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation{" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    name{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.contactInformation.name"
                                  value={
                                    billyEntries.Professional?.billing
                                      ?.contactInformation?.name
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.contactInformation.phoneNumber"
                                  value={
                                    billyEntries.Professional?.billing
                                      ?.contactInformation?.phoneNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.contactInformation.faxNumber"
                                  value={
                                    billyEntries.Professional?.billing
                                      ?.contactInformation?.faxNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    email{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.contactInformation.email"
                                  value={
                                    billyEntries.Professional?.billing
                                      ?.contactInformation?.email
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.billing.contactInformation.phoneExtension"
                                  value={
                                    billyEntries.Professional?.billing
                                      ?.contactInformation?.phoneExtension
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 9}
                    onChange={() => {
                      if (accordionCurrent == 9) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(9);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Referring (Loop: 2420F){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 9 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerType{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.providerType"
                              value={
                                billyEntries.Professional?.referring
                                  ?.providerType
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                npi <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.npi"
                              value={billyEntries.Professional?.referring?.npi}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                ssn <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=SY,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.ssn"
                              value={billyEntries.Professional?.referring?.ssn}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerId{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=EI,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.employerId"
                              value={
                                billyEntries.Professional?.referring?.employerId
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                commercialNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=G2
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.organizationName"
                              value={
                                billyEntries.Professional?.referring
                                  ?.organizationName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                locationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=LU
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.locationNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.locationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                payerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=2U
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.payerIdentificationNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.payerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=EI
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.employerIdentificationNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.employerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimOfficeNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02 when
                                REF01=FY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.claimOfficeNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.claimOfficeNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                naic <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.naic"
                              value={billyEntries.Professional?.referring?.naic}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                stateLicenseNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=0B
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.stateLicenseNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.stateLicenseNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerUpinNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=1G, Notes: UPINs must be
                                formatted as either X99999 or XXX999
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.providerUpinNumber"
                              value={
                                billyEntries.Professional?.referring
                                  ?.providerUpinNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                taxonomyCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                PRV03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.taxonomyCode"
                              value={
                                billyEntries.Professional?.referring
                                  ?.taxonomyCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                firstName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.firstName"
                              value={
                                billyEntries.Professional?.referring?.firstName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                lastName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.lastName"
                              value={
                                billyEntries.Professional?.referring?.lastName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.middleName"
                              value={
                                billyEntries.Professional?.referring?.middleName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                suffix{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.suffix"
                              value={
                                billyEntries.Professional?.referring?.suffix
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.referring.organizationName"
                              value={
                                billyEntries.Professional?.referring
                                  ?.organizationName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 ){" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.address1"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.address1
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.address2"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.address2
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    city{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.city"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.city
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    state{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.state"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.state
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.postalCode"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.postalCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.countryCode"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.countryCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.address.countrySubDivisionCode"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.address?.countrySubDivisionCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation{" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    name{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.contactInformation.name"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.contactInformation?.name
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.contactInformation.phoneNumber"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.contactInformation?.phoneNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.contactInformation.faxNumber"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.contactInformation?.faxNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    email{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.contactInformation.email"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.contactInformation?.email
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.referring.contactInformation.phoneExtension"
                                  value={
                                    billyEntries.Professional?.referring
                                      ?.contactInformation?.phoneExtension
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 10}
                    onChange={() => {
                      if (accordionCurrent == 10) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(10);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Rendering (Loop: 2420A){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 10 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerType{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.providerType"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.providerType
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                npi <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.npi"
                              value={billyEntries.Professional?.rendering?.npi}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                ssn <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=SY,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.ssn"
                              value={billyEntries.Professional?.rendering?.ssn}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerId{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=EI,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.employerId"
                              value={
                                billyEntries.Professional?.rendering?.employerId
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                commercialNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=G2
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.commercialNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.commercialNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                locationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=LU
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.locationNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.locationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                payerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=2U
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.payerIdentificationNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.payerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=EI
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.employerIdentificationNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.employerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimOfficeNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02 when
                                REF01=FY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.claimOfficeNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.claimOfficeNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                naic <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.naic"
                              value={billyEntries.Professional?.rendering?.naic}
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                stateLicenseNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=0B
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.stateLicenseNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.stateLicenseNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerUpinNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=1G, Notes: UPINs must be
                                formatted as either X99999 or XXX999
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.providerUpinNumber"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.providerUpinNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                taxonomyCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                PRV03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.taxonomyCode"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.taxonomyCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                firstName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.firstName"
                              value={
                                billyEntries.Professional?.rendering?.firstName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                lastName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.lastName"
                              value={
                                billyEntries.Professional?.rendering?.lastName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.middleName"
                              value={
                                billyEntries.Professional?.rendering?.middleName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                suffix{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.suffix"
                              value={
                                billyEntries.Professional?.rendering?.suffix
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.rendering.organizationName"
                              value={
                                billyEntries.Professional?.rendering
                                  ?.organizationName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 ){" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.address1"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.address1
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.address2"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.address2
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    city{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.city"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.city
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    state{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.state"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.state
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.postalCode"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.postalCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.countryCode"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.countryCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.address.countrySubDivisionCode"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.address?.countrySubDivisionCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation{" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    name{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.contactInformation.name"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.contactInformation?.name
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.contactInformation.phoneNumber"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.contactInformation?.phoneNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.contactInformation.faxNumber"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.contactInformation?.faxNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    email{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.contactInformation.email"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.contactInformation?.email
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.rendering.contactInformation.phoneExtension"
                                  value={
                                    billyEntries.Professional?.rendering
                                      ?.contactInformation?.phoneExtension
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <Accordion
                    slotProps={{ transition: { unmountOnExit: true } }}
                    expanded={accordionCurrent == 11}
                    onChange={() => {
                      if (accordionCurrent == 11) {
                        setAccordionCurrent(0);
                      } else {
                        setAccordionCurrent(11);
                      }
                    }}
                    sm={12}
                    md={12}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ background: "#e8ebeb" }}
                    >
                      <Typography
                        component="div"
                        gutterBottom
                        sx={{
                          width: "73%",
                          flexShrink: 0,
                          fontWeight: "bold",
                        }}
                        variant="body2"
                      >
                        Supervising (Loop: 2420D){" "}
                        <span className="lbl837Claim">object</span>
                      </Typography>
                    </AccordionSummary>
                    {accordionCurrent == 11 ? (
                      <AccordionDetails>
                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerType{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: NM1, Element: NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="supervising.providerType"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.providerType
                              }
                              onChange={(e) =>
                                handleInputChangeSuper(
                                  e,
                                  "supervising",
                                  "providerType",
                                  e.target.name
                                )
                              }
                              size="small"
                            />

                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                npi <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM109
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.supervising?.npi
                              }
                              onChange={(e) =>
                                handleInputChangeProf(e, "supervising", "npi")
                              }
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                ssn <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=SY,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.supervising?.ssn
                              }
                              onChange={(e) =>
                                handleInputChangeProf(e, "supervising", "ssn")
                              }
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerId{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=EI,
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.supervising
                                  ?.employerId
                              }
                              onChange={(e) =>
                                handleInputChangeProf(
                                  e,
                                  "supervising",
                                  "employerId"
                                )
                              }
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                commercialNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=G2
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.supervising
                                  ?.commercialNumber
                              }
                              onChange={(e) =>
                                handleInputChangeProf(
                                  e,
                                  "supervising",
                                  "commercialNumber"
                                )
                              }
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                locationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=LU
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              value={
                                billyEntries.Professional?.supervising
                                  ?.locationNumber
                              }
                              onChange={(e) =>
                                handleInputChangeProf(
                                  e,
                                  "supervising",
                                  "locationNumber"
                                )
                              }
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                payerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=2U
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.payerIdentificationNumber"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.payerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                employerIdentificationNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                LOOP: 2010AC, Segment: REF, Element: REF02 when
                                REF01=EI
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.employerIdentificationNumber"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.employerIdentificationNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                claimOfficeNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02 when
                                REF01=FY
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.claimOfficeNumber"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.claimOfficeNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                naic <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                Loop: 2010AC, Segment: REF, Element: REF02
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.naic"
                              value={
                                billyEntries.Professional?.supervising?.naic
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                stateLicenseNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=0B
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.stateLicenseNumber"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.stateLicenseNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                providerUpinNumber{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                REF02 when REF01=1G, Notes: UPINs must be
                                formatted as either X99999 or XXX999
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.providerUpinNumber"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.providerUpinNumber
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                taxonomyCode{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                PRV03
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.taxonomyCode"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.taxonomyCode
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                firstName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM104
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.firstName"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.firstName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                lastName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.lastName"
                              value={
                                billyEntries.Professional?.supervising?.lastName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                middleName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM105
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.middleName"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.middleName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                suffix{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM107
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.suffix"
                              value={
                                billyEntries.Professional?.supervising?.suffix
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                          <Grid xs={5}>
                            <div>
                              <Typography variant="body1">
                                organizationName{" "}
                                <span className="lbl837Claim">string</span>
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                style={{ fontSize: "12px" }}
                                color="text.secondary"
                              >
                                NM103
                              </Typography>
                            </div>
                          </Grid>
                          <Grid xs={3}>
                            {" "}
                            <TextField
                              InputProps={{
                                readOnly: PayerReadOnly === 1,
                              }}
                              name="Professional.supervising.organizationName"
                              value={
                                billyEntries.Professional?.supervising
                                  ?.organizationName
                              }
                              onChange={handleChangeRendering}
                              size="small"
                            />
                            <div></div>
                          </Grid>
                        </Grid>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              address ( N3 and N4 ){" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address1{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N301
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.address1"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.address1
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    address2{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N3, Element: N302
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.address2"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.address2
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    city{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N401
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.city"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.city
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    state{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N402
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.state"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.state
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    postalCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N403
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.postalCode"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.postalCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countryCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N404
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.countryCode"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.countryCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    countrySubDivisionCode{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: N4, Element: N407
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.address.countrySubDivisionCode"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.address?.countrySubDivisionCode
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          slotProps={{ transition: { unmountOnExit: true } }}
                          sm={12}
                          md={12}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{ background: "#e8ebeb" }}
                          >
                            <Typography
                              component="div"
                              gutterBottom
                              sx={{
                                width: "73%",
                                flexShrink: 0,
                                fontWeight: "bold",
                              }}
                              variant="body2"
                            >
                              contactInformation{" "}
                              <span className="lbl837Claim">object</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    name{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER02 and PER01=IC
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.contactInformation.name"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.contactInformation?.name
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 (Provider,
                                    Submitter, Subscriber, Dependent) or PER06
                                    (Provider, Submitter) or PER08 (Provider,
                                    Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.contactInformation.phoneNumber"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.contactInformation?.phoneNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    faxNumber{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This is used in (Provider,
                                    Submitter) when PER03=FX or PER05=FX or
                                    PER07=FX
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.contactInformation.faxNumber"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.contactInformation?.faxNumber
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    email{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER04 or PER06 or
                                    PER08, Note: This used in (Provider,
                                    Submitter) when PER03=EM or PER05=EM or
                                    PER07=EM
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.contactInformation.email"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.contactInformation?.email
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>

                            <Grid container md={12} sx={{ pl: 2, pt: 1 }}>
                              <Grid xs={5}>
                                <div>
                                  <Typography variant="body1">
                                    phoneExtension{" "}
                                    <span className="lbl837Claim">string</span>
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1.5 }}
                                    style={{ fontSize: "12px" }}
                                    color="text.secondary"
                                  >
                                    Segment: PER, Element: PER06 (Provider,
                                    Submitter, Subscriber, Dependent) or PER08
                                    (Provider, Submitter),Note: Used when
                                    PER05=EX (Provider, Submitter, Subscriber,
                                    Dependent) or PER07=EX (Provider, Submitter)
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid xs={3}>
                                {" "}
                                <TextField
                                  InputProps={{
                                    readOnly: PayerReadOnly === 1,
                                  }}
                                  name="Professional.supervising.contactInformation.phoneExtension"
                                  value={
                                    billyEntries.Professional?.supervising
                                      ?.contactInformation?.phoneExtension
                                  }
                                  onChange={handleChangeRendering}
                                  size="small"
                                />
                                <div></div>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </AccordionDetails>
                    ) : (
                      ""
                    )}
                  </Accordion>
                  <div></div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isSubmitButtonClick == 0
            ? "Void"
            : isSubmitButtonClick == 1
            ? "Copy Claim"
            : isSubmitButtonClick == 5
            ? "Hold"
            : isSubmitButtonClick == 6
            ? "Release"
            : "Verify Claim Status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isSubmitButtonClick == 0
              ? "Are you sure you want to void this record?"
              : isSubmitButtonClick == 1
              ? "Are you sure you want to copy claim this record?"
              : isSubmitButtonClick == 5
              ? "Are you sure you want to on hold this record?"
              : isSubmitButtonClick == 6
              ? "Are you sure you want to release this record?"
              : "Are you sure you want to verify claim status this record?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            classname={"button-styles !w-20"}
            filled={true}
            text="No"
            onclick={() => handleClose()}
          ></Button>

          <Button
            classname={"button-styles !w-20"}
            filled={true}
            onclick={() => handlePopupCallButton()}
            autoFocus
            text="Yes"
          ></Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingg || isLoading || payerLoading || proceedLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ClaimSubmitDetails;
