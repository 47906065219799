import React, { useEffect, useMemo, useRef, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetDocAiListApi } from "../../apis/docAiConfiguration";
import Notification from "../../components/Notification";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import UpdateConfiguration from "./UpdateConfiguration";
import List from "./List";
import { logTelemetry } from "../../logTelemetry";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ConfigurationList = () => {
  const gridRef = useRef();
  const { mutateAsync, isLoading } = GetDocAiListApi();

  const loginDetails = useSelector((state) => state.loginDetails);
  const details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const intial = {
    sourceFileTypeID: 0,
    recData: {},
    fileTypeId: 0,
  };
  const [reqData, setReqData] = useState(intial);
  const [loading, setLoading] = useState(false);

  const [columns, setColumns] = useState([
    { headerName: "Edit", field: "edit" },
    { headerName: "File Id", field: "fileTypeID", width: 120 },
    { headerName: "File Type Code", field: "fileTypeCode", width: 170 },
    { headerName: "Model Type", field: "modelType", width: 160 },
    {
      headerName: "File Type Description",
      field: "fileTypeDesc",
      width: 350,
    },
    { headerName: "Sort order", field: "sortOrder", width: 150 },
    { headerName: "Version #", field: "versionNo", width: 140 },
    { headerName: "Api Priority", field: "apiPriority", width: 150 },
    { headerName: "Update Date", field: "updateDate", width: 160 },
    { headerName: "Update User", field: "updateUser", width: 160 },
    { headerName: "Record Status", field: "recordStatus", width: 170 },
    { headerName: "Create Date", field: "createDate", width: 160 },
    { headerName: "Create User", field: "createUser", width: 160 },
  ]);
  const [rows, setRows] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const addNewClick = () => {
    setReqData(intial);
    setCurrentTab(1);
  };

  const refreshClick = () => {
    getDocAiList();
  };

  useEffect(() => {
    if (!currentTab) getDocAiList();
  }, [currentTab]);

  async function getDocAiList() {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "92437",
        moduleId: "0",
        SectionId: "0",
        RecordId: "0",
        RequestString: "0",
        UserId: "0",
        RequestType: "0",
      };
      const res = await mutateAsync(payload);

      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        setRows(res?.data?.fileTypeList);
        setModelList(res?.data?.modelList);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "DocAiConfigurationList",
          "getDocAiList",
          `${res?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        setLoading(false);
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "DocAiConfigurationList",
        "getDocAiList",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getDocAiList" + e.message,
        type: "error",
      });
    }
  }

  const handleTabChange = (event, newValue) => {
    if (reqData.sourceFileTypeID == "0" && newValue == 1) {
      setNotify({
        isOpen: true,
        message: "Click on the edit icon to see the desired configuration.",
        type: "warning",
      });
      return;
    }
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      label: "DocAi Configuration List",
      component: (
        <List
          columns={columns}
          rows={rows}
          gridRef={gridRef}
          addNewClick={addNewClick}
          refreshClick={refreshClick}
          setNotify={setNotify}
          setCurrentTab={setCurrentTab}
          loading={isLoading}
          setReqData={setReqData}
        />
      ),
    },
    {
      label: "Create / Update Configuration",
      component: (
        <UpdateConfiguration reqData={reqData} modelList={modelList} />
      ),
    },
  ];

  return (
    <div>
      <div>
        <Box className="my-2 mt-3">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </div>

      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ConfigurationList;
