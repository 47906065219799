import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import Tooltip from "@mui/material/Tooltip";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  FormControl,
  Typography,
  Grid,
} from "@mui/material";
import convertToPhrase from "../../components/utils/nameConversion";

const ellipStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
};

const alignInSingle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "fit-content",
  display: "flex",
  alignItems: "baseline",
  gap: "5px",
  paddingRight: "10px",
};

const CustomModal = ({
  data,
  checkFun,
  close,
  selectedVal,
  setChangeTab,
  setLabelData,
  onItemClick,
  valueNeedToSendPie,
}) => {
  let colors = [
    "#1f77b4", // blue
    "#ff7f0e", // orange
    "#2ca02c", // green
    "#d62728", // red
    "#9467bd", // purple
    "#8c564b", // brown
    "#e377c2", // pink
    "#7f7f7f", // gray
    "#bcbd22", // olive
    "#17becf", // cyan
    "#aec7e8", // light blue
    "#ffbb78", // light orange
    "#98df8a", // light green
    "#ff9896", // light red
    "#c5b0d5", // light purple
    "#c49c94", // light brown
    "#f7b6d2", // light pink
    "#c7c7c7", // light gray
    "#dbdb8d", // light olive
    "#9edae5", // light cyan
  ];
  useEffect(() => {
    console.log(
      {
        data,
        checkFun,
        close,
        selectedVal,
        setChangeTab,
        setLabelData,
      },
      "data 1234",
      valueNeedToSendPie
    );
  }, []);
  const [radioVal, setRadioVal] = useState(selectedVal);
  //   const [selectedData, setSelectedData] = useState(dataOptions);
  // const [pieData, setPieData] = useState(data);

  const pieData = data;
  const calculateTotal = (data) => {
    let totalCount = 0;
    for (let i = 0; i < data?.length; i++) {
      totalCount += parseInt(data[i].value);
    }
    return totalCount;
  };

  return (
    <>
      <b>{convertToPhrase(selectedVal)}</b>
      <Grid container>
        <div className="!flex">
          <div className="mr-10 mt-5 mb-10">
            <PieChart
              series={[{ data: pieData }]}
              width={300}
              height={300}
              data={pieData}
              margin={{ top: 20, bottom: 0, left: 0, right: 0 }}
              slotProps={{ legend: { hidden: true } }}
              onItemClick={(event, d, item) => setLabelData(item)}
              //   {
              //   // const params = {
              //   //   ...filterInput,
              //   //   from: item.label.split("(")[0],
              //   //   pRequest_Type: UpdateTransValue,
              //   //   subType: subCategory[d.dataIndex].subRequestType,
              //   //   loc: { UpdateLocationName, UpdateLocationValue, filterInput },
              //   // }; // Example parameters
              //   // setDataToTransfer(params);
              //   // setValue(1);
              //   close();
              //   setChangeTab(1);
              //   setLabelData(item.label);
              //   // navigate("/transactionSummary/transaction-List", { state: params });
              //   // window.location.href = "/transactionSummary/transaction-List";
              // }}
            />
          </div>

          <div
            className="flex-1 w-[520px]"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {pieData && pieData.length > 0 ? (
              pieData?.map((item) => (
                <React.Fragment key={item.label}>
                  <span style={alignInSingle}>
                    <>
                      <span
                        style={{
                          minWidth: "10px",
                          height: "10px",
                          display: "inline-block",
                          backgroundColor: item.color,
                          marginRight: "2px",
                        }}
                      />
                      <Tooltip title={item.label}>
                        <Typography
                          variant="subtitle1"
                          style={ellipStyle}
                          onClick={() => setLabelData(item)}
                        >
                          {item.displayName}
                          {/* {item.displayName.length > 20
                          ? item.displayName.substring(0, 20) + "..."
                          : item.displayName} */}
                        </Typography>
                      </Tooltip>
                    </>

                    <span>
                      {item?.payerId?.length > 0
                        ? "(" + item?.payerId + ")"
                        : ""}
                      {/* {item?.payerId?.length > 0
                        ? item?.payerId?.length > 20
                          ? item.payerId.substring(0, 20) + "..."
                          : "(" + item?.payerId + ")"
                        : ""} */}
                    </span>
                    <span>({item.value})</span>
                  </span>
                </React.Fragment>
              ))
            ) : (
              <span>no data</span>
            )}
          </div>
        </div>
      </Grid>
      <h3 className="highlight-total">
        Total Count : <b>{calculateTotal(pieData)}</b>
      </h3>
    </>
  );
};

export default CustomModal;
