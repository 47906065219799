import React, { useEffect } from "react";
import { Grid, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AgGridComponent } from "./AgGridComponent";

const List = ({
  addReportClick,
  refreshClick,
  btnDownloadCSVClick,
  rows,
  columns,
  gridRef,
  btnDownloadXLClick,
  onAdGridRowClick,
  setNotify,
  setGridApi,
}) => {
  useEffect(() => {
    return () => {
      setNotify({
        isOpen: false,
      });
    };
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className="mb-3"
        >
          <Box>
            {/* <Button
              variant="outlined"
              color="primary"
              endIcon={<AddIcon />}
              onClick={addReportClick}
              sx={{ marginRight: 1 }}
              className="button-styles !min-w-[150px]"
            >
              Add Report
            </Button> */}
            <Button
              variant="outlined"
              endIcon={<RefreshIcon />}
              onClick={refreshClick}
              className="button-styles !min-w-[150px] !mr-2"
            >
              Refresh
            </Button>
          </Box>
        </Grid>

        <div className="mt-3">
          <Box className="ag-theme-alpine docVHgt relative">
            <div className="absolute z-10 top-[6px] right-[1px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadCSVClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export CSV
                <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadXLClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export XL
                <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
              </Button>
            </div>
            <AgGridComponent
              rows={rows}
              gridRef={gridRef}
              columns={columns}
              onAdGridRowClick={onAdGridRowClick}
              setGridApi={setGridApi}
              alwaysShowVerticalScroll={true}
              paginationPageSize={100}
              paginationPageSizeSelector={[100, 250, 500, 1000]}
            />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default List;
