// import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { Grid } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import MyContext from "../../components/MyContext";
import { GetURL } from "../../apis/patientDetails";
import { TIFFViewer } from "react-tiff";
// import "react-tiff/dist/index.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function PreviewModal({
  open,
  setOpen,
  handleClose,
  data,
  currentIndex,
  setCurrentIndex,
  isPDF,
  pdfURL,
  imageName,
  imageId,
  setLoading,
}) {
  // setLoading(true);
  // console.log(imageId, " ravi ");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Preview
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            {/* {data.length > 0 && ( */}
            {console.log(
              {
                open,
                setOpen,
                handleClose,
                data,
                currentIndex,
                setCurrentIndex,
                isPDF,
                pdfURL,
                imageName,
                imageId,
                setLoading,
              },
              "check here pdfURL"
            )}
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {isPDF ? (
                <object
                  id="showPDFICON1"
                  data={pdfURL}
                  type="application/pdf"
                  width="100%"
                  height="670px"
                />
              ) : imageName?.toLowerCase()?.includes(".tif") ||
                imageName?.toLowerCase()?.includes(".tiff") ? (
                <TIFFViewer
                  key={imageName}
                  tiff={pdfURL ? URL.createObjectURL(pdfURL) : ""}
                />
              ) : (
                <img
                  //   src={pdfURL ? window?.URL?.createObjectURL(pdfURL) : ""}
                  src={pdfURL}
                  alt="preview"
                  style={{
                    width: "100%",
                    height: "650px",
                    objectFit: "contain",
                  }}
                />
              )}
            </Typography>
            {/* )} */}
            <Box className="flex justify-center mt-4">
              <Typography
                variant="subtitle1"
                component="div"
                className="!font-bold"
              >
                {imageName}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
