import React, { useRef } from "react";
import ReusableGrid from "../../components/ReusableGrid";

const ActivityLog = () => {
  const gridRef = useRef(null);

  const agGridColumns = [
    // your column definitions
  ];

  const agGridRows = [
    // your row data
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const onGridReady = (params) => {
    // Do something when the grid is ready
  };

  const exportCsv = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv();
    }
  };

  const exportXls = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsExcel();
    }
  };

  return (
    <div>
      <ReusableGrid
        agGridColumns={agGridColumns}
        agGridRows={agGridRows}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        btnExportCsvClick={exportCsv}
        btnExportXlClick={exportXls}
        ref={gridRef} // Pass the ref
        data={[
          {
            activity_ID: 178142,
            service_Order_ID: 0,
            activity_Date: "2024-09-04T01:36:21",
            activity_Details: "Location information updated",
            order_Status: "Active",
            system_User_Name: "ravim",
          },
          {
            activity_ID: 178141,
            service_Order_ID: 0,
            activity_Date: "2024-09-04T01:32:14",
            activity_Details: "Location information updated",
            order_Status: "Active",
            system_User_Name: "ravim",
          },
          {
            activity_ID: 178044,
            service_Order_ID: 0,
            activity_Date: "2024-02-07T16:25:05",
            activity_Details: "New Location address (1 Main St | Main) saved",
            order_Status: "Active",
            system_User_Name: "padma",
          },
          {
            activity_ID: 178045,
            service_Order_ID: 0,
            activity_Date: "2024-02-07T16:25:05",
            activity_Details: "New Location Created",
            order_Status: "Active",
            system_User_Name: "padma",
          },
        ]}
      />
    </div>
  );
};

export default ActivityLog;
