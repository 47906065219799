import { instance, Blobinstance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetBatchNumbers, GetReportsList } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getBatchNumbers(val) {
  try {
    let { data } = await instance.post(GetBatchNumbers, val);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getReportsList(val) {
  try {
    let { data } = await instance.post(GetReportsList, val);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

// test
export function getBatchNumbersApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getBatchNumbers, {});
}

export function getReportsListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getReportsList, {});
}
