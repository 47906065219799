import { instance, Blobinstance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { CorePresign, GetModules, ProcessImport } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function uploadFile(value) {
  try {
    let { data } = await instance.post(CorePresign, value);
    // let { data } = await instance.post(
    //   "https://qav.api.orbithc.net/api/v1/presign",
    //   value
    // );
    if (data.status.code == 200) {
      return callPreSignUrl(data, value);
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function callPreSignUrl(signData, inputData) {
  try {
    const response = await Blobinstance.put(
      signData.data.signedUrl,
      inputData.file
    );
    if (response.status === 201) {
      return { signData, response };
    } else {
      console.log(response);
      throw new Error("Unexpected response from the server");
    }
  } catch (error) {
    console.error("Error uploading to signed URL:", error);
    return { success: false, message: error.message };
  }
}

async function getAllModules(value) {
  try {
    let { data } = await instance.post(GetModules, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function processData(value) {
  try {
    let { data } = await instance.post(ProcessImport, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function UploadFileFun() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(uploadFile, {});
}

export function ProcessImportedData() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(processData, {});
}

export function GetAllModules() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getAllModules, {});
}
