import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomPieChart from "./CustomPieChart";
import colors from "./colors";
import convertToPhrase from "../../components/utils/nameConversion";
import ViewListIcon from "@mui/icons-material/ViewList";
// import colors from "./colors";
// import BasicPie from "./basicPie";

const CustomRender = React.memo(
  ({
    data,
    onPieChartClick,
    onItemClickPie,
    viewAllClick,
    parentData,
    pIndex,
    subCategory,
    e,
    pList,
    selectedOption,
    setSelectedOption,
    pieData,
    setPieData,
  }) => {
    useEffect(() => {
      const initialSelections = data?.map((item) => {
        const options = Object.keys(transformData(item?.subType));
        return options.length > 0
          ? item?.subRequestType.toLowerCase() === "failure"
            ? options[1]
            : options[0]
          : null;
      });
      // if (selectedOption.length === 0) {
      setSelectedOption(initialSelections, pIndex);
      // }

      const initialPieData = data?.map((item) => {
        const options = transformData(item?.subType);
        const firstOption =
          item?.subRequestType.toLowerCase() === "failure"
            ? Object.keys(options)[1]
            : Object.keys(options)[0];
        return options[firstOption];
      });
      // if (pieData.length === 0) {
      // setSelectedOption(initialSelections);
      setPieData(initialPieData, pIndex);
      // }
    }, [e[pIndex]]);

    const handleRadioChange = (id, index) => {
      // console.log({ id, index, pIndex }, " elections ", selectedOption);
      const updatedSelectedOption = [...selectedOption[pIndex]];
      updatedSelectedOption[index] = id;
      setSelectedOption(updatedSelectedOption, pIndex);

      const updatedPieData = [...pieData[pIndex]];
      updatedPieData[index] = transformData(data[index]?.subType)[id];
      setPieData(updatedPieData, pIndex);
    };

    function chktrim(str) {
      str =
        str?.name?.length > 20
          ? str?.name?.replace("(", "").replace(")", "").substring(0, 19) +
            "(" +
            str?.failureStatuscode +
            "," +
            str?.failureStatusCategory +
            ")"
          : str?.name?.replace("(", "").replace(")", "") +
            "(" +
            str?.failureStatuscode +
            "," +
            str?.failureStatusCategory +
            ")";
      return str.replace(/^\s+|\s+$/g, "");
    }

    const transformData = (originalData) => {
      if (checkArraysEmpty(originalData)) {
        return {
          byPayer: originalData.byPayer.byCount.map((item, index) => ({
            ...item,
            value: parseInt(item.count),
            label: item.displayName,
            color: colors[index],
          })),
          byReason: originalData.byReason.byLatency.map((item, index) => ({
            ...item,
            value: parseInt(item.count),
            label: chktrim(item),
            color: colors[index],
          })),
        };
      } else {
        return {
          byPayerCount: originalData.byPayer.byCount.map((item, index) => ({
            ...item,
            value: parseInt(item.count),
            label: item.displayName,
            color: colors[index],
          })),
          byPayerLatency: originalData.byPayer.byLatency.map((item, index) => ({
            ...item,
            value: parseInt(item.latency),
            label: item.displayName,
            color: colors[index],
          })),
        };
      }
    };

    const checkArraysEmpty = (obj) => {
      return !(
        obj?.byReason?.byCount?.length === 0 &&
        obj?.byReason?.byLatency?.length === 0
      );
    };

    const calculateTotal = (data, from, testLat) => {
      let totalCount = 0;
      if (from === "single") {
        for (let i = 0; i < data.length; i++) {
          totalCount += parseInt(data[i].totalSubRequests);
        }
        return totalCount;
      } else {
        // console.log(testLat, "data", data);
        for (let i = 0; i < data.length; i++) {
          totalCount += testLat.includes("Lat")
            ? parseInt(data[i].count)
            : data[i].value;
        }
        return totalCount;
      }
    };

    const retunArray = (item) => {
      if (item?.subRequestType.toLowerCase() === "failure") {
        return Object.keys(transformData(item?.subType)).reverse();
      }
      return Object.keys(transformData(item?.subType));
    };

    return (
      <>
        {subCategory?.length > 0 ? (
          <div className="customCardView relative">
            <Grid container className="mx-4">
              <Grid item sm={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <h2 className="text-slate-600 font-medium mar-bot-15">
                    Summary
                  </h2>
                </Stack>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ marginTop: "15px" }} />
            {/* pie start  */}
            <CustomPieChart
              data={subCategory}
              onItemClick={onItemClickPie}
              // index={index}
              pList={pList}
              isFrom="single"
              pIndex={pIndex}
              // cData={item}
              pData={parentData}
              e={e}
              // by={selectedOption?.[index]}
            />

            <div className="absolute bottom-[15px] left-[15px]">
              <h4 className="highlight-total">
                Total Count : <b>{calculateTotal(subCategory, "single")}</b>
              </h4>
            </div>

            {/* pie end */}
          </div>
        ) : (
          <>
            {data?.map((item, index) => (
              <div className="customCardView" key={index}>
                <Grid container className="mx-4">
                  <Grid item sm={12}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <h2 className="text-slate-600 font-medium mar-bot-15">
                        {item?.subRequestType}
                      </h2>

                      <FormControl>
                        <RadioGroup
                          row
                          name={`options-${index}`}
                          value={
                            selectedOption?.[pIndex]?.length > 0
                              ? selectedOption?.[pIndex]?.[index]
                              : ""
                          }
                          onChange={(e) =>
                            handleRadioChange(e.target.value, index)
                          }
                        >
                          {/* {console.log(
                          item,
                          index,
                          "console elections ",
                          retunArray(item)
                        )} */}
                          {retunArray(item)?.map((option) => (
                            <FormControlLabel
                              key={option}
                              value={option}
                              control={<Radio />}
                              label={convertToPhrase(option)}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider variant="middle" style={{ marginBottom: "15px" }} />
                {/* pie start  */}
                <>
                  {pieData?.[pIndex]?.[index]?.length > 0 ? (
                    <CustomPieChart
                      data={pieData?.[pIndex]?.[index] || []}
                      onItemClick={onPieChartClick}
                      index={index}
                      pIndex={pIndex}
                      cData={item}
                      pList={pList}
                      pData={parentData}
                      by={selectedOption?.[pIndex]?.[index]}
                      e={e}
                      latency={
                        selectedOption?.[pIndex]?.length > 0
                          ? selectedOption?.[pIndex]?.[index]
                          : ""
                      }
                    />
                  ) : (
                    <div className="!flex">
                      <div className="!flex-1" style={{ padding: "6.2rem" }}>
                        <div className="d-flex justify-content-center align-items-center border border-dotted border-black">
                          <div
                            className="border p-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <h5>No Data to display</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
                {/* pie end */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {pieData?.[pIndex]?.[index]?.length > 0 && (
                    <h4 className="highlight-total">
                      {/* {console.log(pieData, "pieData")} */}
                      Total Count :{" "}
                      <b>
                        {calculateTotal(
                          pieData?.[pIndex]?.[index],
                          "multi",
                          selectedOption?.[pIndex]?.[index]
                        )}
                      </b>
                    </h4>
                  )}
                  {pieData[pIndex]?.[index]?.length > 10 && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="button-styles"
                        startIcon={<ViewListIcon />}
                        onClick={() =>
                          viewAllClick(
                            pIndex,
                            item,
                            selectedOption?.[pIndex]?.[index],
                            pieData?.[pIndex]?.[index] || [],
                            pList
                          )
                        }
                      >
                        View All
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </>
    );
  }
);

export default CustomRender;
