import Constants from "../constants";

export const setLookUpInfo = (data) => {
  return {
    type: Constants.STORE_LOOKUP_DATA,
    payload: data,
  };
};

export const setdId = (data) => {
  return {
    type: Constants.STORE_DID,
    payload: data,
  };
};

export const setFilteredData = (data) => {
  return {
    type: Constants.STORE_FILTERED_DATA,
    payload: data,
  };
};
export const createAction = (type, payload) => ({
  type,
  payload,
});

export const setFormData = (data) =>
  createAction(Constants.STORE_SET_FORM_DATA, data);

export const setPageModel = (data) =>
  createAction(Constants.STORE_SET_PAGE_MODEL, data);

export const setSortModel = (data) =>
  createAction(Constants.STORE_SET_SORT_MODEL, data);

export const setFilterModel = (data) =>
  createAction(Constants.STORE_SET_FILTER_MODEL, data);

export const setFiltersApplied = (data) =>
  createAction(Constants.STORE_SET_FILTER_APPLIED, data);

export const resetData = (data) =>
  createAction(Constants.STORE_RESET_PREVIOUS_COMP_DATA, data);
