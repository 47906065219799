import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveComponent } from "../redux/actions/index";
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  Typography,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  VolunteerActivism,
  AddHomeWork,
  AddPhotoAlternate,
  Telegram,
  NoteAlt,
  FactCheck,
  Assessment,
  LibraryBooks,
  Storage,
  Ballot,
  Task,
  Feed,
  DashboardCustomize,
  PinDrop,
  People,
  Summarize,
} from "@mui/icons-material";
import Logo from "../images/Logo.png";
import {
  setFilterModel,
  setPageModel,
  setSortModel,
  setFiltersApplied,
  setFormData,
  resetData,
} from "../redux/actions/documentRecognizer";
import { filterFormInitialData, resetInitData } from "./utils/SidebarRoutes";
import { resetPatientData } from "../redux/actions/patientDetails";
import { handleComponentRender, handleActionRender } from "./utils/activeComponent";

export default function TemporaryDrawer({
  open,
  setOpen,
  toggleDrawer,
  navigateToRoute,
  details,
  onItemClick
}) {
  const dispatch = useDispatch();
  const accessDetails = useSelector((state) => state.accessRights);
  let accessRights = useMemo(() => {
    if (accessDetails) {
      return JSON.parse(accessDetails);
    }
    return null;
  }, [accessDetails]);
  // console.log("accessRights", accessRights);

  const [open1, setOpen1] = useState({});
  const [dynamicSidebar, setDynamicSidebar] = useState([]);

  useEffect(() => {
    if (Array.isArray(accessRights)) {
      const formattedSidebar = accessRights.map((item) => ({
        segName: item.segName,
        mods: item.mods
          .map((mod) => ({
              modName: mod.modName,
              hasAccess: mod.secs?.some(
                (sec) => sec.funcs?.some((func) => func.accInd === 1)
              ) || false,
              route: `/${item.segName.toLowerCase()}/${mod.modName.toLowerCase()}`,
            icon: moduleIcons[mod.modName],
            parentIcon: segmentIcons[item.segName],
          }))
          .filter((mod) => mod.hasAccess),
      })).filter((item) => item.mods.length > 0);
      // Only update dynamicSidebar if it has changed
      if (JSON.stringify(dynamicSidebar) !== JSON.stringify(formattedSidebar)) {
        setDynamicSidebar(formattedSidebar);
      }
    } else {
      console.error("accessRights is not an array! ", accessRights);
      setDynamicSidebar([]);
    }
  }, [accessRights, dynamicSidebar]);


  const handleToggle = (name) => {
    // Toggle the clicked section
    setOpen1((prevOpenState) => ({
      ...prevOpenState,
      [name]: !prevOpenState[name],
    }));

    // Close all other sections *after* the clicked section's state is updated
    setOpen1((prevOpenState) => {
      const updatedOpenState = { ...prevOpenState };
      for (const key in updatedOpenState) {
        if (key !== name && updatedOpenState[key]) {
          updatedOpenState[key] = false;
        }
      }
      return updatedOpenState;
    });
  };



  // Helper function to find segId and modId
  const getSegmentAndModuleIds = (segmentName, moduleName, accessRights, handleItemClick) => {
    const segment = accessRights.find((s) => s.segName === segmentName);
    const moduleDetails = segment?.mods.find((m) => m.modName === moduleName);
    handleActionRender(moduleDetails);
    if (segment && moduleDetails) {
      handleItemClick(segment.segId, moduleDetails.modId);
    } else {
      console.error("Segment or module details not found.");
    }
  };

  const handleItemClick = (segId, modId) => {
    const newComponent = handleComponentRender(modId);
    if (newComponent != null) {
      setOpen(false);
      dispatch(setActiveComponent(newComponent));
      dispatch(resetData(resetInitData));
      // dispatch(resetPatientData());
      // setOpen1({});
    }
  };

  const moduleIcons = {
    "Insurance Verification": () => <span className="oh-HealthNew"></span>,
    "Capture Or Upload": () => <span className="oh-photo"></span>,
    "Send SMS or Email": () => <span className="oh-SendMail"></span>,
    "Discover Insurance": () => <span className="oh-invoicing"></span>,
    
    "Claims": () => <span className="oh-claim"></span>,
    "Claim Status": () => <span className="oh-claimstatus"></span>,

    "Location": () => <span className="oh-company"></span>,
    "Staff": () => <span className="oh-resource"></span>,
    "Data Import Wizard": () => <span className="oh-dataimport"></span>,
    "DRF Configuration": () => <span className="oh-RecNoSch"></span>,

    "Transaction Summary": () => <span className="oh-leadsummary"></span>,
    "Custom Reports": () => <span className="oh-CustRpt"></span>,

    "Task Summary": () => <span className="oh-workqsummary"></span>,

    "Document Summary": () => <span className="oh-DocSmry"></span>,
    "Document Recognizer": () => <span className="oh-Preauthorization"></span>,
  };

  const segmentIcons =
  {
    "Intake": () => <span className="oh-marketing"></span>,
    "Billing": () => <span className="oh-billing"></span>,
    "Administration": () => <span className="oh-administration"></span>,
    "Reports": () => <span className="oh-reports"></span>,
    "User Apps": () => <span className="oh-userapps"></span>,
    "Documents": () => <span className="oh-documents"></span>,
  };
  return (
    <div>
      {open && (
        <Drawer
          open={open}
          onClose={(_, reason) => reason === "backdropClick" && setOpen(false)}
          sx={{ minWidth: "200px" }}
        >
          <div>
            <div
              className="flex justify-center align-items-center mt-4 mb-4"
              onClick={() => handleItemClick("/patient-list")}
            >
              <div className="cursor-pointer">
                <img src={Logo} alt="logo" className="h-8" />
              </div>
            </div>
            <Divider />
            <List className="!pt-0">
              {dynamicSidebar.map((segment) => (
                <React.Fragment key={segment.segName}>
                  {segment.mods.length > 0 && (
                    <>
                      <ListItem disablePadding sx={{ cursor: "pointer" }}>
                        <ListItemButton
                          onClick={(e) => {
                            e.preventDefault();
                            handleToggle(segment.segName);
                          }}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {segment.mods[0].parentIcon && (
                              React.createElement(segment.mods[0].parentIcon, {
                                sx: {
                                  fontSize: "1.4rem",
                                  mr: 1.5,
                                  color: "#171a1c",
                                },
                              })
                            )}
                            <Typography variant="body1" gutterBottom className="!m-0">
                              {segment.segName}
                            </Typography>
                          </div>
                          {segment.mods.length > 0 ? (
                            open1[segment.segName] ? (
                              <ExpandLess sx={{ fontSize: "1.4rem", mr: 1.5 }} />
                            ) : (
                              <ExpandMore sx={{ fontSize: "1.4rem", mr: 1.5 }} />
                            )
                          ) : null}
                        </ListItemButton>
                      </ListItem>
                      <Collapse in={open1[segment.segName]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {segment.mods.map((mod) => (
                            <ListItem key={mod.modName} disablePadding sx={{ cursor: "pointer" }}>
                              <ListItemButton
                                onClick={() => getSegmentAndModuleIds(segment.segName, mod.modName, accessRights, handleItemClick)}
                              >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  {mod.icon && <mod.icon sx={{ fontSize: "1.2rem", mr: 1 }} />}
                                  <Typography variant="body2" className="!m-0">
                                    {mod.modName}
                                  </Typography>
                                </div>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  )}
                </React.Fragment>
              ))}
            </List>
          </div>
        </Drawer>
      )}
    </div>
  );
}