import dayjs from 'dayjs';
import { logTelemetry } from "../../../logTelemetry";


export const validateContactInformation = (contactInformation) => {
  const hasError = contactInformation.some(info => !info.name);

  return {
    isValid: !hasError,
    updatedContactInformation: contactInformation.map(info => ({
      ...info,
      error: {
        ...info.error,
        name: !info.name ? 'Required' : ''
      }
    }))
  };
};

export const btnAddContactInfo = (setSubmitterData, submitterData, setNotify) => {
  try {
    const { isValid, updatedContactInformation } = validateContactInformation(submitterData.contactInformation);

    if (!isValid) {
      setSubmitterData(prevState => ({
        ...prevState,
        contactInformation: updatedContactInformation
      }));

      setNotify({
        isOpen: true,
        message: "Please fill out the required field in contact information: Name",
        type: "error",
      });
    } else {
      setSubmitterData(prevState => ({
        ...prevState,
        contactInformation: [
          ...prevState.contactInformation,
          { name: '', phoneNumber: '', phoneExtension: '', faxNumber: '', email: '', error: {} }
        ]
      }));
    }
  } catch (e) {
    logTelemetry("Failed", "CMS1500", "btnAddContactInfo", e.message, e);
  }
};


export const btnRemoveContactInfo = (index, setSubmitterData, setOpenSnackbar, submitterData) => {
  try {
    if (submitterData.contactInformation.length > 1) {
      const newContactInformation = submitterData.contactInformation.filter((_, i) => i !== index);
      setSubmitterData(prevState => ({
        ...prevState,
        contactInformation: newContactInformation,
      }));
    } else {
      setOpenSnackbar(true);
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "btnRemoveContactInfo", e.message, e);
    // console.error(e, "error");
  }
};

const validateEmail = (email) => {
  try {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "validateEmail", e.message, e);
    // console.error(e, "error");
  }

};

export const handleContactInputChange = (index, event, setSubmitterData, formData) => {
  try {
    const { name, value } = event.target;
    if (formData?.readOnlyFlag == 0) {
      setSubmitterData(prevState => {
        const contactInformation = [...prevState.contactInformation];
        contactInformation[index][name] = value;
        if (name === 'email') {
          contactInformation[index].emailError = !validateEmail(value);
        }
        return { ...prevState, contactInformation };
      });
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleContactInputChange", e.message, e);
    // console.error(e, "error");
  }

};

export const handleCloseSnackbar = (setOpenSnackbar) => {
  try {
    setOpenSnackbar(false);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleCloseSnackbar", e.message, e);
    // console.error(e, "error");
  }
};

export const formatDateCMS = (dateString) => {
  try {
    return dateString && dayjs(dateString).isValid() ? dayjs(dateString) : null;
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "formatDateCMS", e.message, e);
    // console.error(e, "error");
  }
  // return dayjs(dateString);

};

export const formatDate = (dateString) => {
  try {
    if (!dateString) {
      return '';
    }
    const date = dayjs(dateString);
    if (!date.isValid()) {
      return '';
    }
    return date.format('MM/DD/YYYY');
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "formatDate", e.message, e);
    // console.error(e, "error");
  }

};

export const handleCurrentPayerInputChange = (
  fieldName, event, setCurrentPayerDetails, setTempCurrentPayerDetails,
  setPreviousPayerDetails, setShowPreviousDiv, previousPayerDetails, currentPayerDetails, payerList,
  tempCurrentPayerDetails
) => {
  try {
    if (event && event.hasOwnProperty("target")) {
      if (event.target.role === "option") {
        const { innerText } = event.target;
        let selectedPayer = payerList.find((x) => x.displayName === innerText);
        setCurrentPayerDetails(selectedPayer);
        if (Object.keys(previousPayerDetails).every(key => previousPayerDetails[key] === "")) {
          setShowPreviousDiv(true);
          setPreviousPayerDetails({ ...currentPayerDetails });
        }
        setShowPreviousDiv(true);
      } else {
        setShowPreviousDiv(false);
        const { value } = event.target;
        setPreviousPayerDetails({ ...tempCurrentPayerDetails });
        setCurrentPayerDetails(prev => {
          const updatedDetails = { ...prev, [fieldName]: value };
          if (fieldName === 'payerName') {
            updatedDetails.chPayerId = '';
            updatedDetails.payerId = '';
          } else if (fieldName === 'cpid') {
            updatedDetails.chPayerName = '';
            updatedDetails.payerId = '';
          } else if (fieldName === 'payerId') {
            updatedDetails.chPayerName = '';
            updatedDetails.chPayerId = '';
          }
          return updatedDetails;
        });
      }
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleCurrentPayerInputChange", e.message, e);
    // console.error(e, "error");
  }

};

export const handleServiceInputChange = (index, name, value, setServiceList, diagnosis) => {
  if (name.toUpperCase() === "DIAGNOSISPOINTER") {
    // Split the input values by comma, trim whitespace, and convert to uppercase
    let inputValues = value.split(',').map(val => val.trim().toUpperCase());

    // Filter out any values that are already present in the input
    inputValues = inputValues.filter((val, idx, self) => {
      // Check if the value is a duplicate
      return self.indexOf(val) === idx;
    });

    // Validate that all entered values exist in the diagnosis array
    let validInput = true;
    for (let i = 0; i < inputValues.length; i++) {
      const currentValue = inputValues[i];

      if (currentValue === '') continue;

      const foundDiagnosis = diagnosis.find(d => d.name === currentValue);
      // Check if the diagnosis exists and has a non-empty value
      if (!foundDiagnosis || !foundDiagnosis.value) {
        validInput = false;
        break;
      }
    }

    if (validInput) {
      // If the input is valid, update the service list state
      setServiceList(prevState => {
        const updatedList = [...prevState];
        updatedList[index] = {
          ...updatedList[index],
          diagnosisPointer: inputValues
        };
        return updatedList;
      });
    }
  }
  else {
    setServiceList(prevList => {
      const updatedList = [...prevList];
      updatedList[index] = {
        ...updatedList[index],
        [name]: value
      };
      return updatedList;
    });
  }

};


export const btnRemoveService = (index, setAddServiceData, setOpenSnackbar, addServiceData) => {
  try {
    if (addServiceData.length > 1) {
      const newFields = addServiceData.filter((_, i) => i !== index);
      setAddServiceData(newFields);
    }
    else {
      setOpenSnackbar(true);
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "btnRemoveService", e.message, e);
    // console.error(e, "error");
  }
};

export const btnAddNewService = (setAddServiceData, addServiceData) => {
  try {
    const defaultService = {
      fromDate: null,
      toDate: null,
      placeOfService: '',
      emg: '',
      cptHcpcs: null,
      modifier: '',
      diagnosisPointer: '',
      charges: '',
      daysOrUnits: '',
      epsdtFamilyPlan: '',
      idQual: '',
      renderingProviderId: ''
    };

    if (!Array.isArray(addServiceData) || addServiceData.length === 0) {
      setAddServiceData([defaultService]);
    } else {
      setAddServiceData([...addServiceData, defaultService]);
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "btnAddNewService", e.message, e);
  }

};

export const btnCurrentPayerReset = (setShowPreviousDiv, setCurrentPayerDetails, tempCurrentPayerDetails) => {
  try {
    setShowPreviousDiv(false);
    setCurrentPayerDetails(tempCurrentPayerDetails);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "btnCurrentPayerReset", e.message, e);
  }
}

export const btnCurrentPayerClear = (setShowPreviousDiv, setPreviousPayerDetails, setCurrentPayerDetails,
  previousPayerDetails, currentPayerDetails
) => {
  try {
    if (Object.keys(previousPayerDetails).every(key => previousPayerDetails[key] === "")) {
      setShowPreviousDiv(true);
      setPreviousPayerDetails({ ...currentPayerDetails });
    }
    setCurrentPayerDetails({ chPayerName: '', chPayerId: '', payerId: '', payerPriority: '', claimAction: '' });
    setShowPreviousDiv(false);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "btnCurrentPayerClear", e.message, e);
  }
}

export const handlePrimaryInsuranceChange = (e, key, setInsuredData, subscriberData, setSubscriberData) => {
  // try {
  //   const value = e?.target ? e.target.value : e;
  //   setInsuredData(prevState => ({
  //     ...prevState,
  //     [key]: value
  //   }));

  //   const insuranceName = `${insuredData?.lastName || ''}, ${insuredData?.firstName || ''} ${insuredData?.middleName || ''}`;
  //   const patientName = `${subscriberData?.lastName || ''}, ${subscriberData?.firstName || ''} ${subscriberData?.middleName || ''}`;
  //   if (insuranceName === patientName) {
  //     setInsuredData(prevState => ({
  //       ...prevState,
  //       relationshipToPatient: "self"
  //     }));
  //   } else {
  //     setInsuredData(prevState => ({
  //       ...prevState,
  //       relationshipToPatient: ""
  //     }));
  //   }
  // }
  try {
    let relationshipToPatient = "";
    const value = e?.target ? e.target.value : e;

    if (subscriberData[key] === value) {
      relationshipToPatient = "self";
    }

    setInsuredData(prevState => ({
      ...prevState,
      [key]: value
    }));
    setSubscriberData(prevState => ({
      ...prevState,
      relationshipToPatient: relationshipToPatient,
    }));

  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handlePrimaryInsuranceChange", e.message, e);
  }
};

export const handlePatientDataChange = (e, key, setSubscriberData, insuredData) => {
  try {
    let relationshipToPatient = "";
    const value = e?.target ? e.target.value : e;

    if (insuredData[key] === value) {
      relationshipToPatient = "self";
    }

    setSubscriberData(prevState => ({
      ...prevState,
      [key]: value,
      relationshipToPatient: relationshipToPatient,
    }));

  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handlePatientDataChange", e.message, e);
  }
};

export const handleClose = (setOpen) => {
  try {
    setOpen(false);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleClose", e.message, e);
  }
};

export const handleHoldPopupData = (setOpen, setisSubmitButtonClick) => {
  try {
    setOpen(true);
    setisSubmitButtonClick(5);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleHoldPopupData", e.message, e);
  }
};
export const handleReleasePopupData = (setOpen, setisSubmitButtonClick) => {
  try {
    setOpen(true);
    setisSubmitButtonClick(6);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleReleasePopupData", e.message, e);
  }
};

export const handleVoidPopupData = (setOpen, setisSubmitButtonClick) => {
  try {
    setOpen(true);
    setisSubmitButtonClick(0);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleVoidPopupData", e.message, e);
  }
};

export const handleCopyClaimData = (setOpen, setisSubmitButtonClick) => {
  try {
    setOpen(true);
    setisSubmitButtonClick(1);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleCopyClaimData", e.message, e);
  }
};

export const handleClearData = (setShowDiv, setPatientControlNumber, setProviderControlNumber, setOrbitClaimId,
  setisDataDisplaying
) => {
  try {
    setShowDiv(false);
    setPatientControlNumber('');
    setProviderControlNumber('');
    setOrbitClaimId('');
    setisDataDisplaying(false);
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleClearData", e.message, e);
    // console.error(e, "error");
  }
};

export const handlePopupCallButton = async (setOpen, handleSaveAndSubmit, isSubmitButtonClick) => {
  try {
    setOpen(false);
    if (isSubmitButtonClick == 5) {
      handleSaveAndSubmit(5); //hold
    }
    else if (isSubmitButtonClick == 0) {
      handleSaveAndSubmit(4); //void
    }
    else if (isSubmitButtonClick == 1) {
      handleSaveAndSubmit(3); //copy claim
    }
    else if (isSubmitButtonClick == 6) {
      handleSaveAndSubmit(6); //Release
    }
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handlePopupCallButton", e.message, e);
    // console.error(e, "error");
  }

};

export const handleStateChanges = async (event, state, key, setState) => {
  try {
    const value = event?.target ? event.target.value : event;
    let finalValue = value;

    if (key === "address") {
      let name = event.target.name;
      let address = state['address'];
      address[name] = value;

      finalValue = address;
    }
    else if (key === "contactInformation") {
      let name = event.target.name;
      let contactInformation = state['contactInformation'];
      contactInformation[name] = value;

      finalValue = contactInformation;
    }
    setState(prevState => ({
      ...prevState,
      [key]: finalValue
    }));
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleStateChanges", e.message, e);
    // console.error(e, "error");
  }
};

export const handleBillingAddressChanges = (event, state, key, setState) => {
  try {
    const value = event.target.value;
    const addressParts = value.split(' ').filter(part => part.trim() !== '');

    // You need to decide how to split the address parts based on your use case
    setState(prevState => ({
      ...prevState,
      address: {
        address1: addressParts[0] || '',
        address2: addressParts[1] || '',
        city: addressParts[2] || '',
        state: addressParts[3] || '',
        postalCode: addressParts[4] || ''
      }
    }));
  }
  catch (e) {
    logTelemetry("Failed", "CMS1500", "handleBillingAddressChanges", e.message, e);
    // console.error(e, "error");
  }
};
