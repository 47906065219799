import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetDocAiList, UpdateConfigure } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function updateConfigure(value) {
  try {
    let { data } = await instance.post(UpdateConfigure, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getDocAiList(value) {
  try {
    let { data } = await instance.post(GetDocAiList, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function UpdateConfigureApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(updateConfigure, {});
}

export function GetDocAiListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getDocAiList, {});
}
