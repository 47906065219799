import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDocumentSummaryAI } from "./Urls";
import { getDocumentSummaryAIList } from "./Urls";
import { useNavigate } from "react-router-dom";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getpieChart(value) {
  try {
    let { data } = await instance.post(getDocumentSummaryAI, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getPieChartDetails(value) {
  try {
    let { data } = await instance.post(getDocumentSummaryAI, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}
async function getCallDocumentSummaryAIListmaryAI(value) {
  try {
    let { data } = await instance.post(getDocumentSummaryAIList, value);
    if (data.status.code == 200) {
      // console.log(data);
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function GetpieChart() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getpieChart);
}

export function GetPieChartDetails() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getPieChartDetails);
}

export function GetCallDocumentSummaryAIListmaryAI() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getCallDocumentSummaryAIListmaryAI);
}
