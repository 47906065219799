import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QrCodeUrl } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { string } from "yup";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function updateQRGenerator(value) {
  try {
    let { data } = await instance.post(QrCodeUrl, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      // toast.error("Session expied")
      sessionMessage();
      window.location.href = "/login";
    } else {
      // toast.error(`${data.status.message}`);
      return data;
    }
  } catch (error) {
    // toast.error(error.message);
    return error;
  }
}

export function UpdateQRGenerator() {
  let qClient = useQueryClient();
  // qClient.invalidateQueries("");
  return useMutation(updateQRGenerator, {});
}
