import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { GetLocationList } from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function getLocationListApi(value) {
  try {
    let { data } = await instance.post(GetLocationList, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      // toast.error("Session expied")
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function GetLocationListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getLocationListApi, {});
}
