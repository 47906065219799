import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import SearchIcon from "@mui/icons-material/Search";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import { setActiveComponent } from "../../redux/actions";
import dayjs from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MyContext from "../../components/MyContext";
import { useContext } from "react";
import { CallClaimStatus } from "../../apis/edi837claim";
import { SaveEdi837Claim } from "../../apis/edi837claim";
import { GetPayerNamesList } from "../../apis/edi837claim";
import Notification from "../../components/Notification";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { logTelemetry } from "../../logTelemetry";
import { date } from "yup";
import { useSelector,useDispatch } from "react-redux";
import { GetEdi837Claim } from "../../apis/edi837claim";
import {
  setClaimStatusData,
  } from "../../redux/actions/patientDetails";

const textFieldStyles = {
  width: "100%",
  margin: "12px",
};

const textFieldStylesddl = {
  width: "100%",
};

const ClaimStatusDetails = ({ dataFromTSL, isFromList, setDataFromTSL }) => {
  // console.log("dataFromTSL", dataFromTSL);
  //// debugger;
  const location = useLocation();
  const providerRef = useRef(null);
  const navigate = useNavigate();
  const providerClaimId = useRef(null);
  const patientControlRef = useRef(null);
  const [isDataObject, setisDataObject] = useState("");
  const dispatch = useDispatch();
  // debugger;

  const intialVals = {
    providerControlNumber: false,
    orbitClaimsPayerId: false,
    providerNpi: false,
    providerName: false,
    payerName: false,
    payerId: false,
    patientFirstName: false,
    patientLastName: false,
    patientGender: false,
    memberId: false,
    patientDob: false,
    dos: false,
    memberId: false,
    patientGender: false,
    dFirstName: false,
    dLastName: false,
    dDob: false,
    dGender: false,
  };

  const [isChanged, setIsChanged] = useState(intialVals);

  const [isFocusedFields, setIsFocusedFields] = useState({
    providerControlNumber: true,
    orbitClaimsPayerId: true,
    providerNpi: true,
    providerName: true,
    payerName: true,
    payerId: true,
    patientFirstName: true,
    patientLastName: true,
    patientGender: true,
    memberId: true,
    patientDob: true,
    dos: true,
    dFirstName: true,
    dLastName: true,
    dDob: true,
    dGender: true,
  });

  const IntilialFormData = {
    // providerControlNumber: {
    orbitAssignedClaimId: "",
    patientControlNumber: "",
    providerControlNumber: "",
    orbitClaimsPayerId: "",
    payerId: "",
    payerName: "",
    patientFirstName: "",
    patientLastName: "",
    patientDob: "",
    dos: null,
    claimAmount: "",
    renderingProvider: "",
    providerNpi: "",
    providerName: "",
    memberId: "",
    patientGender: "",
    dFirstName: "",
    dLastName: "",
    dDob: "",
    dGender: "",
    checked: false,
    isDataDisplaying: false,
    // },
  };

  const [formData, setFormData] = useState(IntilialFormData);
  const [open, setOpen] = React.useState(false);
  const [listOne, setListOne] = useState([]);
  const [listTwo, setListTwo] = useState([]);
  const [listThree, setListThree] = useState([]);
  var setisFocusOut = false;
  let [isSeacrhCall, setisSeacrhCall] = useState(0);
  let ClaimStatusDetails = useSelector((state) => state.ClaimStatusDetails);

  const handleClickOpen = () => {
    setOpen(true);
  };
  //// debugger;

  const handleProviderInputChange = (e) => {
    const { value } = e.target;
    setproviderControlnumber(value);
    // setFieldValue("mobileNumber", formatPhoneNumber(newValue));
  };

  useEffect(() => {
    // Assign initial value to the input using its ref
    if (providerRef.current == null || ClaimStatusDetails !=null) {
      if (ClaimStatusDetails != null) {
        let providerControlNumbersdata = ClaimStatusDetails;

        providerRef.current.value =providerControlNumbersdata?.providercontrolnumber || "";
        providerClaimId.current.value =  providerControlNumbersdata?.orbitclaimspayerid || "";
        patientControlRef.current.value =  providerControlNumbersdata?.patientControlNumber || "";
var patDOB=providerControlNumbersdata?.patientDob;
if(providerControlNumbersdata?.patientDob == "" || providerControlNumbersdata?.patientDob == null || providerControlNumbersdata?.patientDob == undefined)
{
  patDOB=providerControlNumbersdata?.patientdob
}


        let formDataValues = {
          orbitAssignedClaimId:
            providerControlNumbersdata?.orbitAssignedClaimId || "",
          patientControlNumber:
            providerControlNumbersdata?.patientControlNumber || "",
          providerControlNumber:
            providerControlNumbersdata?.providercontrolnumber || "",
          orbitClaimsPayerId:
            providerControlNumbersdata?.orbitclaimspayerid || "",
          payerId: providerControlNumbersdata?.cpid || "",
          payerName: providerControlNumbersdata?.payername || "",
          patientFirstName: providerControlNumbersdata?.patientFirstName || "",
          patientLastName: providerControlNumbersdata?.patientLastName || "",
          patientDob:
          patDOB == ""
              ? "null"
              : moment(patDOB).format(
                  "MM/DD/YYYY"
                ),
          patientGender:
            providerControlNumbersdata?.patientGender == ""
              ? ""
              : providerControlNumbersdata?.patientGender == "M"
              ? "Male"
              : providerControlNumbersdata?.patientGender == "F"
              ? "Female"
              : "",
          dos:
            providerControlNumbersdata?.dos == ""
              ? moment(new date()).format("MM/DD/YYYY")
              : moment(providerControlNumbersdata?.dos).format("MM/DD/YYYY"),
          claimAmount: providerControlNumbersdata?.claimAmount || "",
          providerName:
            providerControlNumbersdata?.renderingprovider?.trim() || "",
          providerNpi: providerControlNumbersdata?.renderingproviderNPI || "",
          memberId: providerControlNumbersdata?.memberId || "",
          dFirstName: "",
          dLastName: "",
          dDob: "",
          dGender: "",
        };
        setFormData(formDataValues);
      }
    }
    if (
      providerRef.current &&
      dataFromTSL?.providercontrolnumber?.providercontrolnumber != undefined
    ) {
      providerRef.current.value =
        dataFromTSL?.providercontrolnumber?.providercontrolnumber;
    }
    if (
      providerClaimId.current &&
      dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId != undefined
    ) {
      providerClaimId.current.value =
        dataFromTSL?.providercontrolnumber?.orbitAssignedClaimId;
    }

    if (
      dataFromTSL?.providercontrolnumber != null &&
      dataFromTSL?.providercontrolnumber != "" &&
      dataFromTSL?.providercontrolnumber != undefined
    ) {
      var patDOB=dataFromTSL.providercontrolnumber.patientdob;
      if(dataFromTSL.providercontrolnumber.patientdob == "" || dataFromTSL.providercontrolnumber.patientdob == null || dataFromTSL.providercontrolnumber.patientdob == undefined)
      {
        patDOB=dataFromTSL.providercontrolnumber.patientDob
      }

      let formDataValues = {
        orbitAssignedClaimId:
          dataFromTSL.providercontrolnumber.orbitAssignedClaimId || "",
        patientControlNumber:
          dataFromTSL.providercontrolnumber.patientControlnumber || "",
        providerControlNumber:
          dataFromTSL.providercontrolnumber.providercontrolnumber || "",
        orbitClaimsPayerId:
          dataFromTSL.providercontrolnumber.orbitclaimspayerid || "",
        payerId: dataFromTSL.providercontrolnumber.cpid || "",
        payerName: dataFromTSL.providercontrolnumber.payername || "",
        patientFirstName:
          dataFromTSL.providercontrolnumber.patientFirstName || "",
        patientLastName:
          dataFromTSL.providercontrolnumber.patientLastName || "",
        patientDob:
        patDOB == "" && isFromList
            ? "null"
            : moment(patDOB).format(
                "MM/DD/YYYY"
              ),
        patientGender:
          dataFromTSL.providercontrolnumber.patientGender == "" && isFromList
            ? ""
            : dataFromTSL.providercontrolnumber.patientGender == "M"
            ? "Male"
            : dataFromTSL.providercontrolnumber.patientGender == "F"
            ? "Female"
            : "",
        dos:
          dataFromTSL.providercontrolnumber.dos == "" && isFromList
            ? "null"
            : dataFromTSL.providercontrolnumber.dos,
        claimAmount: dataFromTSL.providercontrolnumber.claimAmount || "",
        providerName:
          dataFromTSL.providercontrolnumber.renderingprovider?.trim() || "",
        providerNpi:
          dataFromTSL.providercontrolnumber.renderingproviderNPI || "",
        memberId: dataFromTSL.providercontrolnumber.memberId || "",
        dFirstName: "",
        dLastName: "",
        dDob: "",
        dGender: "",
      };

      if (dataFromTSL && dataFromTSL.providercontrolnumber) {
        setFormData(formDataValues);
      }
      if (isFromList) {
        handleButtonClick(formDataValues);
      }
    }
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const handleClose = () => {
    setOpen(false);
  };
  let [PayerName, setPayerName] = useState("");
  let [PayerId, setPayerId] = useState("");

  // const { details?.companyId, setdetails?.companyId } = useContext(MyContext);
  const { data, mutateAsync, isLoading, isError } = CallClaimStatus();
  const {
    mutateAsync: mutateClaimTypeAsync,
    data: PayerClaimData,
    isLoading: claimLoading,
  } = GetEdi837Claim();

  const {
    data: saveData,
    mutateAsync: mutateServiceTypeAsync,
    isLoading: proceedLoading,
  } = SaveEdi837Claim();
  const {
    data: PayerData,
    mutateAsync: mutatePayerTypeAsync,
    isLoading: payerLoading,
  } = GetPayerNamesList();

  const [emailError, setemailError] = useState(false);
  const [orbitAssignedClaimID, setorbitAssignedClaimID] = useState("");
  const [emailTestData, setemailTestData] = useState(false);
  const [isDataDisplaying, setisDataDisplaying] = useState(false);

  const [mobileNumberError, setmobileNumberError] = useState(false);
  const [providerControlnumber, setproviderControlnumber] =
    useState("227378367");
  const [procedureModifiersvalue, setprocedureModifiersvalue] = useState("");
  const [procPiagnosisCodePointersvalue, setprocPiagnosisCodePointersvalue] =
    useState("");

  const [billyEntries, setbillyEntries] = useState({});
  const [dummp, setDummp] = useState({});

  const objClaimInformationList = [];

  const [claimNote, setclaimNote] = useState([]);
  const [healthCareCodeInformation, sethealthCareCodeInformation] = useState(
    []
  );
  const [serviceLines, setserviceLines] = useState([]);
  const [ambulanceDropOffLocation, setambulanceDropOffLocation] = useState([]);
  const [
    spinalManipulationServiceInformation,
    setspinalManipulationServiceInformation,
  ] = useState([]);
  const [serviceFacilityLocation, setserviceFacilityLocation] = useState([]);
  const [ambulanceCertification, setambulanceCertification] = useState([]);
  const [
    patientConditionInformationVision,
    setpatientConditionInformationVision,
  ] = useState([]);
  const [claimSupplementalInformation, setclaimSupplementalInformation] =
    useState([]);
  const [ambulanceTransportInformation, setambulanceTransportInformation] =
    useState([]);
  const [ambulancePickUpLocation, setambulancePickUpLocation] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // console.log(formData, "formData");

  const breadcrumbItems = [
    // { label: "Patient List", link: "/patient-list" },
    { label: "Claims Status", link: "/patient-list/claim-Status" },
  ];

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  let companyId =
    details?.companyId != undefined ? details?.companyId : details.CompanyId;

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        var datasucess = data?.status?.message.replace(
          "at Eligibility Captureapi request failed. Msg : Bad Request",
          ""
        );
        try {
          var objectdata = JSON.parse(datasucess);
          var myJSON1 = JSON.stringify(objectdata, undefined, 4);
          setisDataObject(myJSON1);
        } catch (ex) {
          logTelemetry(
            "Failed",
            "ClaimStatusDetails",
            "useEffect",
            `${ex?.message}`,
            ex
          );
          setisDataObject(datasucess);
        }
        setisDataDisplaying(true);
      }
      if (data?.status && data?.status?.code == 200) {
        var myJSON1 = JSON.stringify(data?.data, undefined, 4);
        setisDataObject(myJSON1);
        setisDataDisplaying(true);
      }
    }

    if (data) {
      if (data?.response && data?.response?.status != 200) {
        setisDataObject(data?.response?.message);
        setisDataDisplaying(true);
        logTelemetry(
          "Failed",
          "ClaimStatusDetails",
          "useEffect-342",
          `${data?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (saveData) {
      if (saveData?.status && saveData?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "ClaimStatusDetails",
          "useEffect-360",
          `${saveData?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${saveData?.status?.message}`,
          type: "error",
        });
      }
      if (saveData?.status && saveData?.status?.code == 200) {
        setNotify({
          isOpen: true,
          message: `${saveData?.status?.message}`,
          type: "success",
        });
      }
    }

    if (saveData) {
      if (saveData?.response && saveData?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "ClaimStatusDetails",
          "useEffect",
          `${saveData?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${saveData?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [saveData]);

  useEffect(() => {
    if (PayerClaimData) {
      if (PayerClaimData?.status && PayerClaimData?.status?.code != 200) {
        setNotify({
          isOpen: true,
          message: `${PayerClaimData?.status?.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummaryList",
          "getTransationSummaryList",
          `${PayerClaimData?.status?.message}`
        );
      }
      if (PayerClaimData?.status && PayerClaimData?.status?.code == 200) {

        if (PayerClaimData?.data?.edi837ListObject?.length > 0) {
          var chkindex = 1;
          if (
            providerClaimId.current.value != "" &&
            providerClaimId.current.value != undefined
          ) {
            var checkValue = providerClaimId.current.value;
            var index = PayerClaimData?.data?.edi837ListObject
              .map(function (o) {
                return o.orbitAssignedClaimID?.toLowerCase().trim();
              })
              .indexOf(checkValue.toLowerCase().trim());
            if (index != -1) {
              chkindex = index + 1;
            }
          }
          const billyEntries = JSON.parse(PayerClaimData?.data?.edi837ListObject[chkindex-1]?.inputJson);
          const headerEntities = PayerClaimData?.data?.edi837ListObject[chkindex-1];
            let formDataValues = {
              orbitAssignedClaimId:
              headerEntities?.orbitAssignedClaimId || "",
              patientControlNumber:
              headerEntities?.patientControlNumber || "",
              providerControlNumber:
              headerEntities?.providerControlNumber || "",
              orbitClaimsPayerId:
              headerEntities?.orbitclaimspayerid || "",
              payerId: headerEntities?.cpid || "",
              payerName: headerEntities?.payerName || "",
              patientFirstName:billyEntries.Professional?.subscriber?.firstName,
              patientLastName: billyEntries.Professional?.subscriber?.lastName,
              patientDob:
              billyEntries.Professional?.subscriber?.dateOfBirth == ""
                  ? "null"
                  : moment(billyEntries.Professional?.subscriber?.dateOfBirth).format(
                      "MM/DD/YYYY"
                    ),
              patientGender:
              billyEntries.Professional?.subscriber?.gender == ""
                  ? ""
                  : billyEntries.Professional?.subscriber?.gender == "M"
                  ? "Male"
                  : billyEntries.Professional?.subscriber?.gender == "F"
                  ? "Female"
                  : "",
              dos:
              headerEntities?.dateOfService == ""
                  ? moment(new date()).format("MM/DD/YYYY")
                  : moment(headerEntities?.dateOfService).format("MM/DD/YYYY"),
              claimAmount: billyEntries?.claimAmount || "",
              providerName:billyEntries.Professional?.rendering?.lastName,
              providerNpi: billyEntries.Professional?.rendering?.npi,
              memberId:billyEntries.Professional?.subscriber?.memberId,
              dFirstName: "",
              dLastName: "",
              dDob: "",
              dGender: "",
            };
           
            setFormData(formDataValues);
          }else {
            setNotify({
              isOpen: true,
              message: "There is no data",
              type: "error",
            });
          }
        } else {
          setNotify({
            isOpen: true,
            message: "There is no data",
            type: "error",
          });
        }
      }

    if (PayerClaimData) {
      if (PayerClaimData?.response && PayerClaimData?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${PayerClaimData?.response?.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummaryList",
          "getTransationSummaryList",
          `${PayerClaimData?.response?.message}`
        );
      }
    }
  }, [PayerClaimData]);

  useEffect(() => {
    if (PayerData) {
      setisFocusOut = false;
      if (PayerData?.status && PayerData?.status?.code != 200) {
        logTelemetry(
          "Failed",
          "ClaimStatusDetails",
          "useEffect-401",
          `${PayerData?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${PayerData?.status?.message}`,
          type: "error",
        });
      }
      if (
        PayerData?.status &&
        PayerData?.status?.code == 200 &&
        PayerData?.data?.length > 0
      ) {
        if (isSeacrhCall == 0) {
          setListOne(PayerData?.data);
        } else {
          setListTwo(PayerData?.data);
        }
      }
    }

    if (PayerData) {
      if (PayerData?.resultStatus && PayerData?.response?.status != 200) {
        logTelemetry(
          "Failed",
          "ClaimStatusDetails",
          "useEffect-428",
          `${PayerData?.response?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${PayerData?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [PayerData]);
  const handleSearchpayerData = async (selval) => {
    //// debugger;
    let valuess = {
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        auditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      },
      inputJson: selval == 1 ? formData?.payerId : formData?.payerName,
      requestName: selval == 1 ? "payerId" : "name",
    };
    try {
      await mutatePayerTypeAsync(valuess);
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimStatusDetails",
        "handleSearchPayerData",
        `${e?.message}`,
        e
      );
    }
  };

  const handleClaimPayerData = async (selval) => {
    //// debugger;
    try {
      const providerClaimIdValue = providerClaimId.current.value;
      const providerRefValue = providerRef.current.value;
      const patientRefValue = patientControlRef.current.value;
      if (!providerClaimIdValue && !providerRefValue && !patientRefValue) {
        setNotify({
          isOpen: true,
          message: "Enter Provider Control Number or Claim ID",
          type: "error",
        });
        return false;
      }

      await mutateClaimTypeAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.coCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
          processStatus: "",
          deliveryStatus: "",
        },
        providerControlNumber: providerRef.current.value,
        orbitAssignedClaimID: providerClaimId.current.value,
        patientControlNumber: patientControlRef?.current?.value,
      });
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimStatusDetails",
        "handleSearchPayerData",
        `${e?.message}`,
        e
      );
    }
  };

  let isProviderControlOrOrbitClaimsPayerIdPopulated;

  const handleInitiSubmit = async (handleInitiSubmitObj) => {
    try {
      await mutateAsync({
        controlNumber:
          handleInitiSubmitObj?.providercontrolnumber?.providerControlNumber,
        tradingPartnerServiceId:
          handleInitiSubmitObj?.providercontrolnumber?.payerId,
        providers: [
          {
            firstName: "",
            lastName: handleInitiSubmitObj?.providercontrolnumber?.providerName,
            taxId: "",
            npi: handleInitiSubmitObj?.providercontrolnumber?.providerNpi,
            providerType: "BillingProvider",
          },
        ],
        subscriber: {
          memberId: handleInitiSubmitObj?.providercontrolnumber?.memberId,
          firstName:
            handleInitiSubmitObj?.providercontrolnumber?.patientFirstName,
          lastName:
            handleInitiSubmitObj?.providercontrolnumber?.patientLastName,
          gender: handleInitiSubmitObj?.providercontrolnumber?.patientGender,
          dateOfBirth:
            handleInitiSubmitObj?.providercontrolnumber?.patientDob == ""
              ? null
              : moment(
                  handleInitiSubmitObj?.providercontrolnumber?.patientDob
                ).format("YYYYMMDD"),
        },
        encounter: {
          beginningDateOfService:
            handleInitiSubmitObj?.providercontrolnumber?.dos == ""
              ? moment(new Date()).format("YYYYMMDD")
              : moment(handleInitiSubmitObj?.providercontrolnumber?.dos).format(
                  "YYYYMMDD"
                ),
          endDateOfService:
            handleInitiSubmitObj?.providercontrolnumber?.dos == ""
              ? moment(new Date()).format("YYYYMMDD")
              : moment(handleInitiSubmitObj?.providercontrolnumber?.dos).format(
                  "YYYYMMDD"
                ),
          submittedAmount: null,
          trackingNumber: null,
        },
      });
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimStatusDetails",
        "handleSubmit",
        `${e?.message}`,
        e
      );
    }
  };

  const handleSubmit = async (formData) => {
    // console.log("formData111", formData);
    const fieldsToSkip = [
      "renderingProvider",
      "patientControlNumber",
      "claimAmount",
      "orbitAssignedClaimId",
      "providerControlNumber",
      "orbitClaimsPayerId",
      "payerName",
    ];

    if (!checked) {
      fieldsToSkip.push("dFirstName", "dLastName", "dDob", "dGender");
    }

    try {
      formData.patientDob = formData?.patientDob;
    } catch (ex) {}

    const isAnyFieldEmptyOrNull = Object.entries(formData)
      .filter(([key]) => !fieldsToSkip.includes(key))
      .some(([, value]) => value === "" || value === null || value === "null");

    // console.log("ggg1", isAnyFieldEmptyOrNull);
    isProviderControlOrOrbitClaimsPayerIdPopulated =formData.providerControlNumber !== "";
      // formData.providerControlNumber !== "" ||
      // formData.orbitClaimsPayerId !== "";


    // console.log(
    //   isProviderControlOrOrbitClaimsPayerIdPopulated,
    //   "isProviderControlOrOrbitClaimsPayerIdPopulated"
    // );
    // const isPatientDobOrDosPopulated = formData.patientDob !== "" || formData.dos !== "";
    if (isProviderControlOrOrbitClaimsPayerIdPopulated) {
      if (isAnyFieldEmptyOrNull) {
        setNotify({
          isOpen: true,
          message: "All Fields are mandatory",
          type: "error",
        });
        // Display an alert if any field is false
        return; // Exit the function if data is not valid
      }
    } else {
      setNotify({
        isOpen: true,
        message: "All Fields are mandatory",
        type: "error",
      });
      // Display an alert if any field is false
      return; // Exit the function if data is not valid
    }
    var controlNumber= formData?.providerControlNumber;
    if(controlNumber==undefined|| controlNumber==null || controlNumber=="")
    {

      controlNumber=providerRef.current.value;
    }
    try {
      await mutateAsync({
        controlNumber:controlNumber,
        tradingPartnerServiceId: formData?.payerId,
        providers: [
          {
            firstName: "",
            lastName: formData?.providerName,
            taxId: "",
            npi: formData?.providerNpi,
            providerType: "BillingProvider",
          },
        ],
        subscriber: {
          memberId: formData?.memberId,
          firstName: formData?.patientFirstName,
          lastName: formData?.patientLastName,
          gender: formData?.patientGender,
          dateOfBirth: formData?.patientDob == "" ? null : formData?.patientDob,
          dateOfBirth: moment(
            new Date(formData?.patientDob == "" ? null : formData?.patientDob)
          ).format("YYYYMMDD"),
        },
        encounter: {
          beginningDateOfService:
            formData?.dos == ""
              ? moment(new Date()).format("YYYYMMDD")
              : moment(new Date(formData?.dos)).format("YYYYMMDD"),
          endDateOfService:
            dataFromTSL?.providercontrolnumber?.dos == ""
              ? moment(new Date()).format("YYYYMMDD")
              : moment(new Date(formData?.dos)).format("YYYYMMDD"),
          submittedAmount: null,
          trackingNumber: null,
        },
      });
    } catch (e) {
      logTelemetry(
        "Failed",
        "ClaimStatusDetails",
        "handleSubmit",
        `${e?.message}`,
        e
      );
    }
  };

  const handleButtonClick = (formData) => {
    // let formData = formData;
    // console.clear();
    // console.log(checked, " checked ");
    // return;
    // if (!checked) {
    formData.patientDob =
      formData.patientDob !== null && formData.patientDob !== ""
        ? formData.patientDob
        : "null";
    // }

    if (checked) {
      formData.dDob =
        formData.dDob !== null && formData.dDob !== "" ? formData.dDob : "null";
    }
    formData.dos =
      formData.dos !== null && formData.dos !== "" ? formData.dos : "null";
    // console.log(formData, "formData");
    const updatedIsChanged = { ...isChanged };
    //patientDob
    // Loop through formData keys and update isChanged for empty values
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (
          key === "renderingProvider" ||
          key === "patientControlNumber" ||
          key === "orbitClaimsPayerId" ||
          key === "claimAmount" ||
          key === "orbitAssignedClaimId"
        ) {
          continue;
        }
        if (!checked) {
          if (
            key === "dFirstName" ||
            key === "dLastName" ||
            key === "dDob" ||
            key === "dGender"
          ) {
            continue;
          }
        }
        updatedIsChanged[key] = true;
      }
    }

    // console.log("Updated isChanged:", updatedIsChanged);

    setIsChanged(updatedIsChanged);

    setTimeout(() => {
      handleSubmit(formData);
    }, 100);
  };

  const getBorderColor = (field) => {
    if (field == "payerName") {
      return isFocusedFields[field] || isChanged[field] ? "black" : "red";
    } else {
      // console.log("kkkkk", field, isChanged[field]);
      return isFocusedFields[field] || isChanged[field] ? "white" : "red";
    }
    // return isChanged[field] ? 'white' : 'red';
  };

  const [checked, setChecked] = React.useState(false);

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      const prevChanges = { ...isChanged };
      prevChanges.dFirstName = false;
      prevChanges.dGender = false;
      prevChanges.dLastName = false;
      prevChanges.dDob = false;
      setFormData((prev) => ({
        ...prev,
        dDob: "",
      }));
      setIsChanged(prevChanges);
    }
  };

    const handleClearSaveData = () => {
      providerClaimId.current.value = "";
      patientControlRef.current.value = "";
      providerRef.current.value = "";
    providerClaimId.current.focus();
    onReset();
  }

  const onReset = () => {
    dispatch(setClaimStatusData(null));
    setListOne([]);
    setListTwo([]);
    setListThree([]);
    setChecked(false);
    setisDataDisplaying(false);
    setFormData(IntilialFormData);
    setIsChanged(IntilialFormData);
    if (setDataFromTSL) setDataFromTSL({});
  };

  const handleChange1 = (field, value) => {
    if (value == null) value = "";
    if (value != null && value.length == "") {
      setIsChanged({
        ...isChanged,
        [field]: true,
      });
    } else {
      setIsChanged({
        ...isChanged,
        [field]: false,
      });
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleFocusChange = (field, isFocused) => {
    setIsFocusedFields({
      ...isFocusedFields,
      [field]: isFocused,
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Grid container className="my-2 mt-3">
          {!location?.pathname?.includes("transation-summary") && (
            <Grid item xs={8} sm={10} className="my-4 flex items-center">
              <Breadcrumb items={breadcrumbItems} isRedux={true} />
            </Grid>
          )}
        </Grid>
        {/* neel added new block */}
        <Card className="mb-3">
          <CardContent>
            <Grid
              container
              sx={{
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <Grid container xs={12} sm={12}>
                <Grid item xs={3} sm={3} className="flex justify-center">
                  <TextField
                    autoFocus
                    id="Patient Control Number"
                    label="Patient Control Number"
                    name="Patient Control Number"
                    autoComplete="off"
                    inputRef={patientControlRef}
                    className="m-0"
                    size="small"
                    sx={{ background: "white" }}
                    style={textFieldStyles}
                  />
                </Grid>
                <span
                  style={{
                    marginTop: "18px",
                  }}
                >
                  (or)
                </span>
                <Grid item xs={3} sm={3} className="flex justify-center">
                  <TextField
                    id="Provider Control Number"
                    label="Provider Control Number"
                    name="Provider Control Number"
                    autoComplete="off"
                    inputRef={providerRef}
                    className="m-0"
                    size="small"
                    sx={{ background: "white" }}
                    style={textFieldStyles}
                  />
                </Grid>
                <span
                  style={{
                    marginTop: "18px",
                  }}
                >
                  (or)
                </span>
                <Grid item xs={4} sm={4} className="flex justify-center">
                  <TextField
                    autoFocus
                    id="outlined-password-input2"
                    label="Orbit Claim Id"
                    name="Orbit Claim Id"
                    autoComplete="off"
                    inputRef={providerClaimId}
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={textFieldStyles}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={12}>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  className="pt-4"
                  style={{ paddingLeft: "10px" }}
                >
                  <Button
                    text="Search"
                    classname={"button-styles"}
                    filled={true}
                    onclick={() => handleClaimPayerData()}
                  />
                </Grid>

                {/* {isDataDisplaying &&
                  PayerReadOnly == "0" &&
                  CopyClaimFlag == "0" && (
                    <>
                      <Grid item xs={12} sm={1} className="pt-4 pl-1">
                        <Button
                          text="Save"
                          classname={"button-styles"}
                          filled={true}
                          onclick={handleSaveData}
                        />
                      </Grid>

                      {headerEntities?.onHold == 1 &&
                      headerEntities?.processFlag == 10 ? (
                        <></>
                      ) : (
                        <Grid xs={12} sm={1} className="pt-4 pl-1">
                          <Button
                            text="Submit"
                            classname={"button-styles"}
                            filled={true}
                            onclick={() => handleSubmitData()}
                          />
                        </Grid>
                      )}
                    </>
                  )} */}

                <Grid item xs={12} sm={1} className="pt-4 pl-1">
                  <Button
                    width="200px"
                    text="Clear"
                    classname={"button-styles"}
                    filled={true}
                    onclick={handleClearSaveData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end */}
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="mb-3"
            >
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                  Payer Details
                </Typography>
              </Grid>
              <Grid
                item
                alignItems={"center"}
                justifyContent={"space-between"}
                xs={12}
                sm={3}
                md={8}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item xs={12} sm={3} md={4}>
                  <Button
                    text="Verify Claim Status"
                    // navigate="/capture/capture-driving-license/patient-info"
                    classname={"button-styles"}
                    filled={true}
                    onclick={() => handleButtonClick(formData)}
                  />
                </Grid>
                &nbsp;
                {/* <Grid item xs={12} sm={3} md={2}>
                  <Button
                    text="Clear"
                    // navigate="/capture/capture-driving-license/patient-info"
                    classname={"button-styles"}
                    filled={true}
                    onclick={() => onReset()}
                  />
                </Grid> */}
              </Grid>
            </Grid>

            <Divider />
            <Grid container className="mb-6">
              <Grid
                gap={1.5}
                columns={16}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {/* <Grid item xs={3.8} sm={3.8}>
                  <label
                    id="outlined-password-input1"
                    label="Provider Control Number"
                    name="Provider Control Number"
                    autoComplete="off"
                    value={formData?.providerControlNumber}
                    onChange={(e) =>
                      handleChange1("providerControlNumber", e.target.value)
                    }
                    className="m-0"
                    size="small"
                    error={
                      formData?.providerControlNumber ||
                      formData?.orbitClaimsPayerId
                        ? false
                        : isChanged?.providerControlNumber
                    }
                    sx={{ background: "white" }}
                    style={{ ...textFieldStyles }}
                  />
                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                <label
                    id="outlined-password-input23"
                    label="Orbit Claim Id"
                    name="Orbit Claim Id"
                    autoComplete="off"
                    value={formData.orbitClaimsPayerId}
                    onChange={(e) =>
                      handleChange1("orbitClaimsPayerId", e.target.value)
                    }
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={{ ...textFieldStyles }}
                  />
                </Grid> */}
                {/* <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    id="outlined-password-input23"
                    label="Orbit Claim Id"
                    name="Orbit Claim Id"
                    autoComplete="off"
                    value={formData.orbitClaimsPayerId}
                    onChange={(e) =>
                      handleChange1("orbitClaimsPayerId", e.target.value)
                    }
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    style={{ ...textFieldStyles }}
                  />
                </Grid> */}
                <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    id="outlined-password-input2"
                    label="Provider NPI"
                    name="Provider NPI"
                    autoComplete="off"
                    value={formData.providerNpi}
                    onChange={(e) =>
                      handleChange1("providerNpi", e.target.value)
                    }
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    error={isChanged?.providerNpi && !formData.providerNpi}
                    style={{ ...textFieldStyles }}
                  />
                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    id="outlined-password-input3"
                    label="Provider Name"
                    name="Provider Name"
                    autoComplete="off"
                    value={formData?.providerName}
                    onChange={(e) =>
                      handleChange1("providerName", e.target.value)
                    }
                    className="m-0"
                    sx={{ background: "white" }}
                    size="small"
                    error={isChanged?.providerName && !formData?.providerName}
                    style={{ ...textFieldStyles }}
                  />
                </Grid>
                <Grid item xs={3.8} sm={3.8} sx={{ ml: 1.5 }}>
                  <Paper
                    size="small"
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    style={{ height: "40px", boxShadow: "none" }}
                  >
                    <InputBase
                      // error={isChanged?.payerName && !formData.payerName} // Set error prop based on isChanged.providerNpi
                      // onFocus={() => handleFocusChange('payerName', true)}
                      // onBlur={() => handleFocusChange('payerName', false)}
                      sx={{ ml: 1, flex: 1, mt: 4 }}
                      size="small"
                      name="Search by payer name"
                      id="Search by payer name id"
                      placeholder="Search by payer name"
                      value={formData?.payerName}
                      onBlur={(e) => {
                        setTimeout(() => {
                          if (setisFocusOut == false) {
                            setListOne([]);
                          }
                        }, 1000);
                      }}
                      onChange={(e) => {
                        formData.payerId = "";
                        handleChange1("payerName", e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setisSeacrhCall(0);
                          handleSearchpayerData(2);
                          event.preventDefault();
                        }
                      }}
                      style={{ ...textFieldStyles }}
                    />
                    <Divider
                      sx={{ height: 10, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      className="search-icon"
                      onClick={() => {
                        setisFocusOut = true;
                        setisSeacrhCall(0);
                        handleSearchpayerData(2);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {listOne?.length > 0 && isSeacrhCall == 0 && (
                    <Paper className="menuPopupData">
                      <MenuList dense>
                        {listOne?.map((k, index) => {
                          return (
                            <MenuItem key={index}>
                              <ListItemText
                                onClick={() => {
                                  // debugger;
                                  setFormData({
                                    ...formData,
                                    payerName: k.chPayerName,
                                  });
                                  setFormData({
                                    ...formData,
                                    payerId: k.chPayerId,
                                    payerName: k.chPayerName,
                                  });
                                  setListOne([]);
                                }}
                                inset
                              >
                                {k.displayName}
                              </ListItemText>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Paper>
                  )}
                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <Paper
                    size="small"
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    style={{ height: "40px", boxShadow: "none" }}
                    className={
                      isChanged?.payerId && !formData.payerId ? "red" : ""
                    }
                  >
                    <InputBase
                      // error={isChanged?.payerName && !formData.payerName} // Set error prop based on isChanged.providerNpi
                      // onFocus={() => handleFocusChange('payerName', true)}
                      // onBlur={() => handleFocusChange('payerName', false)}
                      sx={{ ml: 1, flex: 1, mt: 4 }}
                      size="small"
                      placeholder="CP ID"
                      name="CP ID"
                      id="CP ID"
                      value={formData?.payerId}
                      onBlur={(e) => {
                        setTimeout(() => {
                          if (setisFocusOut == false) {
                            setListTwo([]);
                          }
                        }, 1000);
                      }}
                      error={isChanged?.payerId && !formData.payerId}
                      style={{ ...textFieldStyles }}
                      onChange={(e) => {
                        formData.payerName = "";
                        handleChange1("payerId", e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setisSeacrhCall(1);
                          handleSearchpayerData(1);
                          event.preventDefault();
                        }
                      }}
                    />
                    <Divider
                      sx={{ height: 10, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      className="search-icon"
                      onClick={() => {
                        setisFocusOut = true;
                        setisSeacrhCall(1);
                        handleSearchpayerData(1);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {listTwo?.length > 0 && isSeacrhCall == 1 && (
                    <Paper className="menuPopupData">
                      <MenuList dense>
                        {listTwo?.map((k, index) => {
                          return (
                            <MenuItem key={index}>
                              <ListItemText
                                onClick={() => {
                                  // debugger;
                                  setFormData({
                                    ...formData,
                                    payerName: k.chPayerName,
                                  });
                                  setFormData({
                                    ...formData,
                                    payerId: k.chPayerId,
                                    payerName: k.chPayerName,
                                  });
                                  setListTwo([]);
                                }}
                                inset
                              >
                                {k.displayName}
                              </ListItemText>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Paper>
                  )}
                  {/* <TextField
                // onFocus={() => handleFocusChange('payerId', true)}
                // onBlur={() => handleFocusChange('payerId', false)}
                id="outlined-password-input4"
                label="CP ID"
                name="CP Id"
                value={formData?.payerId}
                onChange={(e) => handleChange1("payerId", e.target.value)}
                className="m-0"
           
                style={{ ...textFieldStyles }}
                size="small"
              /> */}
                </Grid>
              </Grid>

              <Grid
                gap={1.5}
                columns={16}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
               <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    id="outlined-password-input1"
                    label="Provider Control Number"
                    name="Provider Control Number"
                    autoComplete="off"
                    value={formData?.providerControlNumber}
                    onChange={(e) =>
                      handleChange1("providerControlNumber", e.target.value)
                    }
                    className="m-0"
                    size="small"
                    error={
                      formData?.providerControlNumber 
                        ? false
                        : isChanged?.providerControlNumber
                    }
                    sx={{ background: "white" }}
                    style={{ ...textFieldStyles }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="mb-1"
            >
              <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                Patient Details
              </Typography>
            </Grid>

            <Divider />
            <Grid container className="mb-2">
              <Grid
                gap={1.5}
                columns={16}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    id="outlined-password-input5"
                    label="First Name"
                    name="First Name"
                    value={formData?.patientFirstName}
                    // payerId
                    onChange={(e) =>
                      handleChange1("patientFirstName", e.target.value)
                    }
                    className="m-0"
                    error={
                      isChanged?.patientFirstName && !formData.patientFirstName
                    }
                    style={{ ...textFieldStyles }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <TextField
                    // onFocus={() => handleFocusChange('patientLastName', true)}
                    // onBlur={() => handleFocusChange('patientLastName', false)}
                    id="outlined-password-input6"
                    label="Last Name"
                    name="Last Name"
                    // value={lastName}
                    // onChange={(e) => setLastName(e.target.value)}
                    value={formData?.patientLastName}
                    // payerId
                    onChange={(e) =>
                      handleChange1("patientLastName", e.target.value)
                    }
                    className="m-0"
                    error={
                      isChanged?.patientLastName && !formData.patientLastName
                    }
                    style={{ ...textFieldStyles }}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  sm={3.8}
                  sx={{ ml: 1.5 }}
                  className="displayGrid"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        error={true}
                        inputFormat="MM/dd/yyyy"
                        label="DOB (MM/DD/YYYY)"
                        value={
                          formData?.patientDob
                            ? dayjs(formData?.patientDob)
                            : null
                          // formData.patientDob
                          //   ? dayjs(formData?.patientDob)
                          //   : formData.patientDob == null
                          //   ? dayjs(formData?.patientDob)
                          //   : null
                        }
                        // value={dayjs(formData?.patientDob)}
                        onChange={(date) => handleChange1("patientDob", date)}
                        style={{ ...textFieldStyles }} // Replace 'unset' with a neutral color
                        size="small"
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3.8} sm={3.8}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-select-label" size="small">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      name="gender"
                      label="Gender"
                      value={formData?.patientGender ?? ""}
                      onChange={(e) =>
                        handleChange1("patientGender", e.target.value)
                      }
                      // onChange={(e) => setGender(e.target.value)}
                      size="small"
                      error={
                        isChanged?.patientGender && !formData?.patientGender
                      }
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  name="checkbox"
                  id="check box"
                  checked={checked}
                  onChange={handleChangeCheck}
                />
              }
              name="test name"
              id="test name"
              label="Patient Relationship To Insured"
              sx={{ fontWeight: "bold" }}
            />
            {checked && (
              <>
                <Divider />
                <Grid
                  gap={1.5}
                  columns={16}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="mb-6"
                >
                  <Grid item xs={3.8} sm={3.8}>
                    <TextField
                      id="outlined-password-input5"
                      label="First Name"
                      name="First Name"
                      value={formData?.dFirstName}
                      onChange={(e) =>
                        handleChange1("dFirstName", e.target.value)
                      }
                      className="m-0"
                      error={isChanged?.dFirstName && !formData.dFirstName}
                      style={{ ...textFieldStyles }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={3.8} sm={3.8}>
                    <TextField
                      id="outlined-password-input6"
                      label="Last Name"
                      name="Last Name"
                      // value={lastName}
                      // onChange={(e) => setLastName(e.target.value)}
                      value={formData?.dLastName}
                      // payerId
                      onChange={(e) =>
                        handleChange1("dLastName", e.target.value)
                      }
                      className="m-0"
                      error={isChanged?.dLastName && !formData.dLastName}
                      style={{ ...textFieldStyles }}
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3.8}
                    sm={3.8}
                    sx={{ ml: 1.5 }}
                    className="displayGrid"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          error={true}
                          name="date picker1"
                          id="date picker1"
                          inputFormat="MM/dd/yyyy"
                          label="DOB (MM/DD/YYYY)"
                          value={formData.dDob ? dayjs(formData?.dDob) : null}
                          // value={dayjs(formData?.patientDob)}
                          onChange={(date) => handleChange1("dDob", date)}
                          style={{ ...textFieldStyles }} // Replace 'unset' with a neutral color
                          size="small"
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3.8} sm={3.8}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label" size="small">
                        Gender
                      </InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="demo-simple-select"
                        label="Gender"
                        value={formData?.dGender}
                        onChange={(e) =>
                          handleChange1("dGender", e.target.value)
                        }
                        // onChange={(e) => setGender(e.target.value)}
                        size="small"
                        error={isChanged?.dGender && !formData?.dGender}
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="mb-1"
            >
              <Typography sx={{ fontWeight: "bold" }} gutterBottom>
                Insurance Details
              </Typography>
            </Grid>
            <Divider />

            <Grid
              gap={1.5}
              columns={16}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className="mb-6"
            >
              <Grid item xs={3.8} sm={3.8}>
                <TextField
                  // onFocus={() => handleFocusChange('memberId', true)}
                  // onBlur={() => handleFocusChange('memberId', false)}
                  id="outlined-password-input8"
                  label="Member Id"
                  name="Member Id"
                  value={formData?.memberId}
                  onChange={(e) => handleChange1("memberId", e.target.value)}
                  className="m-0"
                  error={isChanged?.memberId && !formData?.memberId}
                  style={{ ...textFieldStyles }}
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={3.8}
                sm={3.8}
                sx={{ ml: 1.5 }}
                className="displayGrid"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      inputFormat="MM/dd/yyyy"
                      label="DOS (MM/DD/YYYY)"
                      name="date picker"
                      id="date picker"
                      // value={dayjs(formData.dos)}
                      value={
                        formData?.dos ? dayjs(formData?.dos) : null
                        // formData.dos
                        //   ? dayjs(formData?.dos)
                        //   : formData.dos == null
                        //   ? dayjs(formData?.dos)
                        //   : null
                      }
                      style={{ ...textFieldStyles }} // Replace 'unset' with a neutral color
                      size="small"
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(date) => handleChange1("dos", date)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {isDataDisplaying && (
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%", pt: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div style={{ paddingTop: "20px" }}>
              <TextField
                xs={12}
                sm={12}
                id="outlined-claim-status"
                name="claimStatus"
                label="Claim Status"
                value={isDataObject}
                style={{ background: "aliceblue" }}
                multiline
                maxRows={15}
              />
            </div>
          </Box>
        )}
      </Container>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">Delete</DialogTitle>
        <DialogContent>test</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
            color="default"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || payerLoading || proceedLoading || claimLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ClaimStatusDetails;
