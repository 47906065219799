import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Badge,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Typography,
  Button as MuiButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  // GetDocumentListApi,
  GetLookUpsForDSApi,
  GetDocumentSummaryListApi,
  // GetExportElements,
} from "../../apis/documentSummary";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialogs from "../../components/DocSummaryDialog";
import dayjs from "dayjs";
// import { AgGridReact } from "ag-grid-react";
// import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
// import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import useCommonParams from "../../components/utils/useCommonParams";
import { DocumentContext } from "../../components/utils/DocumentContextProvider";
import { logTelemetry } from "../../logTelemetry";
import CollapsibleTable from "./CollapsibleTable";
import { useSelector } from "react-redux";
// import { list } from "./list";

const DocumentSummary = () => {
  const commonParams = useCommonParams();
  // const appInsights = useAppInsightsContext();

  const filterFormInitialData = {
    statusDate: {
      label: "Create Date",
      value: "CDT",
    },
    for: "1",
    daysOrMonths: "days",
    fromDate: dayjs().subtract(1, "day"),
    toDate: dayjs(),
    location: {
      companyName: "All",
      companyId: "0",
    },
    status: {
      description: "All",
      code: "0",
    },
    subStatus: {
      description: "All",
      type: "0",
    },
    assignedTo: {
      description: "All",
      code: "0",
    },
    process: {
      id: 0,
      description: "All",
    },
    subProcess: {
      description: "All",
      code: "1000",
    },
  };
  function getList(arr) {
    const lstColumns = [
      {
        colName: "0-5",
        colDesc: "Bin1",
        isShow: true,
        width: 0,
      },
      {
        colName: "6-10",
        colDesc: "Bin2",
        isShow: true,
        width: 0,
      },
      {
        colName: "10+",
        colDesc: "BinN",
        isShow: true,
        width: 0,
      },
    ];
    let finalRes = arr.length > 0 ? arr : lstColumns;
    finalRes.unshift({
      colName: "Sum",
      isShow: true,
    });
    finalRes.unshift({
      colName: "Visit Status",
      isShow: true,
    });
    return finalRes.filter((item) => item.isShow);
  }
  const [formData, setFormData] = useState(filterFormInitialData);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [list, setList] = useState([]);
  const [finalRes, setFinalRes] = useState(getList([]));
  const gridRef = useRef(null);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );
  const navigate = useNavigate();
  const { mutateAsync } = GetDocumentSummaryListApi();
  const { mutateAsync: lookUpsApi } = GetLookUpsForDSApi();
  // const { mutateAsync: GetExportElementsApi } = GetExportElements();
  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  // const { details?.companyId } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [lookUpData, setLookUpData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [rows, setRows] = useState([]);
  // const [filtersApplied, setFiltersApplied] = useState(false);
  const [subStatusData, setSubStatusData] = useState([]);
  const [open, setOpen] = useState(false);
  let [filters, setFilters] = useState({});
  const agree = () => {
    setOpen(false);
    // setFilters({});
  };
  const disagree = () => {
    setOpen(false);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const handleInputChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    if (name === "for") {
      let fromDate;
      if (formData?.daysOrMonths === "days") {
        fromDate = formData?.toDate.subtract(parseInt(value, 10), "day");
      } else {
        fromDate = formData?.toDate.subtract(parseInt(value, 10), "month");
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        fromDate,
      }));
    } else if (name == "Batch Number") {
      setFormData((prevData) => ({
        ...prevData,
        batchNumber: value,
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      if (name === "daysOrMonths") {
        let fromDate;
        if (value === "days") {
          fromDate = formData?.toDate.subtract(
            parseInt(formData?.for, 10),
            "day"
          );
        } else {
          fromDate = formData?.toDate.subtract(
            parseInt(formData?.for, 10),
            "month"
          );
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
          fromDate,
        }));
      }
    }
  };
  const handleDateChange = (value, name) => {
    // const { name, value } = e.target;
    if (name === "fromDate") {
      setFormData((prevData) => ({ ...prevData, [name]: value, for: "" }));
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleAutocompleteChange = (name, value) => {
    if (name === "status") {
      const res = lookUpData?.subStatusList?.filter(
        (item) => item.code === value.code
      );
      res.unshift({
        description: "All",
        key: "0",
      });
      setSubStatusData(res);
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    lookUpsApiCall();
    getDocumentList(formData);
  }, []);

  async function getDocumentList(param) {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "91596",
        fromDate:
          (param.fromDate && dayjs(param.fromDate).format("MM/DD/YYYY")) ??
          "01/01/2023",
        toDate:
          (param.toDate && dayjs(param.toDate).format("MM/DD/YYYY")) ??
          "07/01/2024",
        companyId: param?.location?.companyId ?? "0",
        assignId: param?.assignedTo?.code ?? "0",
        status: param?.status?.description ?? "All",
        state: "assignedTo",
        // process:"",
        // FilterTyp:"",
        // status:"",
        process: `${param?.process?.id}` ?? "70159",
        filterTyp: "CD",
        subStatus: param?.subStatus?.description ?? "All",
        payerData: "0",
        subProcess: param?.subProcess?.description ?? "All",
        //above new payload
        //old
        // moduleId: "0",
        // SectionId: "0",
        // dateFilter: param?.statusDate?.value ?? "CDT",
        // // displayFlag: "0", // always
        // // searchValue: param?.searchVal || "",
        // dataSrc: param?.dataSource?.description ?? "0",
        // batchNumber: param?.batchNumber || "",
      };
      const res = await mutateAsync(payload);
      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        let data = res?.data;
        setList(data?.byCountMatrix?.list);
        setFinalRes(getList(data?.byCountMatrix?.lstColumns));
        // const rows = data?.map((item, index) => ({
        //   ...item,
        //   index: index + 1,
        // }));
        // setRows(rows);
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "DocumentSummary",
          "getDocumentList",
          `${res?.status?.message}`
        );
        setLoading(false);
        setList([]);
        setFinalRes(getList([]));
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      logTelemetry(
        "Failed",
        "DocumentSummary",
        "getDocumentList",
        e.message,
        e
      );
      console.error(e, "error in catch");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getDocumentList: " + e.message,
        type: "error",
      });
    }
  }

  // console.log(finalRes);
  //lstColumns here

  function handleClearValues() {
    setFiltersApplied(false);
    setFormData(filterFormInitialData);
    setOpen(false);
    getDocumentList(filterFormInitialData);
  }
  // list

  async function lookUpsApiCall() {
    setLoading(true);
    try {
      // console.clear();
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "0",
        RecordId: "0",
      };
      const res = await lookUpsApi(payload);
      // console.log(res, " Result ");
      if (res?.status?.code === 200) {
        // setNotify({
        //   isOpen: true,
        //   message: `${res?.status?.message}`,
        //   type: "success",
        // });
        res?.data?.statusList?.length > 0
          ? res?.data?.statusList?.unshift({
              description: "All",
              code: "0",
            })
          : [];
        setLookUpData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
        logTelemetry(
          "failed",
          "DocumentSummary",
          "lookUpsApiCall",
          `${res?.status?.message}`
        );
      }
    } catch (e) {
      logTelemetry("Failed", "DocumentSummary", "lookUpsApiCall", e.message, e);
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "lookUpsApiCall " + e.message,
        type: "error",
      });
    }
  }

  const btnFilterClick = () => {
    setOpen(true);
  };

  function handleSubmit() {
    // console.log(formData);
    getDocumentList(formData);
    setFiltersApplied(true);
    setOpen(false);
  }

  function btnRefreshClick() {
    getDocumentList(filterFormInitialData);
    setFormData(filterFormInitialData);
    setFiltersApplied(false);
  }

  function btnExportXlClick() {
    if (gridApi) {
      gridApi.exportDataAsExcel({
        fileName: "Document Verification List",
      });
    } else {
      console.error("Grid API not available");
    }
  }

  function btnExportCsvClick() {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "Document Verification List",
      });
    } else {
      console.error("Grid reference is not available");
    }
  }

  const downloadCsv = (data) => {
    const convertToCSV = (objArray) => {
      const array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
      let str = "";

      // Get the headers
      const headers = Object.keys(array[0]);
      str += headers.join(",") + "\r\n";

      // Get the rows
      array.forEach((item) => {
        let line = "";
        headers.forEach((header, index) => {
          if (index > 0) line += ",";
          line += item[header];
        });
        str += line + "\r\n";
      });

      return str;
    };

    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Export List.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const btnExportListCsvClick = async () => {
    const docIds = gridRef.current?.api
      ?.getRenderedNodes()
      .map((node) => node.data.documentId)
      .join("^");
    // Define the payload for the API call
    const payload = {
      commonParams,
      ConfigId: "0",
      ModuleId: "0",
      SectionId: "0",
      RecordId: docIds,
      RequestType: "1", // Assuming different RequestType for CSV export
    };

    setLoading(true);

    try {
      let res = await GetExportElementsApi(payload);

      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });
        downloadCsv(res?.data?.searchValues); // Assuming downloadCsv function exists
        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "DocumentSummary",
          "btnExportListCsvClick",
          `${res?.status?.message}`
        );
        setLoading(false);
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "warning",
        });
      }
    } catch (e) {
      console.error(e, "error");
      logTelemetry(
        "Failed",
        "DocumentSummary",
        "exportListCsvClick",
        e.message,
        e
      );
      setLoading(false);
      setNotify({
        isOpen: true,
        message: e.message,
        type: "error",
      });
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item={true} xs={12} sm={12}>
          <Grid container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              className="!my-3 flex justify-between"
            >
              <Typography variant="h6">Document Summary</Typography>
              <Box>
                <Tooltip title="Apply Filter" className="!mx-3">
                  <MuiButton
                    className={"filterButton"}
                    onClick={() => btnFilterClick()}
                  >
                    {filtersApplied ? (
                      <Badge variant="dot" color="error">
                        <FilterAltIcon />
                      </Badge>
                    ) : (
                      <FilterAltIcon />
                    )}
                  </MuiButton>
                </Tooltip>
                <Tooltip title="Refresh">
                  <MuiButton
                    className={"filterButton"}
                    onClick={() => btnRefreshClick()}
                  >
                    <RefreshIcon />
                  </MuiButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div>
        <CollapsibleTable
          list={list.length > 0 ? list : []}
          header={finalRes}
        />
      </div>

      {open ? (
        <Dialogs
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          // setIsFilters={setIsFilters}
          setOpen={setOpen}
          lookUpData={lookUpData}
          formData={formData}
          setFormData={setFormData}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          subStatusData={subStatusData}
          handleClearValues={handleClearValues}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default DocumentSummary;
