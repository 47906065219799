//#region mui Material
import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Container,
  Button,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Chip,
  Pagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { MobileDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
//#endregion mui Material

//#region  Custom handler
import handlePhoneNumberKeyDown from "../../components/utils/phoneNumberFormat";
import PaginationDropdown from "./PaginationDrownDrop";
import {
  GetEdi837Claim,
  GetPayerNamesList,
  GetICD,
  GetServices,
  GetServiceModifier,
} from "../../apis/edi837claim";

import { SaveEdi837Claim } from "../../apis/edi837claim";
import Notification from "../../components/Notification";
import {
  AntDesignFormLabel,
  contentStyle,
  ErrorContainer,
  customDropdownStyle,
  inputCursorBlock,
} from "./handlerCMSStyle";
import MoneyInput from "../../components/MoneyInput";
import "../../assets/style/cms1500.css";
import {
  handlePageChange,
  btnAddContactInfo,
  btnRemoveContactInfo,
  handleContactInputChange,
  handleCloseSnackbar,
  formatDateCMS,
  handleCurrentPayerInputChange,
  handleServiceInputChange,
  btnRemoveService,
  btnAddNewService,
  btnCurrentPayerReset,
  btnCurrentPayerClear,
  formatDate,
  handleClose,
  handleHoldPopupData,
  handleVoidPopupData,
  handlePopupCallButton,
  handleClearData,
  handleCopyClaimData,
  handleStateChanges,
  handleBillingAddressChanges,
  validateContactInformation,
  handlePatientDataChange,
  handlePrimaryInsuranceChange,
  handleReleasePopupData,
} from "././handlers/cmsMasterHandler";
import { logTelemetry } from "../../logTelemetry";
import ConfirmDialog from "./deleteConfirmation";
import { useSelector } from "react-redux";
//#endregion Custom Handler

const theme = createTheme();

const CMSMaster = () => {

  // const loginInitialDetails = useSelector((state)=>JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null; 
  }, [loginDetails]);

  //#region API Call
  const {
    data: saveData,
    mutateAsync: mutateServiceTypeAsync,
    isLoading: proceedLoading,
  } = SaveEdi837Claim();
  const { data, mutateAsync, isLoading, isError } = GetEdi837Claim();
  const {
    data: icdData,
    mutateAsync: mutateDynosisService,
    isLoading: dynosisLoading,
  } = GetICD();
  const {
    data: serviceCPTData,
    mutateAsync: mutateServiceCPTData,
    isLoading: serviceCPTLoading,
  } = GetServices();
  const {
    data: serviceModifierData,
    mutateAsync: mutateServiceModifierData,
    isLoading: serviceModifierLoading,
  } = GetServiceModifier();
  const {
    data: tempPayerList,
    mutateAsync: mutatePayerTypeAsync,
    isLoading: payerLoading,
  } = GetPayerNamesList();
  //#endregion API Call

  //#region React State
  const [isSubmitClick, setisSubmitClick] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [patientControlNumber, setPatientControlNumber] = useState("");
  const [providerControlNumber, setProviderControlNumber] = useState("");
  const [orbitClaimId, setOrbitClaimId] = useState("");
  const [isDataDisplaying, setisDataDisplaying] = useState(false);
  const [loadingg, setLoadingg] = useState(false);
  const [claimData, setClaimData] = useState([]);
  const [formData, setFormData] = useState({});
  const [payerData, setPayerData] = useState({});
  const [claimActionData, setClaimActionData] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const [fullAddressData, setFullAddressData] = useState("");
  const [payerList, setPayerList] = useState([]);
  const [showPreviousDiv, setShowPreviousDiv] = useState(false);
  const [showIfEditableDiv, setShowIfEditableDiv] = useState(false);
  const [selectedInputJsonData, setSelectedInputJsonData] = useState({});
  const [selectedPage, setSelectedPage] = useState();
  const [open, setOpen] = React.useState(false);
  let [isSubmitButtonClick, setisSubmitButtonClick] = useState(0);
  const [dependentData, setDependentData] = useState(null);
  const [diagnosisInput, setDiagnosisInput] = useState("");

  const [currentPayerDetails, setCurrentPayerDetails] = useState({
    chPayerName: "",
    chPayerId: "",
    payerId: "",
    payerPriority: "",
    claimAction: "",
  });
  const [tempCurrentPayerDetails, setTempCurrentPayerDetails] = useState({
    chPayerName: "",
    chPayerId: "",
    payerId: "",
    payerPriority: "",
    claimAction: "",
  });
  const [previousPayerDetails, setPreviousPayerDetails] = useState({
    chPayerName: "",
    chPayerId: "",
    payerId: "",
    payerPriority: "",
    claimAction: "",
  });

  const [patientData, setPatientData] = useState({
    memberId: null,
    ssn: null,
    paymentResponsibilityLevelCode: null,
    organizationName: null,
    insuranceTypeCode: null,
    subscriberGroupName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    fullName: null,
    suffix: null,
    gender: null,
    dateOfBirth: null,
    policyNumber: null,
    groupNumber: null,
    contactInformation: null,
    relationshipToPatient: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  });

  const [patientRelatedTo, setPatientRelatedTo] = useState({
    employment: "",
    autoAccident: "",
    otherAccident: "",
    state: "",
    claimCodes: "",
  });

  const [recordsCountState, setRecordsCountState] = React.useState({
    totalRecordCount: 0,
    totalPageCount: 1,
  });

  const [subscriberData, setSubscriberData] = useState({
    memberId: null,
    ssn: null,
    paymentResponsibilityLevelCode: null,
    organizationName: null,
    insuranceTypeCode: null,
    subscriberGroupName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    fullName: null,
    suffix: null,
    gender: null,
    dateOfBirth: null,
    policyNumber: null,
    groupNumber: null,
    contactInformation: null,
    relationshipToPatient: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  });

  const [submitterData, setSubmitterData] = useState({
    organizationName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    fullName: null,
    contactInformation: [
      {
        name: "",
        phoneNumber: "",
        phoneExtension: "",
        faxNumber: "",
        email: "",
      },
    ],
  });
  const [claimInformation, setClaimInformation] = useState({
    claimFilingCode: null,
    healthCareCodeInformation: [
      {
        diagnosisTypeCode: "",
        diagnosisCode: "",
      },
    ],
  });

  const [billingData, setBillingData] = useState({
    npi: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      countryCode: null,
      countrySubDivisionCode: null,
    },
    contactInformation: {
      phoneNumber: null,
    },
  });

  const [receiverData, setReceiverData] = useState({
    organizationName: null,
  });

  const [ICNData, setICNData] = useState({
    claimControlNumber: null,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [insuredData, setInsuredData] = useState({
    memberId: null,
    ssn: null,
    paymentResponsibilityLevelCode: null,
    organizationName: null,
    insuranceTypeCode: null,
    subscriberGroupName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    fullName: null,
    suffix: null,
    gender: null,
    dateOfBirth: null,
    policyNumber: null,
    groupNumber: null,
    contactInformation: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  });

  const [otherInsuredData, setOtherInsuredData] = useState({
    memberId: null,
    ssn: null,
    paymentResponsibilityLevelCode: null,
    organizationName: null,
    insuranceTypeCode: null,
    subscriberGroupName: null,
    firstName: null,
    lastName: null,
    middleName: null,
    fullName: null,
    suffix: null,
    gender: null,
    dateOfBirth: null,
    policyNumber: null,
    groupNumber: null,
    contactInformation: null,
    address: {
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  });

  const [insuranceTypeData, setInsuranceTypeData] = useState("");
  const [insuredSignature, setInsuredSignature] = useState("");
  const [patientSignature, setPatientSignature] = useState({
    signed: "",
    signedDate: "",
  });
  const [dateOfIllness, setDateOfIllness] = useState({
    date: "",
    qual: "",
  });
  const [otherDate, setOtherDate] = useState({
    date: "",
    qual: "",
  });

  const [patientUnavailableDate, setPatientUnavailableDate] = useState({
    fromDate: "",
    toDate: "",
  });

  const [referringData, setReferringData] = useState({
    providerType: null,
    npi: null,
    ssn: null,
    employerId: null,
    commercialNumber: null,
    locationNumber: null,
    payerIdentificationNumber: null,
    employerIdentificationNumber: null,
    claimOfficeNumber: null,
    naic: null,
    stateLicenseNumber: null,
    providerUpinNumber: null,
    taxonomyCode: null,
    firstName: null,
    lastName: null,
    middleName: null,
    suffix: null,
    organizationName: null,
    address: null,
    contactInformation: null,
  });

  const [hospitalizationDate, setHospitalizationDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [resubmissionCode, setResubmissionCode] = useState({
    code: "",
    originalRefNo: "",
  });
  const [outsideLab, setoutsideLab] = useState({
    outsideLab: "",
    charges: "",
  });

  const [additionalClaim, setAdditionalClaim] = useState("");
  const [priorAuthorizationNumber, setPriorAuthorizationNumber] = useState("");
  const [acceptAssignmentData, setAcceptAssignmentData] = useState("");
  const [federalTaxInformation, setFederalTaxInformation] = useState({
    federalTaxId: "",
    federalTaxOption: "",
  });

  const [totalCharges, setTotalCharges] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [physicianSignature, setPhysicianSignature] = useState({
    description: "",
    signed: "",
    signedDate: "",
  });
  const [serviceFacilityLocation, setServiceFacilityLocation] = useState({
    description: "",
    npi: "",
    b32: "",
  });

  const [diagnosisList, setDiagnosisList] = useState([]);
  const [serviceCPTList, setServiceCPTList] = useState([]);
  const [serviceCPT, setServiceCPT] = useState([]);
  const [serviceModifierList, setServiceModifierList] = useState([]);
  const [serviceModifier, setServiceModifier] = useState([]);
  const [defaultPage, setDefaultPage] = useState();

  const [diagnosis, setDiagnosis] = useState([
    { name: "A", value: "", diagnosisCode: "" },
    { name: "B", value: "", diagnosisCode: "" },
    { name: "C", value: "", diagnosisCode: "" },
    { name: "D", value: "", diagnosisCode: "" },
    { name: "E", value: "", diagnosisCode: "" },
    { name: "F", value: "", diagnosisCode: "" },
    { name: "G", value: "", diagnosisCode: "" },
    { name: "H", value: "", diagnosisCode: "" },
    { name: "I", value: "", diagnosisCode: "" },
    { name: "J", value: "", diagnosisCode: "" },
    { name: "K", value: "", diagnosisCode: "" },
    { name: "L", value: "", diagnosisCode: "" },
  ]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [service, setService] = useState({
    assignedNumber: null,
    fromDate: null,
    toDate: null,
    placeOfService: "",
    emg: "",
    cptHcpcs: "",
    modifier: [],
    diagnosisPointer: "",
    charges: "",
    daysOrUnits: "",
    epsdtFamilyPlan: "",
    idQual: "",
    renderingProviderId: "",
    isCptValid: false,
  });

  const [serviceOriginal, setServiceOriginal] = useState({
    assignedNumber: null,
    serviceDate: null,
    serviceDateEnd: null,
    providerControlNumber: null,
    professionalService: {
      procedureIdentifier: null,
      procedureCode: null,
      procedureModifiers: [null],
      description: null,
      lineItemChargeAmount: null,
      measurementUnit: null,
      serviceUnitCount: null,
      placeOfServiceCode: null,
      compositeDiagnosisCodePointers: {
        diagnosisCodePointers: [null],
      },
      emergencyIndicator: null,
      epsdtIndicator: null,
      familyPlanningIndicator: null,
      copayStatusCode: null,
    },
    durableMedicalEquipmentService: null,
    serviceLineSupplementalInformation: null,
    durableMedicalEquipmentCertificateOfMedicalNecessity: null,
    ambulanceTransportInformation: null,
    durableMedicalEquipmentCertification: null,
    ambulanceCertification: null,
    hospiceEmployeeIndicator: null,
    conditionIndicatorDurableMedicalEquipment: null,
    serviceLineDateInformation: null,
    ambulancePatientCount: null,
    obstetricAnesthesiaAdditionalUnits: null,
    testResults: null,
    contractInformation: null,
    serviceLineReferenceInformation: null,
    salesTaxAmount: null,
    postageTaxAmount: null,
    fileInformation: null,
    additionalNotes: null,
    goalRehabOrDischargePlans: null,
    thirdPartyOrganizationNotes: null,
    purchasedServiceInformation: null,
    linePricingRepricingInformation: null,
    drugIdentification: null,
    renderingProvider: {
      providerType: "RenderingProvider",
      npi: null,
      ssn: null,
      employerId: null,
      commercialNumber: null,
      locationNumber: null,
      payerIdentificationNumber: null,
      employerIdentificationNumber: null,
      claimOfficeNumber: null,
      naic: null,
      stateLicenseNumber: null,
      providerUpinNumber: null,
      taxonomyCode: null,
      firstName: null,
      lastName: null,
      middleName: null,
      suffix: null,
      organizationName: null,
      address: null,
      contactInformation: null,
      otherIdentifier: null,
      secondaryIdentifier: null
    },
    purchasedServiceProvider: null,
    serviceFacilityLocation: null,
    supervisingProvider: null,
    orderingProvider: null,
    referringProvider: null,
    ambulancePickUpLocation: null,
    ambulanceDropOffLocation: null,
    lineAdjudicationInformation: null,
    formIdentification: null,
  });

  const [serviceList, setServiceList] = useState([]);
  const [serviceListOriginal, setServiceListOriginal] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteKey, setDeleteKey] = useState(null);
  const [focusStates, setFocusStates] = useState(
    Array(diagnosis.length).fill(false)
  ); // Array to track focus state for each textbox

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  //#endregion React State

  //#region useEffect
  useEffect(() => {
    if (saveData?.status && saveData?.status?.code == 200) {
      setLoadingg(false);
      if (saveData?.data?.edi837ListObject?.length > 0) {
        setClaimData(saveData.data.edi837ListObject);
      }
    }
  }, [saveData]);

  useEffect(() => {
    if (icdData?.status && icdData?.status?.code == 200) {
      setLoadingg(false);
      if (icdData?.data?.edi837ListObject?.length > 0) {
        setClaimData(icdData.data.GetICD);
      }
    }
  }, [icdData]);

  useEffect(() => {
    if (formData?.readOnlyFlag === 1) {
      setShowIfEditableDiv(false);
    } else {
      setShowIfEditableDiv(true);
    }
  }, [formData?.readOnlyFlag]);

  useEffect(() => {
    if (claimData.length > 0) {
      setCurrentPage(defaultPage);
      onPageDropDownChange(null, defaultPage);
    }
  }, [claimData, defaultPage]);

  useEffect(() => {
    if (
      tempPayerList &&
      tempPayerList.status &&
      tempPayerList.status.code == 200
    ) {
      setPayerList(tempPayerList.data ?? []);
    }
  }, [tempPayerList]);



  useEffect(() => {
    let patientFullname =
      patientData.firstName + patientData.middleName + patientData.lastName;
    let insuredFullname =
      insuredData.firstName + insuredData.middleName + insuredData.lastName;
    let patientRelationship = subscriberData.relationshipToPatient;

    if (patientFullname === insuredFullname) {
      patientRelationship = "self";
    } else {
      patientRelationship = subscriberData.relationshipToPatient;
    }
    setSubscriberData((prevState) => ({
      ...prevState,
      relationshipToPatient: patientRelationship,
    }));
  }, [patientData]);

  useEffect(() => {
    if (formData?.readOnlyFlag === "1") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [formData]);

  //#endregion UseEffect

  //#region Functionalities

  const handleFocus = (index) => {
    const newFocusStates = [...focusStates];
    newFocusStates[index] = true;
    setFocusStates(newFocusStates);
  };

  const handleBlur = (index) => {
    const newFocusStates = [...focusStates];
    newFocusStates[index] = false;
    setFocusStates(newFocusStates);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handlePatientControlNumberChange = (event) =>
    setPatientControlNumber(event.target.value);
  const handleProviderControlNumberChange = (event) =>
    setProviderControlNumber(event.target.value);
  const handleOrbitClaimIdChange = (event) =>
    setOrbitClaimId(event.target.value);

  const btnSearchClick = async () => {
    try {
      // let details = JSON.parse(sessionStorage?.getItem("details"));
      // let details = loginInitialDetails;
      if (!patientControlNumber && !providerControlNumber && !orbitClaimId) {
        setNotify({
          isOpen: true,
          message: "Enter Provider Control Number or Claim ID",
          type: "error",
        });
        return false;
      }
      const commonParams = {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.coCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      };
      const response = await mutateAsync({
        commonParams,
        providerControlNumber: providerControlNumber,
        orbitAssignedClaimID: orbitClaimId,
        patientControlNumber: patientControlNumber,
      });
      const records = response?.data?.edi837ListObject || [];

      const matchingIndex = records.findIndex(
        (record) => record.orbitAssignedClaimID === orbitClaimId
      );

      if (matchingIndex !== -1) {
        setDefaultPage(matchingIndex + 1); // Set the position number in state
      }

      if (records.length > 0) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
        setNotify({
          isOpen: true,
          message: "There is no data",
          type: "error",
        });
      }
      setClaimData(records);
      TotalRecordsCount(records);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "btnSearchClick", e.message, e);
      // console.error(e, "error");
    }
  };

  const TotalRecordsCount = (records) => {
    try {
      setRecordsCountState((prev) => ({
        ...prev,
        totalPageCount: records.length,
        totalRecordCount: records.length,
      }));
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "TotalRecordsCount", e.message, e);
      // console.error(e, "error");
    }
  };

  const handleDiagnosis = async (name) => {
    try {
      // let details =loginInitialDetails;
      let requestString = "";
      // if (diagnosis) {
      //   const item = diagnosis.find((item) => item.name === name);
      //   // requestString = item ? item.value : '';
      //   requestString = diagnosisInput;
      // }
      if (diagnosis) {
        const item = diagnosis.find((item) => item.name === name);
        if (item?.diagnosisCode && item.diagnosisCode !== "") {
          requestString = item.diagnosisCode;
        } else {
          requestString = diagnosisInput;
        }
      }
      const commonParams = {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.coCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      };
      const response = await mutateDynosisService({
        commonParams,
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "code",
        RequestString: requestString,
        RecordId: "0",
      });
      const filteredData = response.data.list;
      setDiagnosisList(filteredData);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleDiagnosis", e.message, e);
      // console.error(e, "error");
    }
  };

  const handleServiceCPTInfo = async (requestString) => {
    try {
      // let details =loginInitialDetails;
      const commonParams = {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.coCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      };

      const response = await mutateServiceCPTData({
        commonParams,
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "code",
        RequestString: requestString,
        RecordId: "0",
      });

      const filteredData = response.data.itemList.filter((item) =>
        item.item_Code.startsWith(requestString)
      );
      setServiceCPTList(filteredData);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleServiceCPTInfo", e.message, e);
      // console.error(e, "error");
    }
  };

  const handleServiceModifierInfo = async (requestString) => {
    try {
      // let details = loginInitialDetails;
      const commonParams = {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.coCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      };

      const response = await mutateServiceModifierData({
        commonParams,
        configId: "91596",
        moduleId: "0",
        SectionId: "0",
        RequestType: "code",
        RequestString: requestString,
        RecordId: "0",
      });
      const filteredData = response.data.list.filter((item) =>
        item.code.toLowerCase().startsWith(requestString.toLowerCase())
      );
      setServiceModifierList(filteredData);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleServiceCPTInfo", e.message, e);
    }
  };

  const onSelectedDiagnosis = (event, name, newValue, diagnosisCode) => {
    let newDiagnosisValue = "";
    let newDiagnosisCode = diagnosisCode;

    if (newValue && typeof newValue === "object") {
      // If newValue is an object, set the diagnosisCode and description
      newDiagnosisValue = newValue.description;
      newDiagnosisCode = newValue.code;
    } else if (diagnosisList && newValue) {
      // If newValue is a string, attempt to match against diagnosisList
      const matchedItem = diagnosisList.find(
        (item) => item.shortDescription === newValue
      );
      if (matchedItem) {
        newDiagnosisValue = matchedItem.shortDescription;
        newDiagnosisCode = matchedItem.diagnosisCode;
      }
    }

    const updatedDiagnosis = diagnosis.map((item) =>
      item.name === name
        ? { ...item, value: newDiagnosisValue, diagnosisCode: newDiagnosisCode }
        : item
    );

    setDiagnosis(updatedDiagnosis);
    setDiagnosisList([]);
  };

  const handleDiagnosisChange = (
    event,
    newValue,
    name,
    diagnosisCode,
    reason
  ) => {
    try {
      if (newValue === "") {
        let newDiagnosisValue = newValue;
        let newDiagnosisCode = "";
        let updatedDiagnosis = diagnosis.map((item) =>
          item.name === name
            ? {
              ...item,
              value: newDiagnosisValue,
              diagnosisCode: newDiagnosisCode,
            }
            : item
        );

        // Adjust the shifting logic to handle multiple empty values
        for (let i = 0; i < updatedDiagnosis.length - 1; i++) {
          if (updatedDiagnosis[i].value === "") {
            // Find the next non-empty value to shift up
            let nextNonEmptyIndex = i + 1;
            while (
              nextNonEmptyIndex < updatedDiagnosis.length &&
              updatedDiagnosis[nextNonEmptyIndex].value === ""
            ) {
              nextNonEmptyIndex++;
            }

            // If a non-empty value is found, shift it up
            if (nextNonEmptyIndex < updatedDiagnosis.length) {
              updatedDiagnosis[i].value =
                updatedDiagnosis[nextNonEmptyIndex].value;
              updatedDiagnosis[i].diagnosisCode =
                updatedDiagnosis[nextNonEmptyIndex].diagnosisCode;

              // Clear the moved value from its original position
              updatedDiagnosis[nextNonEmptyIndex].value = "";
              updatedDiagnosis[nextNonEmptyIndex].diagnosisCode = "";
            }
          }
        }

        // Updating the serviceList to remove the cleared diagnosis (e.g., removing 'B' if it's cleared)
        const updatedServiceList = serviceList.map((service) => {
          let pointerArray = [];
          if (
            typeof service.diagnosisPointer === "string" &&
            service.diagnosisPointer.trim() !== ""
          ) {
            pointerArray = service.diagnosisPointer
              .split(",")
              .map((val) => val.trim());
          } else if (typeof service.diagnosisPointer === "object") {
            pointerArray = service.diagnosisPointer;
          }

          // Filter out the diagnosis to be removed
          pointerArray = pointerArray.filter(
            (val) => val.toUpperCase() !== name.toUpperCase()
          );

          // Join the remaining diagnoses back into a string
          const updatedPointer = pointerArray.join(", ");

          // Return the updated service with the modified diagnosisPointer
          return { ...service, diagnosisPointer: updatedPointer };
        });

        setServiceList(updatedServiceList);
        setDiagnosis(updatedDiagnosis);
      } else {
        let updatedDiagnosis = diagnosis.map((item) =>
          item.name === name
            ? {
              ...item,
              diagnosisCode: newValue,
            }
            : item
        );

        setDiagnosis(updatedDiagnosis);
        setDiagnosisInput(newValue);
      }
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleDiagnosisChange", e.message, e);
    }
  };

  const handleServiceCPTChange = (index, event, newValue, setServiceCPT) => {
    try {
      if (newValue === "") {
        setServiceList((prevList) => {
          const updatedList = [...prevList];
          updatedList[index] = {
            ...updatedList[index],
            ["cptHcpcs"]: newValue,
            ["isCptValid"]: false,
          };
          return updatedList;
        });

        return;
      }

      let newServiceCPTValue = newValue;
      if (typeof newValue === "string") {
        const splitValue = newValue.split(" - ");
        newServiceCPTValue = splitValue[1]
          ? splitValue[1].trim()
          : newValue.trim();
      }

      if (serviceCPTList && newValue) {
        const matchedItem = serviceCPTList.find(
          (item) => item.item_Description === newValue.description
        );
        if (matchedItem) {
          newServiceCPTValue = matchedItem.item_Code;
        }
      }
      setServiceList((prevList) => {
        const updatedList = [...prevList];
        updatedList[index] = {
          ...updatedList[index],
          ["cptHcpcs"]: newServiceCPTValue,
          ["isCptValid"]: true,
        };
        return updatedList;
      });
      setServiceCPT((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          serviceCPT: newServiceCPTValue,
        };
        return newData;
      });

      setServiceCPTList("");
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleServiceCPTChange", e.message, e);
    }
  };

  const handleServiceModifierChange = (
    index,
    event,
    newValue,
    setServiceModifier
  ) => {
    try {
      if (newValue.length > 4) {
        newValue = newValue.slice(0, 4);
      }
      let newServiceModifierValues = newValue;
      if (serviceModifierList && newValue.length > 0) {
        newServiceModifierValues = newValue.map((value) => {
          if (typeof value === "string") {
            const matchedItem = serviceModifierList.find(
              (item) => item.description === value || item.code === value
            );
            return matchedItem ? matchedItem.code : value;
          } else {
            return value.code;
          }
        });
      }
      const updatedServiceList = [...serviceList];
      updatedServiceList[index].modifier = newServiceModifierValues;
      setServiceList(updatedServiceList);
      setServiceModifier((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          serviceModifier: newServiceModifierValues,
        };
        return newData;
      });
      setServiceModifierList("");
    } catch (e) {
      console.error("Error in handleServiceModifierChange:", e.message);
    }
  };

  const handleConfirmRemoveContactInfo = () => {
    btnRemoveContactInfo(
      index,
      setSubmitterData,
      setOpenSnackbar,
      submitterData,
      setNotify
    );
  };

  const handleSaveAndSubmit = async (setButtonStatus) => {
    try {
      const hasFilledDiagnosis = diagnosis.some(
        (item) => item.diagnosisCode && item.diagnosisCode.trim() !== ""
      );
      const { updatedServiceList, hasEmptyFields } =
        validateServiceRequiredFields(serviceList, serviceCPT);
      const { isValid, updatedContactInformation } = validateContactInformation(
        submitterData.contactInformation
      );
      if (setButtonStatus === 0 || setButtonStatus === 1) {
        if (!hasFilledDiagnosis) {
          setNotify({
            isOpen: true,
            message: "Please fill at least one diagnosis.",
            type: "error",
          });
          return; // Stop further execution if the condition is not met
        }
        if (hasEmptyFields) {
          setServiceList(updatedServiceList);
          setNotify({
            isOpen: true,
            message:
              "Please fill out the required fields: From Date, To Date, CPT, Diagnosis, and Charge.",
            type: "error",
          });
          return;
        }

        if (!isValid) {
          setSubmitterData((prevState) => ({
            ...prevState,
            contactInformation: updatedContactInformation,
          }));
          setNotify({
            isOpen: true,
            message:
              "Please fill out the required field in contact information: Name",
            type: "error",
          });
          return;
        }
      }
      // Only execute the following code if the first three conditions are not met
      const ClaimInputJsonData = await updatedClaimData();
      // const details = loginInitialDetails;

      if (ClaimInputJsonData.inputJson) {
        const requestPayload = {
          commonParams: {
            organizationID: details?.orgId,
            companyID: details?.companyId,
            coCode: details?.companyCode,
            groupID: details?.groupID,
            timeZone: details?.timeZone,
            sessionId: details?.sesionId,
            loginUserId: `${details?.userId}`,
            loginUserName: details?.userName,
            auditTrail: 0,
            editTrail: 0,
            dataSource: "",
            processStatus: "",
            deliveryStatus: "",
          },
          orbitAssignedClaimID: formData.orbitAssignedClaimID,
          ...(setButtonStatus === 4 || setButtonStatus === 1
            ? { searchClaimID: orbitClaimId }
            : {}),
          providerControlNumber,
          orbitAssignedClaimIDSrch: orbitClaimId,
          patientControlNumber,
          submitDocument: setButtonStatus,
          stgEDI837Id: formData.stgEDI837Id,
          inputJson: JSON.stringify(ClaimInputJsonData.inputJson),
        };

        setFormData(ClaimInputJsonData);
        setisSubmitClick(0);

        try {
          const response = await mutateServiceTypeAsync(requestPayload);
          const message =
            response?.status?.message || "Unexpected error occurred";

          if (response?.status?.code === 200) {
            setNotify({
              isOpen: true,
              message,
              type: "success",
            });
            const UpdatedClaimData = response?.data?.edi837ListObject;
            bindData(UpdatedClaimData, selectedPage);
            if (setButtonStatus === 3) {
              TotalRecordsCount(UpdatedClaimData);
            }

          } else {
            setNotify({
              isOpen: true,
              message,
              type: "error",
            });
          }
        } catch (ex) {
          // Optionally log error details here
        }
      }
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleSaveAndSubmit", e.message, e);
      // Optionally handle general errors here
    }
  };

  const parseInputJson = (selectedClaimData) => {
    try {
      if (typeof selectedClaimData?.inputJson === "string") {
        try {
          const inputJson = JSON.parse(selectedClaimData.inputJson);
          selectedClaimData.inputJson = inputJson;
        } catch (e) {
          selectedClaimData.inputJson = {};
        }
      }
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "parseInputJson", e.message, e);
      // console.error(e, "error");
    }
  };

  const updateSubscriberData = (professionalData) => {
    try {
      const subscriber = professionalData?.subscriber;
      if (subscriber) {
        subscriber.fullName = `${subscriber.firstName ?? ""} ${subscriber.lastName ?? ""
          } ${subscriber.middleName ?? ""}`.trim();
      } else {
        console.error("Subscriber is undefined or null.");
      }
      return subscriber;
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "updateSubscriberData", e.message, e);
      // console.error(e, "error");
    }
  };

  const updateSubmitterData = (professionalData) => {
    try {
      if (professionalData?.submitter) {
        const contactInfo = professionalData.submitter.contactInformation || [];
        return {
          ...professionalData.submitter,
          contactInformation:
            contactInfo.length > 0
              ? contactInfo.map((info) => ({
                name: info.name ?? "",
                phoneNumber: info.phoneNumber ?? "",
                phoneExtension: info.phoneExtension ?? "",
                faxNumber: info.faxNumber ?? "",
                email: info.email ?? "",
                emailError: info.emailError ?? false,
              }))
              : [
                {
                  name: "",
                  phoneNumber: "",
                  phoneExtension: "",
                  faxNumber: "",
                  email: "",
                  emailError: false,
                },
              ],
        };
      } else {
        console.error("professionalData.submitter is undefined or null.");
        return {
          contactInformation: [
            {
              name: "",
              phoneNumber: "",
              phoneExtension: "",
              faxNumber: "",
              email: "",
              emailError: false,
            },
          ],
        };
      }
    } catch (e) {
      console.error(e, "error");
    }
  };

  const updateState = (
    selectedClaimData,
    professionalData,
    subscriber,
    dependentData
  ) => {
    try {
      setFormData({
        ...selectedClaimData,
        dateOfService: formatDate(selectedClaimData.dateOfService),
      });
      setPayerData(professionalData);
      setClaimInformation(professionalData.claimInformation);
      setSubmitterData(updateSubmitterData(professionalData));
      setReceiverData(professionalData?.receiver);
      // Service Lines
      setServiceListOriginal(professionalData?.claimInformation?.serviceLines);
      updateServiceLineState(professionalData?.claimInformation?.serviceLines);

      setBillingData(professionalData?.billing);
      setICNData(
        professionalData?.claimInformation?.claimSupplementalInformation
      );
      setSelectedInputJsonData(selectedClaimData?.inputJson);
      if (professionalData?.referring) {
        setReferringData(professionalData.referring);
      }
      setDependentData(professionalData?.dependent);

      // Insurance State Update
      setInsuredData(subscriber);
      if (dependentData == null || dependentData.length === 0) {
        const newObject = structuredClone(subscriber);
        setPatientData(newObject);
        subscriber.relationshipToPatient = "self";
      } else if (dependentData.length === 1) {
        setPatientData(dependentData[0]);
      } else if (dependentData.length > 1) {
        setPatientData(dependentData[1]);
      }

      if (subscriber) {
        if (!subscriber.hasOwnProperty("relationshipToPatient")) {
          subscriber.relationshipToPatient = null;
        }
        const newObject = structuredClone(subscriber);
        setSubscriberData(newObject);
      }

      setisDataDisplaying(selectedClaimData ? claimData.length > 0 : false);

      // Claim Action Data
      let tempClaimData =
        selectedClaimData?.claimType === "Original Claim"
          ? 1
          : professionalData?.claimInformation?.claimFilingCode;
      if (tempClaimData != 1 && tempClaimData != 7 && tempClaimData != 8) {
        tempClaimData = "";
      }
      setClaimActionData(tempClaimData);

      // Address
      const address1 = subscriberData?.address?.address1 || "";
      const address2 = subscriberData?.address?.address2 || "";
      setFullAddressData(`${address1.trim()} ${address2.trim()}`.trim());

      // Current and Temp Payer Details
      setCurrentPayerDetails((prev) => ({
        ...prev,
        chPayerName: selectedClaimData?.payerName,
        chPayerId: selectedClaimData?.cpid,
        payerId: professionalData?.tradingPartnerServiceId,
        payerPriority: subscriber?.paymentResponsibilityLevelCode,
      }));
      setTempCurrentPayerDetails((prev) => ({
        ...prev,
        chPayerName: selectedClaimData?.payerName,
        chPayerId: selectedClaimData?.cpid,
        payerId: professionalData?.tradingPartnerServiceId,
        payerPriority: subscriber?.paymentResponsibilityLevelCode,
      }));

      // Additional Data
      if (professionalData) {
        setInsuranceTypeData(professionalData.insuranceType ?? null);
        setPatientRelatedTo(professionalData.patientRelatedTo ?? null);
        setPatientSignature(professionalData.patientSignature ?? null);
        setInsuredSignature(professionalData.insuredSignature ?? null);
        setDateOfIllness(professionalData.dateOfIllness ?? null);
        setAdditionalClaim(professionalData.additionalClaim ?? null);
        setOtherDate(professionalData.otherDate ?? null);
        setPatientUnavailableDate(professionalData.patientUnavailableDate ?? null);
        setHospitalizationDate(professionalData.hospitalizationDate ?? null);
        setResubmissionCode(professionalData.resubmissionCode ?? null);
        // setPriorAuthorizationNumber(professionalData.priorAuthorizationNumber);
        setPriorAuthorizationNumber(
          professionalData?.claimSupplementalInformation?.priorAuthorizationNumber ?? null
        );
        setFederalTaxInformation(professionalData.federalTaxInformation ?? null);
        setAcceptAssignmentData(professionalData.acceptAssignmentData ?? null);
        setoutsideLab(professionalData.outsideLab ?? null);
        setTotalCharges(professionalData.totalCharges ?? null);
        setAmountPaid(professionalData.amountPaid ?? null);
        setPhysicianSignature(professionalData.physicianSignature ?? null);
        setServiceFacilityLocation(professionalData.serviceFacilityLocation ?? null);

        const claimInfoData = professionalData.claimInformation;
        if (
          claimInfoData &&
          claimInfoData.hasOwnProperty("healthCareCodeInformation")
        ) {
          let healthCareCodeInformation =
            claimInfoData.healthCareCodeInformation;
          let tempDiagnosis = diagnosis;

          tempDiagnosis.forEach((element) => {
            element.diagnosisCode = "";
            element.value = "";
          });

          for (let i = 0; i < healthCareCodeInformation.length; i++) {
            for (let k = 0; k < tempDiagnosis.length; k++) {
              let item = tempDiagnosis[k];
              if (item.value === "" || item.value === null) {
                item.value = healthCareCodeInformation[i].diagnosisTypeCode;
                item.diagnosisCode = healthCareCodeInformation[i].diagnosisCode;
                break;
              }
            }
          }

          setDiagnosis(tempDiagnosis);
        }
      }
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "updateState", e.message, e);
      // console.error(e, "error");
    }
  };
  const onBlurDiagnosis = () => {
    setDiagnosisList("");
  };

  const bindData = (claimData, selectedPage) => {
    if (isNaN(selectedPage)) {
      selectedPage = 0;
      setCurrentPage(1);
    }

    try {
      const selectedClaimData = claimData[selectedPage];
      if (!selectedClaimData) {
        console.error("No claim data found for the selected page.");
        return;
      }
      parseInputJson(selectedClaimData);
      const professionalData = selectedClaimData.inputJson.Professional;
      const subscriber = updateSubscriberData(professionalData);
      const dependentData = professionalData?.dependent;
      updateState(
        selectedClaimData,
        professionalData,
        subscriber,
        dependentData
      );
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "bindData", e.message, e);
      // console.error(e, "error");
    }
  };

  const updateServiceLineState = (serviceLines) => {
    if (serviceLines && serviceLines.length === 0) {
      service.assignedNumber = 1;
      setServiceList([service]);
    } else if (serviceLines && serviceLines.length > 0) {
      let tempList = [];
      serviceLines.forEach((item) => {
        const tempService = structuredClone(service);
        tempService.assignedNumber = item.assignedNumber ? item.assignedNumber.toString() : null;
        tempService.fromDate = item.serviceDate ?? null;
        tempService.toDate = item.serviceDateEnd ?? null;
        tempService.placeOfService =
          item.professionalService.placeOfServiceCode ?? null;
        // tempService.serviceDateEnd = item.emg;
        tempService.cptHcpcs = item.professionalService.procedureCode ?? null;
        tempService.modifier = item.professionalService.procedureModifiers ?? null;
        tempService.diagnosisPointer =
          item.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers ?? null;
        tempService.charges = item.professionalService.lineItemChargeAmount ?? null;
        tempService.daysOrUnits = item.professionalService.measurementUnit ?? null;
        // tempService.serviceDateEnd = item.epsdtFamilyPlan;
        // tempService.serviceDate = item.idQual;
        if (item.renderingProvider && typeof (item.renderingProvider) === "object") {
          tempService.renderingProviderId = item.renderingProvider.employerId ?? null;
        }
        else {
          tempService.renderingProviderId = null;
        }
        tempService.isCptValid = false;
        tempList.push(tempService);
      });
      setServiceList(tempList);
    }
  };

  const validateServiceRequiredFields = (serviceList, serviceCPT) => {
    let hasEmptyFields = false;
    const updatedServiceList = serviceList.map((row, index) => {
      const errors = {
        fromDate: !row.fromDate ? "Required" : "",
        toDate: !row.toDate ? "Required" : "",
        cptHcpcs: !row.cptHcpcs ? "Required" : "",
        diagnosisPointer: !row.diagnosisPointer ? "Required" : "",
      };
      if (
        errors.fromDate ||
        errors.toDate ||
        errors.cptHcpcs ||
        errors.diagnosisPointer
      ) {
        hasEmptyFields = true;
        return { ...row, error: errors };
      }
      return { ...row, error: {} };
    });
    return { updatedServiceList, hasEmptyFields };
  };

  const btnAddService = () => {
    const { updatedServiceList, hasEmptyFields } =
      validateServiceRequiredFields(serviceList, serviceCPT);
    if (hasEmptyFields) {
      setServiceList(updatedServiceList);
      setNotify({
        isOpen: true,
        message:
          "Please fill out the required fields in service: From Date, To Date, CPT, Diagnosis, and Charge.",
        type: "error",
      });
      return;
    }
    let maxAssignedNumber = serviceList.reduce(
      (max, item) => Math.max(max, parseInt(item.assignedNumber, 10)),
      0
    );
    maxAssignedNumber =
      isNaN(maxAssignedNumber) || maxAssignedNumber <= 0
        ? 1
        : maxAssignedNumber + 1;
    let tempService = structuredClone(service);
    let tempServiceList = structuredClone(serviceList);
    tempService.assignedNumber = maxAssignedNumber.toString();
    tempServiceList.push(tempService);
    setServiceList(tempServiceList);
  };

  const handleDeleteClick = (key) => {
    setDeleteKey(key);
    setDialogOpen(true);
  };

  const handleConfirmServiceDelete = () => {
    const updatedServiceList = serviceList.filter(
      (item) => item.assignedNumber !== deleteKey
    );
    const updatedOriginalServiceList = serviceListOriginal.filter(
      (item) => item.assignedNumber !== deleteKey
    );

    if (updatedServiceList.length === 0) {
      updatedServiceList.push(service);
    }

    setServiceList(updatedServiceList);
    setServiceListOriginal(updatedOriginalServiceList);
  };

  const btnRemoveContactInfo = (
    index,
    setSubmitterData,
    setOpenSnackbar,
    submitterData,
    setNotify
  ) => {
    try {
      let newContactInformation = submitterData.contactInformation.filter(
        (_, i) => i !== index
      );
      if (newContactInformation.length === 0) {
        newContactInformation = submitterData.contactInformation.slice(0, -1);
        setSubmitterData((prevState) => ({
          ...prevState,
          contactInformation: newContactInformation,
        }));
        btnAddContactInfo(setSubmitterData, submitterData, setNotify);
      } else {
        setSubmitterData((prevState) => ({
          ...prevState,
          contactInformation: newContactInformation,
        }));
      }
    } catch (e) {
      // Log the error for telemetry and debugging purposes
      logTelemetry("Failed", "CMS1500", "btnRemoveContactInfo", e.message, e);
    }
  };

  const onPageDropDownChange = async (event, value) => {
    try {
      const dataIndex = value - 1;
      setSelectedPage(dataIndex);
      setCurrentPage(value);
      bindData(claimData, dataIndex);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "onPageDropDownChange", e.message, e);
      // console.error(e, "error");
    }
  };

  const getFinalDiagnosis = (diagnosis) => {
    try {
      let diagnosisArray = [];
      diagnosis.map((item) => {
        if (item.value !== "" && item.value != null) {
          const transformedItem = {
            ...item,
            diagnosisTypeCode: item.value,
          };
          delete transformedItem.name;
          delete transformedItem.value;
          diagnosisArray.push(transformedItem);
        }
      });
      return diagnosisArray;
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "getFinalDiagnosis", e.message, e);
      // console.error(e, "error");
    }
  };

  const updatedClaimData = async () => {
    try {
      if (formData && formData !== "") {
        let dependentArray = dependentData;
        let diagonosisData = getFinalDiagnosis(diagnosis);
        if (subscriberData.relationshipToPatient !== "self") {
          if (
            !dependentArray ||
            (dependentArray && dependentArray.length == 1)
          ) {
            dependentArray = [patientData];
          } else if (dependentArray && dependentArray.length > 1) {
            dependentArray[dependentArray.length - 1] = patientData;
          }
        }

        // Call a Function to construct final service line
        for (let i = 0; i < serviceList.length; i++) {
          let tempService = serviceList[i];
          let tempOrg = serviceListOriginal.find(
            (x) => x.assignedNumber == tempService.assignedNumber
          );
          if (tempOrg) {
            tempOrg.serviceDate = tempService.fromDate;
            tempOrg.serviceDateEnd = tempService.toDate;
            tempOrg.professionalService.placeOfServiceCode =
              tempService.placeOfService;
            // tempOrg.serviceDateEnd = tempService.emg;
            tempOrg.professionalService.procedureCode = tempService.cptHcpcs;
            tempOrg.professionalService.procedureModifiers = tempService.modifier;
            tempOrg.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers = tempService.diagnosisPointer;
            tempOrg.professionalService.lineItemChargeAmount = tempService.charges;
            tempOrg.professionalService.measurementUnit = tempService.daysOrUnits;
            // tempOrg.serviceDateEnd = tempService.epsdtFamilyPlan;
            // tempOrg.serviceDate = tempService.idQual;
            // This Logic Handles the Rendering Provider Object
            if (tempOrg.renderingProvider && tempOrg.renderingProvider.hasOwnProperty("employerId")) {
              tempOrg.renderingProvider.employerId = tempService.renderingProviderId;
            }
            else {
              let tempSer = structuredClone(serviceOriginal);
              tempSer.renderingProvider.employerId = tempService.renderingProviderId;
              tempOrg.renderingProvider = tempSer.renderingProvider;
            }
          } else {
            let newService = structuredClone(serviceOriginal);
            newService.assignedNumber = tempService.assignedNumber;
            newService.serviceDate = tempService.fromDate;
            newService.serviceDateEnd = tempService.toDate;
            newService.professionalService.placeOfServiceCode =
              tempService.placeOfService;
            // newService.serviceDateEnd = tempService.emg;
            newService.professionalService.procedureCode = tempService.cptHcpcs;
            newService.professionalService.procedureModifiers =
              tempService.modifier;
            newService.professionalService.compositeDiagnosisCodePointers.diagnosisCodePointers =
              tempService.diagnosisPointer;
            newService.professionalService.lineItemChargeAmount =
              tempService.charges;
            newService.professionalService.measurementUnit =
              tempService.daysOrUnits;
            // newService.serviceDateEnd = tempService.epsdtFamilyPlan;
            // newService.serviceDate = tempService.idQual;
            newService.renderingProvider.employerId = tempService.renderingProviderId;

            serviceListOriginal.push(newService);
          }
        }

        setServiceListOriginal(serviceListOriginal);

        const updatedFormData = {
          ...formData,
          inputJson: {
            ...formData.inputJson,
            Professional: {
              ...formData.inputJson.Professional,
              insuranceType: insuranceTypeData || "",
              receiver: receiverData,
              subscriber: {
                ...subscriberData,
                address: subscriberData.address,
              },
              submitter: {
                ...submitterData,
                contactInformation: submitterData.contactInformation,
              },
              // claimInformation: {
              //   ...claimInformation,
              //   healthCareCodeInformation: diagonosisData,
              //   serviceLines: serviceListOriginal,
              // },
              claimInformation: {
                ...claimInformation,
                healthCareCodeInformation: diagonosisData,
                serviceLines: serviceListOriginal,
                claimSupplementalInformation: {
                  priorAuthorizationNumber: priorAuthorizationNumber,
                },
              },
              dependent: dependentArray,
              referring: referringData,
              patientRelatedTo: patientRelatedTo,
              patientSignature: patientSignature,
              insuredSignature: insuredSignature,
              dateOfIllness: dateOfIllness,
              otherDate: otherDate,
              patientUnavailableDate: patientUnavailableDate,
              // referringProvider: referringProvider,
              hospitalizationDate: hospitalizationDate,
              additionalClaim: additionalClaim,
              resubmissionCode: resubmissionCode,
              priorAuthorizationNumber: priorAuthorizationNumber,
              federalTaxInformation: federalTaxInformation,
              acceptAssignmentData: acceptAssignmentData,
              outsideLab: outsideLab,
              amountPaid: amountPaid,
              totalCharges: totalCharges,
              physicianSignature: physicianSignature,
              serviceFacilityLocation: serviceFacilityLocation,
            },
          },
          payerName: currentPayerDetails.chPayerName,
          cpid: currentPayerDetails.chPayerId,
          tradingPartnerServiceId: currentPayerDetails.payerId,
        };

        return updatedFormData;
      } else {
        console.error("formData is empty or not defined");
      }
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "updatedClaimData", e.message, e);
      // console.error(e, "error");
    }
  };

  const handleSearchpayerData = async (selval) => {
    try {
      // let details =loginInitialDetails;

      let values = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: "System",
          auditTrail: 0,
          editTrail: 0,
          dataSource: "",
          processStatus: "",
          deliveryStatus: "",
        },
        inputJson:
          selval == 1
            ? currentPayerDetails.cpid
            : selval == 2
              ? currentPayerDetails.payerId
              : currentPayerDetails.payerName,
        requestName: selval == 1 ? "payerId" : selval == 2 ? "payerId" : "name",
      };

      await mutatePayerTypeAsync(values);
    } catch (e) {
      logTelemetry("Failed", "CMS1500", "handleSearchpayerData", e.message, e);
      // console.error(e, "error");
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setContactToDelete(null);
  };

  const handleContactConfirmDelete = () => {
    if (contactToDelete !== null) {
      btnRemoveContactInfo(
        contactToDelete,
        setSubmitterData,
        setOpenSnackbar,
        submitterData
      );
      handleCloseConfirmDialog();
    }
  };

  const handleClickOpenConfirmDialog = (index) => {
    setContactToDelete(index);
    setOpenConfirmDialog(true);
  };

  //#endregion Functionalities

  return (
    <div className="cms-css masterpage-claim-cms accordion-spacing">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ backgroundColor: "#e7e7e7" }}
          className="font-medium"
        >
          <Typography>Claim Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Container maxWidth="xl" className="claim-container">
              <ThemeProvider theme={theme}>
                <Box sx={contentStyle} className="claim-header-field">
                  <form>
                    <Grid
                      container
                      spacing={2}
                    // className="table-bottom-spacing"
                    >
                      <Grid item xs={12} md={2} className="pad-0">
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Patient Control Number"
                            size="small"
                            className="custom-input-label"
                            value={patientControlNumber}
                            onChange={handlePatientControlNumberChange}
                            InputLabelProps={{
                              shrink: true,
                              className: "custom-label-claim",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs="auto" className="pad-0 margin-top-5">
                        <span className="pad-right-5">(or)</span>
                      </Grid>
                      <Grid item xs={12} md={2} className="pad-0">
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Provider Control Number"
                            size="small"
                            className="custom-input-label"
                            value={providerControlNumber}
                            onChange={handleProviderControlNumberChange}
                            InputLabelProps={{
                              shrink: true,
                              className: "custom-label-claim",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs="auto" className="pad-0 margin-top-5">
                        <span className="pad-right-5">(or)</span>
                      </Grid>
                      <Grid item xs={12} md={2} className="pad-0">
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Orbit Claim Id"
                            size="small"
                            className="custom-input-label"
                            value={orbitClaimId}
                            onChange={handleOrbitClaimIdChange}
                            InputLabelProps={{
                              shrink: true,
                              className: "custom-label-claim",
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5} className="custom-claim-btn">
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={6}
                            sm={3}
                            className="custom-claim-btnsearch pad-left-10 !pt-[5px]"
                          >
                            <FormControl fullWidth>
                              <Button
                                className="button-styles btnHeight custom-label "
                                variant="outlined"
                                color="primary"
                                onClick={() => btnSearchClick()}
                              >
                                Search
                              </Button>
                            </FormControl>
                          </Grid>
                          {formData?.readOnlyFlag == 0 && formData?.copyClaimFlag == 0 && (
                            <>
                              <Grid item xs={6} sm={2} className="custom-claim-btnsearch !pt-[5px]">
                                <FormControl fullWidth>
                                  <Button
                                    className="button-styles btnHeight custom-label"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleSaveAndSubmit(0)}
                                  >
                                    Save
                                  </Button>
                                </FormControl>
                              </Grid>
                              {formData?.onHold == 1 && formData?.processFlag == 10 ?
                                // formData.processFlag == 20 &&
                                // formData?.readOnlyFlag == 0 ? 
                                (
                                  <></>
                                ) : (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                    className="!pt-[5px]"
                                  >
                                    <FormControl fullWidth>
                                      <Button
                                        className="button-styles btnHeight custom-label"
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleSaveAndSubmit(1)}
                                      >
                                        Submit
                                      </Button>
                                    </FormControl>
                                  </Grid>
                                )}
                            </>
                          )}

                          <Grid
                            item
                            xs={6}
                            sm={3}
                            className="custom-claim-btnsearch !pt-[5px]"
                          >
                            <FormControl fullWidth>
                              <Button
                                className="button-styles btnHeight custom-label"
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleClearData(
                                    setShowDiv,
                                    setPatientControlNumber,
                                    setProviderControlNumber,
                                    setOrbitClaimId,
                                    setisDataDisplaying
                                  )
                                }
                              >
                                Clear
                              </Button>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
                {showDiv && (
                  <Grid
                    container
                    spacing={2}
                    className="table-bottom-spacing pad-top-10 margin-bottom-0 pad-bottom-0"
                  >
                    <Grid item xs={12} className="custom-grid-divider pad-left-0">
                      <Divider className="divider-width" />
                    </Grid>
                  </Grid>
                )}
                {/* {showDiv && (
                  <Grid
                    container
                    spacing={2}
                    className="table-bottom-spacing pad-top-2"
                    justifyContent="space-between"
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      md={5}
                      className="custom-claim-pagination margin-bottom-4 pad-left-0 pad-left-15"
                      spacing={2}
                    >
                      {formData?.recordStatus == 1 && (
                        <Grid item xs={12} sm={6} md={2} className="pad-top-0 pad-left-0">
                          <FormControl fullWidth>
                            <Button
                              className="button-styles btnHeight custom-label"
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleVoidPopupData(
                                  setOpen,
                                  setisSubmitButtonClick
                                )
                              }
                            >
                              Void
                            </Button>
                          </FormControl>
                        </Grid>
                      )}

                      {formData?.onHold == 0 && formData?.processFlag == 10 && (
                        <Grid item xs={12} sm={6} md={2} className="pad-top-0 pad-left-0">
                          <FormControl fullWidth>
                            <Button
                              className="button-styles btnHeight custom-label"
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                handleHoldPopupData(
                                  setOpen,
                                  setisSubmitButtonClick
                                )
                              }
                            >
                              Hold
                            </Button>
                          </FormControl>
                        </Grid>
                      )}

                      {formData?.onHold == 1 && formData?.processFlag == 10 && (
                        <Grid item xs={12} sm={6} md={2} className="pad-top-0 pad-left-0">
                          <FormControl fullWidth>
                            <Button
                              className="button-styles btnHeight custom-label"
                              variant="outlined"
                              color="primary"
                            >
                              Release
                            </Button>
                          </FormControl>
                        </Grid>
                      )}
                      {
                        // isDataDisplaying &&
                        formData.copyClaimFlag == "1" && (
                          <Grid item xs={12} sm={6} md={3} className="pad-top-0 pad-left-0">
                            <FormControl fullWidth>
                              <Button
                                className="button-styles btnHeight custom-label"
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleCopyClaimData(
                                    setOpen,
                                    setisSubmitButtonClick
                                  )
                                }
                              >
                                Copy Claim
                              </Button>
                            </FormControl>
                          </Grid>
                        )
                      }
                      <Grid item xs={12} sm={6} md={2} className="pad-top-0 pad-left-0">
                        {formData?.readOnlyFlag == 1 && (
                          <span className="claim-status-edit-view font-bold">
                            Read Only
                          </span>
                        )}
                        {formData?.readOnlyFlag == 0 && (
                          <span className="claim-status-edit-view font-bold">
                            Editable
                          </span>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      md={7}
                      justifyContent={isSmallScreen ? "center" : "flex-end"}
                      spacing={2}
                    >
                      <Grid item xs={12} sm={6} md={12} className="pad-top-0 text-align-end">
                        <FormLabel className="custom-claim-label font-bold pad-right-13">
                          Record Count: {recordsCountState.totalRecordCount}
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} className="pad-top-0 text-align-end adjust-flex-custom-pagination">
                        <Pagination
                          totalPages={recordsCountState.totalPageCount}
                          count={recordsCountState.totalPageCount}
                          page={currentPage}
                          onChange={onPageDropDownChange}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )} */}

                {showDiv && (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="button-alignment !mt-8"
                  >
                    {/* Left side with buttons */}
                    <Grid item>
                      <Grid
                        container
                        className="custom-claim-pagination margin-bottom-4 pad-left-0"
                        spacing={2}
                      >
                        {formData && formData.recordStatus == 1 ? (
                          <Grid item className="pad-top-0 pad-left-0">
                            <FormControl fullWidth>
                              <Button
                                className="button-styles btnHeight custom-label"
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleVoidPopupData(
                                    setOpen,
                                    setisSubmitButtonClick
                                  )
                                }
                              >
                                Void
                              </Button>
                            </FormControl>
                          </Grid>
                        ) : ""}

                        {formData?.onHold == 0 &&
                          formData?.processFlag == 10 && (
                            <Grid item className="pad-top-0 pad-left-0">
                              <FormControl fullWidth>
                                <Button
                                  className="button-styles btnHeight custom-label"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleHoldPopupData(
                                      setOpen,
                                      setisSubmitButtonClick
                                    )
                                  }
                                >
                                  Hold
                                </Button>
                              </FormControl>
                            </Grid>
                          )}

                        {formData?.onHold == 1 &&
                          formData?.processFlag == 10 && (
                            <Grid item className="pad-top-0 pad-left-0">
                              <FormControl fullWidth>
                                <Button
                                  className="button-styles btnHeight custom-label"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handleReleasePopupData(
                                      setOpen,
                                      setisSubmitButtonClick
                                    )
                                  }
                                >
                                  Release
                                </Button>
                              </FormControl>
                            </Grid>
                          )}

                        {formData.copyClaimFlag == "1" && (
                          <Grid item className="pad-top-0 pad-left-0">
                            <FormControl fullWidth>
                              <Button
                                className="button-styles btnHeight custom-label"
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  handleCopyClaimData(
                                    setOpen,
                                    setisSubmitButtonClick
                                  )
                                }
                              >
                                Copy Claim
                              </Button>
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item className="pad-top-0 pad-left-0">
                          {formData?.readOnlyFlag == 1 && (
                            <span className="claim-status-edit-view font-bold">
                              Read Only
                            </span>
                          )}
                          {formData?.readOnlyFlag == 0 && (
                            <span className="claim-status-edit-view font-bold">
                              Editable
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Right side with Record Count */}
                    <Grid item className="align-record pad-right-5">
                      <FormLabel className="custom-claim-label font-bold  pad-bottom-5 ">
                        Record Count: {recordsCountState.totalRecordCount}
                      </FormLabel>
                    </Grid>
                  </Grid>
                )}
                {showDiv && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    className="pad-top-0 pad-bottom-5 adjust-flex-custom-pagination"
                  >
                    <Pagination
                      totalPages={recordsCountState.totalPageCount}
                      count={recordsCountState.totalPageCount}
                      page={currentPage}
                      onChange={onPageDropDownChange}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </Grid>
                )}

                {showDiv && (
                  <Grid container spacing={2} className="table-bottom-spacing">
                    <Grid item xs={12} className="border-right pad-0 ">
                      <FormControl fullWidth>
                        <AntDesignFormLabel >
                          General Details
                        </AntDesignFormLabel>
                      </FormControl>
                      <Grid container spacing={2} className="margin-top-3 pad-bottom-9">
                        <Grid item xs={12} md={3} className="pad-top-0">
                          <FormControl
                            fullWidth
                            className="text-align-left  pad-left-5"
                          >
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="PCN"
                                value={formData.providerControlNumber}
                                disabled
                                // className="custom-input-label width-100 custom-input-disabled font-bold"
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl fullWidth className="text-align-left">
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="DOS"
                                value={formatDate(formData.dateOfService)}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl fullWidth className="text-align-left">
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Claim Id"
                                value={formData.orbitAssignedClaimID}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl
                            fullWidth
                            className="text-align-left pad-right-5"
                          >
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Rec.Type"
                                value={formData.claimType}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="margin-top-3 pad-bottom-9">
                        <Grid item xs={12} md={3} className="pad-top-0">
                          <FormControl
                            fullWidth
                            className="text-align-left pad-left-5"
                          >
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Claim Received Date"
                                value={formatDate(formData.claimReceivedDate)}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl fullWidth className="text-align-left">
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Claim Sent Date"
                                value={formatDate(formData.fileProcessDate)}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl fullWidth className="text-align-left">
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Claim Status"
                                value={formData.transactionReamrks}
                                disabled
                                // className="custom-input-label width-100 custom-input-disabled font-bold"
                                // className={`custom-input-label width-100 custom-input-disabled font-bold ${formData?.transactionReamrks.toUpperCase() === "REJECTED" ? "custom-bg-danger" : ""}`}
                                className={`custom-input-label width-100 custom-input-disabled font-bold ${formData?.transactionReamrks?.toUpperCase() === "REJECTED" ? "custom-bg-danger" : ""}`}
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className="pad-top-0 pad-left-5">
                          <FormControl
                            fullWidth
                            className="text-align-left pad-right-5"
                          >
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Claim Status Date"
                                value={formatDate(formData.claimStatusDate)}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className="margin-top-3">
                        <Grid item xs={12} md={12} className="pad-top-0">
                          <FormControl
                            fullWidth
                            className="text-align-left pad-left-5 pad-right-5 pad-bottom-9"
                          >
                            <div className="custom-aline-claim">
                              <TextField
                                type="text"
                                size="small"
                                label="Remarks"
                                value={formData.statusRemarks}
                                disabled
                                className="custom-input-label width-100 custom-input-disabled font-bold custom-bg-danger"
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                                inputProps={{
                                  style: inputCursorBlock,
                                }}
                              />
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {showDiv && (
                  <Grid container spacing={2} className="table-bottom-spacing ">
                    {formData.headerErrors &&
                      formData.headerErrors.length > 0 && (
                        <>
                          {formData.headerErrors.map((error, index) => (
                            <ErrorContainer
                              key={index}
                              className="error-header-width"
                            >
                              <Grid className="pad-bottom-6">
                                <b>Current Claim Errors</b>
                              </Grid>
                              <FormLabel className="custom-claim-label font-bold">

                                {error.statusDescription}{" "}
                                {error.receivedByEntity}{" "}
                                {error.claimStatusSpecialNotes}{" "}
                                {error.responseReceivedDate}
                              </FormLabel>
                            </ErrorContainer>
                          ))}
                        </>
                      )}

                    {formData.previousHeaderErrors &&
                      formData.previousHeaderErrors.length > 0 && (
                        <>
                          {formData.previousHeaderErrors.map((error, index) => (
                            <ErrorContainer
                              key={index}
                              className="error-header-width"
                            >
                              <Grid className="pad-bottom-6">
                                <b>Original Claim Errors</b>
                              </Grid>
                              <FormLabel className="custom-claim-label font-bold">
                                {error.statusDescription}{" "}
                                {error.receivedByEntity}{" "}
                                {error.claimStatusSpecialNotes}{" "}
                                {error.responseReceivedDate}
                              </FormLabel>
                            </ErrorContainer>
                          ))}
                        </>
                      )}
                  </Grid>

                )}
                {showDiv && (
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className="border-right table-bottom-spacing"
                    >
                      <Grid item xs={12} className=" pad-0 ">
                        <FormControl fullWidth>
                          <AntDesignFormLabel >
                            Current Payer Details
                          </AntDesignFormLabel>
                        </FormControl>
                        <Grid container spacing={2} className="pad-left-5 ">
                          <Grid item xs={2} className="muitextbox adjust-flex-payer-name">
                            <FormControl fullWidth>
                              <Autocomplete
                                freeSolo
                                id="PayerName"
                                name="payerName"
                                filterOptions={(options, state) => {
                                  if (state.inputValue.length >= 3) {
                                    return options;
                                  } else {
                                    return [];
                                  }
                                }}
                                options={payerList.map(
                                  (option) => option.displayName
                                )}
                                value={currentPayerDetails.chPayerName}
                                onInputChange={(event, newInputValue) => {
                                  handleCurrentPayerInputChange(
                                    "payerName",
                                    event,
                                    setCurrentPayerDetails,
                                    setTempCurrentPayerDetails,
                                    setPreviousPayerDetails,
                                    setShowPreviousDiv,
                                    previousPayerDetails,
                                    currentPayerDetails,
                                    payerList,
                                    tempCurrentPayerDetails
                                  );
                                }}
                                PaperComponent={customDropdownStyle}
                                disableClearable
                                disabled={isDisabled}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Payer Name"
                                    placeholder="Search by payer name"
                                    size="small"
                                    className="custom-input-label"
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {!isDisabled && (
                                            <IconButton
                                              edge="end"
                                              onClick={() =>
                                                handleSearchpayerData(0)
                                              }
                                            >
                                              <SearchIcon className="custom-icon-claim" />
                                            </IconButton>
                                          )}
                                          {params.InputProps.endAdornment}
                                        </InputAdornment>
                                      ),
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          params.inputProps.value.length >= 3
                                        ) {
                                          handleSearchpayerData(0);
                                          setNotify({
                                            isOpen: false,
                                            message: "",
                                            type: "",
                                          }); // Hide notification
                                        } else {
                                          setNotify({
                                            isOpen: true,
                                            message:
                                              "Enter at least three characters",
                                            type: "error",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} className="muitextbox pad-left-0 adjust-flex-cpid">
                            <FormControl fullWidth>
                              <Autocomplete
                                freeSolo
                                id="cpid"
                                name="cpid"
                                disableClearable
                                disabled={isDisabled}
                                filterOptions={(options) => options}
                                options={
                                  payerList == []
                                    ? []
                                    : payerList.map(
                                      (option) => option.displayName
                                    )
                                }
                                value={currentPayerDetails.chPayerId}
                                onInputChange={(event, newInputValue) => {
                                  handleCurrentPayerInputChange(
                                    "cpid",
                                    event,
                                    setCurrentPayerDetails,
                                    setTempCurrentPayerDetails,
                                    setPreviousPayerDetails,
                                    setShowPreviousDiv,
                                    previousPayerDetails,
                                    currentPayerDetails,
                                    payerList,
                                    tempCurrentPayerDetails
                                  );

                                  // if (newInputValue.length >= 3) {
                                  //   setNotify({ isOpen: false, message: "", type: "" }); // Hide notification
                                  // } else {
                                  //   setNotify({
                                  //     isOpen: true,
                                  //     message: "Enter at least three characters",
                                  //     type: "error",
                                  //   });
                                  // }
                                }}
                                PaperComponent={customDropdownStyle}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="CPID"
                                    placeholder="Search by CPID"
                                    size="small"
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {!isDisabled && (
                                            <IconButton
                                              edge="end"
                                              onClick={() =>
                                                handleSearchpayerData(1)
                                              }
                                            >
                                              <SearchIcon className="custom-icon-claim" />
                                            </IconButton>
                                          )}
                                          {params.InputProps.endAdornment}
                                        </InputAdornment>
                                      ),
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          params.inputProps.value.length >= 3
                                        ) {
                                          handleSearchpayerData(1);
                                          setNotify({
                                            isOpen: false,
                                            message: "",
                                            type: "",
                                          }); // Hide notification
                                        } else {
                                          setNotify({
                                            isOpen: true,
                                            message:
                                              "Enter at least three characters",
                                            type: "error",
                                          });
                                        }
                                      }
                                    }}
                                    className="custom-input-label"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} className="muitextbox pad-left-0 adjust-flex-payer-id">
                            <FormControl fullWidth>
                              <Autocomplete
                                freeSolo
                                id="payerId"
                                name="payerId"
                                filterOptions={(options) => options}
                                options={
                                  payerList == []
                                    ? []
                                    : payerList.map(
                                      (option) => option.displayName
                                    )
                                }
                                value={currentPayerDetails.payerId}
                                onInputChange={(event, newInputValue) => {
                                  handleCurrentPayerInputChange(
                                    "payerId",
                                    event,
                                    setCurrentPayerDetails,
                                    setTempCurrentPayerDetails,
                                    setPreviousPayerDetails,
                                    setShowPreviousDiv,
                                    previousPayerDetails,
                                    currentPayerDetails,
                                    payerList,
                                    tempCurrentPayerDetails
                                  );
                                }}
                                PaperComponent={customDropdownStyle}
                                disableClearable
                                disabled={isDisabled}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    type="text"
                                    label="Payer ID"
                                    placeholder="Search by Payer ID"
                                    size="small"
                                    className="custom-input-label"
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {!isDisabled && (
                                            <IconButton
                                              edge="end"
                                              onClick={() =>
                                                handleSearchpayerData(2)
                                              }
                                            >
                                              <SearchIcon className="custom-icon-claim" />
                                            </IconButton>
                                          )}
                                          {params.InputProps.endAdornment}
                                        </InputAdornment>
                                      ),
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                          params.inputProps.value.length >= 3
                                        ) {
                                          handleSearchpayerData(2);
                                          setNotify({
                                            isOpen: false,
                                            message: "",
                                            type: "",
                                          }); // Hide notification
                                        } else {
                                          setNotify({
                                            isOpen: true,
                                            message:
                                              "Enter at least three characters",
                                            type: "error",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="muitextbox pad-left-0 adjust-flex-payer-priority"
                          >
                            {/* <FormControl
                              fullWidth
                              size="small"
                              className="custom-input-label"
                            >
                              <InputLabel
                                className="custom-label-claim" shrink={true}> Payer priority</InputLabel>
                              <Select
                                name="payerPriority"
                                disabled={isDisabled}
                                label="Payer priority"
                                value={currentPayerDetails.payerPriority}
                                onChange={(event) =>
                                  handleStateChanges(
                                    event,
                                    currentPayerDetails,
                                    "payerPriority",
                                    setCurrentPayerDetails
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                              >
                                <MenuItem value={"P"}>Primary</MenuItem>
                                <MenuItem value={"S"}>Secondary</MenuItem>
                                <MenuItem value={"T"}>Tertiary</MenuItem>
                                <MenuItem value={"U"}>Other</MenuItem>
                              </Select>
                            </FormControl> */}
                            <FormControl
                              fullWidth
                              size="small"
                              className="custom-input-label custom-select-input"
                            >
                              <InputLabel className="custom-label-claim">
                                {" "}
                                Payer priority{" "}
                              </InputLabel>
                              <Select
                                name="payerPriority"
                                disabled={isDisabled}
                                label="Payer priority"
                                value={currentPayerDetails.payerPriority}
                                onChange={(event) =>
                                  handleStateChanges(
                                    event,
                                    currentPayerDetails,
                                    "payerPriority",
                                    setCurrentPayerDetails
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value={"P"}>Primary</MenuItem>
                                <MenuItem value={"S"}>Secondary</MenuItem>
                                <MenuItem value={"T"}>Tertiary</MenuItem>
                                <MenuItem value={"U"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* <Grid item xs={1} className="muitextbox pad-left-0 adjust-flex-claim-btn ">
                            <FormControl fullWidth size="small">
                              <div className="button-container">
                                <Button
                                  className={`button-styles btnHeight custom-label ${formData?.readOnlyFlag === "1" ? "custom-input-disabled" : ""}`}
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  disabled={formData?.readOnlyFlag === "1"}
                                  onClick={() =>
                                    btnCurrentPayerClear(
                                      setShowPreviousDiv,
                                      setPreviousPayerDetails,
                                      setCurrentPayerDetails,
                                      previousPayerDetails,
                                      currentPayerDetails
                                    )
                                  }
                                >
                                  Clear
                                </Button>
                                <Button
                                  className={`button-styles btnHeight custom-label  ${formData?.readOnlyFlag === "1"
                                    ? "custom-input-disabled"
                                    : ""
                                    }`}
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  disabled={formData?.readOnlyFlag === "1"}
                                  onClick={() =>
                                    btnCurrentPayerReset(
                                      setShowPreviousDiv,
                                      setCurrentPayerDetails,
                                      tempCurrentPayerDetails
                                    )
                                  }
                                >
                                  Cancel
                                </Button>

                              </div>
                            </FormControl>
                          </Grid> */}
                          <Grid item xs={1} className="muitextbox pad-left-0 adjust-flex-claim-btn-clear">
                            <FormControl className="button-container">
                              <Button
                                className={`button-styles btnHeight custom-label ${formData?.readOnlyFlag === "1" ? "custom-input-disabled" : ""}`}
                                type="button"
                                variant="outlined"
                                color="primary"
                                disabled={formData?.readOnlyFlag === "1"}
                                onClick={() =>
                                  btnCurrentPayerClear(
                                    setShowPreviousDiv,
                                    setPreviousPayerDetails,
                                    setCurrentPayerDetails,
                                    previousPayerDetails,
                                    currentPayerDetails
                                  )
                                }
                              >
                                Clear
                              </Button>
                            </FormControl>
                          </Grid>
                          <Grid item xs={1} className="muitextbox pad-left-5 adjust-flex-claim-btn-cancel">
                            <FormControl className="button-container">
                              <Button
                                className={`button-styles btnHeight custom-label  ${formData?.readOnlyFlag === "1"
                                  ? "custom-input-disabled"
                                  : ""
                                  }`}
                                type="button"
                                variant="outlined"
                                color="primary"
                                disabled={formData?.readOnlyFlag === "1"}
                                onClick={() =>
                                  btnCurrentPayerReset(
                                    setShowPreviousDiv,
                                    setCurrentPayerDetails,
                                    tempCurrentPayerDetails
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="muitextbox pad-left-5 adjust-flex-claim-action"
                          >
                            <FormControl
                              fullWidth
                              size="small"
                              className="custom-input-label custom-select-input"
                            >
                              <InputLabel className="custom-label-claim">
                                {" "}
                                Claim Action{" "}
                                <span
                                  style={{ color: "red", fontSize: "14px" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </InputLabel>
                              <Select
                                // name="payerPriority"
                                disabled={isDisabled}
                                label="Claim Action"
                                value={claimActionData}
                                onChange={(event) =>
                                  setClaimActionData(event.target.value)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  className: "custom-label-claim",
                                }}
                              >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value={"1"}>
                                  Original (Admit thru discharge claim)
                                </MenuItem>
                                <MenuItem value={"7"}>
                                  Replacement (Replacement of prior claim)
                                </MenuItem>
                                <MenuItem value={"8"}>
                                  Void (Void/cancel prior claim)
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {claimActionData != 1 &&
                            (claimActionData == 7 || claimActionData == 8) && (
                              <Grid item xs={1} className="muitextbox pad-left-0 ">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    name="icn"
                                    label="ICN"
                                    size="small"
                                    className="custom-input-label width-100"
                                    value={ICNData?.claimControlNumber}
                                    disabled={isDisabled}
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        ICNData,
                                        "claimControlNumber",
                                        setICNData
                                      )
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                      {formData?.readOnlyFlag == 0 && showPreviousDiv && (
                        <Grid item xs={12} className=" pad-0 ">
                          <FormControl fullWidth>
                            <FormLabel className="custom-label pad-left-10 font-bold">
                              Previous Payer Details:
                            </FormLabel>
                          </FormControl>

                          <Grid container spacing={2} className="pad-left-5">
                            <Grid item xs={2} className="muitextbox">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  name="payerName"
                                  label="Payer Name"
                                  size="small"
                                  className="custom-input-label width-100"
                                  value={previousPayerDetails.chPayerName}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: "custom-label-claim",
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  name="cpid"
                                  label="CPID"
                                  size="small"
                                  className="custom-input-label width-100"
                                  value={previousPayerDetails.chPayerId}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: "custom-label-claim",
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  name="payerId"
                                  label="Payer ID"
                                  size="small"
                                  className="custom-input-label width-100"
                                  value={previousPayerDetails.payerId}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: "custom-label-claim",
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} className="muitextbox pad-left-0 margin-top-5">
                              <FormControl
                                fullWidth
                                size="small"
                                className="custom-input-label font-bold custom-input-disabled"
                                disabled
                              >
                                <InputLabel shrink>Payer priority</InputLabel>
                                <Select
                                  label="Payer priority"
                                  value={previousPayerDetails.payerPriority}
                                  sx={{
                                    "& .MuiSelect-select": {
                                      cursor: "not-allowed",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(0, 0, 0, 0.23)",
                                    },
                                  }}
                                >
                                  <MenuItem value={"P"}>Primary</MenuItem>
                                  <MenuItem value={"S"}>Secondary</MenuItem>
                                  <MenuItem value={"T"}>Tertiary</MenuItem>
                                  <MenuItem value={"U"}>Other</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className="table-bottom-spacing"
                    >
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className="custom-border-left  pad-0 "
                      >
                        <FormControl fullWidth>
                          <AntDesignFormLabel
                            className="  custom-required"
                            required
                          >
                            Control Number
                          </AntDesignFormLabel>
                          <TextField
                            type="Text"
                            size="small"
                            disabled
                            className="custom-input-disabled font-bold"
                            inputProps={{
                              style: inputCursorBlock,
                            }}
                            value={formData.providerControlNumber}
                            name="providerControlNumber"
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className="custom-border-left  pad-0 "
                      >
                        <FormControl fullWidth>
                          <AntDesignFormLabel className=" pad-bottom-9">
                            Source Trading Partner ServiceId
                          </AntDesignFormLabel>
                          <TextField
                            type="Text"
                            size="small"
                            disabled
                            className="custom-input-disabled font-bold"
                            inputProps={{
                              style: inputCursorBlock,
                            }}
                            name="cpid"
                            value={formData.cpid}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4} className="border-right  pad-0 ">
                        <FormControl fullWidth>
                          <AntDesignFormLabel className=" pad-bottom-9">
                            Trading Partner ServiceId
                          </AntDesignFormLabel>
                          <TextField
                            type="Text"
                            size="small"
                            disabled
                            className="custom-input-disabled font-bold "
                            inputProps={{
                              style: inputCursorBlock,
                            }}
                            name="tradingPartnerServiceId"
                            value={payerData?.tradingPartnerServiceId}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      className="table-bottom-spacing"
                    >
                      <Grid item xs={12} md={12} className="border-right pad-0">
                        <Grid item xs={12} className=" pad-0 ">
                          <FormControl fullWidth>
                            <AntDesignFormLabel
                              className=" custom-required"
                              required
                            >
                              Submitter
                            </AntDesignFormLabel>
                          </FormControl>
                          <Grid container spacing={2} className="margin-top-3">
                            <Grid item xs={12} md={3} className="pad-top-0 ">
                              <FormControl
                                fullWidth
                                className="text-align-left pad-left-5"
                              >
                                <div className="custom-aline-claim">
                                  <TextField
                                    type="text"
                                    name="organizationName"
                                    label="Organization Name"
                                    size="small"
                                    className="custom-input-label width-100"
                                    value={submitterData?.organizationName}
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        submitterData,
                                        "organizationName",
                                        setSubmitterData
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </div>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} className="pad-top-0 pad-left-0">
                              <FormControl
                                fullWidth
                                className="text-align-left"
                              >
                                <div className="custom-aline-claim">
                                  <TextField
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    size="small"
                                    className="custom-input-label width-100"
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        submitterData,
                                        "lastName",
                                        setSubmitterData
                                      )
                                    }
                                    value={submitterData.lastName}
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                  />
                                </div>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} className="pad-top-0 pad-left-0">
                              <FormControl
                                fullWidth
                                className="text-align-left"
                              >
                                <div className="custom-aline-claim">
                                  <TextField
                                    type="text"
                                    label="First Name"
                                    size="small"
                                    name="firstName"
                                    className="custom-input-label width-100"
                                    value={submitterData.firstName}
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        submitterData,
                                        "firstName",
                                        setSubmitterData
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                  />
                                </div>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3} className="pad-top-0 pad-left-0">
                              <FormControl
                                fullWidth
                                className="text-align-left"
                              >
                                <div className="custom-aline-claim">
                                  <TextField
                                    type="text"
                                    label="Middle Name"
                                    size="small"
                                    name="middleName"
                                    className="custom-input-label width-100"
                                    value={submitterData.middleName}
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        submitterData,
                                        "middleName",
                                        setSubmitterData
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                    disabled={isDisabled}
                                  />
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid spacing={2} className="table-bottom-spacing">
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              xs={12}
                              className="custom-side-header-contact"
                              md={1}
                            >
                              <FormLabel className="custom-label pad-left-10 font-bold">
                                Contact Information:
                              </FormLabel>
                            </Grid>
                            {!isDisabled && (
                              <Grid
                                item
                                xs={12}
                                md={1}
                                className="custom-side-header-add"
                              >
                                <Button
                                  disabled={isDisabled}
                                  className="button-styles btnHeight custom-label"
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    btnAddContactInfo(
                                      setSubmitterData,
                                      submitterData,
                                      setNotify
                                    )
                                  }
                                >
                                  <AddIcon fontSize="small" />
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                          {submitterData?.contactInformation?.map(
                            (info, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} md={3}>
                                  <FormControl
                                    fullWidth
                                    className="text-align-left pad-left-5"
                                  >
                                    <TextField
                                      type="text"
                                      label="Name"
                                      name="name"
                                      size="small"
                                      // className='custom-input-label width-100'
                                      className={`custom-input-label ${!info.name
                                        ? "custom-input-required"
                                        : ""
                                        }`} // Apply red border if the field is empty
                                      value={info.name}
                                      onChange={(e) =>
                                        handleContactInputChange(
                                          index,
                                          e,
                                          setSubmitterData,
                                          formData
                                        )
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                        className: "custom-label-claim",
                                      }}
                                      // helperText={
                                      //   info.error?.name ? "Required" : ""
                                      // }
                                      disabled={isDisabled}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} className="pad-left-0">
                                  <FormControl
                                    fullWidth
                                    className="text-align-left"
                                  >
                                    <TextField
                                      type="text"
                                      label="Phone Number"
                                      name="phoneNumber"
                                      size="small"
                                      value={info.phoneNumber}
                                      onChange={(e) =>
                                        handleContactInputChange(
                                          index,
                                          e,
                                          setSubmitterData,
                                          formData
                                        )
                                      }
                                      className="custom-input-label width-100"
                                      InputLabelProps={{
                                        shrink: true,
                                        className: "custom-label-claim",
                                      }}
                                      inputProps={{ maxLength: 10 }}
                                      onKeyDown={handlePhoneNumberKeyDown}
                                      disabled={isDisabled}
                                      error={
                                        info.phoneNumber.length !== 10 &&
                                        info.phoneNumber.length > 0
                                      }
                                      helperText={
                                        info.phoneNumber.length !== 10 &&
                                          info.phoneNumber.length > 0
                                          ? "Phone number must be 10 digits"
                                          : ""
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  className="pad-left-0 custom-phn-extension"
                                >
                                  <FormControl
                                    fullWidth
                                    className="text-align-left"
                                  >
                                    <TextField
                                      type="text"
                                      label="Phone Extension"
                                      name="phoneExtension"
                                      size="small"
                                      value={info.phoneExtension}
                                      onChange={(e) =>
                                        handleContactInputChange(
                                          index,
                                          e,
                                          setSubmitterData,
                                          formData
                                        )
                                      }
                                      className="custom-input-label width-100"
                                      InputLabelProps={{
                                        shrink: true,
                                        className: "custom-label-claim",
                                      }}
                                      inputProps={{ maxLength: 4 }}
                                      onKeyDown={handlePhoneNumberKeyDown}
                                      disabled={isDisabled}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} className="pad-left-0">
                                  <FormControl
                                    fullWidth
                                    className="text-align-left"
                                  >
                                    <TextField
                                      type="text"
                                      label="Fax Number"
                                      name="faxNumber"
                                      size="small"
                                      value={info.faxNumber}
                                      onChange={(e) =>
                                        handleContactInputChange(
                                          index,
                                          e,
                                          setSubmitterData,
                                          formData
                                        )
                                      }
                                      className="custom-input-label width-100"
                                      InputLabelProps={{
                                        shrink: true,
                                        className: "custom-label-claim",
                                      }}
                                      inputProps={{ maxLength: 10 }}
                                      onKeyDown={handlePhoneNumberKeyDown}
                                      disabled={isDisabled}
                                      error={
                                        info.faxNumber.length !== 10 &&
                                        info.faxNumber.length > 0
                                      }
                                      helperText={
                                        info.faxNumber.length !== 10 &&
                                          info.faxNumber.length > 0
                                          ? "Fax number must be 10 digits"
                                          : ""
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={2}
                                  className="pad-left-0 custom-email-spacing"
                                >
                                  <FormControl
                                    fullWidth
                                    className="text-align-left"
                                  >
                                    <TextField
                                      type="text"
                                      label="Email"
                                      name="email"
                                      size="small"
                                      value={info.email}
                                      onChange={(e) =>
                                        handleContactInputChange(
                                          index,
                                          e,
                                          setSubmitterData,
                                          formData
                                        )
                                      }
                                      className="custom-input-label width-100"
                                      InputLabelProps={{
                                        shrink: true,
                                        className: "custom-label-claim",
                                      }}
                                      disabled={isDisabled}
                                      error={info.emailError}
                                      helperText={
                                        info.emailError
                                          ? "Please enter a valid email address"
                                          : ""
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                {/* {!isDisabled && ( */}
                                <Grid
                                  item
                                  xs={12}
                                  md={1}
                                  className="margin-top-4 custom-delete-spacing"
                                >
                                  {/* <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() =>
                                          handleDeleteClick(
                                            index
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          fontSize="inherit"
                                          className="delete-color"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <ConfirmDialog
                                      open={isDialogOpen}
                                      onClose={() => setDialogOpen(false)}
                                      onConfirm={handleConfirmRemoveContactInfo}
                                    /> */}

                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      disabled={isDisabled}
                                      // onClick={() =>
                                      //   btnRemoveContactInfo(
                                      //     index,
                                      //     setSubmitterData,
                                      //     setOpenSnackbar,
                                      //     submitterData
                                      //   )
                                      // }
                                      onClick={() =>
                                        handleClickOpenConfirmDialog(index)
                                      }
                                    >
                                      <DeleteIcon
                                        fontSize="inherit"
                                        className="delete-color"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                {/* )} */}
                                {/* <Snackbar
                                  open={openSnackbar}
                                  autoHideDuration={6000}
                                  onClose={() =>
                                    handleCloseSnackbar(setOpenSnackbar)
                                  }
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Alert
                                    onClose={() =>
                                      handleCloseSnackbar(setOpenSnackbar)
                                    }
                                    severity="warning"
                                  >
                                    At least one record should be there!
                                  </Alert>
                                </Snackbar> */}
                              </Grid>
                            )
                          )}
                        </Grid>
                        <Dialog
                          open={openConfirmDialog}
                          onClose={handleCloseConfirmDialog}
                        >
                          <DialogTitle>Confirm Deletion</DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this contact
                            information?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleCloseConfirmDialog}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={handleContactConfirmDelete}
                              color="secondary"
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      className="table-bottom-spacing"
                    >
                      <Grid item xs={12} className="border-right pad-0 ">
                        <FormControl fullWidth>
                          <AntDesignFormLabel
                            className=" custom-required"
                            required
                          >
                            Receiver
                          </AntDesignFormLabel>
                        </FormControl>
                        <Grid container spacing={2} className="margin-top-3">
                          <Grid item xs={12} className="pad-top-0">
                            <FormControl
                              fullWidth
                              className="text-align-left pad-left-5"
                            >
                              <div className="custom-aline-claim">
                                <TextField
                                  type="text"
                                  label="Organization Name"
                                  size="small"
                                  name="organizationName"
                                  className="custom-input-label width-100"
                                  value={receiverData?.organizationName}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: "custom-label-claim",
                                  }}
                                  disabled={isDisabled}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      receiverData,
                                      "organizationName",
                                      setReceiverData
                                    )
                                  }
                                />
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </ThemeProvider>
            </Container>
          </div>
        </AccordionDetails>
      </Accordion>

      {showDiv && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{ backgroundColor: "#e7e7e7" }}
          >
            <Typography>CMS 1500</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <div className="div-alignment"> */}
            <Container maxWidth="xl" className="claim-container">
              <div>
                <ThemeProvider theme={theme}>
                  <Box sx={contentStyle} className="box-container">
                    <form className="content-cms-form">
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={8}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>1. Insurance Type</AntDesignFormLabel>
                            <RadioGroup
                              row
                              className="custom-radio-group"
                              onChange={(e) => setInsuranceTypeData(e.target.value)}
                              value={insuranceTypeData}
                            >
                              <FormControlLabel
                                disabled={isDisabled}
                                value="medicare"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="MEDICARE"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="medicaid"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="MEDICAID"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="tricare"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="TRICARE"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="champva"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="CHAMPVA"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="groupHealth"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="GROUP HEALTH PLAN"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="feca"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="FECA BLK LUNG"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                disabled={isDisabled}
                                value="other"
                                control={<Radio size="small" classes={{ root: 'MuiButtonBase-root-MuiRadio-root' }} />}
                                label="OTHER"
                                className="custom-radio"
                              />
                            </RadioGroup>
                          </FormControl>

                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel >
                              1a. Insured's I.D. Number (For Program in item
                              1)
                            </AntDesignFormLabel>
                            <TextField
                              type="text"
                              size="small"
                              className="custom-input"
                              value={subscriberData?.memberId}
                              disabled={isDisabled}
                              onChange={(e) =>
                                handleStateChanges(
                                  e,
                                  subscriberData,
                                  "memberId",
                                  setSubscriberData
                                )
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left  pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" margin-bottom-4">
                              2. Patient's Name (Last Name, First Name, Middle
                              Initial)
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id custom-name-input"
                              >
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    name="lastName"
                                    // onChange={(e) => handleStateChanges(e, subscriberData, 'lastName', setPatientData)}
                                    onChange={(e) =>
                                      handlePatientDataChange(
                                        e,
                                        "lastName",
                                        setSubscriberData,
                                        insuredData
                                      )
                                    }
                                    className="custom-input"
                                    value={subscriberData?.lastName || ""}
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id pad-left-0 custom-name-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="text"
                                    size="small"
                                    name="firstName"
                                    onChange={(e) =>
                                      handlePatientDataChange(
                                        e,
                                        "firstName",
                                        setSubscriberData,
                                        insuredData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={subscriberData?.firstName || ""}
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className="custom-claim-id pad-left-0 custom-middlename-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="text"
                                    size="small"
                                    name="middleName"
                                    onChange={(e) =>
                                      handlePatientDataChange(
                                        e,
                                        "middleName",
                                        setSubscriberData,
                                        insuredData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={subscriberData?.middleName || ""}
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left  pad-0"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={7} >
                              <FormControl fullWidth>
                                <AntDesignFormLabel className="margin-bottom-0">
                                  3. Patient's Birth Date
                                </AntDesignFormLabel>
                                <div className="input-date-label-space ">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}>
                                    <DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <DatePicker
                                        disabled={isDisabled}
                                        value={formatDateCMS(
                                          patientData?.dateOfBirth
                                        )}
                                        error={true}
                                        inputFormat="MM/dd/yyyy"
                                        size="small"
                                        className="custom-input"
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            readOnly: true,
                                            fullWidth: true,
                                            top: '7px',
                                            left: '5px',
                                          },
                                        }}
                                        onChange={(event) =>
                                          handleStateChanges(
                                            event,
                                            patientData,
                                            "dateOfBirth",
                                            setPatientData
                                          )
                                        }
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: "12px",
                                            textAlign: "center",
                                          },
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                                {/* <FormControl fullWidth>
                                    <div className="input-date-spacing ">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            error={true}
                                            inputFormat="MM/dd/yyyy"
                                            size="small"
                                            className="custom-input"
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                readOnly: true,
                                                fullWidth: true, // Ensure full width in the text field props
                                              },
                                            }}
                                            name="signedDate"
                                            value={formatDateCMS(
                                              physicianSignature?.signedDate
                                            )}
                                            onChange={(e) =>
                                              handleStateChanges(
                                                e,
                                                physicianSignature,
                                                "signedDate",
                                                setPhysicianSignature
                                              )
                                            }
                                            InputLabelProps={{
                                              sx: {
                                                fontSize: "12px",
                                                textAlign: "center",
                                              },
                                            }}
                                            disabled={isDisabled}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div>
                                  </FormControl> */}
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} className="pad-left-0 ">
                              <FormControl fullWidth>
                                <AntDesignFormLabel >
                                  Sex
                                </AntDesignFormLabel>
                                <RadioGroup
                                  row
                                  className="custom-patient-radio-group"
                                  value={patientData?.gender}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      patientData,
                                      "gender",
                                      setPatientData
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="M"
                                    control={<Radio size="small" />}
                                    label="Male"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="F"
                                    control={<Radio size="small" />}
                                    label="Female"
                                    className="custom-radio"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right  pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" margin-bottom-4">
                              4. Insured's Name (Last Name, First Name, Middle
                              Initial)
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id custom-name-input"
                              >
                                <FormControl fullWidth>
                                  <TextField
                                    type="Text"
                                    size="small"
                                    name="lastName"
                                    className="custom-input"
                                    value={insuredData?.lastName}
                                    onChange={(e) =>
                                      handlePrimaryInsuranceChange(
                                        e,
                                        "lastName",
                                        setInsuredData,
                                        subscriberData,
                                        setSubscriberData
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id pad-left-0 custom-name-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="Text"
                                    size="small"
                                    name="firstName"
                                    onChange={(e) =>
                                      handlePrimaryInsuranceChange(
                                        e,
                                        "firstName",
                                        setInsuredData,
                                        subscriberData,
                                        setSubscriberData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={insuredData?.firstName}
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className="custom-claim-id pad-left-0 custom-middlename-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="Text"
                                    size="small"
                                    name="middleName"
                                    onChange={(e) =>
                                      handlePrimaryInsuranceChange(
                                        e,
                                        "middleName",
                                        setInsuredData,
                                        subscriberData,
                                        setSubscriberData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={insuredData?.middleName}
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel >
                              5. Patient Address (No., Street)
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              name="address1"
                              className="custom-input"
                              disabled={isDisabled}
                              value={patientData?.address?.address1}
                              InputLabelProps={{ shrink: true }}
                              onChange={(event) =>
                                handleStateChanges(
                                  event,
                                  patientData,
                                  "address",
                                  setPatientData
                                )
                              }
                            />
                          </FormControl>
                          <Grid container spacing={2} className="pad-left-5">
                            <Grid item xs={6} className="muitextbox ">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="City"
                                  size="small"
                                  className="custom-input-label"
                                  name="city"
                                  value={patientData?.address?.city}
                                  disabled={isDisabled}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      patientData,
                                      "address",
                                      setPatientData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="State"
                                  size="small"
                                  className="custom-input-label"
                                  name="state"
                                  value={patientData?.address?.state}
                                  disabled={isDisabled}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      patientData,
                                      "address",
                                      setPatientData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className="pad-left-5">
                            <Grid item xs={6} className="muitextbox">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="Zip Code"
                                  size="small"
                                  className="custom-input-label"
                                  name="postalCode"
                                  value={patientData?.address?.postalCode}
                                  disabled={isDisabled}
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ maxLength: 5 }}
                                  onKeyDown={handlePhoneNumberKeyDown}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      patientData,
                                      "address",
                                      setPatientData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="Telephone"
                                  size="small"
                                  disabled={isDisabled}
                                  className="custom-input-label"
                                  name="contactInformation"
                                  value={
                                    patientData?.address?.contactInformation
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ maxLength: 10 }}
                                  onKeyDown={handlePhoneNumberKeyDown}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      patientData,
                                      "address",
                                      setPatientData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0 "
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <AntDesignFormLabel >
                                  6. Patient Relationship To Insured
                                </AntDesignFormLabel>

                                <RadioGroup
                                  name="relationshipToPatient"
                                  row
                                  // className="custom-radio-group custom-spacing-relation"
                                  className="custom-radio-group"
                                  value={subscriberData.relationshipToPatient}
                                  onChange={(e) => {
                                    handleStateChanges(
                                      e,
                                      subscriberData,
                                      "relationshipToPatient",
                                      setSubscriberData
                                    );
                                  }}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="self"
                                    control={<Radio size="small" />}
                                    label="Self"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="spouse"
                                    control={<Radio size="small" />}
                                    label="Spouse"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="child"
                                    control={<Radio size="small" />}
                                    label="Child"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="parent"
                                    control={<Radio size="small" />}
                                    label="Parent"
                                    className="custom-radio"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className="custom-spacing-nucc"
                            >
                              <FormControl fullWidth>
                                <AntDesignFormLabel >
                                  8. Reserved For NUCC Use
                                </AntDesignFormLabel>
                                <TextField
                                  type="Text"
                                  size="small"
                                  className="custom-input-disabled  font-bold "
                                  disabled
                                  inputProps={{
                                    style: inputCursorBlock,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel >
                              7. Insured's Address (No., Street)
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              disabled={isDisabled}
                              size="small"
                              className="custom-input"
                              name="address1"
                              value={insuredData?.address?.address1}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                handleStateChanges(
                                  e,
                                  insuredData,
                                  "address",
                                  setInsuredData
                                )
                              }
                            />
                          </FormControl>
                          <Grid container spacing={2} className="pad-left-5">
                            <Grid item xs={6} className="muitextbox ">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  disabled={isDisabled}
                                  label="City"
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  className="custom-input-label"
                                  name="city"
                                  value={insuredData?.address?.city}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      insuredData,
                                      "address",
                                      setInsuredData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="State"
                                  disabled={isDisabled}
                                  size="small"
                                  className="custom-input-label"
                                  name="state"
                                  InputLabelProps={{ shrink: true }}
                                  value={insuredData?.address?.state}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      insuredData,
                                      "address",
                                      setInsuredData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className="pad-left-5">
                            <Grid item xs={6} className="muitextbox">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="Zip Code"
                                  disabled={isDisabled}
                                  size="small"
                                  className="custom-input-label"
                                  value={insuredData?.address?.postalCode}
                                  name="postalCode"
                                  inputProps={{ maxLength: 5 }}
                                  onKeyDown={handlePhoneNumberKeyDown}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      insuredData,
                                      "address",
                                      setInsuredData
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="muitextbox pad-left-0">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  label="Telephone"
                                  disabled={isDisabled}
                                  size="small"
                                  className="custom-input-label"
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      insuredData,
                                      "address",
                                      setInsuredData
                                    )
                                  }
                                  name="contactInformation"
                                  InputLabelProps={{ shrink: true }}
                                  value={
                                    insuredData?.address?.contactInformation
                                  }
                                  inputProps={{ maxLength: 10 }}
                                  onKeyDown={handlePhoneNumberKeyDown}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel >
                              9. Other Insured's Name (Last Name,First Name,Middle Initial)
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id custom-name-input"
                              >
                                <FormControl fullWidth>
                                  <TextField
                                    type="Text"
                                    size="small"
                                    name="lastName"
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        otherInsuredData,
                                        "lastName",
                                        setOtherInsuredData
                                      )
                                    }
                                    className="custom-input"
                                    value={otherInsuredData?.lastName}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id pad-left-0 custom-name-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="Text"
                                    size="small"
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        otherInsuredData,
                                        "firstName",
                                        setOtherInsuredData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={otherInsuredData?.firstName}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className="custom-claim-id pad-left-0 custom-middlename-input"
                              >
                                <FormControl fullWidth className="pad-left-0">
                                  <TextField
                                    type="Text"
                                    size="small"
                                    disabled={isDisabled}
                                    name="middleName"
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        otherInsuredData,
                                        "middleName",
                                        setOtherInsuredData
                                      )
                                    }
                                    className="custom-input pad-left-0"
                                    value={otherInsuredData?.middleName}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10">
                              a. Other Insured's Policy Or Group Number
                            </FormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              disabled={isDisabled}
                              className="custom-input"
                              value={otherInsuredData?.memberId}
                              name="memberId"
                              onChange={(e) =>
                                handleStateChanges(
                                  e,
                                  otherInsuredData,
                                  "memberId",
                                  setOtherInsuredData
                                )
                              }
                            />
                          </FormControl>
                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10 ">
                              b. Reserved For Nucc Use
                            </FormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              className="custom-input-disabled font-bold "
                              disabled
                              inputProps={{
                                style: inputCursorBlock,
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10 ">
                              c. Reserved For Nucc Use
                            </FormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              className="custom-input-disabled font-bold "
                              disabled
                              inputProps={{
                                style: inputCursorBlock,
                              }}
                            />
                          </FormControl>
                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10 margin-bottom-2">
                              d. Insurance Plan Name Or Program Name
                            </FormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              disabled={isDisabled}
                              className="custom-input"
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0"
                        >
                          <FormControl fullWidth >
                            <AntDesignFormLabel className="custom-pat-condt" >
                              10. Is Patient's Condition Related To
                            </AntDesignFormLabel>
                          </FormControl>
                          <FormControl
                            fullWidth
                            className="text-align-left custom-spacing-top"
                          >
                            <FormLabel className="custom-label pad-left-10">
                              a. Employment? (Current Or Previous)
                            </FormLabel>
                            <Box display="flex" alignItems="center">
                              <RadioGroup
                                row
                                className="custom-radio-group "
                                name="employment"
                                value={patientRelatedTo?.employment}
                                onChange={(e) =>
                                  handleStateChanges(
                                    e,
                                    patientRelatedTo,
                                    "employment",
                                    setPatientRelatedTo
                                  )
                                }
                              >
                                <FormControlLabel
                                  disabled={isDisabled}
                                  value="yes"
                                  control={<Radio size="small" />}
                                  label="Yes"
                                  className="custom-radio"
                                />
                                <FormControlLabel
                                  disabled={isDisabled}
                                  value="no"
                                  control={<Radio size="small" />}
                                  label="No"
                                  className="custom-radio"
                                />
                              </RadioGroup>
                            </Box>
                          </FormControl>

                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <FormControl
                                fullWidth
                                className="text-align-left custom-spacing-auto"
                              >
                                <FormLabel className="custom-label pad-left-10">
                                  b. Auto Accident?
                                </FormLabel>
                                <Box display="flex" alignItems="center" >
                                  <RadioGroup
                                    row
                                    className="custom-radio-group  custom-spacing-auto-acc"
                                    name="autoAccident"
                                    value={patientRelatedTo?.autoAccident}
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        patientRelatedTo,
                                        "autoAccident",
                                        setPatientRelatedTo
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      disabled={isDisabled}
                                      value="yes"
                                      control={<Radio size="small" />}
                                      label="Yes"
                                      className="custom-radio"
                                    />
                                    <FormControlLabel
                                      disabled={isDisabled}
                                      value="no"
                                      control={<Radio size="small" />}
                                      label="No"
                                      className="custom-radio"
                                    />
                                  </RadioGroup>
                                </Box>
                              </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                              <FormControl
                                fullWidth
                              // className="custom-spacing-state"
                              >
                                <TextField
                                  type="text"
                                  disabled={isDisabled}
                                  label="State"
                                  size="small"
                                  className="custom-input-label"
                                  value={patientRelatedTo?.state}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      patientRelatedTo,
                                      "state",
                                      setPatientRelatedTo
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>

                          <FormControl
                            fullWidth
                            className="text-align-left custom-spacing-next"
                          >
                            <FormLabel className="custom-label  pad-left-10">
                              c. Other Accident?
                            </FormLabel>
                            <Box display="flex" alignItems="center" >
                              <RadioGroup
                                row
                                className="custom-radio-group custom-spacing-accidence"
                                name="otherAccident"
                                value={patientRelatedTo?.otherAccident}
                                onChange={(e) =>
                                  handleStateChanges(
                                    e,
                                    patientRelatedTo,
                                    "otherAccident",
                                    setPatientRelatedTo
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="yes"
                                  disabled={isDisabled}
                                  control={<Radio size="small" />}
                                  label="Yes"
                                  className="custom-radio"
                                />
                                <FormControlLabel
                                  value="no"
                                  disabled={isDisabled}
                                  control={<Radio size="small" />}
                                  label="No"
                                  className="custom-radio"
                                />
                              </RadioGroup>
                            </Box>
                          </FormControl>

                          <FormControl
                            fullWidth
                          // className="custom-claim-code margin-top-1"
                          >
                            <AntDesignFormLabel className=" margin-bottom-0 ">
                              10d. Claim Codes (Designated by NUCC)
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              className="custom-claim-input margin-top-5"
                              name="claimCodes"
                              value={patientRelatedTo?.claimCodes}
                              disabled={isDisabled}
                              onChange={(e) =>
                                handleStateChanges(
                                  e,
                                  patientRelatedTo,
                                  "claimCodes",
                                  setPatientRelatedTo
                                )
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className="custom-pat-condt" >
                              11. Insured's Policy Group Or Feca Number
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              className="custom-input"
                              disabled={isDisabled}
                            />
                          </FormControl>
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              <FormControl
                                fullWidth
                                className="text-align-left"
                              >
                                <FormLabel className="custom-label pad-left-10">
                                  a. Insured's Date Of Birth
                                </FormLabel>
                                {/* <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          size="small"
                                          className="custom-datepicker"
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                            },
                                          }}
                                          disabled={isDisabled}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div> */}
                                <div className="input-date-spacing ">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <DatePicker
                                        error={true}
                                        inputFormat="MM/dd/yyyy"
                                        size="small"
                                        className="custom-input"
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            readOnly: true,
                                            fullWidth: true,
                                            top: '7px',
                                            left: '5px',
                                          },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: "12px",
                                            textAlign: "center",
                                          },
                                        }}
                                        disabled={isDisabled}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                              </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl
                                fullWidth
                                className="text-align-left"
                              >
                                <FormLabel className="custom-label">
                                  Sex
                                </FormLabel>
                                <RadioGroup
                                  row
                                // className="custom-radio-group"
                                >
                                  <FormControlLabel
                                    value="male"
                                    disabled={isDisabled}
                                    control={<Radio size="small" />}
                                    label="Male"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    value="medicaifemaled"
                                    disabled={isDisabled}
                                    control={<Radio size="small" />}
                                    label="Female"
                                    className="custom-radio"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>

                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10">
                              b. Other Claim Id (Designated By NUCC)
                            </FormLabel>
                          </FormControl>
                          <Grid container spacing={2}>
                            <Grid item xs={4} className="custom-claim-id">
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  size="small"
                                  className="custom-input"
                                  disabled={isDisabled}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              className="custom-claim-id pad-left-0"
                            >
                              <FormControl fullWidth>
                                <TextField
                                  type="text"
                                  size="small"
                                  className="custom-input"
                                  disabled={isDisabled}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10">
                              c. Insurance Plan Name Or Program Name
                            </FormLabel>
                            <FormControl fullWidth>
                              <TextField
                                type="Text"
                                size="small"
                                className="custom-input"
                                disabled={isDisabled}
                              />
                            </FormControl>
                          </FormControl>

                          <FormControl fullWidth className="text-align-left">
                            <FormLabel className="custom-label pad-left-10">
                              d. Is There Another Health Benefit Plan?
                            </FormLabel>
                            <Box display="flex" alignItems="center">
                              <RadioGroup
                                row
                                className="custom-radio-group custom-spacing-relation"
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio size="small" />}
                                  label="Yes"
                                  className="custom-radio"
                                  disabled={isDisabled}
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<Radio size="small" />}
                                  label="No"
                                  className="custom-radio"
                                  disabled={isDisabled}
                                />
                              </RadioGroup>
                              <FormLabel className="custom-label pad-left-10">
                                ( If yes, complete items 9, 9a and 9d )
                              </FormLabel>
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={8}
                          className="custom-border-left pad-0 "
                        >
                          <h6 className="confirmationQuestion">
                            READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS
                            FORM.
                          </h6>
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              {" "}
                              12. PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE.
                              I authorize the release of any medical or other
                              information necessary to process this claim. I
                              also request payment of government benefits
                              either to myself or to the party who accepts
                              assignment below.
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5 pad-left-5">
                              <Grid
                                item
                                xs={8}
                                className="custom-claim-id margin-top-4"
                              >
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    label="Signed"
                                    size="small"
                                    name="signed"
                                    value={patientSignature?.signed}
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        patientSignature,
                                        "signed",
                                        setPatientSignature
                                      )
                                    }
                                    className="custom-input-label"
                                    disabled={isDisabled}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className=" custom-claim-id pad-left-0"
                              >
                                <FormControl fullWidth>
                                  {/* <div className="input-date-label-space">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            error={true}
                                            inputFormat="MM/dd/yyyy"
                                            size="small"
                                            className="custom-input"
                                            name="signedDate"
                                            value={formatDateCMS(
                                              patientSignature?.signedDate
                                            )}
                                            onChange={(event) =>
                                              handleStateChanges(
                                                event,
                                                patientSignature,
                                                "signedDate",
                                                setPatientSignature
                                              )
                                            }
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                readOnly: true,
                                                fullWidth: true,
                                              },
                                              
                                            }}
                                            disabled={isDisabled}
                                            InputLabelProps={{
                                              sx: {
                                                fontSize: "12px",
                                                textAlign: "center",
                                                shrink: true,
                                              },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div> */}
                                  <div className="input-date-spacing pad-top-5">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          size="small"
                                          className="custom-input"
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              fullWidth: true, // Ensure full width in the text field props
                                              top: '7px',
                                              left: '5px',
                                            },
                                          }}
                                          name="signedDate"
                                          value={formatDateCMS(
                                            patientSignature?.signedDate
                                          )}
                                          onChange={(event) =>
                                            handleStateChanges(
                                              event,
                                              patientSignature,
                                              "signedDate",
                                              setPatientSignature
                                            )
                                          }
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                          disabled={isDisabled}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className="custom-auth ">
                              13.INSURED'S OR AUTHORIZED PERSON'S SIGNATURE. I
                              authorize payment of medical benefits to the
                              undersigned physician or supplier for services
                              described below.
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              className="custom-input margin-top-3"
                              disabled={isDisabled}
                              onChange={(event) =>
                                setInsuredSignature(event.target.value)
                              }
                              value={insuredSignature}
                              name="insuredSignature"
                            ></TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              14. Date Of Current Illness, Injury, Or
                              Pregnancy (LMP)
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid item xs={7} className="custom-claim-id">
                                <FormControl fullWidth>
                                  <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          label="Selected Date"
                                          size="small"
                                          shrink
                                          className="custom-input font-size-13"
                                          value={formatDateCMS(
                                            dateOfIllness?.date
                                          )}
                                          name="date"
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              dateOfIllness,
                                              "date",
                                              setDateOfIllness
                                            )
                                          }
                                          // slotProps={{ textField: { size: "small", readOnly: true } }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              InputLabelProps: {
                                                shrink: true, // Add shrink property here
                                                sx: {
                                                  fontSize: "13px",
                                                  textAlign: "center",
                                                  top: '7px',
                                                  left: '5px',
                                                },
                                              },
                                              fullWidth: true,
                                            },
                                          }}
                                          disabled={isDisabled}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                className="custom-claim-id pad-left-5 margin-top-4"
                              >
                                <FormControl fullWidth>
                                  <TextField
                                    label="Qual"
                                    type="text"
                                    size="small"
                                    className="custom-input-label"
                                    value={dateOfIllness?.qual}
                                    name="qual"
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        dateOfIllness,
                                        "qual",
                                        setDateOfIllness
                                      )
                                    }
                                    disabled={isDisabled}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              15. Other Date
                            </AntDesignFormLabel>
                          </FormControl>
                          <Grid container spacing={2} className="pad-top-5 pad-left-5 ">
                            <Grid
                              item
                              xs={5}
                              className="custom-claim-id margin-top-4 "
                            >
                              <FormControl fullWidth>
                                <TextField
                                  label="Qual"
                                  type="text"
                                  size="small"
                                  className="custom-input-label"
                                  value={otherDate?.qual}
                                  name="qual"
                                  disabled={isDisabled}
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      otherDate,
                                      "qual",
                                      setOtherDate
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              className="custom-claim-id pad-left-0"
                            >

                              {/* <FormControl fullWidth>
                                    <div className="input-">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            error={true}
                                            inputFormat="MM/dd/yyyy"
                                            label="Selected Date"
                                            size="small"
                                            className="custom-datepicker-other"
                                            // slotProps={{ textField: { size: "small", readOnly: true } }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                readOnly: true,
                                                InputLabelProps: {
                                                  shrink: true, // Add shrink property here
                                                  sx: {
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                  },
                                                },
                                              },
                                            }}
                                            disabled={isDisabled}
                                            InputLabelProps={{
                                              sx: {
                                                fontSize: "12px",
                                                textAlign: "center",
                                              },
                                            }}
                                            value={formatDateCMS(
                                              otherDate?.date
                                            )}
                                            name="date"
                                            onChange={(e) =>
                                              handleStateChanges(
                                                e,
                                                otherDate,
                                                "date",
                                                setOtherDate
                                              )
                                            }
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div>
                                </FormControl> */}
                              <FormControl fullWidth>
                                <div className="input-date-label-space">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <DatePicker
                                        error={true}
                                        inputFormat="MM/dd/yyyy"
                                        label="Selected Date"
                                        size="small"
                                        // className="custom-datepicker-from"
                                        className="custom-input font-size-13"
                                        disabled={isDisabled}
                                        // slotProps={{ textField: { size: "small", readOnly: true } }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            readOnly: true,
                                            InputLabelProps: {
                                              shrink: true, // Add shrink property here
                                              sx: {
                                                fontSize: "13px",
                                                textAlign: "center",
                                                top: '7px',
                                                left: '5px',
                                              },
                                            },
                                          },
                                        }}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: "12px",
                                            textAlign: "center",
                                          },
                                        }}
                                        value={formatDateCMS(
                                          otherDate?.date
                                        )}
                                        name="date"
                                        onChange={(e) =>
                                          handleStateChanges(
                                            e,
                                            otherDate,
                                            "date",
                                            setOtherDate
                                          )
                                        }
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              16. Dates Patient Unable To Work In Current
                              Occupation
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid item xs={6} className="custom-claim-id">

                                <FormControl fullWidth>
                                  <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          label="From Date"
                                          size="small"
                                          // className="custom-datepicker-from"
                                          className="custom-input font-size-13"
                                          disabled={isDisabled}
                                          // slotProps={{ textField: { size: "small", readOnly: true } }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              InputLabelProps: {
                                                shrink: true, // Add shrink property here
                                                sx: {
                                                  fontSize: "13px",
                                                  textAlign: "center",
                                                  top: '7px',
                                                  left: '5px',
                                                },
                                              },
                                            },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}

                                          value={formatDateCMS(
                                            patientUnavailableDate?.fromDate
                                          )}
                                          name="fromDate"
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              patientUnavailableDate,
                                              "fromDate",
                                              setPatientUnavailableDate
                                            )
                                          }
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className="custom-claim-id pad-left-0"
                              >
                                {/* <FormControl fullWidth>
                                    <div className="input-date-label-space">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            error={true}
                                            inputFormat="MM/dd/yyyy"
                                            label="To Date"
                                            size="small"
                                            className="custom-datepicker-to"
                                            // slotProps={{ textField: { size: "small", readOnly: true } }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                readOnly: true,
                                                InputLabelProps: {
                                                  shrink: true, // Add shrink property here
                                                  sx: {
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                  },
                                                },
                                              },
                                            }}
                                            disabled={isDisabled}
                                            value={formatDateCMS(
                                              patientUnavailableDate?.toDate
                                            )}
                                            name="toDate"
                                            onChange={(e) =>
                                              handleStateChanges(
                                                e,
                                                patientUnavailableDate,
                                                "toDate",
                                                setPatientUnavailableDate
                                              )
                                            }
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div>
                                  </FormControl> */}
                                <FormControl fullWidth>
                                  <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          label="To Date"
                                          size="small"
                                          // className="custom-datepicker-from"
                                          className="custom-input font-size-13"
                                          disabled={isDisabled}
                                          // slotProps={{ textField: { size: "small", readOnly: true } }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              InputLabelProps: {
                                                shrink: true, // Add shrink property here
                                                sx: {
                                                  fontSize: "13px",
                                                  textAlign: "center",
                                                  top: '7px',
                                                  left: '5px',
                                                },
                                              },
                                            },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                          value={formatDateCMS(
                                            patientUnavailableDate?.toDate
                                          )}
                                          name="toDate"
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              patientUnavailableDate,
                                              "toDate",
                                              setPatientUnavailableDate
                                            )
                                          }
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              17. Name Of Referring Provider Or Other Source
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-5">
                              <Grid item xs={3} className="custom-claim-id">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    className="custom-input"
                                    value={referringData?.suffix}
                                    name="suffix"
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        referringData,
                                        "suffix",
                                        setReferringData
                                      )
                                    }
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={9}
                                className="custom-claim-id pad-left-0"
                              >
                                <FormControl fullWidth>
                                  <FormControl fullWidth>
                                    <TextField
                                      type="text"
                                      size="small"
                                      className="custom-input"
                                      disabled={isDisabled}
                                    />
                                  </FormControl>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>17a & 17b</AntDesignFormLabel>
                          </FormControl>
                          <Grid container spacing={2}>
                            <Grid
                              container
                              item
                              xs={12}
                              alignItems="center"
                              className="pad-top-5"
                            >
                              <Grid item xs={1}>
                                <span className="custom-label">17a.</span>
                              </Grid>
                              <Grid item xs={3} className="custom-claim-id">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    className="custom-input"
                                    disabled={isDisabled}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                className="custom-claim-id pad-left-0"
                              >
                                <FormControl fullWidth>
                                  <FormControl fullWidth>
                                    <TextField
                                      type="text"
                                      size="small"
                                      className="custom-input"
                                      disabled={isDisabled}
                                    />
                                  </FormControl>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={12}
                              alignItems="center"
                              className="pad-top-1"
                            >
                              <Grid item xs={1}>
                                <span className="custom-label">17b.</span>
                              </Grid>
                              <Grid item xs={3} className=" pad-top-1">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    className="custom-input fontbold"
                                    placeholder="NPI"
                                    disabled={isDisabled}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={8} className=" pad-left-0">
                                <FormControl fullWidth>
                                  <FormControl fullWidth>
                                    <TextField
                                      type="text"
                                      size="small"
                                      className="custom-input"
                                      disabled={isDisabled}
                                    />
                                  </FormControl>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              18. Hospitalization Dates Related To Current
                              Services
                            </AntDesignFormLabel>
                            <Grid container spacing={2} className="pad-top-1">
                              <Grid item xs={6} className="custom-claim-id">
                                <FormControl fullWidth>
                                  <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          label="From Date"
                                          size="small"
                                          // className="custom-datepicker-from"
                                          className="custom-input font-size-13"
                                          disabled={isDisabled}
                                          // slotProps={{ textField: { size: "small", readOnly: true } }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              InputLabelProps: {
                                                shrink: true, // Add shrink property here
                                                sx: {
                                                  fontSize: "13px",
                                                  textAlign: "center",
                                                  top: '7px',
                                                  left: '5px',
                                                },
                                              },
                                            },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                          value={formatDateCMS(
                                            hospitalizationDate?.fromDate
                                          )}
                                          name="fromDate"
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              hospitalizationDate,
                                              "fromDate",
                                              setHospitalizationDate
                                            )
                                          }
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className="custom-claim-id pad-left-0"
                              >
                                {/* <FormControl fullWidth>
                                    <div className="input-date-label-space">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={["DatePicker"]}
                                        >
                                          <DatePicker
                                            error={true}
                                            inputFormat="MM/dd/yyyy"
                                            label="To Date"
                                            size="small"
                                            className="custom-datepicker-to"
                                            disabled={isDisabled}
                                            // slotProps={{ textField: { size: "small", readOnly: true } }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                readOnly: true,
                                                InputLabelProps: {
                                                  shrink: true, // Add shrink property here
                                                  sx: {
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                  },
                                                },
                                              },
                                            }}
                                            InputLabelProps={{
                                              sx: {
                                                fontSize: "12px",
                                                textAlign: "center",
                                              },
                                            }}
                                            value={formatDateCMS(
                                              hospitalizationDate?.toDate
                                            )}
                                            name="toDate"
                                            onChange={(e) =>
                                              handleStateChanges(
                                                e,
                                                hospitalizationDate,
                                                "toDate",
                                                setHospitalizationDate
                                              )
                                            }
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </div>
                                  </FormControl> */}
                                <FormControl fullWidth>
                                  <div className="input-date-label-space">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          label="To Date"
                                          size="small"
                                          // className="custom-datepicker-from"
                                          className="custom-input font-size-13"
                                          disabled={isDisabled}
                                          // slotProps={{ textField: { size: "small", readOnly: true } }}
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              InputLabelProps: {
                                                shrink: true, // Add shrink property here
                                                sx: {
                                                  fontSize: "13px",
                                                  textAlign: "center",
                                                  top: '7px',
                                                  left: '5px',
                                                },
                                              },
                                            },
                                          }}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                          value={formatDateCMS(
                                            hospitalizationDate?.toDate
                                          )}
                                          name="toDate"
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              hospitalizationDate,
                                              "toDate",
                                              setHospitalizationDate
                                            )
                                          }
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={8}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel>
                              19. Additional Claim Information (Designated by
                              NUCC)
                            </AntDesignFormLabel>
                            <TextField
                              type="Text"
                              size="small"
                              disabled={isDisabled}
                              className="custom-input"
                              name="additionalClaim"
                              onChange={(e) =>
                                setAdditionalClaim(e.target.value)
                              }
                              value={additionalClaim}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <AntDesignFormLabel>
                                  20. Outside Lab?
                                </AntDesignFormLabel>
                                <RadioGroup
                                  row
                                  className="custom-radio-group "
                                  value={outsideLab?.outsideLab}
                                  name="outsideLab"
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      outsideLab,
                                      "outsideLab",
                                      setoutsideLab
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value="yes"
                                    disabled={isDisabled}
                                    control={<Radio size="small" />}
                                    label="Yes"
                                    className="custom-radio"
                                  />
                                  <FormControlLabel
                                    value="no"
                                    disabled={isDisabled}
                                    control={<Radio size="small" />}
                                    label="No"
                                    className="custom-radio"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="pad-left-0">
                              <FormControl fullWidth>
                                <AntDesignFormLabel>
                                  $ Charges
                                </AntDesignFormLabel>
                                <MoneyInput
                                  type="Text"
                                  size="small"
                                  value={outsideLab?.charges}
                                  name="charges"
                                  onChange={(e) =>
                                    handleStateChanges(
                                      e,
                                      outsideLab,
                                      "charges",
                                      setoutsideLab
                                    )
                                  }
                                  className="custom-input"
                                  disabled={isDisabled}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={8}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <FormControl fullWidth>
                                  <AntDesignFormLabel>
                                    21. Diagnosis Or Nature Of Illness Or
                                    Injury Relate A-L to service line below
                                    (24E)
                                  </AntDesignFormLabel>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} className="pad-left-0">
                                <FormControl fullWidth>
                                  <AntDesignFormLabel className="pad-bottom-0">
                                    ICD Ind
                                    <TextField
                                      type="Text"
                                      size="small"
                                      className="custom-label-input"
                                      disabled={isDisabled}
                                    />
                                  </AntDesignFormLabel>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Box>
                              <Grid
                                container
                                spacing={2}
                                className="pad-left-5 pad-right-5 custom-bill-align"
                              >
                                {diagnosis.map((val, index) => {
                                  const selectedDiagnosisCodes = diagnosis
                                    .slice(0, index)
                                    .map(
                                      (diagnosisItem) =>
                                        diagnosisItem.diagnosisCode
                                    )
                                    .filter(Boolean);
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      md={2}
                                      lg={2}
                                      key={index}
                                    >
                                      <Autocomplete
                                        freeSolo
                                        filterOptions={(options) => options}
                                        getOptionLabel={(option) =>
                                          typeof option === "string"
                                            ? option
                                            : `${option.code} - ${option.description}`
                                        }
                                        options={
                                          diagnosisList.length === 0
                                            ? []
                                            : diagnosisList
                                              .filter(
                                                (option) =>
                                                  !selectedDiagnosisCodes.includes(
                                                    option.diagnosisCode
                                                  )
                                              )
                                              .map((option) => ({
                                                code: option.diagnosisCode,
                                                description:
                                                  option.shortDescription,
                                              }))
                                        }
                                        value={val.diagnosisCode || ""}
                                        onChange={(e, value, reason) => {
                                          const updatedValue =
                                            typeof value === "string"
                                              ? {
                                                code: "",
                                                description: value,
                                              }
                                              : value;
                                          onSelectedDiagnosis(
                                            e,
                                            val.name,
                                            updatedValue,
                                            val.diagnosisCode
                                          );
                                        }}
                                        onInputChange={(e, value, reason) => {
                                          handleDiagnosisChange(
                                            e,
                                            value,
                                            val.name,
                                            val.diagnosisCode,
                                            reason
                                          );
                                        }}
                                        PaperComponent={customDropdownStyle}
                                        disableClearable
                                        disabled={
                                          isDisabled ||
                                          (index > 0 &&
                                            !diagnosis[index - 1].value)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            id={val.name}
                                            label={val.name}
                                            name={val.name}
                                            size="small"
                                            className="custom-input-label"
                                            InputLabelProps={{
                                              shrink: true,
                                              className: "custom-label-claim",
                                            }}
                                            inputProps={{
                                              ...params.inputProps,
                                              style: {
                                                ...inputCursorBlock,
                                                cursor:
                                                  isDisabled ||
                                                    (index > 0 &&
                                                      !diagnosis[index - 1]
                                                        .value)
                                                    ? "not-allowed"
                                                    : "text",
                                              },
                                            }}
                                            InputProps={{
                                              ...params.InputProps,
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  {!isDisabled && (
                                                    <IconButton
                                                      edge="end"
                                                      onClick={() => {
                                                        if (
                                                          params.inputProps
                                                            .value.length >= 3
                                                        ) {
                                                          handleDiagnosis(
                                                            val.name
                                                          );
                                                        }
                                                      }}
                                                      disabled={
                                                        params.inputProps
                                                          .value.length < 3
                                                      }
                                                    >
                                                      <SearchIcon className="custom-icon-claim" />
                                                    </IconButton>
                                                  )}

                                                  {
                                                    params.InputProps
                                                      .endAdornment
                                                  }
                                                </InputAdornment>
                                              ),
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                  params.inputProps.value
                                                    .length >= 3
                                                ) {
                                                  handleDiagnosis(val.name);
                                                } else {
                                                  setNotify({
                                                    isOpen: true,
                                                    message:
                                                      "Enter at least three characters",
                                                    type: "error",
                                                  });
                                                }
                                              }
                                            }}
                                            onFocus={() => handleFocus(index)}
                                            // onBlur={() => handleBlur(index)}
                                            // helperText={
                                            //   focusStates[index] &&
                                            //     params.inputProps.value.length <
                                            //     3
                                            //     ? ""
                                            //     : ""
                                            // }
                                            onBlur={onBlurDiagnosis}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="border-right pad-0"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <AntDesignFormLabel variant="body2">
                                  22. Resubmission Code
                                </AntDesignFormLabel>
                                <TextField
                                  type="Text"
                                  size="small"
                                  className="custom-input"
                                  value={resubmissionCode?.code}
                                  disabled={isDisabled}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      resubmissionCode,
                                      "code",
                                      setResubmissionCode
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6} className="pad-left-0">
                              <FormControl fullWidth>
                                <AntDesignFormLabel variant="body2">
                                  Original Ref. No.
                                </AntDesignFormLabel>
                                <TextField
                                  type="Text"
                                  size="small"
                                  className="custom-input"
                                  value={resubmissionCode?.originalRefNo}
                                  disabled={isDisabled}
                                  onChange={(event) =>
                                    handleStateChanges(
                                      event,
                                      resubmissionCode,
                                      "originalRefNo",
                                      setResubmissionCode
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="pad-top-0">
                            <FormControl fullWidth>
                              <AntDesignFormLabel>
                                23. Prior Authorization Number
                              </AntDesignFormLabel>
                              <TextField
                                type="Text"
                                size="small"
                                className="custom-input"
                                name="priorauthorizationnumber"
                                value={
                                  claimInformation
                                    ?.claimSupplementalInformation
                                    ?.priorAuthorizationNumber
                                }
                                disabled={isDisabled}
                                onChange={(e) =>
                                  setPriorAuthorizationNumber(e.target.value)
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid item xs={12} className="pad-0">
                          <Box>
                            {!isDisabled && (
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  className="pad-bottom-5 text-align-left"
                                >
                                  <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    disabled={isDisabled}
                                    onClick={() => {
                                      btnAddService();
                                    }}
                                    className="custom-Cms-button"
                                  >
                                    Add Service
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                            <div className="custom-datagrid">
                              <Box>
                                <Grid
                                  spacing={0}
                                  className="custom-grid-container"
                                >
                                  <Grid
                                    container
                                    className="custom-grid-content"
                                  >
                                    {!isDisabled && (
                                      <Grid item className="grid-input">
                                        <Typography className="custom-label">
                                          Action
                                        </Typography>
                                      </Grid>
                                    )}
                                    <Grid
                                      container
                                      className="custom-grid-width"
                                    >
                                      <Grid
                                        item
                                        className="custom-grid-header"
                                      >
                                        <Typography className="custom-label">
                                          24.A. Date(s) Of Service
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        className="grid-inside-header"
                                      >
                                        <Typography className="custom-label">
                                          From
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        className="grid-inside-header"
                                      >
                                        <Typography className="custom-label">
                                          To
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid item className="grid-inside-header">
                                      <Typography className="custom-label">
                                        B. Place Of Service
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        C. EMG
                                      </Typography>
                                    </Grid>
                                    <Grid container style={{ width: 400 }}>
                                      <Grid
                                        item
                                        xs={12}
                                        className="custom-grid-header"
                                      >
                                        <Typography className="custom-label">
                                          D. Procedures, Services, Or Supplies
                                        </Typography>
                                      </Grid>
                                      <Grid container item xs={12}>
                                        <Grid
                                          item
                                          xs={4}
                                          className="grid-inside-header"
                                        >
                                          <Typography className="custom-label">
                                            CPT/HCPCS
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={8}
                                          className="grid-inside-header"
                                        >
                                          <Typography className="custom-label">
                                            Modifier
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        E. Diagnosis Pointer
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          *
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        F. $ Charges
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          *
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        G. Days Or Units
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-inside-header">
                                      <Typography className="custom-label">
                                        H. EPSDT Family Plan
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        I. ID. Qual
                                      </Typography>
                                    </Grid>
                                    <Grid item className="grid-input">
                                      <Typography className="custom-label">
                                        J. Rendering Provider ID.#
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {serviceList?.map((row, index) => (
                                    <Grid
                                      container
                                      className="custom-grid-body"
                                      key={`${row.assignedNumber}`}
                                    >
                                      <ConfirmDialog
                                        open={isDialogOpen}
                                        onClose={() => setDialogOpen(false)}
                                        onConfirm={handleConfirmServiceDelete}
                                      />
                                      {!isDisabled && (
                                        <Grid item className="grid-input">
                                          <Tooltip title="Delete">
                                            <IconButton
                                              aria-label="delete"
                                              size="small"
                                              onClick={() =>
                                                handleDeleteClick(
                                                  row.assignedNumber
                                                )
                                              }
                                            >
                                              <DeleteIcon
                                                fontSize="inherit"
                                                className="delete-color"
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                      )}
                                      <Grid
                                        container
                                        className="custom-grid-width"
                                      >
                                        <Grid
                                          item
                                          className="grid-inside-header"
                                        >
                                          {/* <FormControl fullWidth>
                                              <div className="input-date-label-space">
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                  >
                                                    <DatePicker
                                                      key={row.assignedNumber}
                                                      inputFormat="MM/dd/yyyy"
                                                      label="From Date"
                                                      size="small"
                                                      // className={`custom-datepicker-from-grid ${!row.fromDate ? 'custom-input-required' : ''}`} // Apply red border if the field is empty
                                                      className="custom-datepicker-from-grid" // Apply red border if the field is empty
                                                      value={formatDateCMS(
                                                        row.fromDate
                                                      )}
                                                      disabled={isDisabled}
                                                      onChange={(newValue) =>
                                                        handleServiceInputChange(
                                                          index,
                                                          "fromDate",
                                                          newValue.format(
                                                            "YYYYMMDD"
                                                          ),
                                                          setServiceList,
                                                          formData,
                                                          diagnosis
                                                        )
                                                      }
                                                      // slotProps={{ textField: { size: "small", readOnly: true } }}
                                                      slotProps={{
                                                        textField: {
                                                          size: "small",
                                                          readOnly: true,
                                                          InputLabelProps: {
                                                            shrink: true, // Add shrink property here
                                                            sx: {
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            },
                                                            fullWidth: true, 
                                                          },

                                                        },
                                                      }}
                                                  
                                                      InputLabelProps={{
                                                        sx: {
                                                          fontSize: "12px",
                                                          textAlign: "center",
                                                        },
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          error={
                                                            !!row.error
                                                              ?.fromDate
                                                          }
                                                          helperText={
                                                            row.error?.fromDate
                                                              ? "Required"
                                                              : ""
                                                          }
                                                          size="small"
                                                          InputProps={{
                                                            ...params.InputProps,
                                                            readOnly: true,
                                                            style: {
                                                              cursor: "pointer",
                                                            }, // Make sure the input field is read-only to prevent manual typing
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              </div>
                                            </FormControl> */}
                                          <FormControl fullWidth>
                                            <div className="input-date-label-space">
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DemoContainer
                                                  components={["DatePicker"]}
                                                >
                                                  <DatePicker
                                                    key={row.assignedNumber}
                                                    error={true}
                                                    inputFormat="MM/dd/yyyy"
                                                    label="From Date"
                                                    size="small"
                                                    // className="custom-datepicker-from"
                                                    className="custom-input font-size-13" 
                                                    disabled={isDisabled}
                                                    // slotProps={{ textField: { size: "small", readOnly: true } }}
                                                    slotProps={{
                                                      textField: {
                                                        size: "small",
                                                        readOnly: true,
                                                        InputLabelProps: {
                                                          shrink: true, // Add shrink property here
                                                          sx: {
                                                            fontSize: "13px",
                                                            textAlign: "center",
                                                            top: '7px',
                                                            left: '5px',
                                                          },
                                                        },
                                                      },
                                                    }}
                                                    InputLabelProps={{
                                                      sx: {
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                      },
                                                    }}
                                                    value={formatDateCMS(
                                                      row.fromDate
                                                    )}
                                                    name="fromDate"
                                                    onChange={(newValue) =>
                                                      handleServiceInputChange(
                                                        index,
                                                        "fromDate",
                                                        newValue.format(
                                                          "YYYYMMDD"
                                                        ),
                                                        setServiceList,
                                                        formData,
                                                        diagnosis
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        error={
                                                          !!row.error
                                                            ?.fromDate
                                                        }
                                                        helperText={
                                                          row.error?.fromDate
                                                            ? "Required"
                                                            : ""
                                                        }
                                                        size="small"
                                                        InputProps={{
                                                          ...params.InputProps,
                                                          readOnly: true,
                                                          style: {
                                                            cursor: "pointer",
                                                          }, // Make sure the input field is read-only to prevent manual typing
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                            </div>
                                          </FormControl>
                                        </Grid>
                                        <Grid
                                          item
                                          className="grid-inside-header"
                                        >
                                          {/* <FormControl fullWidth>
                                              <div className="input-date-label-space">
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DemoContainer
                                                    components={["DatePicker"]}
                                                  >
                                                    <DatePicker
                                                      key={row.assignedNumber}
                                                      inputFormat="MM/dd/yyyy"
                                                      label="To Date"
                                                      size="small"
                                                      // className={`custom-datepicker-to-grid ${!row.toDate ? 'custom-input-required' : ''}`} // Apply red border if the field is empty
                                                      className="custom-datepicker-to-grid"
                                                      value={formatDateCMS(
                                                        row.toDate
                                                      )}
                                                      onChange={(newValue) =>
                                                        handleServiceInputChange(
                                                          index,
                                                          "toDate",
                                                          newValue.format(
                                                            "YYYYMMDD"
                                                          ),
                                                          setServiceList,
                                                          formData,
                                                          diagnosis
                                                        )
                                                      }
                                                      // slotProps={{ textField: { size: "small", readOnly: true } }}
                                                      slotProps={{
                                                        textField: {
                                                          size: "small",
                                                          readOnly: true,
                                                          InputLabelProps: {
                                                            shrink: true, // Add shrink property here
                                                            sx: {
                                                              fontSize: "12px",
                                                              textAlign:
                                                                "center",
                                                            },
                                                          },
                                                        },
                                                      }}
                                                      disabled={isDisabled}
                                                      InputLabelProps={{
                                                        sx: {
                                                          fontSize: "12px",
                                                          textAlign: "center",
                                                        },
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          error={
                                                            !!row.error?.toDate
                                                          }
                                                          helperText={
                                                            row.error?.toDate
                                                              ? "Required"
                                                              : ""
                                                          } // Display 'Required' when there's an error
                                                          size="small"
                                                        />
                                                      )}
                                                    />
                                                  </DemoContainer>
                                                </LocalizationProvider>
                                              </div>
                                            </FormControl> */}
                                          <FormControl fullWidth>
                                            <div className="input-date-label-space">
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <DemoContainer
                                                  components={["DatePicker"]}
                                                >
                                                  <DatePicker
                                                    key={row.assignedNumber}
                                                    inputFormat="MM/dd/yyyy"
                                                    label="To Date"
                                                    size="small"
                                                    // className="custom-datepicker-from"
                                                    className="custom-input font-size-13"
                                                    disabled={isDisabled}
                                                    // slotProps={{ textField: { size: "small", readOnly: true } }}
                                                    slotProps={{
                                                      textField: {
                                                        size: "small",
                                                        readOnly: true,
                                                        InputLabelProps: {
                                                          shrink: true, // Add shrink property here
                                                          sx: {
                                                            fontSize: "13px",
                                                            textAlign: "center",
                                                            top: '7px',
                                                            left: '5px',
                                                          },
                                                        },
                                                      },
                                                    }}
                                                    InputLabelProps={{
                                                      sx: {
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                      },
                                                    }}
                                                    value={formatDateCMS(
                                                      row.toDate
                                                    )}
                                                    name="toDate"
                                                    onChange={(newValue) =>
                                                      handleServiceInputChange(
                                                        index,
                                                        "toDate",
                                                        newValue.format(
                                                          "YYYYMMDD"
                                                        ),
                                                        setServiceList,
                                                        formData,
                                                        diagnosis
                                                      )
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        error={
                                                          !!row.error
                                                            ?.fromDate
                                                        }
                                                        helperText={
                                                          row.error?.fromDate
                                                            ? "Required"
                                                            : ""
                                                        }
                                                        size="small"
                                                        InputProps={{
                                                          ...params.InputProps,
                                                          readOnly: true,
                                                          style: {
                                                            cursor: "pointer",
                                                          }, // Make sure the input field is read-only to prevent manual typing
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                </DemoContainer>
                                              </LocalizationProvider>
                                            </div>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        className="grid-inside-header"
                                      >
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row.placeOfService}
                                          name="placeOfService"
                                          disabled={isDisabled}
                                          required
                                          InputLabelProps={{ shrink: true }}
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "placeOfService",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row?.emg}
                                          name="emg"
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "emg",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid container sx={{ width: 399 }}>
                                        <Grid
                                          item
                                          className="grid-inside-header-cpt pad-left-5 pad-top-5"
                                        >
                                          <Autocomplete
                                            fullWidth
                                            freeSolo
                                            key={row.assignedNumber}
                                            id={`cptHcpcs-${index}`}
                                            name="cptHcpcs"
                                            // className={`custom-input-label ${!row.isCptValid ? 'custom-input-required' : ''}`} // Apply red border if the field is empty
                                            className=""
                                            filterOptions={(options) =>
                                              options
                                            }
                                            options={
                                              serviceCPTList.length === 0
                                                ? []
                                                : serviceCPTList.map(
                                                  (option) => ({
                                                    code: option.item_Code,
                                                    description:
                                                      option.item_Description,
                                                  })
                                                )
                                            }
                                            getOptionLabel={(option) =>
                                              typeof option === "string"
                                                ? option
                                                : `${option.code} - ${option.description}`
                                            }
                                            value={
                                              row.cptHcpcs
                                                ? row.cptHcpcs
                                                : undefined
                                            }
                                            onChange={(event, newValue) => {
                                              const updatedValue =
                                                typeof newValue === "string"
                                                  ? {
                                                    code: "",
                                                    description: newValue,
                                                  }
                                                  : newValue;
                                              handleServiceCPTChange(
                                                index,
                                                event,
                                                updatedValue,
                                                setServiceCPT
                                              );
                                            }}
                                            onInputChange={(
                                              event,
                                              newValue
                                            ) =>
                                              handleServiceCPTChange(
                                                index,
                                                event,
                                                newValue,
                                                setServiceCPT
                                              )
                                            }
                                            PaperComponent={
                                              customDropdownStyle
                                            }
                                            disableClearable
                                            disabled={isDisabled}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Search"
                                                size="small"
                                                className="custom-input-label"
                                                InputLabelProps={{
                                                  shrink: true,
                                                  className:
                                                    "custom-label-claim",
                                                }}
                                                disabled={isDisabled}
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      {!isDisabled && (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() =>
                                                            handleServiceCPTInfo(
                                                              params
                                                                .inputProps
                                                                .value
                                                            )
                                                          }
                                                        >
                                                          <SearchIcon className="custom-icon-claim" />
                                                        </IconButton>
                                                      )}
                                                      {
                                                        params.InputProps
                                                          .endAdornment
                                                      }
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleServiceCPTInfo(
                                                      params.inputProps.value
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          className="grid-inside-header-modifier pad-left-5 pad-top-5"
                                          style={{ width: 266 }}
                                        >
                                          <Autocomplete
                                            key={row.assignedNumber}
                                            fullWidth
                                            freeSolo
                                            multiple
                                            id={`modifier-${index}`}
                                            name="modifier"
                                            filterOptions={(options) =>
                                              options
                                            }
                                            options={
                                              serviceModifierList.length === 0
                                                ? []
                                                : serviceModifierList
                                                  .map((option) => ({
                                                    code: option.code,
                                                    description:
                                                      option.description,
                                                  }))
                                                  .filter(
                                                    (option) =>
                                                      !row.modifier.includes(
                                                        option.code
                                                      )
                                                  )
                                            }
                                            getOptionLabel={(option) =>
                                              typeof option === "string"
                                                ? option
                                                : `${option.code} - ${option.description}`
                                            }
                                            value={
                                              Array.isArray(row?.modifier)
                                                ? row.modifier
                                                : []
                                            }
                                            onChange={(event, newValue) =>
                                              handleServiceModifierChange(
                                                index,
                                                event,
                                                newValue,
                                                setServiceModifier
                                              )
                                            }
                                            PaperComponent={
                                              customDropdownStyle
                                            }
                                            disableClearable
                                            disabled={isDisabled}
                                            renderTags={(
                                              value,
                                              getTagProps
                                            ) =>
                                              value
                                                .slice(0, 4)
                                                .map((option, index) => (
                                                  <Chip
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({
                                                      index,
                                                    })}
                                                    className="custom-chip-style custom-label"
                                                  />
                                                ))
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                size="small"
                                                className="custom-input-label custom-label"
                                                InputLabelProps={{
                                                  shrink: true,
                                                  className:
                                                    "custom-label-claim",
                                                }}
                                                disabled={isDisabled}
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      {!isDisabled && (
                                                        <IconButton
                                                          edge="end"
                                                          onClick={() =>
                                                            handleServiceModifierInfo(
                                                              params
                                                                .inputProps
                                                                .value
                                                            )
                                                          }
                                                        >
                                                          <SearchIcon className="custom-icon-claim" />
                                                        </IconButton>
                                                      )}
                                                      {
                                                        params.InputProps
                                                          .endAdornment
                                                      }
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                onKeyDown={(e) => {
                                                  if (e.key === "Enter") {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleServiceModifierInfo(
                                                      params.inputProps.value
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          name="diagnosisPointer"
                                          value={row?.diagnosisPointer}
                                          disabled={isDisabled}
                                          className="custom-input margin-top-5 "
                                          // className={`custom-input margin-top-5 ${!row?.diagnosisPointer ? 'custom-input-required' : ''}`} // Apply red border if the field is empty
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "diagnosisPointer",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <MoneyInput
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          name="charges"
                                          // className={`custom-input margin-top-5 ${!row?.charges ? 'custom-input-required' : ''}`} // Apply red border if the field is empty
                                          className="custom-input margin-top-5"
                                          value={row?.charges}
                                          disabled={isDisabled}
                                          required
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "charges",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row?.daysOrUnits}
                                          name="daysOrUnits"
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "daysOrUnits",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        className="grid-inside-header"
                                      >
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row?.epsdtFamilyPlan}
                                          name="epsdtFamilyPlan"
                                          disabled={isDisabled}
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "epsdtFamilyPlan",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <TextField
                                          key={row.assignedNumber}
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row?.idQual}
                                          disabled={isDisabled}
                                          name="idQual"
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "idQual",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item className="grid-input">
                                        <TextField
                                          key={row.assignedNumber}
                                          disabled={isDisabled}
                                          name="renderingProvider"
                                          type="text"
                                          size="small"
                                          className="custom-input margin-top-5"
                                          value={row?.renderingProviderId}
                                          onChange={(e) =>
                                            handleServiceInputChange(
                                              index,
                                              "renderingProviderId",
                                              e.target.value,
                                              setServiceList,
                                              diagnosis
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={5}
                          className="custom-border-left pad-0 adjust-flex-custom-first"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" custom-aline-tax ">
                              25. Federal Tax ID Number
                            </AntDesignFormLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    disabled={isDisabled}
                                    className="custom-input"
                                    value={
                                      federalTaxInformation?.federalTaxId
                                    }
                                    name="federalTaxId"
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        federalTaxInformation,
                                        "federalTaxId",
                                        setFederalTaxInformation
                                      )
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} className="pad-0">
                                <FormControl fullWidth>
                                  <RadioGroup
                                    row
                                    className="custom-radio-group custom-spacing-tax"
                                    value={
                                      federalTaxInformation?.federalTaxOption
                                    }
                                    name="federalTaxOption"
                                    onChange={(event) =>
                                      handleStateChanges(
                                        event,
                                        federalTaxInformation,
                                        "federalTaxOption",
                                        setFederalTaxInformation
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      value="ssn"
                                      disabled={isDisabled}
                                      control={<Radio size="small" />}
                                      label="SSN"
                                      className="custom-radio"
                                    />
                                    <FormControlLabel
                                      value="ein"
                                      disabled={isDisabled}
                                      control={<Radio size="small" />}
                                      label="EIN"
                                      className="custom-radio"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" custom-aline-tax">
                              26. Patient's Account No
                            </AntDesignFormLabel>
                            <TextField
                              type="text"
                              size="small"
                              className="custom-input "
                              disabled={isDisabled}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" custom-aline-amnt">
                              27. Accept Assignment? (For Govt Claims, See
                              Back)
                            </AntDesignFormLabel>
                            <RadioGroup
                              row
                              className="custom-radio-group"
                              value={acceptAssignmentData}
                              name="acceptAssignmentData"
                              onChange={(event) =>
                                setAcceptAssignmentData(event.target.value)
                              }
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio size="small" />}
                                disabled={isDisabled}
                                label="Yes"
                                className="custom-radio"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio size="small" />}
                                disabled={isDisabled}
                                label="No"
                                className="custom-radio"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" custom-aline-amnt">
                              28. Total Charge
                            </AntDesignFormLabel>
                            <MoneyInput
                              name="totalCharges"
                              value={totalCharges || ""}
                              onChange={(event) =>
                                setTotalCharges(event.target.value)
                              }
                              disabled={isDisabled}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel
                              className=" custom-aline-amnt"
                              name="amountPaid"
                            >
                              29. Amount Paid
                            </AntDesignFormLabel>
                            <MoneyInput
                              name="amountPaid"
                              value={amountPaid || ""}
                              disabled={isDisabled}
                              onChange={(event) =>
                                setAmountPaid(event.target.value)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          className="border-right adjust-flex-custom-last pad-0"
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" custom-aline-amnt">
                              30. Rsvd. for NUCC Use
                            </AntDesignFormLabel>
                            <MoneyInput
                              disabled
                              className="custom-input-disabled "
                              inputProps={{
                                style: inputCursorBlock,
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className="table-bottom-spacing"
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" margin-bottom-0">
                              31.SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING
                              DEGREES OR CREDENTIALS (I certify that the
                              statement on the reverse apply to this bill and
                              are made a part thereof){" "}
                            </AntDesignFormLabel>
                            <FormControl
                              fullWidth
                              className="input-date-spacing custom-label-textarea pad-5"
                            >
                              <TextField
                                rows={2}
                                // rows={2}
                                value={physicianSignature?.description}
                                name="description"
                                disabled={isDisabled}
                                InputProps={{
                                  className: 'custom-label textarea-height'
                                }}
                                onChange={(e) =>
                                  handleStateChanges(
                                    e,
                                    physicianSignature,
                                    "description",
                                    setPhysicianSignature
                                  )
                                }
                              ></TextField>
                            </FormControl>
                            <Grid container spacing={2} className="pad-left-5">
                              <Grid item xs={7}>
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    label="Signed"
                                    size="small"
                                    className="custom-input-label"
                                    value={physicianSignature?.signed}
                                    name="signed"
                                    disabled={isDisabled}
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        physicianSignature,
                                        "signed",
                                        setPhysicianSignature
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                      className: "custom-label-claim",
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} className="pad-left-0">
                                <FormControl fullWidth>
                                  <div className="input-date-spacing ">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <DatePicker
                                          error={true}
                                          inputFormat="MM/dd/yyyy"
                                          size="small"
                                          className="custom-input"
                                          slotProps={{
                                            textField: {
                                              size: "small",
                                              readOnly: true,
                                              fullWidth: true, // Ensure full width in the text field props
                                              top: '7px',
                                              left: '5px',
                                            },
                                          }}
                                          name="signedDate"
                                          value={formatDateCMS(
                                            physicianSignature?.signedDate
                                          )}
                                          onChange={(e) =>
                                            handleStateChanges(
                                              e,
                                              physicianSignature,
                                              "signedDate",
                                              setPhysicianSignature
                                            )
                                          }
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: "12px",
                                              textAlign: "center",
                                            },
                                          }}
                                          disabled={isDisabled}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          className="custom-border-left pad-0 "
                        >
                          <FormControl fullWidth>
                            <AntDesignFormLabel className=" margin-bottom-0 custom-label-length">
                              32.Service Facility Location Information{" "}
                            </AntDesignFormLabel>
                            <FormControl
                              fullWidth
                              className="input-date-spacing custom-label-textarea pad-5"
                            >
                              <TextField
                                rows={2}
                                // rows={2}
                                value={serviceFacilityLocation?.description}
                                name="description"
                                disabled={isDisabled}
                                onChange={(e) =>
                                  handleStateChanges(
                                    e,
                                    serviceFacilityLocation,
                                    "description",
                                    setServiceFacilityLocation
                                  )
                                }
                                InputProps={{
                                  className: 'custom-label textarea-height'
                                }}
                              ></TextField>
                            </FormControl>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    disabled={isDisabled}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <div className="txt-placeholder-container">
                                            <span className="fontbold">
                                              a
                                            </span>
                                            <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              className="divider-container"
                                            />
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="NPI"
                                    className="custom-input custom-icon-claim custom-placeholder-divider"
                                    value={serviceFacilityLocation?.npi}
                                    name="npi"
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        serviceFacilityLocation,
                                        "npi",
                                        setServiceFacilityLocation
                                      )
                                    }
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} className="pad-left-0">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    name="b32"
                                    disabled={isDisabled}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <div className="txt-placeholder-container">
                                            <span className="fontbold">
                                              b
                                            </span>
                                            <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              className="divider-container"
                                            />
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={serviceFacilityLocation?.b32}
                                    onChange={(e) =>
                                      handleStateChanges(
                                        e,
                                        serviceFacilityLocation,
                                        "b32",
                                        setServiceFacilityLocation
                                      )
                                    }
                                    className="custom-input custom-icon-claim custom-placeholder-divider"
                                  />{" "}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={3}
                          className="border-right pad-0"
                        >
                          <FormControl fullWidth >
                            <AntDesignFormLabel className=" margin-bottom-0 custom-label-infophn">
                              33. Billing Provider Info & Ph#
                              <TextField
                                disabled={isDisabled}
                                type="text"
                                size="small"
                                className="custom-label-phn"
                                name="phoneNumber"
                                value={
                                  billingData?.contactInformation
                                    ?.phoneNumber ?? ""
                                }
                                onChange={(e) => {
                                  handleStateChanges(
                                    e,
                                    billingData,
                                    "contactInformation",
                                    setBillingData
                                  );
                                }}
                              />
                            </AntDesignFormLabel>
                            <FormControl
                              fullWidth
                              className="input-date-spacing custom-label custom-label-textarea pad-5 "
                            >
                              <TextField
                                rows={3}
                                // rows={2}
                                className='custom-placeholder'
                                value={
                                  [
                                    billingData?.address?.address1,
                                    billingData?.address?.address2,
                                    billingData?.address?.city,
                                    billingData?.address?.state,
                                    billingData?.address?.postalCode,
                                  ]
                                    .filter(Boolean) // Filter out empty strings or undefined values
                                    .join(",") // Join the remaining parts with a comma and space
                                }
                                disabled={isDisabled}
                                onChange={(e) =>
                                  handleBillingAddressChanges(
                                    e,
                                    billingData,
                                    "address",
                                    setBillingData
                                  )
                                }
                                InputProps={{
                                  className: 'custom-label textarea-height'

                                }}
                              />
                            </FormControl>


                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    placeholder="NPI"
                                    value={billingData?.npi}
                                    disabled={isDisabled}
                                    onChange={(e) => {
                                      handleStateChanges(
                                        e,
                                        billingData,
                                        "npi",
                                        setBillingData
                                      );
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <div className="txt-placeholder-container">
                                            <span className="fontbold">
                                              a
                                            </span>
                                            <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              className="divider-container"
                                            />
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                    className="custom-input custom-icon-claim custom-placeholder-divider"
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={6} className="pad-left-0">
                                <FormControl fullWidth>
                                  <TextField
                                    type="text"
                                    size="small"
                                    disabled={isDisabled}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <div className="txt-placeholder-container">
                                            <span className="fontbold">
                                              b
                                            </span>
                                            <Divider
                                              orientation="vertical"
                                              variant="middle"
                                              className="divider-container"
                                            />
                                          </div>
                                        </InputAdornment>
                                      ),
                                    }}
                                    className="custom-input custom-icon-claim custom-placeholder-divider"
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </ThemeProvider>
              </div>
            </Container>
            {/* </div> */}
          </AccordionDetails>
        </Accordion>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={() => handleClose(setOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isSubmitButtonClick === 0
            ? "Void"
            : isSubmitButtonClick === 1
              ? "Copy Claim"
              : isSubmitButtonClick === 5
                ? "Hold"
                : isSubmitButtonClick === 6
                  ? "Release"
                  : "Verify Claim Status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isSubmitButtonClick === 0
              ? "Are you sure you want to void this record?"
              : isSubmitButtonClick === 1
                ? "Are you sure you want to copy claim this record?"
                : isSubmitButtonClick === 5
                  ? "Are you sure you want to put on hold this record?"
                  : isSubmitButtonClick === 6
                    ? "Are you sure you want to release this record?"
                    : "Are you sure you want to verify claim status this record?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="button-popstyles"
            filled={true}
            text="No"
            onClick={() => handleClose(setOpen)}
          >
            No
          </Button>
          <Button
            className="button-popstyles"
            filled={true}
            onClick={() =>
              handlePopupCallButton(
                setOpen,
                handleSaveAndSubmit,
                isSubmitButtonClick
              )
            }
            autoFocus
            text="Yes"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          loadingg ||
          payerLoading ||
          isLoading ||
          proceedLoading ||
          dynosisLoading ||
          serviceCPTLoading ||
          serviceModifierLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CMSMaster;
