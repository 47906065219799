import Constants from '../constants';

export const cosmosIdReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.STORE_LOOKUP_DATA:
            return action.payload;
        default:
            return state;
    }
};

export const captureDetailsDataReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CAPTURE_DETAILS_DATA:
            return action.payload;
        default:
            return state;
    }
};
export const captureDataReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CAPTURE_DATA:
            return action.payload;
        default:
            return state;
    }
};
export const captureSMSDataReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CAPTUR_SMS_DATA:
            return action.payload;
        default:
            return state;
    }
};
export const ClaimStatusDataReducer = (state = null, action) => {
    switch (action.type) {
        case Constants.CLAIM_STATUS_DATA:
            return action.payload;
        default:
            return state;
    }
};
