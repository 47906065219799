import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import { GetEdi837Claim } from "../../apis/edi837claim";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import types from "./Types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};

function RecursiveComponent({
  data,
  onChange,
  onAddItem,
  onDeleteItem,
  path = "",
}) {
  const directkeys = [1, 3, 2, 698];
  const handleValueChange = (key, event) => {
    const newPath = path ? `${path}.${key}` : key;
    const newValue = event.target.value === "" ? null : event.target.value;
    onChange(newPath, newValue);
  };

  const renderObjectFields = (obj, prefix = "") => {
    return Object.entries(obj).map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return (
          <div key={prefix + key}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 2 }}>
              {key}:
            </Typography>
            {renderObjectFields(value, prefix + key + ".")}
          </div>
        );
      }
      return (
        <Typography key={prefix + key} variant="body2">
          {key}: {String(value)}
        </Typography>
      );
    });
  };

  return Object.entries(data).map(([key, value]) => {
    const currentPath = path ? `${path}.${key}` : key;
    const isHealthCareCodeInformation = currentPath.startsWith(
      "claimInformation.healthCareCodeInformation"
    );
    // console.log("current path", currentPath)
    const cleanPath = currentPath.replace(/\[\d+\]/g, "[]");
    // console.log("path", cleanPath)
    const kk = types.find((item) => item?.jsonFieldPath === cleanPath);
    // console.log("matching path", kk)

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return (
        <Accordion key={currentPath}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e0e0e0", color: "#000" }}
          >
            {kk?.chcIsRequired == "R" ? (
              <Typography
                style={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
                variant="body1"
              >
                {kk?.displayFieldName}{" "}
                <span>
                  {`( Loop: ${kk?.x12Loop} )`}
                  <span style={{ color: "red", fontSize: "10px" }}>
                    {" "}
                    required
                  </span>
                </span>
              </Typography>
            ) : (
              <Typography
                style={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
                variant="body1"
              >
                {kk?.displayFieldName} <span>{`( Loop: ${kk?.x12Loop} )`}</span>
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 2 }}>
            <RecursiveComponent
              data={value}
              onChange={onChange}
              onAddItem={onAddItem}
              onDeleteItem={onDeleteItem}
              path={currentPath}
            />
          </AccordionDetails>
        </Accordion>
      );
    } else if (Array.isArray(value)) {
      return (
        <Accordion key={currentPath}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: "#e0e0e0", color: "#000" }}
          >
            {kk?.chcIsRequired == "R" ? (
              <Typography
                style={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                }}
                variant="body1"
              >
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {kk?.displayFieldName}
                </span>{" "}
                <span
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >{`( Loop: ${kk?.x12Loop},Segment: ${kk.x12Segment}`}</span>
                <span style={{ color: "red", fontSize: "10px" }}>
                  {" "}
                  required
                </span>
              </Typography>
            ) : (
              <Typography
                tyle={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                }}
                variant="body1"
              >
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {" "}
                  {kk?.displayFieldName}
                </span>{" "}
                <span
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >{`( Loop: ${kk?.x12Loop},Segment: ${kk.x12Segment} )`}</span>
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isHealthCareCodeInformation && (
              <IconButton
                color="primary"
                size="large"
                style={{
                  marginLeft: "1000px",
                }}
              >
                <span
                  onClick={() => onAddItem(currentPath, value)}
                  style={{
                    fontSize: "20px",
                  }}
                >
                  ADD
                </span>
              </IconButton>
            )}

            {value.map((item, index) => (
              <div key={`${currentPath}[${index}]`}>
                {isHealthCareCodeInformation ? (
                  <Box
                    key={`${currentPath}[${index}]`}
                    sx={{
                      border: "1px solid lightgray",
                      borderRadius: "4px",
                      backgroundColor: "#f7f7f7",
                      margin: "10px 0",
                      padding: "10px",
                    }}
                  >
                    <RecursiveComponent
                      key={`${currentPath}[${index}]`}
                      data={item}
                      onChange={onChange}
                      path={`${currentPath}[${index}]`}
                    />
                    {isHealthCareCodeInformation && (
                      <IconButton
                        onClick={() => onDeleteItem(currentPath, index)}
                        color="red"
                        size="small"
                        sx={{
                          float: "right",
                          color: "red",
                          marginTop: "-150px",
                        }}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  <RecursiveComponent
                    key={`${currentPath}[${index}]`}
                    data={item}
                    onChange={onChange}
                    path={`${currentPath}[${index}]`}
                  />
                )}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <Grid
          container
          spacing={2}
          key={currentPath}
          alignItems="center"
          sx={{ marginBottom: 1, mt: 1 }}
        >
          <Grid item xs={4}>
            {kk?.chcIsRequired == "R" ? (
              <Typography
                style={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                  fontSize: "0.875rem",
                }}
                variant="body1"
              >
                {directkeys?.includes(kk?.serialNum) ? (
                  <span style={{ fontWeight: "bold" }}>
                    {kk?.displayFieldName}
                  </span>
                ) : (
                  <span>{kk?.displayFieldName}</span>
                )}
                <span style={{ color: "red", fontSize: "10px" }}>
                  {" "}
                  required
                </span>
                <br></br>
                <span
                  style={{ fontSize: "12px", fontWeight: "normal" }}
                >{`Loop: ${kk?.x12Loop}, Segment: ${kk?.x12Segment}, Element: ${kk?.x12DataElement}`}</span>
              </Typography>
            ) : (
              <Typography
                style={{
                  marginRight: "3px",
                  marginLeft: "2px",
                  overflowWrap: "break-word",
                  fontSize: "0.875rem",
                }}
                variant="body1"
              >
                {directkeys?.includes(kk?.serialNum) ? (
                  <span style={{ fontWeight: "bold" }}>
                    {kk?.displayFieldName}
                  </span>
                ) : (
                  <span style={{}}>{kk?.displayFieldName}</span>
                )}
                <br></br>
                <span
                  style={{ fontSize: "12px" }}
                >{`Loop: ${kk?.x12Loop},Segment: ${kk?.x12Segment}, Element: ${kk?.x12DataElement}`}</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            {kk?.controlType === "TextBox" ? (
              <TextField
                className="m-0"
                fullWidth
                variant="outlined"
                value={value === null ? "" : String(value)}
                onChange={(event) => handleValueChange(key, event)}
                size="small"
              />
            ) : kk?.controlType === "Lookup" ? (
              <FormControl sx={{ m: 0, minWidth: 70, marginLeft: "3px" }}>
                <Select
                  value={value}
                  displayEmpty
                  onChange={(event) => handleValueChange(key, event)}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "40px",
                    width: "60",
                  }}
                >
                  {kk.lookupValues.split(",").map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
        </Grid>
      );
    }
  });
}

function ClaimSubmitDynamic({ dataFromTSL }) {
  const [dataa, setData] = useState();
  const { data, mutateAsync, isLoading, isError } = GetEdi837Claim();
  const providerClaimId = useRef("");
  const providerRef = useRef(null);
  const [isDataDisplaying, setisDataDisplaying] = useState(false);
  const [loadingg, setLoadingg] = useState(false);

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Claims", link: "/patient-list/send-sms-or-email" },
  ];

  // let details = JSON.parse(sessionStorage?.getItem("details"));
  // let details = useSelector((state) => JSON.parse(state.loginDetails));

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null; 
  }, [loginDetails]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
      }
      if (data?.status && data?.status?.code == 200) {
        if (data?.data?.edi837ListObject?.length > 0) {
          setisDataDisplaying(true);
          setLoadingg(false);
          console.log("resss", data);
          const parseData = JSON.parse(
            data?.data?.edi837ListObject[0]?.inputJson
          );
          let temp = [];
          console.log("parse", parseData);
          temp.push(parseData.Professional);
          setData(temp);
        } else {
          setNotify({
            isOpen: true,
            message: `${"There is no data"}`,
            type: "error",
          });
        }
      }
    }

    if (data) {
      if (data?.response && data?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
      }
    }
  }, [data]);

  const handleSubmit = async () => {
    if (providerRef.current.value == "" || providerRef.current.value == null) {
      setNotify({
        isOpen: true,
        message: "Enter Provider Control Numberwwwwwssxxxxddddd",
        type: "error",
      });
      return;
    }
    setLoadingg(true);
    await mutateAsync({
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.coCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        AuditTrail: 0,
        editTrail: 0,
        dataSource: "",
        processStatus: "",
        deliveryStatus: "",
      },
      providerControlNumber: providerRef.current.value,
      orbitAssignedClaimID: providerClaimId.current.value,
    });
  };

  const handleChange = (path, value) => {
    setData((currentData) => {
      const newData = JSON.parse(JSON.stringify(currentData)); // Create a deep copy
      const keys = path.split(".");
      let temp = newData[0]; // Assuming data is an array and we work on the first element

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            const index = parseInt(
              key.substring(key.indexOf("[") + 1, key.indexOf("]")),
              10
            );
            temp[baseKey][index] = value;
          } else {
            temp[key] = value;
          }
        } else {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            const index = parseInt(
              key.substring(key.indexOf("[") + 1, key.indexOf("]")),
              10
            );
            temp = temp[baseKey][index];
          } else {
            temp = temp[key];
          }
        }
      });

      return newData;
    });
  };

  const createNullValuedCopy = (item) => {
    const newItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        if (
          typeof item[key] === "object" &&
          item[key] !== null &&
          !Array.isArray(item[key])
        ) {
          newItem[key] = createNullValuedCopy(item[key]); // Recursively nullify nested objects
        } else {
          newItem[key] = null; // Set scalar values and arrays to null
        }
      }
    }
    return newItem;
  };

  const createNullifiedObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        // Recursively nullify objects
        acc[key] = createNullifiedObject(obj[key]);
      } else {
        // Set all other values, including arrays, to null
        acc[key] = null;
      }
      return acc;
    }, {});
  };

  const hasNonNullValues = (obj) => {
    return Object.keys(obj).every((key) => {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        return hasNonNullValues(obj[key]);
      } else {
        return obj[key] !== null;
      }
    });
  };

  const handleAddItem = (path) => {
    setData((currentData) => {
      const newData = JSON.parse(JSON.stringify(currentData));
      const keys = path.split(".");
      let temp = newData[0];

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            temp = temp[baseKey];
            const lastItem = temp[temp.length - 1];
            if (lastItem && hasNonNullValues(lastItem)) {
              const newItem = createNullifiedObject(lastItem);
            }
          } else {
            const lastItem = temp[key][temp[key].length - 1];
            if (lastItem && hasNonNullValues(lastItem)) {
              const newItem = createNullifiedObject(lastItem);
              temp[key].push(newItem);
            }
          }
        } else {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            const idx = parseInt(
              key.substring(key.indexOf("[") + 1, key.indexOf("]")),
              10
            );
            temp = temp[baseKey][idx];
          } else {
            temp = temp[key];
          }
        }
      });

      return newData;
    });
  };

  const handleDeleteItem = (path, index) => {
    setData((currentData) => {
      const newData = JSON.parse(JSON.stringify(currentData));
      const keys = path.split(".");
      let temp = newData[0];

      keys.forEach((key, i) => {
        if (i === keys.length - 1) {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            const idx = parseInt(
              key.substring(key.indexOf("[") + 1, key.indexOf("]")),
              10
            );
            temp[baseKey].splice(idx, 1);
          } else {
            temp[key].splice(index, 1);
          }
        } else {
          if (key.includes("[")) {
            const baseKey = key.substring(0, key.indexOf("["));
            const idx = parseInt(
              key.substring(key.indexOf("[") + 1, key.indexOf("]")),
              10
            );
            temp = temp[baseKey][idx];
          } else {
            temp = temp[key];
          }
        }
      });

      return newData;
    });
  };

  return (
    <div>
      <Grid container>
        {dataFromTSL == undefined && (
          <Grid xs={8} sm={10} className="my-2 md:my-8 flex items-center">
            <Breadcrumb items={breadcrumbItems} isRedux={true} />
          </Grid>
        )}
      </Grid>
      <Card>
        <CardContent>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems={"center"}
            sx={{
              borderRadius: "10px",
              // marginTop: "10px",
            }}
          >
            <Grid item fullWidth xs={5} sm={2} className="flex justify-center">
              <TextField
                autoFocus
                id="outlined-password-input1"
                label="Provider Control Number"
                name="Provider Control Number"
                autocomplete="off"
                // value={providerControlnumber}
                // onChange={(e)=>handleProviderInputChange(e)}
                inputRef={providerRef}
                className="m-0"
                size="small"
                sx={{ background: "white" }}
                style={textFieldStyles}
              />
            </Grid>
            <Grid item fullWidth xs={6} sm={5} className="flex justify-center">
              <TextField
                id="outlined-password-input2"
                label="Orbit Claim Id"
                name="Orbit Claim Id"
                autocomplete="off"
                // inputRef={providerClaimId}
                className="m-0"
                sx={{ background: "white" }}
                size="small"
                style={textFieldStyles}
              />
            </Grid>
            <Grid item fullWidth xs={12} sm={2}>
              <Button
                text="Search"
                // navigate="/capture/capture-driving-license/patient-info"
                classname={"button-styles btnHeight"}
                filled={true}
                onclick={handleSubmit}
              />
            </Grid>
            <Grid xs={2} sm={1} className="my-2 md:my-8">
              <div className="my-4 md:my-0">
                <Button
                  text="Save"
                  classname={"button-styles btnHeight"}
                  filled={true}
                  //   onclick={handleSaveData}
                />
              </div>
            </Grid>
            <Grid xs={2} sm={1} className="my-2 md:my-8">
              <div className="my-4 md:my-0">
                <Button
                  text="Submit"
                  classname={"button-styles btnHeight"}
                  filled={true}
                  //   onclick={handleSaveData}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* (isDataDisplaying && loadingg) */}
      {isDataDisplaying && (
        <Grid item fullWidth>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <div>
                {dataa && (
                  <Grid item fullWidth>
                    {dataa &&
                      dataa.map((item, index) => (
                        <RecursiveComponent
                          key={index}
                          data={item}
                          onChange={handleChange}
                          onAddItem={handleAddItem}
                          onDeleteItem={handleDeleteItem}
                        />
                      ))}
                  </Grid>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || loadingg}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ClaimSubmitDynamic;
