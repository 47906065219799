import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { TIFFViewer } from "react-tiff";
// import 'react-tiff/dist/index.css'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function PreviewModal({
  open,
  setOpen,
  handleClose,
  data,
  currentIndex,
  setCurrentIndex,
}) {
  // debugger;
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  function getPdf(file) {
    const newBlob = new Blob([file], { type: "application/pdf" });
    const data = window.URL.createObjectURL(newBlob);
    return data;
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Preview
              </Typography>
              {data?.length > 1 && (
                <Box className="ml-auto mr-5">
                  <Tooltip title="Previous Document" placement="top" arrow>
                    <Button
                      onClick={handlePrevious}
                      disabled={currentIndex === 0}
                      className="button-styles"
                    >
                      &nbsp;
                      <i className="fa-solid fa-angle-left fa-lg"></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Next Document" placement="top" arrow>
                    <Button
                      onClick={handleNext}
                      disabled={currentIndex === data.length - 1}
                      className="button-styles !ml-2"
                    >
                      &nbsp;
                      <i className="fa-solid fa-angle-right fa-lg"></i>
                    </Button>
                  </Tooltip>
                </Box>
              )}
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            {data.length > 0 && (
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {data?.[currentIndex]?.fileType?.includes("pdf") ? (
                  <iframe
                    id="showPDFICON1"
                    src={getPdf(data?.[currentIndex]?.file)}
                    type="application/pdf"
                    width="100%"
                    height="670px"
                  />
                ) : (
                  <>
                    {(data?.[currentIndex]?.fileType &&
                      typeof data?.[currentIndex]?.fileType === "string" &&
                      data?.[currentIndex]?.fileType?.includes("tiff")) ||
                    data?.[currentIndex]?.fileType
                      ?.toLowerCase()
                      ?.includes("tif") ? (
                      <div
                        style={{
                          width: "100%",
                          height: "670px",
                          objectFit: "contain",
                        }}
                      >
                        <TIFFViewer
                          key={data?.[currentIndex]?.file.name}
                          tiff={
                            data?.[currentIndex]?.file
                              ? URL.createObjectURL(data[currentIndex].file)
                              : ""
                          }
                        />
                      </div>
                    ) : (
                      <img
                        src={
                          data?.[currentIndex]?.file
                            ? URL.createObjectURL(data[currentIndex].file)
                            : ""
                        }
                        alt="preview"
                        style={{
                          width: "100%",
                          height: "670px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </>
                )}
              </Typography>
            )}
            <Box className="flex justify-center mt-4">
              <Typography
                variant="subtitle1"
                component="div"
                className="!font-bold"
              >
                {data?.[currentIndex]?.file?.name}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
