// DocumentContext.js
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs"; // Ensure dayjs is imported

export const DocumentContext = createContext();

const initialFilters = {
  location: {
    companyId: "0",
    companyName: "All",
  },
  for: "14",
  daysOrMonths: "days",
  fromDate: dayjs().subtract(14, "day"),
  toDate: dayjs(),
  statusDate: {
    label: "Create Date",
    value: "CDT",
  },
  dateOfBirth: null,
  batchNumber: "",
  status: {
    label: "",
    value: null,
  },
  deliveryStatus: {
    label: "",
    value: null,
  },
};

export const DocumentContextProvider = ({ children }) => {
  // const [formData, setFormData] = useState({});
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterModel, setFilterModel] = useState(null);
  // const [sortModel, setSortModel] = useState(null);
  // const [pageModel, setPageModel] = useState({
  //   page: 0,
  //   pageSize: 100,
  // });
  //these are used in patient List donot remove them
  const [patientFilterModel, setPatientFilterModel] = useState(null);
  const [patientSortModel, setPatientSortModel] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  let [isFilters, setIsFilters] = useState(false);

  const resetFormData = () => {
    // setFormData(filterFormInitialData);
    setFiltersApplied(false);
    setFilterModel(null);
    resetInsuranceFormData();
  };

  const resetInsuranceFormData = () => {
    setPatientFilterModel(null);
    setPatientSortModel(null);
    setFilters(initialFilters);
    setIsFilters(false);
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
  };

  return (
    <DocumentContext.Provider
      value={{
        // formData,
        // setFormData,
        filtersApplied,
        setFiltersApplied,
        resetFormData,
        filterModel,
        setFilterModel,
        filters,
        setFilters,
        paginationModel,
        setPaginationModel,
        isFilters,
        setIsFilters,
        patientFilterModel,
        setPatientFilterModel,
        // sortModel,
        // setSortModel,
        patientSortModel,
        setPatientSortModel,
        // pageModel,
        // setPageModel,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

DocumentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
