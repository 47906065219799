import { instance } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import { LoginAPI } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// "https://tst.orbithc.net/aicapturewrapper/api/v1/LoginService/SignIn"

async function UserLogins(value) {
  // const response = await instance.post(LoginAPI, value);
  // return response

  // return
  try {
    const url = window.location.href;
    const { data } = await instance.post(LoginAPI, value.params ?? value);
    if (data.status.code == 200) {
    
      if (value.callBack) value.callBack();
      // sessionStorage.setItem(
      //   "token",
      //   JSON.stringify(data?.data?.organizationSetting)
      // );
      // Window.OSession = JSON.stringify(data.data);
      // sessionStorage?.removeItem("patientBackData");
      // sessionStorage.setItem("details", JSON.stringify(data.data));
    }
    return data;
  } catch (error) {
    if (error) {
      return error;
    }
  }
}

export async function fetchGeoData() {
  let response = null;
  try {
    response = await instance.get("https://ipinfo.io");
  } catch (error) {
    console.error("Error fetching geolocation data:", error);
  }

  return response;
}

export function UserLogin() {
  let navigation = useNavigate();
  let qClient = useQueryClient();
  return useMutation(UserLogins, {});
}
