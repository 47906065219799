import React, { useState, useEffect } from 'react';
import { TextField ,Divider} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const MoneyInput = ({ value = '', onChange = () => {}, ...props }) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        // Ensure the initial value is correctly formatted
        let formattedValue = value || '';
        if (!formattedValue.includes('.')) {
            formattedValue = `${formattedValue}`;
        } else if (formattedValue.split('.')[1].length === 1) {
            formattedValue = `${formattedValue}`;
        }
        setInputValue(formattedValue);
    }, [value]);

    const handleBlur = () => {
        let formattedValue = inputValue;
        if (!formattedValue.includes('.')) {
            formattedValue = `${formattedValue}`;
        } else if (formattedValue.split('.')[1].length === 1) {
            formattedValue = `${formattedValue}`;
        }
        setInputValue(formattedValue);

        // Ensure onChange receives the correct structure
        onChange({ target: { value: formattedValue } });
    };

    const handleChange = (e) => {
        if (e && e.target && typeof e.target.value === 'string') {
            const newValue = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(newValue)) {
                setInputValue(newValue);
                onChange(e);
            }
        } else {
            console.error("handleChange received an invalid event object:", e);
        }
    };

    return (
        <TextField
        className='custom-input'
            type="text"
            size="small"
            {...props}
            value={inputValue}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ fontSize: '12px' }}>
                         <span >$</span>
                    </InputAdornment>
                ),
                sx: {
                    padding: '5px 1px 5px 4px',
                    fontSize: '12px',
                },
            }}
            // sx={{
            //     padding: '5px',
            //     '& .MuiInputBase-root': {
            //         height: '30px',
            //         fontSize: '12px',
            //     },
            //     '& .MuiOutlinedInput-input': {},
            // }}
        />
    );
};

export default MoneyInput;
