import { instance, Blobinstance, Blobinstance1 } from "./axios";
import { useMutation, useQueryClient } from "react-query";
import {
  CorePresign,
  GetDocumentList,
  GetLookUpsForDV,
  RecognizeDocument,
  DocumentValidation,
  DocumentVerification,
  CallBatchPreSign,
  UploadBatchFiles,
  GetDocumentById,
  GetBlobFileStream,
  GetExportElementsData,
} from "./Urls";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function uploadSelectedFile(value) {
  try {
    let { data } = await instance.post(CorePresign, value);
    if (data.status.code == 200) {
      return callCorePreSignUrl(data, value);
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function uploadSelectedFiles(value) {
  try {
    let { data } = await instance.post(CallBatchPreSign, value);
    if (data.status.code == 200) {
      let arr = [];
      for (let i = 0; i < data?.data?.length; i++) {
        const signData = data?.data[i];
        const inputData = value.PresignUrlRequests[i];
        // console.log(value, "  ", inputData);
        const res = await callCorePreSignUrls(signData, inputData);
        arr.push(res);
      }
      // debugger;
      return { arr, data };
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}
///// START

// async function uploadSelectedFiles(value) {
//   try {
//     // Make the initial API call
//     let { data } = await instance.post(CallBatchPreSign, value);

//     if (data.status.code === 200) {
//       // Extract pre-signed URL data and input data
//       const { data: preSignData } = data;
//       const { PresignUrlRequests } = value;

//       // Use Promise.all for concurrent uploads
//       const uploadPromises = preSignData.map((signData, index) => {
//         const inputData = PresignUrlRequests[index];
//         return callCorePreSignUrls(signData, inputData)
//           .then((res) => ({ success: true, result: res })) // On success, return result
//           .catch((error) => ({ success: false, error })); // On failure, return error
//       });

//       // Wait for all uploads to settle
//       const uploadResults = await Promise.all(uploadPromises);

//       // Separate successful and failed uploads
//       const successfulUploads = uploadResults.filter((res) => res.success);
//       const failedUploads = uploadResults.filter((res) => !res.success);
//       debugger;
//       return {
//         successfulUploads,
//         failedUploads,
//         originalData: data,
//       };
//     } else {
//       return data; // Return API response if the status code is not 200
//     }
//   } catch (error) {
//     return { error: error.message }; // Return error if API call itself fails
//   }
// }

/////  END

async function callCorePreSignUrl(signData, inputData) {
  try {
    const response = await Blobinstance.put(
      signData.data.signedUrl,
      inputData.file
    );
    // if (response.status === 201) {
    return { signData, response };
    // } else {
    //   return { signData, response };
    // }
  } catch (error) {
    console.error("Error uploading to signed URL::", error);
    return { success: false, message: error.message };
  }
}

async function callCorePreSignUrls(signData, inputData) {
  try {
    const response = await Blobinstance.put(signData.signedUrl, inputData.file);
    if (response.status === 201) {
      return { response, signData: { ...signData, ...inputData } };
    } else {
      // throw new Error("Unexpected response from the server");
      return {
        success: false,
        message: "Unexpected response from the server",
        inputData,
      };
    }
  } catch (error) {
    console.error("Error uploading to signed URL::", error);
    return { success: false, message: error.message, inputData };
  }
}

async function getDocumentList(value) {
  try {
    let { data } = await instance.post(GetDocumentList, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function questionsPost(value) {
  try {
    let { data } = await instance.post(GetBlobFileStream, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getDocumentById(value) {
  try {
    let { data } = await instance.post(GetDocumentById, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function uploadBatchFiles(value) {
  try {
    let { data } = await instance.post(UploadBatchFiles, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getLookUpsForDV(value) {
  try {
    let { data } = await instance.post(GetLookUpsForDV, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function recognizeDocumentApi(value) {
  try {
    let { data } = await instance.post(RecognizeDocument, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function documentValidation(value) {
  try {
    let { data } = await instance.post(DocumentValidation, value);
    // debugger;
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function documentVerification(value) {
  try {
    let { data } = await instance.post(DocumentVerification, value);
    // debugger;
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

async function getExportElementsData(value) {
  try {
    let { data } = await instance.post(GetExportElementsData, value);
    if (data.status.code == 200) {
      return data;
    } else if (data.status.code == -99999) {
      // return data;
      sessionStorage.clear();
      persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export function uploadSelectedFileAPI() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(uploadSelectedFile, {});
}

export function uploadSelectedFilesAPI() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(uploadSelectedFiles, {});
}

export function GetLookUpsForDVApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getLookUpsForDV, {});
}

export function GetDocumentListApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getDocumentList, {});
}

export function RecognizeDocumentApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(recognizeDocumentApi, {});
}

export function DocumentValidationApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(documentValidation, {});
}

export function DocumentVerificationApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(documentVerification, {});
}

export function UploadBatchFilesApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(uploadBatchFiles, {});
}

export function GetDocumentByIdApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getDocumentById, {});
}

export function QuestionsPostApi() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(questionsPost, {});
}

export function GetExportElements() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(getExportElementsData, {});
}
