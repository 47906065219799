import React, { useEffect, useMemo } from "react";
import { Grid, Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Refresh, Edit, Add } from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";

const List = ({
  addNewClick,
  refreshClick,
  rows,
  columns,
  setCurrentTab,
  gridRef,
  setNotify,
  loading,
  setReqData,
}) => {
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // editable: true,
      //   floatingFilter: true,
      // filters: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
    }),
    []
  );

  const { agGridColumns, agGridRows } = convertToAgGridFormat(rows, columns);
  agGridColumns[0] = {
    ...agGridColumns[0],
    width: 90, // Use a number instead of a string for width
    sortable: false, // Disable sorting
    filter: false, // Disable filtering
    cellRenderer: (params) => (
      <IconButton
        onClick={() => {
          setReqData({
            sourceFileTypeID: params.data?.sourceFileTypeID,
            recData: params.data,
            fileTypeId: params.data?.fileTypeID,
          });
          setCurrentTab(1);
        }}
      >
        <Edit />
      </IconButton>
    ),
  };

  useEffect(() => {
    return () => {
      setNotify({
        isOpen: false,
      });
    };
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          className="mb-3"
        >
          <Box>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<Add />}
              onClick={addNewClick}
              sx={{ marginRight: 1 }}
              className="button-styles !min-w-[150px]"
            >
              Add New
            </Button>
            <Button
              variant="outlined"
              endIcon={<Refresh />}
              onClick={refreshClick}
              className="button-styles !min-w-[150px] !mr-2"
            >
              Refresh
            </Button>
          </Box>
        </Grid>

        <div className="mt-3">
          <Box className="ag-theme-alpine docVHgt relative">
            {/* <div className="absolute z-10 top-[6px] right-[1px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadCSVClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export CSV
                <i className="fa-solid fa-file-csv fa-lg ml-2"></i>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={btnDownloadXLClick}
                sx={{ marginRight: 1 }}
                className="button-styles !min-w-[150px]"
              >
                Export XL
                <i className="fa-regular fa-file-excel fa-lg ml-2"></i>
              </Button>
            </div> */}
            <AgGridReact
              ref={gridRef}
              rowData={agGridRows ?? []}
              columnDefs={agGridColumns}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={100}
              paginationPageSizeSelector={[100, 250, 500, 1000]}
              alwaysShowVerticalScroll={true}
              alwaysShowHorizontalScroll={true}
              domLayout="autoHeight"
              animateRows={true}
              rowGroupPanelShow="always"
              suppressCopyRowsToClipboard={true}
              loading={loading}
            />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default List;
