import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AutocompleteModal = ({
  open,
  setOpen,
  value,
  setValue,
  options,
  selectedOption,
  handleOpen,
  ddlDocumentTypeChange,
  handleClose,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="autocomplete-modal-title"
        aria-describedby="autocomplete-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose("close")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Autocomplete
            options={options || []}
            value={selectedOption}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={ddlDocumentTypeChange}
            disableClearable
            name="Select File Type"
            id="Select File Type"
            className="mt-6 mb-3"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select File Type"
                variant="outlined"
                name="Select File Type1"
                id="Select File Type1"
                size="small"
              />
            )}
          />
          <Button
            variant="outlined"
            className="button-styles !mt-3 !float-right"
            onClick={handleClose}
          >
            Download
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AutocompleteModal;
