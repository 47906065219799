import { Grid, TextField } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcrumb";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

const textFieldStyles = {
  width: "100%",
  margin: "12px 12px",
};

const SendSMS = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataa = JSON.parse(queryParams.get("state"));

  const onClearHandler = async () => {
                  let details = JSON.parse(sessionStorage?.getItem("sendSMSId"));
                  const queryParams = new URLSearchParams();
                  queryParams.set("data", JSON.stringify(details));
                  localStorage.removeItem("sendSMSId")
                  window.location.href = `/patient-list/patient-details?${queryParams.toString()}`;
  };

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
    { label: "Send SMS", link: "/patient-list/send-sms-or-email" },
    { label: "Sent SMS", link: "/patient-list/send-sms-or-email/sms-form" },
  ];

  return (
    <div>
    <Grid container>
      <Grid xs={6} sm={6} className="my-2 md:mt-8 flex items-center">
        <Breadcrumb items={breadcrumbItems} isRedux={true} />
      </Grid>
   
      <Grid container xs={6} sm={6}>
      <Grid xs={2} sm={4}></Grid>
      <Grid xs={5} sm={4} className="my-2 md:my-8">
      <div className="mx-2 my-2 md:my-0">
              <Button
                text="Back To List"
                classname={"button-styles btnHeight"}
                navigate="/patient-list"
              />
            </div></Grid>
            <Grid xs={5} sm={4} sx={{ pl: 2 }} className="my-2 md:my-8">
          <div className="my-4 md:my-0">
              <Button
                classname={"button-styles btnHeight"}
                text="Send SMS or Email"
                navigate="/patient-list/send-sms-or-email"
              />
            </div>  </Grid>    
        </Grid>      </Grid> 
      <Card><CardContent>
      {/* <div className="md:flex justify-right">
        <div className="w-auto">
          <div className="md:flex justify-between">
           
           
          </div>
        </div>
      </div> */}
      <div className="h-60 flex justify-center items-center">
        <div>
          <div className="flex justify-center my-4">
            <h2 className="text-xl font-bold text-gray-600">
              {dataa} Sent Successfully
            </h2>
          </div>
          <div className="flex justify-center text-center">
            <p className="text-gray-600">
              You may view details submitted by recipient from the last page
            </p>
          </div>

          <div className="flex justify-center text-center" style={{paddingTop:"10px"}}>
          <Button
                 classname={'button-styles btnHeight'}
                 filled={true}
                text="Track Status"
                onclick={onClearHandler}
              />
          </div>
        </div>
      </div>
     
      </CardContent>
      </Card>
    </div>
  );
};

export default SendSMS;
