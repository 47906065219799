import dayjs from "dayjs";

export const filterFormInitialData = {
  statusDate: {
    label: "Create Date",
    value: "CDT",
  },
  searchVal: "",
  for: "1",
  daysOrMonths: "days",
  fromDate: dayjs().subtract(1, "day"),
  toDate: dayjs(),
  location: {
    companyName: "All",
    companyId: "0",
  },
  status: {
    description: "All",
    code: "0",
  },
  subStatus: {
    description: "All",
    type: "0",
  },
  assignedTo: {
    description: "All",
    code: "0",
  },
  dataSource: {
    description: "All",
    code: "0",
  },
  subProcess: {
    description: "All",
    code: "1000",
  },
  batchNumber: "",
};

export const initialFilters = {
  location: {
    companyId: "0",
    companyName: "All",
  },
  for: "14",
  daysOrMonths: "days",
  fromDate: dayjs().subtract(14, "day"),
  toDate: dayjs(),
  statusDate: {
    label: "Create Date",
    value: "CDT",
  },
  dateOfBirth: null,
  batchNumber: "",
  status: {
    label: "",
    value: null,
  },
  deliveryStatus: {
    label: "",
    value: null,
  },
};

export const finalPatientInitialData = {
  cosmosId: null,
  filteredData: [],
  filters: initialFilters,
  paginationModel: {
    page: 0,
    pageSize: 100,
  },
  patientFilterModel: null,
  patientSortModel: null,
  isFilters: false,
};

const reportsData = {
  filtersData: [],
  dataGrid: null,
  repInfo: {},
};
export const resetInitData = {
  lookUpData: null,
  dId: null,
  filteredData: null,
  formData: { ...filterFormInitialData },
  pageModel: {
    page: 0,
    pageSize: 100,
  },
  filterModel: null,
  sortModel: null,
  filtersApplied: false,
};
const initialCaptureState = {
  cosmosId: null,
  captureDetailsData: null,
  captureData: null,
  captureSMSData: null,
};

export const wholeReduxObj = {
  "/PATIENT-LIST/DOCUMENT-VERIFICATION": resetInitData,
  "/PATIENT-LIST/DOCUMENT-VERIFICATION/SINGLE-DOCUMENT": resetInitData,
  "/PATIENT-LIST/REPORTS": reportsData,
  "/PATIENT-LIST": finalPatientInitialData,
};
