import { appInsights } from "./AppInsights";
// import { store } from "./redux/store";

export const logTelemetry = (
  status,
  page,
  method,
  message,
  exception = null
) => {
  // const state = store.getState();
  // let { companyCode, userName } = JSON.parse(state?.loginDetails);
  let userName = sessionStorage?.getItem("userName")
    ? JSON.parse(sessionStorage.getItem("userName"))
    : null;
  let companyCode = sessionStorage?.getItem("companyCode")
    ? JSON.parse(sessionStorage.getItem("companyCode"))
    : null;

  const telemetryMessage = `${companyCode}|${userName}|${status}|${page}|${method}|${message}`;
  if (exception) {
    appInsights.trackException({
      exception: exception,
      properties: { message: telemetryMessage },
    });
  } else {
    appInsights.trackTrace({ message: telemetryMessage });
  }
};
