import { instance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PatientList } from "./Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { string } from "yup";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

// async function getAllPatientList({ queryKey }) {
//   let page = queryKey[1];
//   let details = queryKey[2];
//   let filters = queryKey[3];

//   let value = {
//     organizationID: details?.OrgId,
//     CompanyId: details?.CompanyId,
//     groupID: details?.groupID,
//     TimeZone: details?.TimeZone,
//     sessionId: details?.SesionId,
//     loginUserId: details?.UserId,
//     loginUserName: details?.UserName,
//     patientId: 0,
//     patientData: filters?.email || "",
//     dobFromDate: "",
//     dobToDate: "",
//     updatedFromDate: "",
//     updatedToDate: "",
//     processStatus: "",
//     deliveryStatus: "",
//     locationName: "",
//     sortColumnName: "",
//     sortColumnValue: "",
//     pageNumber: page,
//     pageSize: 50,
//     auditTrailLevel: 0,
//     editTrailLevel: 0,
//   };

//   let { data } = await instance.post(PatientList, value);
//   return data;
// }
// export function GetAllPatientList(page, details, filters) {
//   return useQuery(["AllPatientLists", page, details,filters], getAllPatientList, {
//     keepPreviousData: true,
//   });
// }

async function updateAllPatientList(value) {
  try {
    let { data } = await instance.post(PatientList, value);
    if (data.status.code == 200) {
      sessionStorage.setItem("patientBackData", JSON.stringify(value));
      if (
        value.searchImport == true &&
        value.arrayObj != undefined &&
        value.arrayObj.length > 0
      ) {
        for (var i = 0; i < value.arrayObj.length; i++) {
          if (
            value.arrayObj[i].ContactMobileNumber != "" ||
            value.arrayObj[i].ContactEmailAddress != ""
          ) {
            if (
              value.arrayObj[i].ContactMobileNumber != undefined ||
              value.arrayObj[i].ContactEmailAddress != undefined
            ) {
              data?.data?.listPatientData.splice(0, 0, {
                primaryPhone: value.arrayObj[i].ContactMobileNumber,
                dateOfBirth: value.arrayObj[i].PatientDateOfBirth,
                locationName: value.arrayObj[i].locationName,
                payerName: value.arrayObj[i].PayerName,
                id: i + 1,
                cosmosKeyID: i + 1,
                patientID: i + 1,
                firstName: value.arrayObj[i].PatientfirstName,
                lastName: value.arrayObj[i].PatientfirstName,
                processStatus: "Open",
              });
            }
          }
        }

        // data?.data?.listPatientData.splice(0, 0, {primaryPhone:value.arrayObj[0],dateOfBirth:"1/1/2001",locationName:"Office",payerName:"BCBS",processStatus:"Open",cosmosKeyID:"1234567890",id:"123",patientID:"123",firstName:"First Import",lastName:"Last Import"});
      }
      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      // toast.error("Session expied")
      sessionMessage();
      window.location.href = "/login";
    } else {
      // toast.error(`${data.status.message}`);
      return data;
    }
  } catch (error) {
    // toast.error(error.message);
    return error;
  }
}

export function UpdateAllPatientList() {
  let qClient = useQueryClient();
  // qClient.invalidateQueries("");
  return useMutation(updateAllPatientList, {});
}
