import {
  Container,
  Typography,
  Button,
  Grid,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  InputLabel,
  FormControl,
  Select,
  Backdrop,
  CircularProgress,
  IconButton,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import { UploadFile, Cancel, AccountTreeOutlined } from "@mui/icons-material";
import {
  GetAllModules,
  ProcessImportedData,
  UploadFileFun,
} from "../../apis/dataImport";
// import {  } from "../../apis/dataImport";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Notification from "../../components/Notification";
import SyncIcon from "@mui/icons-material/Sync";
import ClearStatesConfirmationDialog from "./ClearStatesConfirmationDialog";
import { logTelemetry } from "../../logTelemetry";
import getErrorMessage from "../../components/utils/getErrorMessage";
// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";

const options = [
  { value: ",", label: ", (Comma)" },
  { value: "|", label: "| (Pipes)" },
  { value: "^", label: "^ (Caret)" },
  { value: " ", label: "(Space)" },
  { value: "#", label: "# (Hash)" },
  { value: "$", label: "$ (Dollar)" },
  { value: "*", label: "* (Asterisk)" },
  { value: "-", label: "- (Hyphen)" },
  { value: ":", label: ": (Colon)" },
  { value: ";", label: "; (Semicolon)" },
  { value: "~", label: "~ (Tilde)" },
  { value: "t", label: " (TAB)" },
  { value: "xlsx", label: "xlsx" },
  { value: "xls", label: "xls" },
];
function DataImportModule() {
  let timeoutId;
  // let details = useSelector((state) => JSON.parse(state.loginDetails));
  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [selectedOptions, setSelectedOptions] = useState({});

  let locationData = details?.aILocations;
  locationData.unshift({
    companyName: "",
    companyId: "0",
  });
  let initialLocation = {
    companyName: `${details?.companyName}`,
    companyId: `${details?.companyId}`,
  };
  const { mutateAsync } = GetAllModules();
  const { mutateAsync: processAPICall } = ProcessImportedData();
  const { mutateAsync: uploadFileApiCall } = UploadFileFun();

  const inputRef = useRef(null);
  // const { details?.companyId } = useContext(MyContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [firstRow, setFirstRow] = useState(null);
  const [wholeState, setWholeState] = useState({});
  const [entityArr, setEntityArr] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedServiceprovider, setSelectedServiceprovider] =
    useState(initialLocation);
  const [selectedBillingProvider, setSelectedBillingProvider] =
    useState(initialLocation);

  const [rows, setRows] = useState([]);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedVal, setSelectedVal] = useState({});
  const [selFieldList, setSelFieldList] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const [errors, setErrors] = useState({
    billingProvider: false,
    serveviceProvider: false,
    entity: false,
    section: false,
    file: false,
  });
  const [selectedSeparator, setSelectedSeparator] = React.useState(",");
  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);

  const handleClose = () => {
    setDialogState(intial);
  };

  const ddlFieldSeparatorChange = (event) => {
    setSelectedSeparator(event.target.value);
  };

  const generateRows = () => {
    if (selFieldList.length === 0) {
      // debugger;
      return rows
        .filter(
          (row) =>
            row.fieldDisplayName !== null &&
            row.fieldDisplayName !== undefined &&
            row.fieldDisplayName != ""
        )
        .map((row) => ({
          fieldDBName: row.FieldDBName || row.fieldDBName,
          fieldDBObjectName: row.FieldDBObjectName || row.fieldDBObjectName,
          fieldDisplayName: row.fieldDisplayName,
          fieldID: Number(row.fieldID),
          fieldIndex: Number(row.fieldIndex),
          fieldLength: Number(row.fieldLength),
          fieldType: row.fieldType,
          sectionID: row.sectionID ? row.sectionID : Number(selectedSection),
        }));
    } else {
      // debugger;
      return rows
        .filter(
          (row) =>
            row.dbFieldId !== null &&
            row.dbFieldId !== undefined &&
            row.fieldType !== null &&
            row.fieldType !== undefined &&
            row.fieldType !== "" &&
            row.sourceName !== null &&
            row.sourceName !== undefined
        )
        .map((row) => ({
          fieldDBName: row.dbFieldName || row.fieldDBName,
          fieldDBObjectName: row.dbFieldObjectName || row.fieldDBObjectName,
          fieldDisplayName: row.fieldDisplayName || row.dbFieldDisplayName,
          fieldID: Number(row.dbFieldId),
          fieldIndex: Number(row.dbFieldIndex),
          fieldLength: Number(row.fieldLength),
          fieldType: row.fieldType,
          sectionID: Number(selectedSection),
          sourceFieldName: row?.sourceName,
        }));
    }
  };

  const ddlSelectChange = (value, rowIndex, allInfo, id) => {
    // console.log(fo);
    const isValuePresent = rows.some((row) => row.fieldDisplayName == value);
    // debugger;
    if (isValuePresent && value !== "") {
      setNotify({
        isOpen: true,
        message: `Not allowed,${value} already mapped!`,
        type: "warning",
      });
    } else {
      const re = false;
      // value === "" ? false : Object.values(selectedVal).includes(value);
      // debugger;
      if (re) {
        setNotify({
          isOpen: true,
          message: `Not allowed,${value} already mapped!`,
          type: "warning",
        });
      } else {
        setSelectedVal({
          ...selectedVal,
          [id]: allInfo,
        });
        setRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            fieldDisplayName: value,
            dbFieldId: allInfo.dbFieldId,
          };
          if (selFieldList.length === 0) {
            let filteredData = mandatoryFields.filter(
              (i) => i.fieldDisplayName === value
            );
            let res = filteredData.length > 0 ? filteredData[0] : {};
            newRows[rowIndex] = {
              ...newRows[rowIndex],
              ...res,
              fieldIndex: rowIndex,
            };
          } else {
            // debugger;
            let filteredData = mandatoryFields.filter(
              (i) => i.fieldDisplayName == value
            );
            let res = filteredData.length > 0 ? filteredData[0] : {};
            // res.dbFieldId = "";
            newRows[rowIndex] = {
              ...newRows[rowIndex],
              ...res,
            };
          }
          return newRows;
        });
      }
    }
  };
  // console.log(selectedOptions, " selectedOptions ");
  const ddlDestinationFieldChange = (value, rowIndex, allInfo, id) => {
    setSelectedOptions({
      ...selectedOptions,
      [id]: allInfo,
    });
    // let list = rows?.filter((row) => row.dbFieldId == allInfo.dbFieldId);
    // debugger;
    // if (list.length > 0) {
    //   setNotify({
    //     isOpen: true,
    //     message: `Not allowed,${value} already mapped!`,
    //     type: "warning",
    //   });
    //   return;
    // }
    // debugger;
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        fieldDisplayName: value,
        dbFieldId: allInfo?.dbFieldId ?? allInfo?.fieldID,
        dbFieldIndex: rowIndex,
      };
      if (selFieldList.length === 0) {
        let filteredData = mandatoryFields.filter(
          (i) => i.fieldDisplayName === value
        );
        let res = filteredData.length > 0 ? filteredData[0] : {};
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          ...res,
        };
      } else {
        // debugger;
        let filteredData = mandatoryFields.filter(
          (i) => i.fieldDisplayName == value
        );
        let res = filteredData.length > 0 ? filteredData[0] : {};
        // res.dbFieldId = "";
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          ...res,
        };
      }
      // debugger;
      return newRows;
    });
  };

  const ddlEntityChange = (event, newVal, initialData) => {
    const val = newVal;
    // debugger;
    if (!val) {
      setMandatoryFields([]);
    }
    setSelectedEntity(val);
    setSelectedFile(null);
    setRows([]);
    let myWholeState;
    if (initialData !== "selectOption") {
      myWholeState = { ...initialData };
    } else {
      myWholeState = { ...wholeState };
    }
    const filteredSections = myWholeState?.sectionsList?.filter(
      (mdl) => mdl.moduleID == val?.moduleID
    );
    // debugger;
    setSectionArr(filteredSections);
    if (filteredSections?.length > 0) {
      if (filteredSections?.[0]?.moduleName !== "") {
        setIsLoading(true);
        setSelectedSection(filteredSections?.[0]?.sectionID);
        callApiWithSection(filteredSections?.[0]?.sectionID, val.moduleID);
      } else {
        setSelectedSection("");
      }
    }
    // if (filteredSections.length < 1) {
    //   setIsLoading(true);
    //   callApiWithSection(0);
    // }
    if (errors.entity) {
      setErrors((prev) => ({
        ...prev,
        entity: false,
        section: false,
      }));
    }
  };

  async function callApiWithSection(mySectionID, myModuleId) {
    // debugger;
    try {
      const result = await mutateAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        ConfigId: 91916,
        ModuleId: myModuleId
          ? myModuleId
          : selectedEntity == ""
          ? myModuleId
          : selectedEntity,
        SectionId: mySectionID,
      });
      // debugger;
      if (result?.status?.code == 200) {
        setIsLoading(false);
        // setFieldData(result?.data?.fieldList);
        setSelFieldList(result?.data?.selFieldList);
        setMandatoryFields(result?.data?.fieldList);
      } else {
        setDialogState({
          open: true,
          title: "Something Went Wrong",
          subTitle: result?.status?.message,
          type: "single",
        });
        setIsLoading(false);
      }
    } catch (e) {
      logTelemetry(
        "Failed",
        "DataImportmodule",
        "callApiWithSection",
        e.message,
        e
      );
      setDialogState({
        open: true,
        title: "Error",
        subTitle: "callApiWithSection" + e.message,
        type: "single",
      });
      setIsLoading(false);
    }
  }

  const ddlSectionChange = (event) => {
    // console.log(sectionArr, "RRRRRRRRRRRRRRR");
    const val = event?.target?.value;
    callApiWithSection(val);
    setSelectedSection(val);
    setSelectedFile(null);
    setRows([]);
    if (errors.section) {
      setErrors((prev) => ({
        ...prev,
        section: false,
      }));
    }
  };

  const fileSelectClick = (event) => {
    const file = event.target.files[0];
    // debugger;

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const res = fileExtension === "csv" ? "," : fileExtension;
      setSelectedSeparator(res);

      if (fileExtension === "csv") {
        Papa.parse(file, {
          complete: (result) => {
            if (result.data.length > 0) {
              setHeaders(result.data[0]);
              setFirstRow(result.data[1]);
              setSelectedFile(file);
              setRows([]);
            } else {
              setNotify({
                isOpen: true,
                message:
                  "The file is empty. Please select a file with at least one row of data.",
                type: "error",
              });
            }
          },
          header: false, // We are only interested in the first row for headers
        });
        event.target.value = null;
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const headers = XLSX.utils.sheet_to_json(firstSheet, {
            header: 1,
          })[0];
          const headersTwo = XLSX.utils.sheet_to_json(firstSheet, {
            header: 1,
          })[1];

          if (headers && headers.length > 0) {
            // console.log(headers, "XLSX Headers");
            setHeaders(headers);
            // debugger;
            setFirstRow(headersTwo);
            // how to set setFirstRow here
            setSelectedFile(file); // Set file only if it's valid
            setRows([]);
          } else {
            setNotify({
              isOpen: true,
              message:
                "The file is empty. Please select a file with at least one row of data.",
              type: "error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
        event.target.value = null;
      }
    }
    if (errors.file) {
      setErrors((prev) => ({
        ...prev,
        file: false,
      }));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // let details = JSON.parse(sessionStorage?.getItem("details"));

    // locationData
    getModulesList(details);
    () => {
      clearTimeout(timeoutId);
    };
  }, []);

  function checkMandatory(mandatoryFields) {
    return mandatoryFields.filter(
      (field) => field.fieldAllowNull.toLowerCase() === "no"
    );
  }

  // function validateMandatoryFields(headers, mandatoryFields) {
  //   const mandatoryFieldsNoNull = checkMandatory(mandatoryFields);

  //   const missingMandatoryFields = mandatoryFieldsNoNull.filter(
  //     (field) =>
  //       !rows.some(
  //         (header) =>
  //           header.sourceName.trim().toLowerCase() ===
  //           field.fieldDisplayName.trim().toLowerCase()
  //       )
  //   );

  //   const missingFieldNames = missingMandatoryFields
  //     .map((field) => field.fieldDisplayName)
  //     .join(", ");

  //   return {
  //     missingFieldNames,
  //     isValid: missingFieldNames === "",
  //   };
  // }

  // function mapHeadersToFields(headers, fields) {
  //   return headers.map((header) => {
  //     const invalidItems = [];
  //     const field = fields.find(
  //       (field) =>
  //         header.trim().toLowerCase() ===
  //         field.fieldDisplayName.trim().toLowerCase()
  //     );

  //     if (field) {
  //       debbuger;
  //       const isValid = validateType(
  //         firstRow[Number(field?.dbFieldIndex)],
  //         field?.dbFieldType
  //       );

  //       if (!isValid) {
  //         invalidItems.push({
  //           header,
  //           value: firstRow[Number(field?.dbFieldIndex)],
  //           expectedType: field?.dbFieldType,
  //         });
  //       }
  //       return { ...field, sourceName: header };
  //     }
  //     return {
  //       sourceName: header,
  //       fieldType: "",
  //       fieldLength: "",
  //       fieldDisplayName: "",
  //     };
  //   });
  // }

  const validateType = (value, expectedType) => {
    switch (expectedType) {
      case "varchar":
        return typeof value === "string";

      case "integer":
        return Number.isInteger(Number(value)) && !isNaN(parseInt(value, 10));

      case "float":
        return !isNaN(value) && parseFloat(value) == value;

      case "boolean":
        return value === "true" || value === "false";

      case "date":
        return isValidDate(value);

      default:
        return false;
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  function btnMapFieldsClick() {
    if (!selectedBillingProvider.companyName) {
      setNotify({
        isOpen: true,
        message: "All Highlighted fields are required!",
        type: "error",
      });
      setErrors({
        billingProvider: !selectedBillingProvider.companyName,
        section: !selectedSection || selectedSection === -1,
        serveviceProvider: !selectedServiceprovider.companyName,
        entity: !selectedEntity || selectedEntity.moduleID === -1,
        file: !selectedFile,
        // file:
      });
      setIsLoading(false);
      return;
    }
    if (!selectedServiceprovider.companyName) {
      setNotify({
        isOpen: true,
        message: "Please select Service Provider",
        type: "error",
      });
      setIsLoading(false);
      return;
    }
    if (!selectedEntity || selectedEntity.moduleID === -1) {
      setNotify({
        isOpen: true,
        message: "Please select Entity to continue",
        type: "error",
      });
      setErrors((prev) => ({
        ...prev,
        entity: true,
      }));
      return;
    }
    if (!selectedFile) {
      setNotify({
        isOpen: true,
        message: "Please select file to continue",
        type: "error",
      });
      setErrors((prev) => ({
        ...prev,
        file: true,
      }));
      return;
    }
    if (mandatoryFields?.length === 0) {
      setNotify({
        isOpen: true,
        message: "Please select Highlighted fields",
        type: "error",
      });
      setErrors({
        billingProvider: !selectedBillingProvider,
        section: !selectedSection,
        serveviceProvider: !selectedServiceprovider,
        entity: !selectedEntity,
        file: !selectedFile,
      });
      return;
    }

    if (selFieldList.length === 0) {
      let mapped = {};
      const newFields = [...mandatoryFields];
      const invalidItems = [];
      const result = headers.map((header, headerIndex) => {
        const field = newFields.find(
          (field) =>
            header.replace(/\s/g, "").toLowerCase() ===
            field.fieldDisplayName?.replace(/\s/g, "").toLowerCase()
        ); // recent change

        if (field) {
          // debugger;
          const isValid = validateType(
            firstRow[Number(field?.fieldIndex)],
            field?.fieldType
          );

          if (!isValid) {
            invalidItems.push({
              header,
              value: firstRow[Number(field?.fieldIndex)],
              expectedType: field?.fieldType,
            });
          }
          mapped[header] = {
            ...field,
            sourceName: header,
            fieldIndex: headerIndex,
          };
          return { ...field, sourceName: header, fieldIndex: headerIndex };
        }
        // debugger;
        return {
          sourceName: header,
          fieldType: "",
          fieldLength: "",
          fieldDisplayName: "",
          ...field,
        };
      });

      // console.log(invalidItems, "invalidItems field1234");
      if (invalidItems.length > 0) {
        const invalidFieldNames = invalidItems
          .map((item) => `${item.header} (expected: ${item.expectedType})`)
          .join(", ");
        setNotify({
          isOpen: true,
          message: `The following fields have invalid data types: ${invalidFieldNames}`,
          type: "error",
        });
      }
      const missingFieldNames = returnMissing(result);
      const isValid = missingFieldNames == "";
      // debugger;
      setSelectedVal(mapped);
      setRows(result);
      // setTimeout(() => {
      setIsLoading(false);
      setNotify({
        isOpen: true,
        message: isValid
          ? "Data mapping completed successfully"
          : `The following fields are mandatory and missing: ${missingFieldNames}`,
        type: isValid ? "success" : "error",
      });
      // }, 1000);
    } else {
      const invalidItems = [];
      let mapped = {};
      let dummyMandatoryFields = [...mandatoryFields];
      const result = headers.map((header, rowIndex) => {
        // debugger;
        const field = selFieldList.find(
          // (eachField) => rowIndex == parseInt(eachField.sourceFieldIndex) - 1
          (eachField) =>
            header?.replace(/\s/g, "").toLowerCase() ==
            eachField.sourceFieldName?.replace(/\s/g, "").toLowerCase()
        );
        // debugger;
        if (field) {
          const isValid = validateType(
            firstRow[Number(field?.dbFieldIndex)],
            field?.dbFieldType
          );

          if (!isValid) {
            invalidItems.push({
              header,
              value: firstRow[Number(field?.dbFieldIndex)],
              expectedType: field?.dbFieldType,
            });
          }
          // const filteredVal = dummyMandatoryFields.filter(
          //   (ff) => ff.fieldID == field.dbFieldId
          // );
          // let dummyMandatoryFields = [...mandatoryFields];
          const index = dummyMandatoryFields.findIndex(
            (ff) => ff.fieldID == field.dbFieldId
          );
          if (index !== -1) {
            dummyMandatoryFields[index].dbFieldId = field.dbFieldId;
          }
          // console.log(
          //   { index, dummyMandatoryFields },
          //   " dummyMandatoryFields "
          // );
          // debugger;
          mapped[header] = {
            ...field,
            // ...filteredVal,
            sourceName: header,
            fieldType: field.dbFieldType,
            fieldLength: field.dbFieldLength,
            dbFieldId: field.dbFieldId,
            fieldDisplayName: field?.dbFieldDisplayName,
            dbFieldIndex: rowIndex, // ravi4u backeup code if in case index miss match
          };
          return {
            ...field,
            // ...filteredVal,
            sourceName: header,
            fieldType: field.dbFieldType,
            fieldLength: field.dbFieldLength,
            dbFieldId: field.dbFieldId,
            fieldDisplayName: field?.fieldDisplayName,
            dbFieldIndex: rowIndex, // ravi4u backeup code if in case index miss match
          };
        }
        // ddlSelectChange(header, rowIndex);
        return {
          dbFieldId: "",
          sourceName: header,
          fieldType: "",
          fieldLength: "",
          fieldDisplayName: "",
          ...field,
        };
      });
      const missingFieldNames = returnMissing1(result);
      // debugger;
      const isValid = missingFieldNames == "";

      // console.log(invalidItems, " invalidItems field1234");
      if (invalidItems.length > 0) {
        const invalidFieldNames = invalidItems
          .map((item) => `${item.header} (expected: ${item.expectedType})`)
          .join(", ");
        // setNotify({
        //   isOpen: true,
        //   message: `The following fields have invalid data types: ${invalidFieldNames}`,
        //   type: "error",
        // });
      }
      // console.log(mapped);
      // debugger;
      setSelectedOptions(mapped);
      setRows(result);
      setMandatoryFields(dummyMandatoryFields);
      setTimeout(() => {
        setIsLoading(false);
        setNotify({
          isOpen: true,
          message: isValid
            ? "Data mapping completed successfully"
            : `The following fields are mandatory and missing: ${missingFieldNames}`,
          type: isValid ? "success" : "error",
        });
      }, 1000);
    }
  }

  const getModulesList = async (details) => {
    try {
      const result = await mutateAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        ConfigId: 91916,
        ModuleId: 0,
        SectionId: 0,
      });
      if (result?.status?.code == 200) {
        setWholeState(result?.data);
        setEntityArr(result?.data?.modulesList);
        if (result?.data?.modulesList?.length === 2) {
          timeoutId = setTimeout(() => {
            ddlEntityChange({}, result?.data?.modulesList[1], result?.data);
          }, 500);
        }
        setIsLoading(false);
      } else {
        logTelemetry(
          "Failed",
          "DataImportmodule",
          "getModulesList",
          result?.status?.message
        );
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e, "error");
      logTelemetry(
        "Failed",
        "DataImportmodule",
        "getModulesList",
        e.message,
        e
      );
      setIsLoading(false);
      setNotify({
        isOpen: true,
        message: e.message ?? "Something Went Wrong!!!",
        type: "error",
      });
    }
  };

  function returnMissing(res) {
    const rowsFieldDisplayNames = (rows.length === 0 ? res : rows).map(
      (row) => row.fieldDisplayName
    );
    const missingFields = checkMandatory(mandatoryFields).filter(
      (mandatoryField) => {
        // console.log(mandatoryField, " resresresres ", selFieldList);

        const res = selFieldList?.filter(
          (tt) => tt.dbFieldIndex == mandatoryField.dbFieldId
        );
        // console.log(res, " resresresres ");
        return (
          !rowsFieldDisplayNames.includes(
            res.length > 0
              ? res[0].sourceFieldName
              : mandatoryField.fieldDisplayName
          ) &&
          !Object.values(selectedVal)?.includes(mandatoryField.fieldDisplayName)
        );
      }
    );
    const finalRes = missingFields
      .map((row) => row.fieldDisplayName)
      .join(", ");
    // debugger;
    return finalRes;
  }

  function returnMissing1(res) {
    const rowsFieldDisplayNames = (rows.length === 0 ? res : rows).map(
      (row) => row.dbFieldId
    );
    // debugger;
    const misingFields = checkMandatory(mandatoryFields).filter(
      (mandatoryField) =>
        !rowsFieldDisplayNames.includes(mandatoryField.dbFieldId)
    );
    const finalRes = misingFields.map((row) => row.fieldDisplayName).join(", ");
    return finalRes;
  }

  async function btnProcessClick() {
    // console.clear();
    // console.log();
    // setIsLoading(false);
    // return;
    if (!selectedBillingProvider.companyName) {
      setNotify({
        isOpen: true,
        message: "All Highlighted fields are required!",
        type: "error",
      });
      setErrors({
        billingProvider: !selectedBillingProvider.companyName,
        section: !selectedSection || selectedSection === -1,
        serveviceProvider: !selectedServiceprovider.companyName,
        entity: !selectedEntity || selectedEntity.moduleID === -1,
        file: !selectedFile,
        // file:
      });
      return;
    }
    if (!selectedServiceprovider.companyName) {
      setNotify({
        isOpen: true,
        message: "Please select Service Provider",
        type: "error",
      });
      return;
    }
    if (!selectedEntity || selectedEntity.moduleID === -1) {
      setNotify({
        isOpen: true,
        message: "Please select Entity and Section to continue",
        type: "error",
      });
      setErrors((prev) => ({
        ...prev,
        entity: true,
        section: true,
      }));
      return;
    }
    if (!selectedFile) {
      setNotify({
        isOpen: true,
        message: "Please select file to continue",
        type: "error",
      });
      return;
    }
    if (rows.length === 0) {
      setNotify({
        isOpen: true,
        message: "Click on the MAP FIELDS button to proceed further",
        type: "error",
      });
      return;
    }
    if (selFieldList.length === 0) {
      const rowsFieldDisplayNames = rows.map((row) => row.fieldDisplayName);
      const missingFields = checkMandatory(mandatoryFields).filter(
        (mandatoryField) =>
          !rowsFieldDisplayNames.includes(mandatoryField.fieldDisplayName)
      );
      const finalRes = returnMissing();
      if (finalRes !== "") {
        setNotify({
          isOpen: true,
          message: `The following fields are mandatory and missing: ${finalRes}`,
          type: "error",
        });
        return;
      }
    } else {
      // console.clear();
      const reqFields = checkMandatory(mandatoryFields);
      const newRows = generateRows();
      const notInRes = reqFields
        .filter(
          (mainItem) =>
            !newRows.some(
              (resItem) => resItem.fieldDBName === mainItem.fieldDBName
            )
        )
        .map((item) => item.fieldDisplayName)
        .join(",");
      if (notInRes !== "") {
        setNotify({
          isOpen: true,
          message: `The following fields are mandatory and missing: ${notInRes}`,
          type: "error",
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(true);
    const values = {
      mimeType: selectedFile.type,
      fileName: selectedFile.name,
      locationNumber: details?.companyId,
      file: selectedFile,
    };
    const res = await uploadFileApiCall(values);
    if (res?.response?.status == 201 || res?.response?.status == 200) {
      setNotify({
        isOpen: true,
        message: "File uploaded to Blob",
        type: "success",
      });
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        ConfigId: 91916,
        ModuleId: selectedEntity.moduleID,
        SectionId: selectedSection,
        BillingProviderId: selectedBillingProvider.companyId,
        ServiceProviderId: selectedServiceprovider.companyId,
        ProcessType: "Batch",
        FieldSeparator: selectedSeparator,
        FileName: res?.signData?.data?.fileId,
        //? res?.data?.fileId
        //: "db0c84d65cb04b7c86e58c1ef5748596.csv", //need to make it dymanic
        BatchNumber: "",
        LoadType: "STG", // STG
        FileType:
          selectedFile.type === "text/csv"
            ? 0
            : selectedFile.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ? 1
            : 0,
        ProcessList: generateRows(),
      };

      try {
        const result = await processAPICall(payload);
        if (result?.status?.code === 200) {
          setIsLoading(false);
          setDialogState({
            open: true,
            title: "Success",
            subTitle: `${result?.status?.message}`,
            type: "single",
          });
        } else {
          setIsLoading(false);
          logTelemetry(
            "Failed",
            "DataImportModule",
            "btnProcessClick",
            `${result?.status?.message ?? result?.response?.data?.title}`
          );
          setDialogState({
            open: true,
            title: "Error",
            subTitle: `${
              result?.status?.message ??
              result?.response?.data?.title ??
              "Please contact support team.!!"
            }`,
            type: "single",
          });
        }
      } catch (e) {
        logTelemetry(
          "Failed",
          "DataImportModule",
          "btnProcessClick",
          e.message,
          e
        );
        setNotify({
          isOpen: true,
          message: "btnProcessClick" + e.message,
          type: "info",
        });
        setIsLoading(false);
      }
    } else {
      logTelemetry(
        "Failed",
        "DataImportModule",
        "btnProcessClick",
        `${res?.message ?? "Pre-Sign Fail"}`
      );
      setIsLoading(false);
      setDialogState({
        open: true,
        title: "Error",
        subTitle: getErrorMessage(res?.message),
        type: "single",
      });
    }
  }

  const resetState = () => {
    setSelectedFile(null);
    setFirstRow(null);
    // setWholeState({});
    // setEntityArr([]);
    setSectionArr([]);
    setIsLoading(false);
    setSelectedEntity(null);
    setSelectedSection("");
    setSelectedServiceprovider(initialLocation);
    setSelectedBillingProvider(initialLocation);
    setRows([]);
    setMandatoryFields([]);
    setHeaders([]);
    setSelectedVal({});
    setSelFieldList([]);
    setNotify({
      isOpen: false,
      message: "",
      type: "error",
    });
    if (inputRef.current) {
      inputRef.current.value = null;
    }
    setErrors({
      billingProvider: false,
      serveviceProvider: false,
      entity: false,
      section: false,
      file: false,
    });
    setSelectedSeparator(",");
    setDialogState({ ...intial });
  };

  const cancelClick = () => {
    if (!selectedSection && !selectedFile && !selectedEntity) {
      setNotify({
        isOpen: true,
        message: "There are no changes to cancel",
        type: "warning",
      });
    } else {
      setDialogState({
        open: true,
        title: "Warning",
        subTitle:
          "You have selected ‘Cancel changes’ Are you sure you want to proceed?",
        type: "double",
      });
    }
  };

  const handleConfirm = (title) => {
    if (title == "Error") {
      setDialogState({ ...intial });
    } else {
      resetState();
    }
  };

  function ddlBillingProviderChange(newValue) {
    setSelectedBillingProvider(newValue);
    if (errors.billingProvider) {
      setErrors((prev) => ({
        ...prev,
        billingProvider: false,
      }));
    }
  }

  function ddlServiceProviderChange(newValue) {
    setSelectedServiceprovider(newValue);
    if (errors.serveviceProvider) {
      setErrors((prev) => ({
        ...prev,
        serveviceProvider: false,
      }));
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} className="mb-2 mt-3">
          <div>
            <Container maxWidth="xl">
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                // xs={12}
                // sm={12}
                className="my-4"
              >
                <Typography variant="h6">Data Import Wizard</Typography>
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ ml: 1 }}
                    endIcon={<SyncIcon />}
                    onClick={() => {
                      btnProcessClick();
                    }}
                    className="button-styles btnHeight !min-w-[118px]"
                  >
                    Process
                  </Button>
                  {false && (
                    <Button
                      variant="outlined"
                      sx={{ ml: 1 }}
                      //   endIcon={<ArrowForward />}
                    >
                      Process Now
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    sx={{ ml: 1 }}
                    // color="error"
                    endIcon={<Cancel />}
                    onClick={() => cancelClick()}
                    className="button-styles btnHeight !min-w-[118px] !mr-[16px]"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>

              <Card>
                <CardContent>
                  <Grid container>
                    <Grid container spacing={3} className="pt-5">
                      <Grid item xs={6}>
                        <Autocomplete
                          autoSelect={false}
                          fullWidth
                          disableClearable
                          size="small"
                          options={locationData || []} // Assuming locationData is an array of objects
                          getOptionLabel={(item) => item.companyName}
                          isOptionEqualToValue={(option, value) =>
                            option.companyId === value.companyId
                          }
                          value={
                            selectedBillingProvider
                              ? selectedBillingProvider
                              : null
                          }
                          onChange={(e, newValue) =>
                            ddlBillingProviderChange(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="billingProvider"
                              label={
                                <span>
                                  <span style={{ color: "red" }}>*</span>
                                  Billing Provider
                                </span>
                              }
                              error={errors.billingProvider}
                              InputLabelProps={{
                                id: "billing-provider-label",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          disableClearable
                          autoSelect={false}
                          size="small"
                          options={locationData || []} // Assuming locationData is an array of objects
                          getOptionLabel={(item) => item.companyName}
                          isOptionEqualToValue={(option, value) =>
                            option.companyId === value.companyId
                          }
                          value={selectedServiceprovider}
                          onChange={(e, newValue) =>
                            ddlServiceProviderChange(newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="ServiceProvider"
                              label={
                                <span>
                                  <span style={{ color: "red" }}>*</span>
                                  Service Provider
                                </span>
                              }
                              error={errors.serveviceProvider}
                              InputLabelProps={{
                                id: "service-provider-label",
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Autocomplete
                          options={entityArr}
                          key={"dfasd"}
                          autoSelect={false}
                          id="auto-box-demo"
                          disableClearable
                          getOptionLabel={(option) => option.moduleName}
                          isOptionEqualToValue={(option, value) =>
                            option.moduleName === value.moduleName
                          }
                          value={selectedEntity}
                          onChange={ddlEntityChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <>
                                  <span style={{ color: "red" }}>*</span> Entity
                                </>
                              }
                              variant="outlined"
                              error={errors.entity}
                              name="entity"
                              size="small"
                            />
                          )}
                        />
                        {/* </FormControl> */}
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={errors?.section}
                        >
                          <InputLabel id="section-label">
                            <span style={{ color: "red" }}>*</span> Section
                          </InputLabel>
                          <Select
                            labelId="section-label"
                            id="section"
                            name="section"
                            value={selectedSection}
                            onChange={(e) => ddlSectionChange(e)}
                            label="Section"
                          >
                            {sectionArr?.length > 0 &&
                              sectionArr?.map((sect) => (
                                <MenuItem
                                  value={sect.sectionID}
                                  key={sect.sectionID}
                                >
                                  {sect.sectionName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          size="small"
                          sx={{ height: "2.7rem" }}
                        >
                          {!selectedFile && (
                            <InputLabel id="file-upload-label">
                              Selected File
                            </InputLabel>
                          )}
                          <TextField
                            fullWidth
                            onClick={() => {
                              if (selectedEntity) {
                                inputRef.current.click();
                              } else {
                                setNotify({
                                  isOpen: true,
                                  message: "Kindly select Entity to continue",
                                  type: "warning",
                                });
                              }
                            }}
                            size="small"
                            label={selectedFile ? "Selected File" : ""}
                            value={selectedFile ? selectedFile.name : ""}
                            // disabled
                            error={errors.file}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <IconButton edge="end">
                                  <UploadFile
                                    fontSize="medium"
                                    color="primary"
                                  />
                                </IconButton>
                              ),
                            }}
                          />
                          <input
                            ref={inputRef}
                            type="file"
                            hidden
                            onChange={fileSelectClick}
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel id="section-label">
                            <span style={{ color: "red" }}>*</span> Field
                            Separator
                          </InputLabel>
                          <Select
                            labelId="field-separator-label"
                            id="field-separator"
                            name="field-separator"
                            value={selectedSeparator}
                            onChange={ddlFieldSeparatorChange}
                            label="Field Separator"
                          >
                            {options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        alignItems="center"
                        justifyContent="flex-end"
                        xs={12}
                        sm={12}
                        className="mt-3"
                      >
                        <Box
                          sx={{ display: "flex", flexDirection: "row-reverse" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{ ml: 1 }}
                            // color="error"
                            endIcon={<AccountTreeOutlined />}
                            onClick={() => {
                              btnMapFieldsClick();
                            }}
                            className="button-styles btnHeight w-15p7"
                          >
                            Map Fields
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* Space */}

              <Grid container spacing={2} className="pb-4 !mt-1">
                <Grid item xs={9.7}>
                  <TableContainer
                    //component={Paper}
                    sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow
                          sx={{
                            backgroundColor: "#f5f5f5",
                            minWidth: "30px",
                          }}
                        >
                          <TableCell
                            sx={{
                              width: "24%",
                              textAlign: "center",
                              padding: "13px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Source Field({rows?.length})
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "24%",
                              textAlign: "center",
                              padding: "13px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Destination Field
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "15%",
                              textAlign: "center",
                              padding: "13px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Field Type
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "12%",
                              textAlign: "center",
                              padding: "13px",
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                          >
                            Field Size
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="bg-white">
                        {rows &&
                          rows.length > 0 &&
                          rows?.map((fieldObj, rowIndex) => (
                            <TableRow key={rowIndex}>
                              <TableCell sx={{ padding: "8px", width: "35%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={fieldObj.sourceName}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: "8px", width: "35%" }}>
                                {selFieldList.length === 0 ? (
                                  <FormControl
                                    key={rowIndex}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                  >
                                    <Autocomplete
                                      autoSelect={false}
                                      id={`destination-field-${rowIndex}`}
                                      value={
                                        selectedVal[fieldObj?.sourceName] || ""
                                      }
                                      onChange={(event, newValue) =>
                                        ddlSelectChange(
                                          newValue?.fieldDisplayName ?? "",
                                          rowIndex,
                                          newValue,
                                          fieldObj?.sourceName
                                        )
                                      }
                                      disableClearable
                                      options={mandatoryFields}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // placeholder={fieldObj?.fieldDisplayName}
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                        />
                                      )}
                                      getOptionLabel={(option) =>
                                        option.fieldDisplayName || ""
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.fieldDisplayName ===
                                        value.fieldDisplayName
                                      }
                                      renderOption={(props, option) => (
                                        <li
                                          {...props}
                                          style={{
                                            height:
                                              option.fieldDisplayName === ""
                                                ? "35px"
                                                : "auto",
                                          }}
                                        >
                                          {option.fieldDisplayName}
                                        </li>
                                      )}
                                    />
                                  </FormControl>
                                ) : (
                                  <Autocomplete
                                    autoSelect={false}
                                    key={rowIndex}
                                    fullWidth
                                    disableClearable
                                    size="small"
                                    options={mandatoryFields}
                                    getOptionLabel={(option) =>
                                      option.fieldDisplayName || ""
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.fieldDisplayName ===
                                      value.fieldDisplayName
                                    }
                                    value={
                                      selectedOptions[fieldObj.sourceName] ||
                                      null
                                    }
                                    onChange={(event, newValue) => {
                                      ddlDestinationFieldChange(
                                        newValue?.fieldDisplayName ?? "",
                                        rowIndex,
                                        newValue,
                                        fieldObj.sourceName
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        // label={fieldObj?.fieldDisplayName}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          height:
                                            option.fieldDisplayName === ""
                                              ? "35px"
                                              : "auto",
                                        }}
                                      >
                                        {option.fieldDisplayName}
                                      </li>
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell sx={{ padding: "8px", width: "15%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={fieldObj?.fieldType}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: "8px", width: "12%" }}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  value={
                                    fieldObj?.fieldLength == 0
                                      ? ""
                                      : fieldObj?.fieldLength
                                  }
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={2.3}>
                  <Box
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: 1,
                    }}
                    style={{ backgroundColor: "white" }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#f5f5f5", // Background color for Typography
                        textAlign: "center",
                        padding: "14.5px",
                        fontWeight: "700",
                        textAlign: "left",
                        fontSize: "0.875rem",
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      Mandatory Fields
                    </Typography>
                    <ul
                      style={
                        mandatoryFields?.length !== 0
                          ? {
                              listStyleType: "none",
                              padding: "1rem",
                            }
                          : {}
                      }
                    >
                      {mandatoryFields?.map(
                        (field) =>
                          field.fieldAllowNull.toLowerCase() === "no" &&
                          field.fieldDisplayName !== "" && (
                            <li key={field.fieldID}>
                              {" "}
                              {/* Use a unique identifier like fieldID */}
                              <span style={{ color: "red" }}>*</span>{" "}
                              {field.fieldDisplayName}
                            </li>
                          )
                      )}
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
}

export default DataImportModule;
