let colors = [
  "#F9E0A2", // Light yellow
  "#005F60", // Dark teal
  "#f08c00", // Orange
  "#e03131", // Red
  "#4682B4", // Steel Blue
  "#FF4500", // Orange Red
  "#20B2AA", // Light Sea Green
  "#9932CC", // Dark Orchid
  "#2B3A57", // Dark Orange
  "#FFA07A", // Light Salmon
  "#9370DB", // Medium Purple
  "#7CC674", // Light green
  "#FFD700", // Gold
  "#00CED1", // Dark Turquoise
  "#B0C4DE", // Light Steel Blue
  "#FF6347", // Tomato
  "#6A5ACD", // Slate Blue
  "#32CD32", // Lime Green
  "#FF69B4", // Hot Pink
  "#F0E68C", // Khaki
];

function repeatColor(array, times) {
  const repeatedArr = [];
  for (let i = 0; i < times; i++) {
    repeatedArr.push(...array);
  }
  return repeatedArr;
}
const repeatedColor = repeatColor(colors, 100);
var dynamicColors = [];

for (var i = 0; i < 2000; i++) {
  var chkColor = repeatedColor[i];
  if (chkColor != undefined) {
    dynamicColors.push(chkColor);
  } else {
    dynamicColors.push("#A9A9A9");
  }
}
colors = dynamicColors;

export default colors;
