const getColor = (subRequestType) => {
  if (subRequestType.includes("Success")) {
    return "#099268"; // Green color for Success
  } else if (subRequestType.includes("Failure")) {
    return "#e03131"; // Red color for Failure
  } else {
    return "#ffc107"; // yellow given by neel
  }
};

export default getColor;
