import styled from '@emotion/styled';
import { FormLabel } from '@mui/material';
import { Box ,Paper} from '@mui/material';
import Draggable from 'react-draggable';


export const contentStyle = {
    textAlign: 'center',
    color: '#555050',
    backgroundColor: '#ffffff',
    padding: '20px 0px 20px 0px ',
    fontSize: '16px',

};

export const AntDesignFormLabel = styled(FormLabel)({
    fontSize: '12px',
    fontWeight: '500',
    color: '#000000',
    backgroundColor: '#e4e6eab8',
    padding: '5px 12px 5px 12px',
    marginBottom: '8px',
    display: 'inline-block',
    textAlign: 'left',
});

export const ErrorContainer = styled(Box)(({ theme }) => ({
    borderRadius: '4px',
    padding: theme.spacing(2),
    backgroundColor: '#f7e0e2',
    fontSize: '12px',
    color: '#000000 !important',
}));

export const customDropdownStyle = (props) => (
    <Paper
      {...props}
      sx={{
        fontSize: '12px !important',
        width: '320px !important',
        height: 'auto !important'
      }}
    />
  );
  
  export const inputCursorBlock = {
    cursor: 'not-allowed',
  };
  
  export const  DraggablePopover=(props)=> {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'} // Prevent dragging from inside content
        maxWidth="sm"
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  