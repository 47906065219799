import * as React from "react";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
// import data from "./data";
// data

// Define DynamicTable as a separate function
const DynamicTable = ({ data }) => {
  // console.log("neel ", data);
  return (
    <React.Fragment>
      {data.map((dataSet, index) => {
        const { cells, columnCount, rowCount } = dataSet;

        // Create a grid for the table
        const grid = Array.from({ length: rowCount }, () =>
          Array(columnCount).fill(null)
        );

        // Populate the grid
        cells.forEach((cell) => {
          const {
            columnIndex,
            rowIndex,
            columnSpan = 1,
            rowSpan = 1,
            content,
            kind,
          } = cell;
          for (let row = rowIndex; row < rowIndex + rowSpan; row++) {
            for (let col = columnIndex; col < columnIndex + columnSpan; col++) {
              if (grid[row][col] === null) {
                // Check if the cell is not already filled
                grid[row][col] = { content, kind };
              }
            }
          }
          if (columnSpan > 1) {
            for (
              let col = columnIndex + 1;
              col < columnIndex + columnSpan;
              col++
            ) {
              grid[rowIndex][col] = { content: "", kind };
            }
          }
        });

        return (
          <TableContainer component={Paper} key={index} sx={{ mb: 4 }}>
            <Table>
              <TableBody>
                {grid.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, columnIndex) => (
                      <TableCell
                        key={columnIndex}
                        colSpan={
                          cell?.kind === "columnHeader" ? 1 : cell?.columnSpan
                        }
                        rowSpan={
                          cell?.kind === "columnHeader" ? 1 : cell?.rowSpan
                        }
                        sx={{
                          fontWeight:
                            cell?.kind === "columnHeader" ? "bold" : "normal",
                        }}
                      >
                        {cell?.content}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      })}
    </React.Fragment>
  );
};

export default function VerticalRowsModal({ open, handleClose, data }) {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="div" id="modal-title">
              Table Structure
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DynamicTable data={data} />
        </Box>
      </Modal>
    </React.Fragment>
  );
}
