import {
  initialFilters,
  wholeReduxObj,
} from "../../components/utils/SidebarRoutes";
import Constants from "../constants";

const initialState = {
  previousComponent: null,
  currentComponent: null,
  previousComponentData: wholeReduxObj["/patient-list".toUpperCase()],
  currentComponentData: wholeReduxObj["/patient-list".toUpperCase()],
};

export const previousComponentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.SET_PREVIOUS_AND_CURRENT_COMPONENT: {
      // debugger;
      return {
        ...state,
        previousComponent: state.currentComponent, // Save current to previous
        previousComponentData: state.currentComponentData, // Save current data to previous data
        currentComponent: action.payload.currentComponent,
        currentComponentData:
          state.previousComponent === action.payload.currentComponent
            ? state.previousComponentData
            : wholeReduxObj[action?.payload?.currentComponent?.toUpperCase()] ||
              null,
      };
    }
    case Constants.STORE_SET_FORM_DATA: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          formData: action.payload,
        },
      };
    }
    case Constants.STORE_LOOKUP_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          lookUpData: action.payload,
        },
      };

    case Constants.STORE_DID:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          dId: action.payload,
        },
      };

    case Constants.STORE_FILTERED_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filteredData: action.payload,
        },
      };

    case Constants.STORE_SET_FORM_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          formData: action.payload,
        },
      };

    case Constants.STORE_SET_PAGE_MODEL:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          pageModel: action.payload,
        },
      };

    case Constants.STORE_SET_FILTER_MODEL:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filterModel: action.payload,
        },
      };

    case Constants.STORE_SET_SORT_MODEL:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          sortModel: action.payload,
        },
      };

    case Constants.STORE_SET_FILTER_APPLIED:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filtersApplied: action.payload,
        },
      };

    case Constants.STORE_RESET_PREVIOUS_COMP_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          ...action.payload,
        },
      };

    case Constants.PATIENT_SET_FORM_DATA: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filters: action.payload,
        },
      };
    }
    case Constants.PATIENT_SET_PAGE_MODEL: {
      // debugger;
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          paginationModel: action.payload,
        },
      };
    }
    case Constants.PATIENT_SET_FILTER_MODEL: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          patientFilterModel: action.payload,
        },
      };
    }
    case Constants.PATIENT_SET_SORT_MODEL: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          patientSortModel: action.payload,
        },
      };
    }
    case Constants.PATIENT_SET_FILTER_APPLIED: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          isFilters: action.payload,
        },
      };
    }
    case Constants.RESET_PATIENT_DATA: {
      return {
        ...state,
        currentComponentData: {
          filters: initialFilters,
          paginationModel: {
            page: 0,
            pageSize: 100,
          },
          patientFilterModel: null,
          patientSortModel: null,
          isFilters: false,
        },
      };
    }

    case Constants.STORE_COSMOS_ID: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          cosmosId: action.payload, // Assuming you want to store it under cosmosId
        },
      };
    }

    case Constants.STORE_PATIENT_FILTERED_DATA: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filteredData: action.payload, // Assuming you want to store it under filteredData
        },
      };
    }

    case Constants.STORE_REPORT_DATA: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          filtersData: action.payload,
        },
      };
    }

    case Constants.STORE_REPORT_DATA_GRID: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          dataGrid: action.payload,
        },
      };
    }

    case Constants.STORE_REPORT_INFO: {
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          repInfo: action.payload,
        },
      };
    }

    case Constants.STORE_LOOKUP_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          cosmosId: action.payload,
        },
      };

    case Constants.CAPTURE_DETAILS_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          captureDetailsData: action.payload,
        },
      };

    case Constants.CAPTURE_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          captureData: action.payload,
        },
      };

    case Constants.CAPTUR_SMS_DATA:
      return {
        ...state,
        currentComponentData: {
          ...state.currentComponentData,
          captureSMSData: action.payload,
        },
      };

    default:
      return state;
  }
};
