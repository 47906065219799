import Constants from "../constants";

export const setPreviousAndCurrentComponent = (
  previous,
  current,
  previousComponentData
) => ({
  type: Constants.SET_PREVIOUS_AND_CURRENT_COMPONENT,
  payload: {
    previousComponent: previous,
    currentComponent: current,
    previousComponentData,
  },
});
