import { useDispatch } from "react-redux";
import { setAccessRights } from "../../redux/actions/loginDetails"; // Assuming you have an action creator for setting access rights

const moduleMapping = {
  70180: "/PATIENT-LIST",
  70182: "/PATIENT-LIST/CAPTURE-DRIVING-LICENSE",
  70183: "/PATIENT-LIST/SEND-SMS-OR-EMAIL",
  70181: "/PATIENT-LIST/DISCOVER-INSURANCE",
  70007: "/LOCATION",
  70029: "/STAFF",
  70176: "/PATIENT-LIST/TRANSATION-SUMMARY",
  70140: "/PATIENT-LIST/REPORTS",
  70075: "/TASK-SUMMARY",
  70112: "/DOCUMENT-SUMMARY",
  70179: "/PATIENT-LIST/DOCUMENT-VERIFICATION",
  70142: "/PATIENT-LIST/DATA-IMPORT-MODULE",
  70177: "/PATIENT-LIST/CLAIM-SUBMIT",
  70178: "/PATIENT-LIST/CLAIM-STATUS",
  70184: "/DOCAICONFIGURATION",
};

export const handleComponentRender = (modId) => {
  const route = Object.entries(moduleMapping).find(
    ([key, value]) => parseInt(key) === modId
  );
  return route ? route[1] : null;
}
export const handleActionRender = (data) => {
  if (data?.modId && data?.secs) {
    const section = data.secs.find((section) => section.funcs.some((func) => func.funcId));
    if (section) {
      const func = section.funcs.find((func) => func.funcId);
      if (func) {
        if(func.confId){
          return func.confId;
        }
      }
    }
  }
  return 0;
};



