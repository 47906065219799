import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import DialogBox from "../../components/DialogBox";
import { GetCallDocumentSummaryAIListmaryAI } from "../../apis/pieChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MyContext from "../../components/MyContext";
import Notification from "../../components/Notification";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearAllIcon from "@mui/icons-material/Clear";
import Tooltip from "@mui/material/Tooltip";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import getRole from "../../components/utils/getRole";
import { TabContext } from "../../components/utils/TabProvider";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import convertToAgGridFormat from "../../components/utils/convertToAgGridFormat";
import { logTelemetry } from "../../logTelemetry";
import { useSelector } from "react-redux";

const TransationSummaryList = ({
  dataFromTS,
  setValue,
  setDataForCS,
  setdataForCStatus,
  rows,
  isFrom,
  setDataForRows,
  settabs,
}) => {
  // const { details?.companyId } = useContext(MyContext);
  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  const [filterClick, setfilterClick] = useState(false);
  const [searchClick, setSearchClick] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchImport, setsearchImport] = useState(false);
  let [filters, setFilters] = useState({});
  let [isFilters, setIsFilters] = useState(false);
  let [isSearchFilters, setIsSearchFilters] = useState(false);
  let [SearchInputvalue, setSearchInputvalue] = useState("");
  const [isGridReady, setIsGridReady] = useState(false);

  const {
    paginationModel,
    rowSelectionModel,
    setRowSelectionModel,
    setPaginationModel,
    setOrbitClaimId,
    setProviderControlNumber,
    setPatientControlNumber,
  } = useContext(TabContext);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const { mutateAsync, data, isLoading } = GetCallDocumentSummaryAIListmaryAI();
  // const [exportModel, setexportModel] = useState();

  // console.log(filters)

  async function getTransationSummaryList(val) {
    var RequestFailureCode = "";
    var RequestSubFailureCode = "";

    if (
      dataFromTS?.pRequest_Subtype == "byReason" &&
      dataFromTS?.payerName != ""
    ) {
      var splitArraydata = dataFromTS?.payerName.split("(");
      if (splitArraydata != "" && splitArraydata.length > 1) {
        var dataPayerNameList = splitArraydata[1].split(")");
        if (dataPayerNameList != null && dataPayerNameList.length > 0) {
          var RequestFailureCodeSplit = dataPayerNameList[0].split(",");
          if (
            RequestFailureCodeSplit != null &&
            RequestFailureCodeSplit.length > 1
          ) {
            RequestFailureCode = RequestFailureCodeSplit[0];
          }
          if (
            RequestFailureCodeSplit != null &&
            RequestFailureCodeSplit.length > 1
          ) {
            RequestSubFailureCode = RequestFailureCodeSplit[1];
          }
        }
        dataFromTS.payerName = RequestFailureCode;
      }
    }
    if (
      dataFromTS?.pRequest_Subtype != "" &&
      dataFromTS?.pRequest_Subtype != null
    ) {
      dataFromTS.pRequest_Subtype =
        dataFromTS?.pRequest_Subtype == "byPayerCount"
          ? "byCount"
          : dataFromTS?.pRequest_Subtype == "byPayerLatency"
          ? "bylatency"
          : dataFromTS?.pRequest_Subtype;
    }
    // debugger;
    try {
      await mutateAsync({
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        // ...dataFromTS,
        fromDate: dataFromTS?.fromDate || "",
        toDate: dataFromTS?.toDate || "",
        requestStatus: dataFromTS?.from || "",
        RequestType: dataFromTS?.pRequest_Type || "",
        requestSubType: dataFromTS?.pRequest_Subtype || "",
        companyLocationID: dataFromTS?.location?.companyId || "0",
        requestName: dataFromTS?.payerName || "",
        requestFailureCode: dataFromTS?.failureStatuscode,
        requestSubFailureCode: dataFromTS?.failureStatusCategory,
        payerId: dataFromTS?.payerId,
        holdFlag: dataFromTS?.holdFlag,
      });
    } catch (e) {
      logTelemetry(
        "Failed",
        "TransationSummaryList",
        "getTransationSummaryList",
        e.message,
        e
      );
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      if (data?.status && data?.status?.code != 200) {
        setNotify({
          isOpen: true,
          message: `${data?.status?.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummaryList",
          "getTransationSummaryList",
          `${data?.status?.message}`
        );
      }
      if (data?.status && data?.status?.code == 200) {
        setDataForRows(data?.data);
      }
    }
    if (data) {
      if (data?.response && data?.response?.status != 200) {
        setNotify({
          isOpen: true,
          message: `${data?.response?.message}`,
          type: "error",
        });
        logTelemetry(
          "failed",
          "TransationSummaryList",
          "getTransationSummaryList",
          `${data?.response?.message}`
        );
      }
    }
  }, [data]);
  useEffect(() => {
    // console.log(
    //   {
    //     dataFromTS,
    //     setValue,
    //     setDataForCS,
    //     setdataForCStatus,
    //     data: rows,
    //   },
    //   "  useEffect "
    // );
    if (isFrom) {
      setPaginationModel({
        page: 0,
        pageSize: 100,
      });
      setRowSelectionModel([]);
      getTransationSummaryList();
    }
  }, [filters, search, details?.companyId]);
  useEffect(() => {
    if (searchImport == true) {
      getTransationSummaryList();
    }
    // return () => {
    //   setDataForRows([]);
    // };
  }, [searchImport]);
  let agree = async () => {
    setOpen(false);
    // setFilters({});
  };
  let disagree = async () => {
    setOpen(false);
  };
  const postRowStyle = (record, index) => ({
    // backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    backgroundColor: "red",
  });

  let columns = null;
  if (dataFromTS?.pRequest_Type == "RTE") {
    columns = [
      {
        field: "ediAction",
        headerName: "View",
      },
      {
        field: "patientControlnumber",
        headerName: "Patient Control Number",
        cellRenderer: (cellValues) => {
          if (cellValues.node.group) {
            return null; // Return null for grouped rows to hide the icon
          }
          return <div>{cellValues.value}</div>;
        },
      },
      {
        field: "orbitAssignedClaimId",
        headerName: "Orbit Assigned Id",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "dos",
        headerName: "Service Date",
        cellRenderer: (cellValues) => {
          let values = cellValues.value;
          if (cellValues.node.group) {
            return null; // Return null for grouped rows to hide the icon
          }
          return (
            <span>
              {values == "" ? "" : dayjs(values).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        field: "planStatus",
        headerName: "Plan Status",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "payerid",
        headerName: "Payer Id",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "payername",
        headerName: "Payer Name",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "patientFirstName",
        headerName: "First Name",
      },
      {
        field: "patientLastName",
        headerName: "Last Name",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "patientdob",
        headerName: "DOB",
        cellRenderer: (cellvalues) => {
          if (cellvalues.node.group) {
            return null; // Return null for grouped rows to hide the icon
          }
          return (
            <span>
              {cellvalues.value == ""
                ? ""
                : dayjs(cellvalues.value).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        field: "renderingprovider",
        headerName: "Rendering Provider",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "clearingHouseName",
        headerName: "Clearing House Name",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value.toLowerCase() === "wavehdc"
                ? "Experian Health"
                : cellvalues.value}
            </span>
          );
        },
      },

      {
        field: "editatus",
        headerName: "EDI Status",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "rteErr",
        headerName: "Rte Err",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
    ];
  } else {
    columns = [
      {
        field: "Action",
        headerName: "View",
      },
      {
        field: "ClaimStatus",
        headerName: "CS",
      },
      {
        field: "patientControlnumber",
        headerName: "Patient Control Number",
        width: 240,
        cellRenderer: (cellValues) => {
          // debugger;
          return <div>{cellValues.value}</div>;
        },
      },
      {
        field: "providercontrolnumber",
        headerName: "Provider Control Number",
        width: 240,
        cellRenderer: (cellValues) => {
          return <div>{cellValues.value}</div>;
        },
      },
      {
        field: "dos",
        headerName: "Service Date",
        cellRenderer: (cellValues) => {
          let values = cellValues.value;
          if (cellValues.node.group) {
            return null; // Return null for grouped rows to hide the icon
          }
          return (
            <span>
              {values === "" ? "" : dayjs(values).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        field: "orbitAssignedClaimId",
        headerName: "Orbit Claims Id",
        cellRenderer: (cellvalues) => {
          return (
            <p className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </p>
          );
        },
      },
      {
        field: "cpid",
        headerName: "CPID",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "payerid",
        headerName: "Payer Id",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "payername",
        headerName: "Payer Name",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "patientFirstName",
        headerName: "First Name",
      },
      {
        field: "patientLastName",
        headerName: "Last Name",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "patientdob",
        headerName: "DOB",
        cellRenderer: (cellvalues) => {
          if (cellvalues.node.group) {
            return null; // Return null for grouped rows to hide the icon
          }
          return (
            <span>
              {cellvalues.value === ""
                ? ""
                : dayjs(cellvalues.value).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        field: "claimAmount",
        headerName: "Claim Amount",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "renderingprovider",
        headerName: "Rendering Provider",
        cellRenderer: (cellvalues) => {
          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {cellvalues.value}
            </span>
          );
        },
      },
      {
        field: "claimStatus",
        headerName: "Claim Status",
        cellRenderer: (cellvalues) => {
          const text = cellvalues.value || "";
          const displayText =
            text.length > 90 ? `${text.substring(0, 90)}...` : text;

          return (
            <span className="text-sm text-slate-700 datagridWraptxt">
              {displayText}
            </span>
            // <Tooltip title={text} arrow></Tooltip>
          );
        },
      },
    ];
  }

  if (!getRole("mClaimStatus")) {
    columns = columns.filter((col) => col.headerName !== "CS");
  }
  if (!getRole("tscs")) {
    columns = columns.filter((col) => col.headerName !== "View");
  }

  const breadcrumbItems = [
    { label: "Insurance Verification List", link: "/patient-list" },
  ];

  const { agGridRows, agGridColumns } = convertToAgGridFormat(rows, columns);

  // Add view icon to the first column
  if (dataFromTS?.pRequest_Type != "RTE") {
    agGridColumns[0] = {
      ...agGridColumns[0],
      width: 110,
      cellRenderer: (params) => {
        if (params.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <IconButton
            onClick={() => {
              setOrbitClaimId("");
              setProviderControlNumber("");
              setPatientControlNumber("");
              setRowSelectionModel([params.data.id]);
              setValue(2);
              setDataForCS({
                providercontrolnumber: params.data.providercontrolnumber,
                orbitAssignedClaimId: params.data.orbitAssignedClaimId,
                patientControlnumber: params.data.patientControlnumber,
                selObject: params.data,
              });
            }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    };

    agGridColumns[1] = {
      ...agGridColumns[1],
      width: 100,
      cellRenderer: (params) => {
        if (params.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <IconButton
            onClick={() => {
              setRowSelectionModel([params.data.id]);
              setValue(3);
              setdataForCStatus({ providercontrolnumber: params.data });
            }}
          >
            <TaskAltOutlinedIcon />
          </IconButton>
        );
      },
    };
  } else {
    agGridColumns[0] = {
      ...agGridColumns[0],
      width: 110,
      cellRenderer: (params) => {
        if (params.node.group) {
          return null; // Return null for grouped rows to hide the icon
        }
        return (
          <IconButton
            onClick={() => {
              setRowSelectionModel([params.data.id]);
              const makeTask = {
                label: "Insurance Verification Details",
                role: "mClaimStatus",
              };
              settabs((tabs) => {
                const updatedEntries = [...tabs];
                if (updatedEntries != null && updatedEntries.length > 0) {
                  var isExistsTab = updatedEntries.filter(
                    (o) => o.label == "Insurance Verification Details"
                  );
                  if (isExistsTab != null && isExistsTab.length == 0) {
                    updatedEntries.push(makeTask);
                  }
                }
                return updatedEntries;
              });
              setdataForCStatus({ providercontrolnumber: params.data });
              setValue(4);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    };
  }

  return (
    <div className="">
      <Grid container>
        <Grid item xs={12} sm={7}></Grid>
      </Grid>
      {(isFilters || isSearchFilters) && (
        <Grid container>
          <Grid item xs={12} sm={12}>
            {isFilters && isSearchFilters == false && (
              <span style={{ color: "blue" }}>
                Displaying rows with filters applied. You may change or clear
                filters by clicking on filter.
              </span>
            )}
            {isSearchFilters && isFilters == false && (
              <span style={{ color: "blue" }}>
                Displaying rows with Search applied. You may change or clear
                filters by clicking on filter.
              </span>
            )}
            {isSearchFilters && isFilters && (
              <span style={{ color: "blue" }}>
                Displaying rows with Search/filters are applied. You may change
                or clear filters by clicking on filter.
              </span>
            )}

            <Tooltip title="Apply Filter">
              <FilterListIcon
                onClick={() => {
                  setfilterClick();
                  setOpen(true);
                  setfilterClick(false);
                }}
                sx={{ "&amp;:hover": { color: "red" } }}
                color="primary"
              />
            </Tooltip>
            <Tooltip title="Clear Filters">
              <ClearAllIcon
                placeholder="Clear All"
                onClick={() => {
                  patientBackDataObj = null;
                  sessionStorage?.removeItem("patientBackData");
                  sessionStorage?.removeItem("setCosmosKeyID");
                  setIsFilters(false);
                  setIsSearchFilters(false);
                  setFilters({});
                  setSearchInputvalue("");
                  setMessage("");
                  setSearchClick(false);
                  setSearch(!search);
                }}
                sx={{ "&amp;:hover": { color: "red" } }}
                color="primary"
              />
            </Tooltip>
          </Grid>
        </Grid>
      )}

      <div>
        <Box className="ag-theme-alpine tsHgt relative">
          <AgGridReact
            columnDefs={agGridColumns}
            onGridReady={() => setIsGridReady(true)}
            rowData={
              isGridReady && agGridRows.length > 0
                ? agGridRows.map((row, index) => ({ ...row, id: index }))
                : []
            }
            loading={!isGridReady || isLoading}
            // onCellClicked={handleCellClick}
            pagination={true}
            paginationPageSize={paginationModel.pageSize ?? 100}
            paginationPageSizeSelector={[100, 250, 500, 1000]}
            rowSelection="single"
            rowGroupPanelShow="always"
            onRowSelected={(event) =>
              setRowSelectionModel([event.node.rowIndex])
            }
            alwaysShowVerticalScroll={true}
            domLayout="autoHeight"
            suppressCopyRowsToClipboard={true}
          />
        </Box>
      </div>

      {open ? (
        <DialogBox
          open={true}
          cancel={agree}
          submit={disagree}
          // page={page}
          setFilters={setFilters}
          filters={filters}
          setIsFilters={setIsFilters}
          setOpen={setOpen}
        />
      ) : null}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default TransationSummaryList;
// Repati kosam
//  Dynamic Rows Code Start here 723 to 794 Don't remove this code
// console.log(rows, " rows ");
// if (!rows || rows.length === 0) return null;
// const staticColumns = [
//   {
//     field: "Action",
//     headerName: "View",
//     disableColumnMenu: false,
//     disableExport: true,
//     sortable: true,
//     align: "left",
//     width: 70,
//     maxWidth: 70,
//     cellRenderer: (cellValues) => (
//       <span style={{ paddingLeft: "6px" }}>
//         <VisibilityIcon
//           sx={{
//             '&:hover': {
//               color: 'blue',
//               textDecoration: 'underline',
//               cursor: 'pointer',
//             },
//           }}
//           style={{
//             width: '20px',
//             marginRight: '14px',
//           }}
//         />
//       </span>
//     ),
//   },
//   {
//     field: "ClaimStatus",
//     headerName: "CS",
//     disableColumnMenu: false,
//     disableExport: true,
//     sortable: true,
//     align: "left",
//     width: 70,
//     maxWidth: 70,
//     cellRenderer: (cellValues) => (
//       <span style={{ paddingLeft: "6px" }}>
//         <TaskAltOutlinedIcon
//           sx={{
//             '&:hover': {
//               color: 'blue',
//               textDecoration: 'underline',
//               cursor: 'pointer',
//             },
//           }}
//           style={{
//             width: '20px',
//             marginRight: '14px',
//           }}
//         />
//       </span>
//     ),
//   },
// ];
// const dynamicColumns =
//   rows.length > 0 &&
//   Object.keys(rows?.[0]).map((key) => ({
//     field: key,
//     headerName: key.toUpperCase(),
//     width: 150,
//     cellRenderer: (params) => (
//       <Tooltip title={params?.value}>
//         <span>{params?.value}</span>
//       </Tooltip>
//     ),
//   }));
// const columns = [...staticColumns, ...dynamicColumns];
//  Dynamic Rows Code End here 723 to 794 Don't remove this code
