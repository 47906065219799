// CaptureUpload

import { instance, Blobinstance } from "./axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Captur, CaptureUpload } from "./Urls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import sessionMessage from "../components/utils/sessionMessage";
import { persistor } from "../redux/store";

async function captureUpload(value) {
  try {
    let { data } = await instance.post(CaptureUpload, value);
    // let { data } = await instance.post(
    //   "https://qav.api.orbithc.net/api/v1/presign",
    //   value
    // );
    if (data.status.code == 200) {
      return captureSignUrl(data, value);
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
 persistor.purge();
      sessionMessage();
      window.location.href = "/login";
    } else {
      // toast.error(data?.status?.message);
      return data;
    }
  } catch (error) {
    // toast.error(error.message);
    return error;
  }
}

async function ConvertDataURLtoFile(dataurl, filename) {
  try {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new Blob([u8arr], {
      type: mime,
    });
    file.name = filename;

    return file;
  } catch (ex) {
    return ex;
  }
}

async function captureSignUrl(signData, inputData) {
  try {
    let { data } = await Blobinstance.put(
      signData.data.signedUrl,
      inputData.file
    );
    if (data == "") {
      return signData;
    } else {
      return "";
    }
  } catch (error) {
    return error;
  }
}

export function CaptureUploaddfront() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(captureUpload, {});
}
export function CaptureUploaddback() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(captureUpload, {});
}

export function UploadFileFun() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(captureUpload, {});
}

async function ProceedCapture(value) {
  try {
    const { data } = await instance.post(Captur, value);
    if (data.status.code == 200) {
      // const queryParams = new URLSearchParams();
      // queryParams.set("data", JSON.stringify(data));
      // window.location.href = `/patient-list/capture-driving-license/patient-info?${queryParams.toString()}`;
      sessionStorage.removeItem("captureData");
      sessionStorage.setItem("captureData", JSON.stringify(data));
      sessionStorage.setItem("captureDetailsData", null);
      // window.location.href =
      //   "/patient-list/capture-driving-license/patient-info";

      return data;
    } else if (data.status.code == -99999) {
      sessionStorage.clear();
      persistor.purge();
      // toast.error("Session expied");
      sessionMessage();
      window.location.href = "/login";
    } else {
      // console.log("else");
      return data;
      // toast.error(data?.status?.message)
    }
  } catch (error) {
    // console.log("error");
    // toast.error(error);
    return error;
  }
}

export function ProceedCapturee() {
  let qClient = useQueryClient();
  qClient.invalidateQueries("");
  return useMutation(ProceedCapture, {});
}
