import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Autocomplete,
} from "@mui/material";
import { Cancel, Sync, Download } from "@mui/icons-material";
import React, { useState, useEffect, useMemo } from "react";
import Notification from "../../components/Notification";
import { logTelemetry } from "../../logTelemetry";
import {
  GetDocAiListApi,
  UpdateConfigureApi,
} from "../../apis/docAiConfiguration";
import ClearStatesConfirmationDialog from "../importModule/ClearStatesConfirmationDialog";
import { useSelector } from "react-redux";

function UpdateConfiguration({ modelList, reqData }) {
  const { mutateAsync, isLoading } = GetDocAiListApi();
  const { mutateAsync: updateConfigApi } = UpdateConfigureApi();
  const loginDetails = useSelector((state) => state.loginDetails);

  let details = useMemo(() => {
    if (loginDetails) {
      return JSON.parse(loginDetails);
    }
    return null;
  }, [loginDetails]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [backUpData, setbackUpData] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });
  const intial = {
    open: false,
    title: "",
    subTitle: "",
    type: "",
  };
  const [dialogState, setDialogState] = useState(intial);
  const intialErrors = {
    fileTypeCode: false,
    fileTypeDesc: false,
    ModelType: false,
    sortOrder: false,
    apiPriority: false,
  };
  const [errors, setErrors] = useState(intialErrors);
  const [error, setError] = React.useState({
    apiPriority: false,
    sortOrder: false,
    // fileTypeJson: false,
  });

  useEffect(() => {
    if (reqData.sourceFileTypeID) {
      getDocAiList();
    } else if (Object.keys(reqData.recData).length > 0) {
      setData({
        ...reqData.recData,
        ModelType: reqData.recData?.modelType,
        fileTypeJson: reqData.recData?.fileTypeJson
          ? JSON.stringify(JSON.parse(reqData.recData?.fileTypeJson), null, 2)
          : "",
      });
      setbackUpData({
        ...reqData.recData,
        ModelType: reqData.recData?.modelType,
        fileTypeJson: reqData.recData?.fileTypeJson
          ? JSON.stringify(JSON.parse(reqData.recData?.fileTypeJson), null, 2)
          : "",
      });
    }
  }, []);

  const btnImport = () => {
    setNotify({
      isOpen: true,
      message: "This feature has not been implemented yet!",
      type: "warning",
    });
  };

  const btnConfigure = () => {
    if (!data?.fileTypeCode) {
      setErrors({
        ...errors,
        fileTypeCode: true,
      });
      setNotify({
        isOpen: true,
        message: "Please fill the File Type Code",
        type: "error",
      });
      return;
    }
    if (!data?.ModelType) {
      setErrors({
        ...errors,
        ModelType: true,
      });
      setNotify({
        isOpen: true,
        message: "Please select the Model Type field",
        type: "error",
      });
      return;
    }
    if (!data?.fileTypeDesc) {
      setErrors({
        ...errors,
        fileTypeDesc: true,
      });
      setNotify({
        isOpen: true,
        message: "Please fill the Description field",
        type: "error",
      });
      return;
    }
    if (!data?.sortOrder) {
      setErrors({
        ...errors,
        sortOrder: true,
      });
      setNotify({
        isOpen: true,
        message: "Please fill the Sort Order field",
        type: "error",
      });
      return;
    }
    if (!data?.apiPriority) {
      setErrors({
        ...errors,
        apiPriority: true,
      });
      setNotify({
        isOpen: true,
        message: "Please fill the Api Priority field",
        type: "error",
      });
      return;
    }
    // if (!data?.fileTypeJson) {
    //   setErrors({
    //     ...errors,
    //     fileTypeJson: true,
    //   });
    //   setNotify({
    //     isOpen: true,
    //     message: "Please fill the Json field",
    //     type: "error",
    //   });
    //   return;
    // }
    try {
      if (
        !data?.fileTypeJson ||
        typeof data?.fileTypeJson !== "string" ||
        !JSON.parse(data?.fileTypeJson)
      ) {
        throw new Error();
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message:
          "Invalid JSON provided. Please ensure the input is valid and non-empty.",
        type: "warning",
      });
      // setError({ ...error, fileTypeJson: true });
      setLoading(false);
      return;
    }
    updateConfig();
  };

  async function updateConfig() {
    setLoading(true);

    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        ConfigId: 91234,
        FileTypeID: data.fileTypeID ? data.fileTypeID : 0,
        FileTypeCode: data?.fileTypeCode,
        FileTypeDesc: data?.fileTypeDesc,
        VersionNo: data?.versionNo || "",
        ModelType: data?.ModelType,
        APIPriority: data?.apiPriority,
        SortOrder: data?.sortOrder,
        RecordStatus: data?.recordStatus ? 1 : 0,
        SourceFileTypeID: data?.sourceFileTypeID || "",
        FileTypeJson: data?.fileTypeJson,
      };

      const res = await updateConfigApi(payload);

      if (res?.status?.code === 200) {
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });

        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "UpdateConfiguration",
          "updateConfig",
          `${res?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        setLoading(false);
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "UpdateConfiguration",
        "updateConfig",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "updateConfig" + e.message,
        type: "error",
      });
    }
  }

  async function getDocAiList() {
    setLoading(true);
    try {
      const payload = {
        commonParams: {
          organizationID: details?.orgId,
          companyID: details?.companyId,
          coCode: details?.companyCode,
          groupID: details?.groupID,
          timeZone: details?.timeZone,
          sessionId: details?.sesionId,
          loginUserId: `${details?.userId}`,
          loginUserName: details?.userName,
          AuditTrail: 0,
          editTrail: 0,
          dataSource: "",
        },
        configId: "92437",
        moduleId: "0",
        SectionId: "0",
        RecordId: reqData.sourceFileTypeID, //RecordId northing but sourceFileTypeID
        RequestString: "0",
        UserId: "0",
        RequestType: "0",
      };
      const res = await mutateAsync(payload);

      if (res?.status?.code === 200) {
        const value = res?.data?.fileTypeList?.[0];
        setData({
          ...value,
          ModelType: value?.modelType,
          fileTypeJson: value?.fileTypeJson
            ? JSON.stringify(JSON.parse(value?.fileTypeJson), null, 2)
            : "",
        });
        setbackUpData({
          ...value,
          ModelType: value?.modelType,
          fileTypeJson: value?.fileTypeJson
            ? JSON.stringify(JSON.parse(value?.fileTypeJson), null, 2)
            : "",
        });
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "success",
        });

        setLoading(false);
      } else {
        logTelemetry(
          "failed",
          "UpdateConfiguration",
          "getDocAiList",
          `${res?.status?.message}`
        );
        setNotify({
          isOpen: true,
          message: `${res?.status?.message}`,
          type: "error",
        });
        setLoading(false);
      }
    } catch (e) {
      logTelemetry(
        "failed",
        "DocAiConfigurationList",
        "getDocAiList",
        e.message,
        e
      );
      console.error(e, "error");
      setLoading(false);
      setNotify({
        isOpen: true,
        message: "getDocAiList" + e.message,
        type: "error",
      });
    }
  }
  const handleConfirm = (title) => {
    if (title === "Attention Required") {
      setData({ ...backUpData });
      // versionNo: data?.versionNo, recordStatus: data?.recordStatus

      handleClose();
      setErrors(intialErrors);
    }
  };
  function handleClose() {
    setDialogState(intial);
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.value == "") {
      if (e.target.name != "fileTypeJson") {
        setErrors({
          ...errors,
          [e.target.name]: true,
        });
      }
    } else {
      setErrors({
        ...errors,
        [e.target.name]: false,
      });
      setError({
        ...error,
        [e.target.name]: false,
      });
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} className="mb-2 mt-3">
          <div>
            <Card>
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography variant="body" className="!mb-4 !ml-4">
                      Configuration Status :{" "}
                      <b>
                        {isLoading
                          ? "loading..."
                          : data?.recordStatus
                          ? "Active"
                          : reqData.sourceFileTypeID > 0
                          ? "InActive"
                          : "New"}
                      </b>
                    </Typography>
                    <Typography variant="body" className="!mb-4 !ml-20">
                      Version Number : <b>{data?.versionNo}</b>
                    </Typography>
                  </div>
                  <Box className="mb-4">
                    {reqData.fileTypeId == "" ? (
                      <Button
                        variant="outlined"
                        sx={{ ml: 1 }}
                        endIcon={<Download />}
                        onClick={btnImport}
                        className="button-styles !min-w-[118px]"
                      >
                        Import Setting
                      </Button>
                    ) : (
                      ""
                    )}

                    <Button
                      variant="outlined"
                      sx={{ ml: 1 }}
                      endIcon={<Sync />}
                      onClick={btnConfigure}
                      className="button-styles !min-w-[118px]"
                    >
                      Save & Configure
                    </Button>

                    <Button
                      variant="outlined"
                      sx={{ ml: 1 }}
                      // color="error"
                      endIcon={<Cancel />}
                      onClick={() => {
                        if (data) {
                          setDialogState({
                            open: true,
                            title: "Attention Required",
                            subTitle:
                              "Are you sure you want to clear all the fields ? ",
                            type: "double",
                          });
                        }
                      }}
                      className="button-styles !min-w-[118px] !mr-[16px]"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Grid item md={12} sm={12}>
                    <Divider variant="middle" />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid item md={4} sm={4} className="!mt-8 !ml-4">
                      <Grid container spacing={3}>
                        <Grid item sm={6}>
                          <TextField
                            id="outlined-fileTypeCode-input"
                            label="File Type Code"
                            name="fileTypeCode"
                            size="small"
                            value={data?.fileTypeCode ? data?.fileTypeCode : ""}
                            onChange={handleChange}
                            error={errors.fileTypeCode}
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Autocomplete
                            options={modelList}
                            value={
                              modelList.find(
                                (option) =>
                                  option.description === data.ModelType
                              ) || null
                            } // Ensure value is controlled
                            getOptionLabel={(option) => option.description}
                            isOptionEqualToValue={(option, value) =>
                              option.description === value.description
                            } // Compare by description
                            onChange={(e, newValue) => {
                              setData({
                                ...data,
                                ModelType: newValue
                                  ? newValue.description
                                  : null, // Handle case when cleared
                              });
                            }}
                            disableClearable
                            name="Select Model Type"
                            id="Select Model Type"
                            className=""
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Model Type"
                                variant="outlined"
                                name="ModelType"
                                id="Select Model Type"
                                size="small"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>

                        <Grid item sm={12}>
                          <TextField
                            id="outlined-fileTypeDesc-input"
                            label="Description"
                            name="fileTypeDesc"
                            size="small"
                            fullWidth
                            value={data?.fileTypeDesc ? data?.fileTypeDesc : ""}
                            onChange={handleChange}
                            error={errors.fileTypeDesc}
                          />
                        </Grid>

                        <Grid item sm={6}>
                          <TextField
                            id="outlined-sortOrder-input"
                            label="Sort Order"
                            name="sortOrder"
                            size="small"
                            fullWidth
                            value={data?.sortOrder ? data?.sortOrder : ""}
                            onChange={handleChange}
                            error={errors.sortOrder}
                            helperText={
                              error.sortOrder
                                ? "This field takes number only"
                                : ""
                            }
                            inputProps={{
                              pattern: "[0-9]*", // Allow only digits
                              inputMode: "numeric", // Show numeric keyboard on mobile devices
                              onKeyPress: (event) => {
                                // Prevent non-numeric characters
                                if (!/[0-9]/.test(event.key)) {
                                  setError({ ...error, sortOrder: true });
                                  event.preventDefault();
                                } else {
                                  setError({ ...error, sortOrder: false });
                                }
                              },
                            }}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <TextField
                            id="outlined-apiPriority-input"
                            label="API Priority"
                            name="apiPriority"
                            size="small"
                            fullWidth
                            value={data?.apiPriority ? data?.apiPriority : ""}
                            onChange={handleChange}
                            error={errors.apiPriority}
                            helperText={
                              error.apiPriority
                                ? "This field takes number only"
                                : ""
                            }
                            inputProps={{
                              pattern: "[0-9]*", // Allow only digits
                              inputMode: "numeric", // Show numeric keyboard on mobile devices
                              onKeyPress: (event) => {
                                // Prevent non-numeric characters
                                if (!/[0-9]/.test(event.key)) {
                                  setError({ ...error, apiPriority: true });
                                  event.preventDefault();
                                } else {
                                  setError({ ...error, apiPriority: false });
                                }
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={7} sm={7} className="!mt-8 !mr-4">
                      <div className="textAreaDynHgt">
                        <TextField
                          id="outlined-fileTypeJson-static"
                          label="JSON"
                          name="fileTypeJson"
                          multiline
                          value={data?.fileTypeJson ? data?.fileTypeJson : ""}
                          fullWidth
                          onChange={handleChange}
                          // error={errors.fileTypeJson || error.fileTypeJson}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#1a73e8", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Notification notify={notify} setNotify={setNotify} />
      <ClearStatesConfirmationDialog
        onClose={() => handleClose()}
        onConfirm={handleConfirm}
        open={dialogState.open}
        title={dialogState.title}
        subTitle={dialogState.subTitle}
        type={dialogState.type}
      />
    </div>
  );
}

export default UpdateConfiguration;
