import React from "react";
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    styled,
} from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import "../../assets/style/taskSummary.css";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&::before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            Boolean(props?.section?.substatusList.length) ? (
                <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
            ) : (
                <ArrowForwardIosSharpIcon
                    sx={{ fontSize: "0.9rem", visibility: "hidden" }}
                />
            )
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "rgba(0, 0, 0, .03)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function TaskSummaryGrid({ list, header }) {
    return (
        <TableContainer component={Paper} className="docSumHgt mTableCustom table-container-color">
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow className="table-body">
                        {header?.length > 0 &&
                            header?.map((heading, index) => (
                                <TableCell
                                    key={index}
                                    align={index === 0 ? "inherit" : "right"}
                                    sx={{
                                        fontSize: "1rem",
                                        height: 55,
                                    }}
                                >
                                    {heading?.colName ? heading.colName : heading}
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list?.length > 0 ? (
                        list.map((section, index) => (
                            <React.Fragment key={index}>
                                <TableRow
                                    key={index}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? "#ffdddd" : "#ddffdd", 
                                        color: "black", 
                                    }}
                                >
                                    <TableCell
                                        className="level0"
                                        colSpan={header?.length}
                                        style={{ padding: 0 }}
                                    >
                                        <Accordion>
                                            <AccordionSummary section={section} className="table-head-color">
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow className="level1">
                                                            <TableCell className="text-left">
                                                                {section?.orderStatus}
                                                            </TableCell>

                                                            {header.slice(1).map((item, index, arr) => (
                                                                <TableCell
                                                                    key={index}
                                                                    align="right"
                                                                    sx={{ minWidth: 30 }}
                                                                >
                                                                    {index === 0
                                                                        ? section.sum
                                                                        : arr.length === index + 1
                                                                            ? section["binN"]
                                                                            : section["bin" + index]}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </AccordionSummary>
                                            {section?.substatusList?.length > 0 ? (
                                                <AccordionDetails sx={{ padding: 0 }}>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {section?.substatusList?.map((sub, subIndex) => (
                                                                <TableRow
                                                                    key={subIndex}
                                                                    sx={{
                                                                        backgroundColor:
                                                                            subIndex % 2 === 0 ? "#e1e1e1" : "#f0f0f0", // Alternating background colors for sub rows
                                                                        color: "black",
                                                                    }}
                                                                    className="level2 table-body"
                                                                >
                                                                    <TableCell className="text-left">
                                                                        {sub?.orderSubStatus}
                                                                    </TableCell>
                                                                    {header.slice(1).map((item, index, arr) => (
                                                                        <TableCell
                                                                            key={index}
                                                                            align="right"
                                                                            sx={{ minWidth: 30 }}
                                                                        >
                                                                            {index === 0
                                                                                ? sub.sum
                                                                                : arr.length === index + 1
                                                                                    ? sub["binN"]
                                                                                    : sub["bin" + index]}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </AccordionDetails>
                                            ) : null}
                                        </Accordion>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={header.length} className="centerMiddle">
                                <Typography align="center">No Data Available</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TaskSummaryGrid;
